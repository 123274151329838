import moment from "moment-timezone";

export const mixin = {
         methods: {
           $_formatMoney(value) {
             if (value || value == 0) {
               const formatter = new Intl.NumberFormat("en-US", {
                 style: "currency",
                 currency: "USD",
                 minimumFractionDigits: 2
               });

               return formatter.format(value);
             }
           },

           $_scrubMoney(value) {
             if (value) {
               return value.replace(/\D/g, "");
             }
           },

          $_formatPercentage(value) {
            if (value) {
              let decimalNum = "";
              //capture digits after decimal if value contains a decimal
              if (value % 1 !== 0) {
                decimalNum = value.toString().split(".")[1];

                //Remove Trailing 0's
                decimalNum = decimalNum.replaceAll(/0*$/g, "");
              } else {
                decimalNum = value.toString();
              }
              //show decimal if decimal value has more than 2 digits
              if (decimalNum.length > 2) {
                return `${(value * 100).toFixed(2)}%`;
              } else {
                return `${(value * 100).toFixed(0)}%`;
              }
            }
          },

           $_formatDate(date) {
             if (!date) return "";
             const [year, month, day] = date.split(/[\\\/|-]/);
             return `${month}/${day}/${year}`;
           },

           $_formatStringDate(j_date) {
             //initially clear string of special characters and letters
             var date = j_date.replace(/\D/g, "");
             var formattedDate = "";
             //insert the property date special characters
             if (date.length == 8 && date.slice(0, 2) < 13 && date.slice(2, 4) < 32) {
               formattedDate = date.slice(0, 2) + "/" + date.slice(2, 4) + "/" + date.slice(4);
             }
             //return blank string if digit combination does not constitute an actual date
             return formattedDate;
           },

           $_dateExpired(dateVal) {
             if (!dateVal) return "";

             var today = new Date();
             if (Date.parse(dateVal) < Date.parse(today)) {
               return 1;
             } else {
               return 0;
             }
           },

           $_formatDateMonth(date) {
             if (!date) return "";
             const [year, month] = date.split(/[\\\/|-]/);
             return `${month}/${year}`;
           },

           $_parseDate(date) {
             if (!date) return "";
             const [month, day, year] = date.split(/[\\\/|-]/);
             return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
           },

           $_parseDateMonth(date) {
             if (!date) return "";
             const [month, year] = date.split(/[\\\/|-]/);
             return `${year}-${month.padStart(2, "0")}`;
           },

           $_dateOfNextMonth(day) {
              let newDate = new Date();
              newDate.setMonth(newDate.getMonth() + 1,day);
              return newDate.toISOString().split('T')[0];
           },

           $_timestampToDate(value) {
             if (value) {
               return moment(value, moment.ISO_8601, true)
                 .tz("EST")
                 .format("MM/DD/YYYY");
             }
           },

           $_formatTimestamp(value) {
             if (value) {
               return moment(value, moment.ISO_8601, true)
                 .tz("EST")
                 .format("MM/DD/YYYY h:mm A");
             }
           },

           $_leapYear(year) {
             return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
           },

           $_getCurrentDate() {
             //format MM/DD/YYYY
             var date = new Date();
             return (
               ("0" + (date.getMonth() + 1)).slice(-2) +
               "/" +
               ("0" + date.getDate()).slice(-2) +
               "/" +
               date.getFullYear()
             );
             //created 6/2/2021 BG
           },

           $_monthNumToText(val) {
             if (val === 1) {
               return "January";
             }
             if (val === 2) {
               return "February";
             }
             if (val === 3) {
               return "March";
             }
             if (val === 4) {
               return "April";
             }
             if (val === 5) {
               return "May";
             }
             if (val === 6) {
               return "June";
             }
             if (val === 7) {
               return "July";
             }
             if (val === 8) {
               return "August";
             }
             if (val === 9) {
               return "September";
             }
             if (val === 10) {
               return "October";
             }
             if (val === 11) {
               return "November";
             }
             if (val === 12) {
               return "December";
             }
           },

           $_formatPhone(phone) {
             if (phone) {
              phone = phone.replace(/[^0-9]/g, "");
              let ext = "";
              if (phone.length > 10) {
                ext = " ext." + phone.slice(10);
              }
              return phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6, 10) + ext;
             }
           },

           $_formatStateInitials(state) {
             if (state) {
               return state.replace(/[^a-zA-Z ]/g, "").toUpperCase();
             }
           },

           $_switchRating(rating) {
             if (rating === "1 STAR" || rating === 1) {
               return 1;
             }
             if (rating === "2 STAR" || rating === 2) {
               return 2;
             }
             if (rating === "3 STAR" || rating === 3) {
               return 3;
             }
             if (rating === "4 STAR" || rating === 4) {
               return 4;
             }
             if (rating === "5 STAR" || rating === 5) {
               return 5;
             }

             return 0;
           },

           $_switchProviderStatus(value) {
             if (value === 1) {
               return "Prospective";
             }
             if (value === 2) {
               return "Active";
             }
             if (value === 3) {
               return "Under Review";
             }
             return "Inactive";
           },

           $_switchCarePlanStatus(value) {
             if (value === 1) {
               return "Pending";
             }
             if (value === 2) {
               return "Submitted For Review";
             }
             if (value === 3) {
               return "Returned For Correction";
             }
             if (value === 4) {
               return "Approved";
             }
             if (value === 5) {
               return "Denied";
             }
             if (value === 6) {
               return "Terminated";
             }
           },

           $_switchProviderType(value) {
             if (value === 1) {
               return "Center";
             }
             if (value === 2) {
               return "Family CC Home";
             }
             if (value === 3) {
              return "Unlicensed Program";
            }
           },

           $_switchGeneralSection(value) {
             if (value === 1) {
               return "Provider";
             }
             if (value === 2) {
               return "Client";
             }
             if (value === 3) {
               return "Teacher";
             }
             if (value === 4) {
              return "Waiting List";
            }
           },

           $_switchClassification(value) {
             if (value === 1) {
               return "FPL";
             }
             if (value === 2) {
               return "SMI";
             }
             if (value === 3) {
               return "SMI NC-PreK";
            }
           },

           $_checkNull(value) {
             if (value === null) {
               return "";
             }
             return value;
           },

           $_activeStatus(value) {
             if (value === 1 || value === true) {
               return "Active";
             }
             return "Inactive";
           },

           $_yesNo(value) {
             if (value === 1 || value === true) {
               return "Yes";
             }
             if (value === 0 || value === false) {
               return "No";
             }
             return "";
           },

           $_taskStatus(value) {
              if (value === 2) {
                return "Open";
              }
              if (value === 1) {
                return "Completed";
              }
              if (value === 0 || value === false) {
                return "Voided";
              }
           },

           $_actionStatus(value) {
             if (value === 1 || value === true) {
               return "Open";
             }
             return "Completed";
           },

           $_clientImportStatus(value) {
             if (value === 1) {
               return "Pending Import";
             }
             if (value === 2) {
               return "Imported - New Household";
             }
             if (value === 4) {
               return "Skipped";
             }
             if (value === 5) {
               return "Imported - Match Household";
             }
             if (value === 6) {
               return "Ignore";
             }
             return "Error";
           },

           $_attendanceStatus(value) {
             if (value === 1) {
               return "Pending";
             }
             if (value === 2) {
               return "Denied";
             }
             return "Approved";
           },

           $_teacherReviewStatus(value) {
             if (value === 1) {
               return "Pending";
             }
             if (value === 2) {
               return "Denied";
             }
             return "Approved";
           },

           $_paymentStatus(value) {
             if (value === 1) {
               return "Pending";
             }
             if (value === 2) {
               return "Error";
             }
             if (value === 3) {
               return "Approved";
             }
             return "Voided";
           },

           $_teacherPaymentStatus(value) {
             if (value === 1) {
               return "Pending";
             }
             if (value === 2) {
               return "Error";
             }
             if (value === 3) {
               return "Approved";
             }
             return "Voided";
           },

           $_carePlanItemStatus(value) {
             if (value === 1) {
               return "Pending";
             }
             if (value === 2) {
               return "Submitted For Review";
             }
             if (value === 3) {
               return "Returned For Correction";
             }
             if (value === 4) {
               return "Approved";
             }
             if (value === 5) {
               return "Denied";
             }
           },

           $_applicationStatus(value) {
             if (value === 1 || value === true) {
               return "Pending";
             }
             return "Completed";
           },

           $_fullName(first, middle, last) {
             let name = first;
             if (middle) {
               name += ` ${middle}`;
             }
             name += ` ${last}`;
             return name;
           },

           $_calculatedAge(dateBirth, dateEvaluate) {
             if (dateBirth && dateEvaluate) {
               const offset1 = Date.parse(dateEvaluate);
               if (offset1 === NaN) {
                 return "";
               }
               const offset = new Date(offset1);

               const birthDate1 = Date.parse(dateBirth);

               if (birthDate1 === "NaN") {
                 return "";
               }
               const birthDate = new Date(birthDate1);

               let age = offset.getFullYear() - birthDate.getFullYear();
               const month = offset.getMonth() - birthDate.getMonth();
               if (month < 0 || (month === 0 && offset.getDate() + 1 < birthDate.getDate())) {
                 --age;
               }
               if (!age) {
                 age = 0;
               }
               return age.toString();
             }

             return "";
           },

           $_checkSection(data) {
             let i;
             for (i = 0; i < data.length; i++) {
               if (this.section == data[i].applicant.f_type) {
                 return true;
               }
             }
           },

           $_firstInitial(value) {
             if (value) {
               return value.charAt(0);
             }
           },

           $_switchAuditSection(value) {
             if (value === 1) {
               return "Accounting Code";
             }
             if (value === 2) {
               return "Alert Type";
             }
             if (value === 3) {
               return "Alert";
             }
             if (value === 4) {
               return "Age Category";
             }
             if (value === 5) {
               return "Agency";
             }
             if (value === 6) {
               return "Applicant";
             }
             if (value === 7) {
               return "Application";
             }
             if (value === 8) {
               return "Attendance";
             }
             if (value === 9) {
               return "Attendance Item";
             }
             if (value === 10) {
               return "Bookmark";
             }
             if (value === 11) {
               return "Care Plan Option";
             }
             if (value === 12) {
               return "Care Plan";
             }
             if (value === 13) {
               return "Classroom History";
             }
             if (value === 14) {
               return "Classroom";
             }
             if (value === 15) {
               return "Client";
             }
             if (value === 16) {
               return "Contact Method";
             }
             if (value === 17) {
               return "Contact";
             }
             if (value === 18) {
               return "Correspondence";
             }
             if (value === 19) {
               return "County";
             }
             if (value === 20) {
               return "Dependent";
             }
             if (value === 21) {
               return "Document Type";
             }
             if (value === 22) {
               return "Document";
             }
             if (value === 23) {
               return "Education";
             }
             if (value === 24) {
               return "Employment";
             }
             if (value === 25) {
               return "Facility";
             }
             if (value === 26) {
               return "Funding Source";
             }
             if (value === 27) {
               return "Help Topic";
             }
             if (value === 28) {
               return "Household";
             }
             if (value === 29) {
               return "Income";
             }
             if (value === 30) {
               return "FPL/SMI";
             }
             if (value === 31) {
               return "Merge Code Section";
             }
             if (value === 32) {
               return "Merge Code";
             }
             if (value === 33) {
               return "Message Board";
             }
             if (value === 34) {
               return "Note Type";
             }
             if (value === 35) {
               return "Note";
             }
             if (value === 36) {
               return "Note";
             }
             if (value === 37) {
               return "Program Coding";
             }
             if (value === 38) {
               return "Program Option";
             }
             if (value === 39) {
               return "Program";
             }
             if (value === 40) {
               return "Provider";
             }
             if (value === 41) {
               return "Rate";
             }
             if (value === 42) {
               return "Rating";
             }
             if (value === 43) {
               return "Report";
             }
             if (value === 44) {
               return "Task Type";
             }
             if (value === 45) {
               return "Task";
             }
             if (value === 46) {
               return "Template";
             }
             if (value === 47) {
               return "Time Option";
             }
             if (value === 48) {
               return "Tuition";
             }
             if (value === 49) {
               return "User";
             }
             if (value === 50) {
               return "Value List Item";
             }
             if (value === 51) {
               return "Value List";
             }
             if (value === 52) {
               return "Waiting List";
             }
             if (value === 53) {
               return "Year";
             }
             if (value === 54) {
               return "Teacher";
             }
             if (value === 55) {
               return "Teacher License";
             }
           },

           $_clearSpaces(value) {
             return value.replace(/\s+/g, "");
           },

           async $_recordNavigation(section, mode, idx) {
             this.$store.dispatch("progressStore/set", true);

             let records;
             /* Grab records from found set */
             /* ---------- Facility section ----------*/
             if (section === 1) {
               records = this.$store.getters["facilitiesStore/getFacilities"];
             } else if (section === 2) {
               /* ---------- Provider section ----------*/
               records = this.$store.getters["providersStore/getProviders"];
             } else if (section === 3) {
               /* ---------- Client section ----------*/
               records = this.$store.getters["clientsStore/getClients"];
             } else if (section === 4) {
               /* ---------- Teacher section ----------*/
               records = this.$store.getters["teachersStore/getTeachers"];
             } else if (section === 5) {
               /* ---------- Waiting List section ----------*/
               records = this.$store.getters["waitingListStore/getWaitingList"];
            }

             let index = idx;

             /* If new list */
             if (
               (mode === 1 && records.current_page !== 1) ||
               index === -1 ||
               index === records.per_page ||
               (mode === 4 && records.current_page !== records.last_page)
             ) {
               let filters;
               let data;
               /* Grab current filters for new search */
               /* ---------- Facility section ----------*/
               if (section === 1) {
                 filters = this.$store.getters["facilitiesStore/getFilters"];
                 data = {
                   ffacid: filters.ffacid,
                   ffacname: filters.ffacname,
                   fcounty: filters.fcounty,
                   foptype: filters.foptype,
                   starrating: filters.starrating,
                   fstatus: filters.fstatus
                 };
               } else if (section === 2) {
                 /* ---------- Provider section ----------*/
                 filters = this.$store.getters["providersStore/getFilters"];
                 data = {
                   fk_userID: filters.fk_userID,
                   d_licenseNumber: filters.d_licenseNumber,
                   d_nameLicense: filters.d_nameLicense,
                   fk_countyID: filters.fk_countyID,
                   f_type: filters.f_type,
                   fk_ratingID: filters.fk_ratingID,
                   f_status: filters.f_status
                 };
               } else if (section === 3) {
                 /* ---------- Client section ----------*/
                 filters = this.$store.getters["clientsStore/getFilters"];
                 data = {
                   id: filters.id,
                   fk_userID: filters.fk_userID,
                   fk_providerID: filters.fk_providerID,
                   d_childIDv1: filters.d_childIDv1,
                   fk_householdID: filters.fk_householdID,
                   d_applicantIDv1: filters.d_applicantIDv1,
                   d_nameLast: filters.d_nameLast,
                   d_nameFirst: filters.d_nameFirst,
                   d_nameDisplay: filters.d_nameDisplay,
                   fk_counselorID: filters.fk_counselorID,
                   f_status: filters.f_status,
                   primaryApplicant: filters.primaryApplicant
                 };
               } else if (section === 4) {
                 /* ---------- Teacher section ----------*/
                 filters = this.$store.getters["teachersStore/getFilters"];
                 data = {
                   fk_userID: filters.fk_userID,
                   d_nameFirst: filters.d_nameFirst,
                   d_nameLast: filters.d_nameLast,
                   d_nameDisplay: filters.d_nameDisplay,
                   f_type: filters.f_type,
                   f_status: filters.f_status
                 };
               } else if (section === 5) {
                /* ---------- Waiting List section ----------*/
                filters = this.$store.getters["waitingListStore/getFilters"];
                data = {
                  d_wlsID: filters.d_wlsID,
                  d_priority: filters.d_priority,
                  f_incomeRank: filters.f_incomeRank,
                  d_nameFirst: filters.d_nameFirst,
                  d_nameLast: filters.d_nameLast,
                  d_residenceCounty: filters.d_residenceCounty,
                  fk_userID: filters.fk_userID,
                  fk_counselorID: filters.fk_counselorID,
                  d_nameInitialCounselor: filters.d_nameInitialCounselor,
                  f_status: filters.f_status,
                };
              }

               /* First function and not on the first page */
               if (mode === 1 && records.current_page !== 1) {
                 data.page = 1;
               } else if (index === -1) {
                 /* Previous function when on the first record of the current list and going to a record on the previous page */
                 data.page = records.current_page - 1;
                 index = records.per_page - 1;
               } else if (index === records.per_page) {
                 /* Next function when on the last record of the current list and going to a record on the next list */
                 data.page = records.current_page + 1;
                 index = 0;
               } else if (mode === 4 && records.current_page !== records.last_page) {
                 /* Last function and not on the last page */
                 data.page = records.last_page;
               }

               /* Fetch new list, store filters and grab records */
               /* ---------- Facility section ----------*/
               if (section === 1) {
                 await this.$store.dispatch("facilitiesStore/fetch", data);
                 this.$store.dispatch("facilitiesStore/storeFilters", data);
                 records = this.$store.getters["facilitiesStore/getFacilities"];
               } else if (section === 2) {
                 /* ---------- Provider section ----------*/
                 await this.$store.dispatch("providersStore/fetch", data);
                 this.$store.dispatch("providersStore/storeFilters", data);
                 records = this.$store.getters["providersStore/getProviders"];
               } else if (section === 3) {
                 /* ---------- Client section ----------*/
                 await this.$store.dispatch("clientsStore/fetch", data);
                 this.$store.dispatch("clientsStore/storeFilters", data);
                 records = this.$store.getters["clientsStore/getClients"];
               } else if (section === 4) {
                 /* ---------- Teacher section ----------*/
                 await this.$store.dispatch("teachersStore/fetch", data);
                 this.$store.dispatch("teachersStore/storeFilters", data);
                 records = this.$store.getters["teachersStore/getTeachers"];
               } else if (section === 5) {
                /* ---------- Waiting List section ----------*/
                await this.$store.dispatch("waitingListStore/fetch", data);
                this.$store.dispatch("waitingListStore/storeFilters", data);
                records = this.$store.getters["waitingListStore/getWaitingList"];
              }
             }

             /* First function */
             if (mode === 1) {
               index = 0;
             } else if (mode === 4) {
               /* Last function */
               index = records.total - records.from;
             }

             /* ---------- Facility section ----------*/
             let record;
             let user;
             let data2;
             if (section === 1) {
               /* Grab record uuid based on index */
               this.$store.dispatch("facilitiesStore/readByIndex", index);
               /* Grab record */
               record = this.$store.getters["facilitiesStore/getFacility"];
               /* Set current index for record navigation */
               this.$store.dispatch("facilitiesStore/currentIndex", record.uuid);
               /* Pull latest data of record */
               await this.$store.dispatch("facilitiesStore/read", record.uuid);
             } else if (section === 2) {
               /* ---------- Provider section ----------*/
               /* Grab record uuid based on index */
               this.$store.dispatch("providersStore/readByIndex", index);
               /* Grab record */
               record = this.$store.getters["providersStore/getProvider"];
               /* Set current index for record navigation */
               this.$store.dispatch("providersStore/currentIndex", record.uuid);
               /* Pull latest data of record */
               await this.$store.dispatch("providersStore/read", record.uuid);
               this.$router.push({
                 name: "provider",
                 params: { providerUUID: record.uuid }
               });

               /* Refresh bookmark */
               user = this.$store.getters["usersStore/getAuthUser"];
               const provider = this.$store.getters["providersStore/getProvider"];
               data2 = {
                 fk_providerID: provider.id,
                 fk_userID: user.id
               };
               await this.$store.dispatch("bookmarksStore/detail", data2);
             } else if (section === 3) {
               /* ---------- Client section ----------*/
               /* Grab record uuid based on index */
               this.$store.dispatch("clientsStore/readByIndex", index);
               /* Grab record */
               record = this.$store.getters["clientsStore/getClient"];
               /* Set current index for record navigation */
               this.$store.dispatch("clientsStore/currentIndex", record.uuid);
               /* Pull latest data of record */
               await this.$store.dispatch("clientsStore/read", record.uuid);
               this.$router.push({ name: "client", params: { clientUUID: record.uuid } });

               /* Refresh bookmark */
               user = this.$store.getters["usersStore/getAuthUser"];
               const client = this.$store.getters["clientsStore/getClient"];
               data2 = {
                 fk_clientID: client.id,
                 fk_userID: user.id
               };
               await this.$store.dispatch("bookmarksStore/detail", data2);
             } else if (section === 4) {
               /* ---------- Teacher section ----------*/
               /* Grab record uuid based on index */
               this.$store.dispatch("teachersStore/readByIndex", index);
               /* Grab record */
               record = this.$store.getters["teachersStore/getTeacher"];
               /* Set current index for record navigation */
               this.$store.dispatch("teachersStore/currentIndex", record.uuid);
               /* Pull latest data of record */
               await this.$store.dispatch("teachersStore/read", record.uuid);
               this.$router.push({
                 name: "teacher",
                 params: { teacherUUID: record.uuid }
               });

               /* Refresh bookmark */
               user = this.$store.getters["usersStore/getAuthUser"];
               const teacher = this.$store.getters["teachersStore/getTeacher"];
               data2 = {
                 fk_teacherID: teacher.id,
                 fk_userID: user.id
               };
               await this.$store.dispatch("bookmarksStore/detail", data2);
             } else if (section === 5) {
              /* ---------- WaitingList section ---------- */
              /* Grab record uuid based on index */
              this.$store.dispatch("waitingListStore/readByIndex", index);
              /* Grab record */
              record = this.$store.getters["waitingListStore/getWaitingListItem"];
              /* Set current index for record navigation */
              this.$store.dispatch("waitingListStore/currentIndex", record.uuid);
              /* Pull latest data of record */
              await this.$store.dispatch("waitingListStore/read", record.uuid);
              this.$router.push({
                name: "waitingListItem",
                params: { waitingListUUID: record.uuid }
              });
              /* Refresh bookmark */
              user = this.$store.getters["usersStore/getAuthUser"];
              const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
              data2 = {
                fk_waitingListID: waitingListItem.id,
                fk_userID: user.id
              };
              await this.$store.dispatch("bookmarksStore/detail", data2);
            }

             this.$store.dispatch("progressStore/set", false);
           }
         }
       };
