import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  providers: [],
  providersSearch: [],
  provider: null,
  providersValueList: [],
  export: [],
  entry: {
    show: false,
    show2: false,
    show3: false,
    show4: false,
    valid: '',
    mode: '',
    id: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_countyID: '',
    fk_ratingID: '',
    d_nameLicense: '',
    d_nameFederal: '',
    d_licenseNumber: '',
    d_taxpayerID: '',
    d_website: '',
    d_email: '',
    d_emailPayment: '',
    d_facilityStreet1: '',
    d_facilityStreet2: '',
    d_facilityCity: '',
    d_facilityState: '',
    d_facilityPostalCode: '',
    d_businessStreet1: '',
    d_businessStreet2: '',
    d_businessCity: '',
    d_businessState: '',
    d_businessPostalCode: '',
    d_facilityPhone: '',
    d_facilityFax: '',
    d_businessPhone: '',
    d_businessFax: '',
    d_bankRoutingNumber: '',
    d_bankAccountNumber: '',
    d_mailingsAttendance: '',
    d_mailingsPayment: '',
    d_overageType: '',
    d_referralID: '',
    d_referralReason: '',
    d_datePacketUpdate: '',
    d_directDepositName: '',
    d_specialRequests: '',
    f_textingOptIn: '',
    d_textingPhone: '',
    d_textingName: '',
    d_contractedStatus: '',
    j_prekSetting: '',
    d_assignedTA: '',
    f_sameAddress: '',
    f_overage: '',
    f_onReferral: '',
    f_parentFee: '',
    f_type: '',
    f_status: '',
    date1: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_userID: '',
    d_licenseNumber: '',
    d_nameLicense: '',
    fk_countyID: '',
    f_type: '',
    fk_ratingID: '',
    f_status: '',
    d_email: '',
    fk_actionTypeID: '',
    contactName: '',
    d_contractedStatus: '',
    fk_reportID: '',
    f_month: '',
    d_year: '',
  },
  currentIndex: 0,
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_PROVIDER(state) {
    state.provider = '';
  },
  CLEAR_PROVIDERS(state) {
    state.providers = [];
  },
  FETCH(state, { list }) {
    state.providers = list;
  },
  FETCH_SEARCH(state, { list }) {
    state.providersSearch = list;
  },
  CREATE(state, { data }) {
    state.provider = data;
  },
  READ(state, { data }) {
    state.provider = data;
  },
  UPDATE(state, { data }) {
    state.provider = data;
  },
  DELETE(state, { id }) {
    const idx = state.providers.data.map((item) => item.id).indexOf(id);
    state.providers.data.splice(idx, 1);
  },
  CURRENT_INDEX(state, { uuid }) {
    const idx = state.providers.data.map((item) => item.uuid).indexOf(uuid);
    state.currentIndex = idx;
  },
  READ_BY_INDEX(state, { index }) {
    state.provider = state.providers.data[index];
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_countyID = data.fk_countyID;
    state.entry.fk_ratingID = data.fk_ratingID;
    state.entry.d_nameLicense = data.d_nameLicense;
    state.entry.d_nameFederal = data.d_nameFederal;
    state.entry.d_licenseNumber = data.d_licenseNumber;
    state.entry.d_taxpayerID = data.d_taxpayerID;
    state.entry.d_website = data.d_website;
    state.entry.d_email = data.d_email;
    state.entry.d_emailPayment = data.d_emailPayment;
    state.entry.d_facilityStreet1 = data.d_facilityStreet1;
    state.entry.d_facilityStreet2 = data.d_facilityStreet2;
    state.entry.d_facilityCity = data.d_facilityCity;
    state.entry.d_facilityState = data.d_facilityState;
    state.entry.d_facilityPostalCode = data.d_facilityPostalCode;
    state.entry.d_businessStreet1 = data.d_businessStreet1;
    state.entry.d_businessStreet2 = data.d_businessStreet2;
    state.entry.d_businessCity = data.d_businessCity;
    state.entry.d_businessState = data.d_businessState;
    state.entry.d_businessPostalCode = data.d_businessPostalCode;
    state.entry.d_facilityPhone = data.d_facilityPhone;
    state.entry.d_facilityFax = data.d_facilityFax;
    state.entry.d_businessPhone = data.d_businessPhone;
    state.entry.d_businessFax = data.d_businessFax;
    state.entry.d_bankRoutingNumber = data.d_bankRoutingNumber;
    state.entry.d_bankAccountNumber = data.d_bankAccountNumber;
    state.entry.d_mailingsAttendance = data.d_mailingsAttendance;
    state.entry.d_mailingsPayment = data.d_mailingsPayment;
    state.entry.d_overageType = data.d_overageType;
    state.entry.d_referralID = data.d_referralID;
    state.entry.d_referralReason = data.d_referralReason;
    state.entry.date1 = data.d_datePacketUpdate;
    state.entry.d_directDepositName = data.d_directDepositName;
    state.entry.d_specialRequests = data.d_specialRequests;
    state.entry.f_textingOptIn = data.f_textingOptIn;
    state.entry.d_textingPhone = data.d_textingPhone;
    state.entry.d_textingName = data.d_textingName;
    state.entry.d_contractedStatus = data.d_contractedStatus;
    state.entry.j_prekSetting = data.j_prekSetting;
    state.entry.d_assignedTA = data.d_assignedTA;
    state.entry.f_sameAddress = data.f_sameAddress === 1;
    state.entry.f_overage = data.f_overage;
    state.entry.f_onReferral = data.f_onReferral;
    state.entry.f_parentFee = data.f_parentFee;
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    // state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_countyID = '';
    state.entry.fk_ratingID = '';
    state.entry.d_nameLicense = '';
    state.entry.d_nameFederal = '';
    state.entry.d_licenseNumber = '';
    state.entry.d_taxpayerID = '';
    state.entry.d_website = '';
    state.entry.d_email = '';
    state.entry.d_emailPayment = '';
    state.entry.d_facilityStreet1 = '';
    state.entry.d_facilityStreet2 = '';
    state.entry.d_facilityCity = '';
    state.entry.d_facilityState = '';
    state.entry.d_facilityPostalCode = '';
    state.entry.d_businessStreet1 = '';
    state.entry.d_businessStreet2 = '';
    state.entry.d_businessCity = '';
    state.entry.d_businessState = '';
    state.entry.d_businessPostalCode = '';
    state.entry.d_facilityPhone = '';
    state.entry.d_facilityFax = '';
    state.entry.d_businessPhone = '';
    state.entry.d_businessFax = '';
    state.entry.d_bankRoutingNumber = '';
    state.entry.d_bankAccountNumber = '';
    state.entry.d_mailingsAttendance = '';
    state.entry.d_mailingsPayment = '';
    state.entry.d_overageType = '';
    state.entry.d_referralID = '';
    state.entry.d_referralReason = '';
    state.entry.d_datePacketUpdate = '';
    state.entry.d_directDepositName = '';
    state.entry.d_specialRequests = '';
    state.entry.f_textingOptIn = '';
    state.entry.d_textingPhone = '';
    state.entry.d_textingName = '';
    state.entry.d_contractedStatus = '';
    state.entry.j_prekSetting = '';
    state.entry.d_assignedTA = '';
    state.entry.f_sameAddress = '';
    state.entry.f_overage = '';
    state.entry.f_onReferral = '';
    state.entry.f_parentFee = '';
    state.entry.f_type = '';
    state.entry.f_status = '';
    state.entry.date1 = '';
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.providersValueList = list;
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearProvider: ({ commit }) => {
    commit('CLEAR_PROVIDER');
  },
  clearProviders: ({ commit }) => {
    commit('CLEAR_PROVIDERS');
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/providers', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchSearch({ commit }, data) {
    try {
      const response = await axios.post('/api/providers', data, { headers: getHeader() });
      commit('FETCH_SEARCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/providers/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/providers/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async readByID({ commit }, id) {
    try {
      const response = await axios.get(`/api/providersbyid/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/providers/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/providers/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/providers/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get('/api/providersvaluelist', { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async currentIndex({ commit }, uuid) {
    try {
      commit('CURRENT_INDEX', { uuid });
    } catch (error) {

    }
  },
  async readByIndex({ commit }, index) {
    try {
      commit('READ_BY_INDEX', { index });
    } catch (error) {

    }
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post('/api/providers', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getProviders: (state, getters) => state.providers,
  getProvidersSearch: (state, getters) => state.providersSearch,
  getProvider: (state, getters) => state.provider,
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
