<template>
  <div>
    <v-tooltip
      v-if="datePickerSection > 0"
      top
      :color="enabled === true ? 'error' : 'grey lighten-1'"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openDatePicker(datePickerSection, providersStore.provider.id)"
        >
          <v-icon :color="enabled === true ? 'error' : 'grey lighten-1'">
            fal fa-file-pdf
          </v-icon>
        </v-btn>
      </template>
      <span v-if="datePickerSection === 1">Attendance Sheets</span>
      <span v-if="datePickerSection === 2">Check Request</span>
      <span v-if="datePickerSection === 3">Provider Payment Detail</span>
      <span v-if="datePickerSection === 4">Teacher Reviews</span>
      <span v-if="datePickerSection === 5">Teacher Check Request</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card v-if="progress === true" color="primary" dark>
        <v-card-text>
          Processing Report
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>

      <v-card v-else flat class="white">
        <v-card-title class="text-h5 secondary--text">
          {{ title }}
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left>fal fa-times</v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <span class="text-subtitle-1">{{ message }}</span>
                </v-col>
              </v-row>

              <!--Client Caseload-->
              <v-row v-if="id === 7" dense>
                <!--Include Terminated Care Plans-->
                <v-col cols="12" sm="5">
                  <v-checkbox
                    v-model="showTerminatedCarePlans"
                    value="false"
                    color="primary"
                    hide-details
                  >
                    <template v-slot:label>
                      <span style="font-size: 12px">Include Terminated Care Plans</span>
                    </template>
                  </v-checkbox>
                </v-col>
                <!--Check In Date-->
                <v-col cols="12" sm="8">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateEnd"
                        label="Eligibility Check-In Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        @blur="date1 = $_parseDate(d_dateEnd)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <!--Client Caseload-->
              <v-row v-if="id === 7" dense>
                <v-col cols="12" sm="12" md="8">
                  <v-autocomplete
                    :items="usersStore.usersValueList"
                    label="Specialist"
                    placeholder=" "
                    persistent-placeholder
                    item-text="name"
                    item-value="id"
                    v-model="fk_userID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <!--Isn't Client Caseload or Isn't Payment Based Client Contact Info or Isn't School Information or Isn't Task Assignments-->
              <v-row v-if="id !== 7 && id !== 9 && id !== 13 && id !== 24 && id !== 25 && id !== 26" dense>
                <!--Month Picker-->
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="valueListsStore.months"
                    label="Month"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_month"
                    clearable
                    clear-icon="fal fa-times-circle"
                    :rules="rules.f_month"
                  />
                </v-col>

                <!--Year-->
                <v-col v-if="id !== 7 && id !== 9 && id !== 13 && id !== 24 && id !== 25 && id !== 26" cols="12" sm="12" md="4">
                  <v-select
                    :items="yearsStore.yearsValueList"
                    item-text="d_yearStart"
                    item-value="d_yearStart"
                    label="Year"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_year"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <!--Payment Based Client Contact Info or School Information or Task Assignments-->
              <v-row v-if="id === 9 || id === 13 || id === 24 || id === 25 || id === 26" dense>
                <!--Range Start Date-->
                <v-col cols="12" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu2"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="dateStart"
                        label="Range Start Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date3 = $_parseDateMonth(dateStart)"
                      />
                    </template>
                    <v-date-picker
                      type="month"
                      v-model="date3"
                      @input="menu2 = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Range End Date-->
                <v-col cols="12" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu3"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="dateEnd"
                        label="Range End Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date4 = $_parseDateMonth(dateEnd)"
                      />
                    </template>
                    <v-date-picker
                      type="month"
                      v-model="date4"
                      @input="menu3 = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <!--Attendance Sheets-->
              <v-row dense v-if="id === 4">
                <!--Missing Clients-->
                <v-col cols="12">
                  <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                    >Clients Missing Attendance</label
                  >
                  <v-checkbox
                    v-model="missingClients"
                    label=""
                    value="Yes"
                    color="primary"
                    class="pr-2 mt-1 pt-0"
                    hide-details
                  />
                </v-col>
              </v-row>

              <!--SSRS-->
              <v-row dense v-if="id === 8">
                <!--Restricted CCDF Codes-->
                <v-col cols="12">
                  <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                    >Restrict To Specific Funding Codes</label
                  >
                  <v-checkbox
                    v-model="restrictAccountingCodes"
                    label=""
                    value="Yes"
                    color="primary"
                    class="pr-2 mt-1 pt-0"
                    hide-details
                  />
                </v-col>
              </v-row>

              <!--Process Attendance Sheets-->
              <v-row v-if="id === 1" dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="providersStore.providersValueList"
                    label="Specific Provider"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_display"
                    item-value="id"
                    v-model="providerID"
                    clearable
                    clear-icon="fal fa-times-circle"
                    @input="pullClients(providerID)"
                  />
                </v-col>
              </v-row>

              <v-row v-if="id === 1 && providerID > 0" dense>
                <!--Client-->
                <v-col cols="12" sm="12" v-if="!fk_providerID">
                  <v-select
                    :items="clientsStore.clientsValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Client"
                    placeholder=" "
                    persistent-placeholder
                    v-model="clientID"
                    clearable
                    clear-icon="fal fa-times-circle"
                    @input="pullCarePlans(clientID)"
                  />
                </v-col>
              </v-row>

              <v-row v-if="id === 1 && clientID > 0" dense>
                <!--Care Plan - For Clients-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="carePlansStore.carePlansValueList"
                    item-text="dateRange"
                    item-value="id"
                    label="Care Plan Certification Period"
                    placeholder=" "
                    persistent-placeholder
                    v-model="carePlanID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <!--Process Teacher Reviews-->
              <v-row v-if="id === 14" dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="providersStore.providersValueList"
                    label="Specific Provider"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_display"
                    item-value="id"
                    v-model="providerID"
                    clearable
                    clear-icon="fal fa-times-circle"
                    @input="pullTeachers(providerID)"
                  />
                </v-col>
              </v-row>

              <v-row v-if="id === 14 && providerID > 0" dense>
                <!--Teacher-->
                <v-col cols="12" sm="12" v-if="!fk_providerID">
                  <v-select
                    :items="teachersStore.teachersValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Teacher"
                    placeholder=" "
                    persistent-placeholder
                    v-model="teacherID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()">
            <v-icon small left>fal fa-check</v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

const { mapFields } = createHelpers({
  getterType: "reportsStore/getDatePickerField",
  mutationType: "reportsStore/updateDatePickerField"
});

export default {
  name: "Report",
  mixins: [mixin],
  computed: {
    ...mapState({
      reportsStore: state => state.reportsStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore,
      providersStore: state => state.providersStore,
      clientsStore: state => state.clientsStore,
      carePlansStore: state => state.carePlansStore,
      yearsStore: state => state.yearsStore,
      teachersStore: state => state.teachersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "id",
      "fk_carePlanItemID",
      "f_month",
      "d_year",
      "missingClients",
      "title",
      "message",
      "progress",
      "date1",
      "d_dateEnd",
      "fk_userID",
      "fk_providerID",
      "providerID",
      "clientID",
      "carePlanID",
      "dateStart",
      "dateEnd",
      "date3",
      "date4",
      "restrictAccountingCodes",
      "showTerminatedCarePlans",
      "teacherID"
    ])
  },
  props: {
    /* Attendance = 1,  Check Request = 2, Provider Payment Detail = 3, Teacher Review = 4, Teacher Check Request = 5, Provider Teacher Payment Detail = 6*/
    datePickerSection: {
      type: Number,
      required: false
    },
    enabled: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    date1() {
      this.d_dateEnd = this.$_formatDate(this.date1);
    },
    date3() {
      this.dateStart = this.$_formatDateMonth(this.date3);
    },
    date4() {
      this.dateEnd = this.$_formatDateMonth(this.date4);
    }
  },
  data() {
    return {
      menu: "",
      menu2: "",
      menu3: "",
      rules: {
        f_month: [v => !!v || "Required"],
        d_year: [v => !!v || "Required"],
        d_dateEnd: [v => !!v || "Required"]
      }
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("reportsStore/resetDatePicker");
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async openDatePicker(section, providerID) {
      let data;
      let reportID;
      let title;
      /* Attendance Sheets */
      if (section === 1) {
        reportID = 4;
        title = "Attendance Sheets";
      } else if (section === 2) {
        /* Check Requests */
        reportID = 3;
        title = "Check Request";
      } else if (section === 3) {
        /* Provider Payment Detail */
        reportID = 5;
        title = "Provider Payment Detail";
      } else if (section === 4) {
        /* Teacher Conformation Forms */
        reportID = 17;
        title = "Teacher Conformation Forms";
      } else if (section === 5) {
        /* Teacher Check Request */
        reportID = 16;
        title = "Teacher Check Request";
      } else if (section === 6) {
        /* Provider Teacher Payment Detail */
        reportID = 18;
        title = "Provider Teacher Payment Detail";
      }
      /* Grab current date, month and year for reports */
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();

      const message = "Select Month and Year before continuing.";

      data = {
        id: reportID,
        f_month: currentMonth,
        d_year: currentYear,
        message,
        title,
        fk_providerID: providerID
      };

      await this.$store.dispatch("yearsStore/valueList");

      await this.$store.dispatch("reportsStore/datePicker", data);
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        this.progress = true;
        await this.$store.dispatch("reportsStore/clearReport");
        if (!this.providerID) {
          this.clientID = "";
          this.teacherID = "";
        }
        const entry = this.$store.getters["reportsStore/getDatePicker"];
        await this.$store.dispatch("reportsStore/runReport", entry);
        const reportData = this.$store.getters["reportsStore/getReport"];

        let message;

        /* If report data exists */
        if (
          (this.id === 1 && reportData) ||
          (this.id === 2 && reportData) ||
          (this.id === 14 && reportData) ||
          (this.id === 15 && reportData)
        ) {
          message = {
            color: "success",
            text: "Process has completed successfully."
          };
          this.$store.commit("messagesStore/setMessage", message);
        } else if (
          /* If report data exists */
          (this.id === 3 && reportData) ||
          (this.id === 4 && reportData.length > 0) ||
          (this.id === 5 && reportData.providers.length > 0) ||
          (this.id === 16 && reportData) ||
          (this.id === 17 && reportData.length > 0) ||
          (this.id === 18 && reportData.providers.length > 0)
        ) {
          let doc;
          let filename;

          let currentDate = new Date();
          currentDate = currentDate.toLocaleString();

          /* 3 ---------- Check Request PDF ----------*/
          if (this.id === 3) {
            let monthEnd;

            doc = new jsPDF();
            filename = "CheckRequest.pdf";

            /* Determine last date of the month */
            if (
              this.f_month === 1 ||
              this.f_month === 3 ||
              this.f_month === 5 ||
              this.f_month === 7 ||
              this.f_month === 8 ||
              this.f_month === 10 ||
              this.f_month === 12
            ) {
              monthEnd = 31;
            } else if (this.f_month === 2) {
              if (this.$_leapYear(this.d_year)) {
                monthEnd = 29;
              } else {
                monthEnd = 28;
              }
            } else {
              monthEnd = 30;
            }

            const reportHeader = `CCSA Child Care Subsidy Program Check Request: ${this.f_month}/${monthEnd}/${this.d_year} DIRECT DEPOSIT`;
            const reportMonth = `${this.$_monthNumToText(this.f_month)} ${this.d_year}`;

            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            doc.text(reportHeader, 10, 10);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Payment Authorization:", 10, 20);
            doc.setDrawColor("#000000");
            doc.setLineWidth(0.5);
            doc.line(80, 20, 150, 20);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Payment Month:", 10, 26);
            doc.text(reportMonth, 80, 26);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Batch #:", 10, 32);
            doc.setDrawColor("#000000");
            doc.setLineWidth(0.5);
            doc.line(80, 32, 110, 32);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Total Check Request:", 10, 42);
            doc.text(this.$_formatMoney(reportData.paymentTotal), 80, 42);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Total Providers:", 10, 48);
            doc.text(reportData.providersTotal.toString(), 80, 48);

            /* Add style to funding sources */
            const data = [];
            const fundingCodeHeader = [];
            let i;
            for (i = 0; i < reportData.fundingCodes.length; i++) {
              let newData = "";
              if (reportData.fundingCodes[i].header === 1) {
                fundingCodeHeader.push(i);
              }
              newData = [
                reportData.fundingCodes[i].fundingCode,
                reportData.fundingCodes[i].code,
                // reportData.fundingCodes[i].paymentCount,
                this.$_formatMoney(reportData.fundingCodes[i].paymentAmount)
              ];

              data.push(newData);
            }

            /* Funding source table */
            doc.autoTable({
              margin: { top: 52, left: 8 },
              theme: "plain",
              columnStyles: {
                2: { halign: "right" }
              },
              body: data,
              didParseCell: data => {
                if (fundingCodeHeader.includes(data.row.index)) {
                  data.cell.styles.fontStyle = "bold";
                }
              }
            });

            doc.setFontSize(8);
            doc.setFont("helvetica", "bold");
            doc.text("Payable To/Doing Business As", 10, doc.lastAutoTable.finalY + 10);

            doc.setFontSize(8);
            doc.setFont("helvetica", "bold");
            doc.text("Payment Type/License Number/Taxpayer ID", 100, doc.lastAutoTable.finalY + 10);

            let newStart = doc.lastAutoTable.finalY + 12;
            if (reportData.providers && reportData.providers.length > 0) {
              let n;
              for (i = 0; i < reportData.providers.length; i++) {
                /* Provider Section */
                if (newStart + 24 >= 280) {
                  doc.addPage();
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportHeader, 10, 10);
                  doc.setFontSize(8);
                  doc.setFont("helvetica", "bold");
                  doc.text("Payable To/Doing Business As", 10, 18);

                  doc.setFontSize(8);
                  doc.setFont("helvetica", "bold");
                  doc.text("Payment Type/License Number/Taxpayer ID", 100, 18);
                  newStart = 20;
                }
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(10, newStart, 200, newStart);

                doc.setFontSize(8);
                doc.setFont("helvetica", "bold");
                doc.text(reportData.providers[i].d_nameFederal, 10, newStart + 4);
                doc.text("Direct Deposit", 100, newStart + 4);

                doc.text(reportData.providers[i].d_nameLicense, 10, newStart + 8);
                doc.text(reportData.providers[i].d_licenseNumber, 100, newStart + 8);

                doc.text(reportData.providers[i].d_facilityStreet1, 10, newStart + 12);
                doc.text(reportData.providers[i].d_taxpayerID, 100, newStart + 12);

                doc.text(reportData.providers[i].cityStateZip, 10, newStart + 16);

                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(
                  this.$_formatMoney(reportData.providers[i].totalAmount),
                  200,
                  newStart + 16,
                  "right"
                );

                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(10, newStart + 18, 200, newStart + 18);

                /* Funding Code Section */
                let padding = 0;
                for (n = 0; n < reportData.providers[i].fundingCodes.length; n++) {
                  /* Provider Section */
                  if (newStart + 24 >= 280) {
                    doc.addPage();
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportHeader, 10, 10);
                    doc.setFontSize(8);
                    doc.setFont("helvetica", "bold");
                    doc.text("Payable To/Doing Business As", 10, 18);

                    doc.setFontSize(8);
                    doc.setFont("helvetica", "bold");
                    doc.text("Payment Type/License Number/Taxpayer ID", 100, 18);
                    newStart = 20;
                  }
                  newStart += 4;
                  doc.setFontSize(8);
                  if (reportData.providers[i].fundingCodes[n].header === 1) {
                    doc.setFont("helvetica", "bold");
                  } else {
                    doc.setFont("helvetica", "normal");
                  }
                  doc.text(
                    `${reportData.providers[i].fundingCodes[n].fundingCode}  ${
                      reportData.providers[i].fundingCodes[n].code
                    }  ${this.$_formatMoney(
                      reportData.providers[i].fundingCodes[n].paymentAmount
                    )}`,
                    200,
                    newStart + 18,
                    "right"
                  );
                  padding += 2;
                }

                newStart = newStart + 24 + padding;
              }
            }

            // PAGE NUMBERING
            // Add Page number at bottom-left
            // Get the number of pages
            const pageCount = doc.internal.getNumberOfPages();

            // For each page, print the page number and the total pages
            for (let p = 1; p <= pageCount; p++) {
              // Go to page i
              doc.setPage(p);
              // Print Page 1 of 4 for example
              doc.setFontSize(8);
              doc.setFont("helvetica", "bold");
              doc.text(
                `Page ${String(p)} of ${String(pageCount)}     Printed: ${currentDate}`,
                10,
                288
              );
            }
          }

          /* 4 ----------- Attendence Sheets PDF ----------*/
          if (this.id === 4) {
            doc = new jsPDF("lanscape");
            filename = "AttendenceSheets.pdf";

            let providerID;
            let previousProviderID;
            let newData;
            let totalPagesStart;
            let pageNumberingStart;
            let totalPagesNew;
            let providerPages;

            for (let i = 0; i < reportData.length; i++) {
              /* Grab total number of pages */
              if (i === 0) {
                totalPagesStart = 0;
              } else {
                totalPagesStart = doc.internal.getNumberOfPages();
              }

              const providerHeader = `${reportData[i].providerName} (${reportData[i].providerLicense})     ${reportData[i].providerPhone}`;
              const monthYear = `Month: ${this.$_monthNumToText(this.f_month)}     Year:${
                this.d_year
              }`;

              if (i !== 0) {
                doc.addPage();
              }

              /* ---------- Provider and Month/Year ----------*/
              doc.setFontSize(10);
              doc.setFont("helvetica", "bold");
              doc.text(providerHeader, 10, 18);
              doc.setFontSize(10);
              doc.setFont("helvetica", "bold");
              doc.text(monthYear, 10, 22);

              /* ---------- Signature Line ----------*/
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.line(170, 19, 240, 19);
              doc.setFontSize(9);
              doc.setFont("helvetica", "normal");
              doc.text("Signature of Scholarship Agent", 170, 19 + 4);
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.line(250, 19, 283, 19);
              doc.setFontSize(9);
              doc.setFont("helvetica", "normal");
              doc.text("Date", 250, 19 + 4);

              /* Clients table */
              doc.autoTable({
                margin: { top: 26, left: 10 },
                headStyles: {
                  lineWidth: 0.25,
                  lineColor: "#000000",
                  textColor: "#000000",
                  fillColor: "#FFFFFF"
                },
                bodyStyles: {
                  lineWidth: 0.25,
                  lineColor: "#000000",
                  textColor: "#000000"
                },
                theme: "grid",
                body: reportData[i].clients,
                columns: [
                  { header: "Child's Name", dataKey: "clientName" },
                  { header: "Care Start", dataKey: "carePlanStart" },
                  { header: "Care End", dataKey: "carePlanEnd" },
                  { header: "Start/End Date\n(if different)", dataKey: "differentDates" },
                  { header: "# Days\nPresent", dataKey: "daysPresent" },
                  { header: "# Days\nAbsent", dataKey: "daysAbsent" },
                  { header: "# Days\nClosed", dataKey: "daysClosed" },
                  { header: "Total\nDays", dataKey: "totalDays" },
                  { header: "Reason Absent", dataKey: "reasonAbsent" },
                  { header: "Parent Fee\nUnpaid\n(check)", dataKey: "parentFee" }
                ],
                didDrawPage(data) {
                  // Header
                  doc.setFontSize(11);
                  doc.setFont("helvetica", "bold");
                  doc.text(
                    "CCSA Child Care Scholarship Monthly Attendance Worksheet",
                    142,
                    10,
                    "center"
                  );
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  doc.text(providerHeader, 10, 18);
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  doc.text(monthYear, 10, 22);

                  /* ---------- Signature Line ----------*/
                  doc.setDrawColor("#000000");
                  doc.setLineWidth(0.25);
                  doc.line(170, 19, 240, 19);
                  doc.setFontSize(9);
                  doc.setFont("helvetica", "normal");
                  doc.text("Signature of Scholarship Agent", 170, 19 + 4);
                  doc.setDrawColor("#000000");
                  doc.setLineWidth(0.25);
                  doc.line(250, 19, 283, 19);
                  doc.setFontSize(9);
                  doc.setFont("helvetica", "normal");
                  doc.text("Date", 250, 19 + 4);
                },
                didDrawCell(data) {
                  if (data.row.section === "body" && data.column.dataKey === "parentFee") {
                    doc.rect(data.cell.x + 10, data.cell.y + 2, 4, 4); // empty square
                  }
                }
              });

              let newStart = doc.lastAutoTable.finalY;

              /* ----------Comments Section----------*/
              if (newStart + 26 >= 200) {
                doc.addPage();
                doc.setFontSize(11);
                doc.setFont("helvetica", "bold");
                doc.text(
                  "CCSA Child Care Scholarship Monthly Attendance Worksheet",
                  142,
                  10,
                  "center"
                );
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(providerHeader, 10, 18);
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(monthYear, 10, 22);

                /* ---------- Signature Line ----------*/
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(170, 19, 240, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Signature of Scholarship Agent", 170, 19 + 4);
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(250, 19, 283, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Date", 250, 19 + 4);

                newStart = 30;
              } else {
                newStart += 4;
              }

              doc.setFontSize(10);
              doc.setFont("helvetica", "bold");
              doc.text("Comments:", 11, newStart + 4);
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text(
                "Please use this space to communicate about excessive absences, past due parent fees, exits or notice of service termination from families, reasons for facility\nclosures, or any issue requiring our attention.",
                32,
                newStart + 4
              );

              doc.setDrawColor("#000000"); // draw red lines
              doc.setLineWidth(0.25);
              doc.rect(10, newStart, 273, 24);

              newStart += 24;

              /* ----------Attendance Submission Line 1----------*/
              if (newStart + 15 >= 200) {
                doc.addPage();
                doc.setFontSize(11);
                doc.setFont("helvetica", "bold");
                doc.text(
                  "CCSA Child Care Scholarship Monthly Attendance Worksheet",
                  142,
                  10,
                  "center"
                );
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(providerHeader, 10, 18);
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(monthYear, 10, 22);

                /* ---------- Signature Line ----------*/
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(170, 19, 240, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Signature of Scholarship Agent", 170, 19 + 4);
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(250, 19, 283, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Date", 250, 19 + 4);

                newStart = 30;
              } else {
                newStart += 4;
              }

              doc.setFontSize(10);
              doc.setFont("helvetica", "bold");
              doc.text("Attendance Submission:", 11, newStart);

              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text("- Please ", 11, newStart + 4);

              doc.setFont("helvetica", "bold");
              doc.text("SCAN", 25, newStart + 4);

              doc.setFont("helvetica", "normal");
              doc.text(" and ", 35, newStart + 4);

              doc.setFont("helvetica", "bold");
              doc.text("E-MAIL", 43, newStart + 4);

              doc.setFont("helvetica", "normal");
              doc.text(" to ", 55, newStart + 4);

              doc.setTextColor("#0000FF");
              doc.text("scholarship@childcareservices.org", 60, newStart + 4);

              doc.setTextColor("#000000");

              doc.setFont("helvetica", "normal");
              doc.text("; ", 115, newStart + 4);

              doc.setFont("helvetica", "bold");
              doc.text("FAX", 117, newStart + 4);

              doc.setFont("helvetica", "normal");
              doc.text(" to ", 125, newStart + 4);

              doc.setFont("helvetica", "bold");
              doc.text("919-403-6959", 130, newStart + 4);

              doc.setFont("helvetica", "normal");
              doc.text(", Attn: Scholarship; or ", 152, newStart + 4);

              doc.setFont("helvetica", "bold");
              doc.text("MAIL", 187, newStart + 4);

              doc.setFont("helvetica", "normal");
              doc.text(" to or ", 196, newStart + 4);

              doc.setFont("helvetica", "bold");
              doc.text("DROP OFF", 205, newStart + 4);

              doc.setFont("helvetica", "normal");
              doc.text(" at the front desk at 1201 S. Briggs Ave.", 224, newStart + 4);
              newStart += 4;
              doc.text(
                "in Durham or 1829 E. Franklin St., Bldg 1000 in Chapel Hill.",
                13,
                newStart + 4
              );

              /* ----------Attendance Submission Line 2----------*/
              if (newStart + 10 >= 200) {
                doc.addPage();
                doc.setFontSize(11);
                doc.setFont("helvetica", "bold");
                doc.text(
                  "CCSA Child Care Scholarship Monthly Attendance Worksheet",
                  142,
                  10,
                  "center"
                );
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(providerHeader, 10, 18);
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(monthYear, 10, 22);

                /* ---------- Signature Line ----------*/
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(170, 19, 240, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Signature of Scholarship Agent", 170, 19 + 4);
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(250, 19, 283, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Date", 250, 19 + 4);

                newStart = 30;
              } else {
                newStart += 4;
              }
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text(
                "- All attendance worksheets must be received by the due date on the Attendance Worksheet Due Date Calendar, and signature is required for reimbursement.",
                11,
                newStart + 4
              );

              newStart += 6;

              /* ----------By Signing Below Line 1----------*/
              if (newStart + 15 >= 200) {
                doc.addPage();
                doc.setFontSize(11);
                doc.setFont("helvetica", "bold");
                doc.text(
                  "CCSA Child Care Scholarship Monthly Attendance Worksheet",
                  142,
                  10,
                  "center"
                );
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(providerHeader, 10, 18);
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(monthYear, 10, 22);

                /* ---------- Signature Line ----------*/
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(170, 19, 240, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Signature of Scholarship Agent", 170, 19 + 4);
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(250, 19, 283, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Date", 250, 19 + 4);

                newStart = 30;
              } else {
                newStart += 4;
              }

              doc.setFontSize(10);
              doc.setFont("helvetica", "bold");
              doc.text(
                "With my signature above, I attest to the following and confirm that I am authorized to do so:",
                11,
                newStart
              );
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text(
                "- The information provided on this form accurately reflects attendance for the children listed.",
                11,
                newStart + 4
              );

              /* ----------By Signing Below Line 2----------*/
              if (newStart + 10 >= 200) {
                doc.addPage();
                doc.setFontSize(11);
                doc.setFont("helvetica", "bold");
                doc.text(
                  "CCSA Child Care Scholarship Monthly Attendance Worksheet",
                  142,
                  10,
                  "center"
                );
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(providerHeader, 10, 18);
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(monthYear, 10, 22);

                /* ---------- Signature Line ----------*/
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(170, 19, 240, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Signature of Scholarship Agent", 170, 19 + 4);
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(250, 19, 283, 19);
                doc.setFontSize(9);
                doc.setFont("helvetica", "normal");
                doc.text("Date", 250, 19 + 4);

                newStart = 30;
              } else {
                newStart += 4;
              }
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text(
                "- The above named facility is in compliance with the Scholarship Program's General Provider Agreement and Licensure & Regulatory Compliance Policies, including\nhaving notified the Scholarship program of any child maltreatment investigation open at the facility.",
                11,
                newStart + 4
              );

              /* ---------- Page Numbering ----------*/
              /* Grab total number of pages */
              totalPagesNew = doc.internal.getNumberOfPages();

              providerPages = totalPagesNew - totalPagesStart;

              if (i === 0) {
                pageNumberingStart = 0;
              } else {
                pageNumberingStart = totalPagesNew - providerPages;
              }

              // For each page, print the page number and the total pages
              for (let p = 1; p <= providerPages; p++) {
                // Go to page i
                doc.setPage(pageNumberingStart + p);
                // Print Page 1 of 4 for example
                doc.setFontSize(8);
                doc.setFont("helvetica", "bold");
                doc.text(`Page ${String(p)} of ${String(providerPages)}`, 10, 200);
              }
            }
          }

          /* 5 ---------- Provider Payment Detail ----------*/
          if (this.id === 5) {
            doc = new jsPDF();
            filename = "ProviderDetail.pdf";
            let totalPagesStart;
            let pageNumberingStart;
            let newStart;
            let clientTotal;
            let pageNumber = 1;
            let clientTotalStart;
            let clientTotalPage;
            let clientIDPrevious;
            let clientIDCurrent;
            let totalPagesNew;
            let providerPages;

            const reportHeader =
              "Child Care Services Association Scholarship Provider Reimbursement Detail";
            const reportMonth = `Payment Month: ${this.$_monthNumToText(this.f_month)} ${
              this.d_year
            }`;

            for (let i = 0; i < reportData.providers.length; i++) {
              clientTotal = "";

              /* Grab total number of pages */
              if (i === 0) {
                totalPagesStart = 0;
              } else {
                totalPagesStart = doc.internal.getNumberOfPages();
              }

              if (i !== 0) {
                doc.addPage();
                pageNumber++;
              }

              /* ---------- Header----------*/
              doc.setFontSize(13);
              doc.setFont("helvetica", "bold");
              doc.text(reportHeader, 24, 10);
              doc.setFontSize(12);
              doc.setFont("helvetica", "bold");
              doc.text(reportMonth, 78, 16);

              /* ---------- Provider Details----------*/
              doc.setFontSize(11);
              doc.setFont("helvetica", "bold");
              doc.text(reportData.providers[i].d_nameFederal, 10, 28);
              doc.text(
                `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                10,
                33
              );
              doc.setFont("helvetica", "normal");
              doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
              doc.text(reportData.providers[i].cityStateZip, 10, 43);
              doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

              doc.setFont("helvetica", "bold");
              doc.text(
                `Total Payment: ${this.$_formatMoney(reportData.providers[i].totalAmount)}`,
                200,
                28,
                "right"
              );

              newStart = 54;

              clientIDPrevious = "";
              clientIDCurrent = "";
              clientTotal = "";

              for (let r = 0; r < reportData.providers[i].payments.length; r++) {
                newStart += 6;

                if (newStart >= 271) {
                  doc.addPage();
                  pageNumber++;

                  /* ---------- Header----------*/
                  doc.setFontSize(13);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportHeader, 24, 10);
                  doc.setFontSize(12);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportMonth, 78, 16);

                  /* ---------- Provider Details----------*/
                  doc.setFontSize(11);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportData.providers[i].d_nameFederal, 10, 28);
                  doc.text(
                    `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                    10,
                    33
                  );
                  doc.setFont("helvetica", "normal");
                  doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
                  doc.text(reportData.providers[i].cityStateZip, 10, 43);
                  doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

                  newStart = 54;

                  if (reportData.providers[i].d_noteExternal) {
                    doc.text(reportData.providers[i].d_noteExternal, 10, newStart);
                    newStart = 80;
                  }
                }

                clientIDCurrent = reportData.providers[i].payments[r].fk_clientID;

                /* Client Row */
                if (!clientIDPrevious || clientIDPrevious !== clientIDCurrent) {
                  if (clientTotal || clientTotal === 0) {
                    /* Client Payment Total */
                    doc.setPage(clientTotalPage);
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.text(this.$_formatMoney(clientTotal), 200, clientTotalStart, "right");

                    doc.setPage(pageNumber);
                    doc.setFont("helvetica", "normal");
                    doc.setDrawColor("#000000");
                    doc.setLineWidth(0.25);
                    doc.line(15, newStart - 2, 200, newStart - 2);

                    newStart += 6;
                  }

                  clientTotal = "";
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  if (reportData.providers[i].payments[r].f_placeholder == 1) {
                    doc.text(
                      `${reportData.providers[i].payments[r].d_clientNameFirst} ${reportData.providers[i].payments[r].d_clientNameLast} - ${reportData.providers[i].payments[r].fk_clientID}`,
                      15,
                      newStart
                    );
                  } else {
                    doc.text(
                      `${reportData.providers[i].payments[r].d_clientNameFirst} ${reportData.providers[i].payments[r].d_clientNameLast}`,
                      15,
                      newStart
                    );
                  }
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "normal");
                  doc.text(
                    `DOB: ${this.$_formatDate(
                      reportData.providers[i].payments[r].d_clientDateBirth
                    )}`,
                    75,
                    newStart
                  );
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  doc.text("TOTAL", 180, newStart, "right");

                  clientTotalStart = newStart;
                  clientTotalPage = pageNumber;
                }

                newStart += 6;

                if (clientIDPrevious === clientIDCurrent) {
                  newStart -= 6;
                }

                /* ---------- Payment Row ----------*/
                if (newStart + 8 >= 274) {
                  doc.addPage();
                  pageNumber++;

                  /* ---------- Header----------*/
                  doc.setFontSize(13);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportHeader, 24, 10);
                  doc.setFontSize(12);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportMonth, 78, 16);

                  /* ---------- Provider Details----------*/
                  doc.setFontSize(11);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportData.providers[i].d_nameFederal, 10, 28);
                  doc.text(
                    `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                    10,
                    33
                  );
                  doc.setFont("helvetica", "normal");
                  doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
                  doc.text(reportData.providers[i].cityStateZip, 10, 43);
                  doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

                  newStart = 60;
                }
                /* Classroom */
                if (reportData.providers[i].payments[r].d_classroom) {
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "normal");
                  doc.text(
                    `Classroom: ${reportData.providers[i].payments[r].d_classroom}`,
                    15,
                    newStart
                  );
                }
                /* Attendance */
                doc.setFontSize(10);
                doc.setFont("helvetica", "normal");
                doc.text(
                  `Attendance: ${this.$_monthNumToText(
                    reportData.providers[i].payments[r].f_attendanceMonth
                  )} ${reportData.providers[i].payments[r].d_attendanceYear}`,
                  75,
                  newStart
                );
                /* Payment */
                doc.setFontSize(10);
                doc.setFont("helvetica", "normal");
                doc.text(
                  `${reportData.providers[i].payments[r].d_program} - ${reportData.providers[i].payments[r].d_programOption}`,
                  180,
                  newStart,
                  "right"
                );
                doc.text(
                  this.$_formatMoney(reportData.providers[i].payments[r].d_amount),
                  200,
                  newStart,
                  "right"
                );

                clientTotal =
                  Number(clientTotal) + Number(reportData.providers[i].payments[r].d_amount);

                if (reportData.providers[i].payments[r].d_noteExternal) {
                  if (newStart + 20 >= 274) {
                    doc.addPage();
                    pageNumber++;

                    /* ---------- Header----------*/
                    doc.setFontSize(13);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportHeader, 24, 10);
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportMonth, 78, 16);

                    /* ---------- Provider Details----------*/
                    doc.setFontSize(11);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportData.providers[i].d_nameFederal, 10, 28);
                    doc.text(
                      `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                      10,
                      33
                    );
                    doc.setFont("helvetica", "normal");
                    doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
                    doc.text(reportData.providers[i].cityStateZip, 10, 43);
                    doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

                    newStart = 60;
                  }
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "italic");
                  doc.text(reportData.providers[i].payments[r].d_noteExternal, 20, newStart + 6);
                  doc.setFont("helvetica", "normal");
                  newStart += 4;
                }

                clientIDPrevious = reportData.providers[i].payments[r].fk_clientID;
              }

              /* ---------- Page Numbering ----------*/
              /* Grab total number of pages */
              totalPagesNew = doc.internal.getNumberOfPages();

              providerPages = totalPagesNew - totalPagesStart;

              if (i === 0) {
                pageNumberingStart = 0;
              } else {
                pageNumberingStart = totalPagesNew - providerPages;
              }

              // For each page, print the page number and the total pages
              for (let p = 1; p <= providerPages; p++) {
                // Go to page i
                doc.setPage(pageNumberingStart + p);
                // Print Page 1 of 4 for example
                doc.setFontSize(8);
                doc.setFont("helvetica", "bold");
                doc.text(`Page ${String(p)} of ${String(providerPages)}`, 10, 288);
              }

              if (clientTotal || clientTotal === 0) {
                /* Client Payment Total */
                doc.setPage(clientTotalPage);
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(this.$_formatMoney(clientTotal), 200, clientTotalStart, "right");

                doc.setFont("helvetica", "normal");
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
              }
            }
          }

          /**Teacher Payment Check Request */
          if (this.id === 16) {
            let monthEnd;

            doc = new jsPDF();
            filename = "CheckRequest.pdf";

            /* Determine last date of the month */
            if (
              this.f_month === 1 ||
              this.f_month === 3 ||
              this.f_month === 5 ||
              this.f_month === 7 ||
              this.f_month === 8 ||
              this.f_month === 10 ||
              this.f_month === 12
            ) {
              monthEnd = 31;
            } else if (this.f_month === 2) {
              if (this.$_leapYear(this.d_year)) {
                monthEnd = 29;
              } else {
                monthEnd = 28;
              }
            } else {
              monthEnd = 30;
            }

            const reportHeader = `CCSA Teacher Payments Program Check Request: ${this.f_month}/${monthEnd}/${this.d_year} DIRECT DEPOSIT`;
            const reportMonth = `${this.$_monthNumToText(this.f_month)} ${this.d_year}`;

            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            doc.text(reportHeader, 10, 10);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Payment Authorization:", 10, 20);
            doc.setDrawColor("#000000");
            doc.setLineWidth(0.5);
            doc.line(80, 20, 150, 20);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Payment Month:", 10, 26);
            doc.text(reportMonth, 80, 26);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Batch #:", 10, 32);
            doc.setDrawColor("#000000");
            doc.setLineWidth(0.5);
            doc.line(80, 32, 110, 32);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Total Check Request:", 10, 42);
            doc.text(this.$_formatMoney(reportData.paymentTotal), 80, 42);

            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("Total Providers:", 10, 48);
            doc.text(reportData.providersTotal.toString(), 80, 48);

            /* Add style to funding sources */
            const data = [];
            const fundingCodeHeader = [];
            let i;
            for (i = 0; i < reportData.fundingCodes.length; i++) {
              let newData = "";
              if (reportData.fundingCodes[i].header === 1) {
                fundingCodeHeader.push(i);
              }
              newData = [
                reportData.fundingCodes[i].fundingCode,
                reportData.fundingCodes[i].code,
                // reportData.fundingCodes[i].paymentCount,
                this.$_formatMoney(reportData.fundingCodes[i].paymentAmount)
              ];

              data.push(newData);
            }

            /* Funding source table */
            doc.autoTable({
              margin: { top: 52, left: 8 },
              theme: "plain",
              columnStyles: {
                2: { halign: "right" }
              },
              body: data,
              didParseCell: data => {
                if (fundingCodeHeader.includes(data.row.index)) {
                  data.cell.styles.fontStyle = "bold";
                }
              }
            });

            doc.setFontSize(8);
            doc.setFont("helvetica", "bold");
            doc.text("Payable To/Doing Business As", 10, doc.lastAutoTable.finalY + 10);

            doc.setFontSize(8);
            doc.setFont("helvetica", "bold");
            doc.text("Payment Type/License Number/Taxpayer ID", 100, doc.lastAutoTable.finalY + 10);

            let newStart = doc.lastAutoTable.finalY + 12;
            if (reportData.providers && reportData.providers.length > 0) {
              let n;
              for (i = 0; i < reportData.providers.length; i++) {
                /* Provider Section */
                if (newStart + 24 >= 280) {
                  doc.addPage();
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportHeader, 10, 10);
                  doc.setFontSize(8);
                  doc.setFont("helvetica", "bold");
                  doc.text("Payable To/Doing Business As", 10, 18);

                  doc.setFontSize(8);
                  doc.setFont("helvetica", "bold");
                  doc.text("Payment Type/License Number/Taxpayer ID", 100, 18);
                  newStart = 20;
                }
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(10, newStart, 200, newStart);

                doc.setFontSize(8);
                doc.setFont("helvetica", "bold");
                doc.text(reportData.providers[i].d_nameFederal, 10, newStart + 4);
                doc.text("Direct Deposit", 100, newStart + 4);

                doc.text(reportData.providers[i].d_nameLicense, 10, newStart + 8);
                doc.text(reportData.providers[i].d_licenseNumber, 100, newStart + 8);

                doc.text(reportData.providers[i].d_facilityStreet1, 10, newStart + 12);
                doc.text(reportData.providers[i].d_taxpayerID, 100, newStart + 12);

                doc.text(reportData.providers[i].cityStateZip, 10, newStart + 16);

                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(
                  this.$_formatMoney(reportData.providers[i].totalAmount),
                  200,
                  newStart + 16,
                  "right"
                );

                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
                doc.line(10, newStart + 18, 200, newStart + 18);

                /* Funding Code Section */
                let padding = 0;
                for (n = 0; n < reportData.providers[i].fundingCodes.length; n++) {
                  /* Provider Section */
                  if (newStart + 24 >= 280) {
                    doc.addPage();
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportHeader, 10, 10);
                    doc.setFontSize(8);
                    doc.setFont("helvetica", "bold");
                    doc.text("Payable To/Doing Business As", 10, 18);

                    doc.setFontSize(8);
                    doc.setFont("helvetica", "bold");
                    doc.text("Payment Type/License Number/Taxpayer ID", 100, 18);
                    newStart = 20;
                  }
                  newStart += 4;
                  doc.setFontSize(8);
                  if (reportData.providers[i].fundingCodes[n].header === 1) {
                    doc.setFont("helvetica", "bold");
                  } else {
                    doc.setFont("helvetica", "normal");
                  }
                  doc.text(
                    `${reportData.providers[i].fundingCodes[n].fundingCode}  ${
                      reportData.providers[i].fundingCodes[n].code
                    }  ${this.$_formatMoney(
                      reportData.providers[i].fundingCodes[n].paymentAmount
                    )}`,
                    200,
                    newStart + 18,
                    "right"
                  );
                  padding += 2;
                }

                newStart = newStart + 24 + padding;
              }
            }

            // PAGE NUMBERING
            // Add Page number at bottom-left
            // Get the number of pages
            const pageCount = doc.internal.getNumberOfPages();

            // For each page, print the page number and the total pages
            for (let p = 1; p <= pageCount; p++) {
              // Go to page i
              doc.setPage(p);
              // Print Page 1 of 4 for example
              doc.setFontSize(8);
              doc.setFont("helvetica", "bold");
              doc.text(
                `Page ${String(p)} of ${String(pageCount)}     Printed: ${currentDate}`,
                10,
                288
              );
            }
          }

          /* 17 ---------- Teacher Confirmation Forms ----------*/
          if (this.id === 17) {
            doc = new jsPDF("lanscape");
            filename = "ConfirmationForms.pdf";

            let totalPagesStart;
            let pageNumberingStart;
            let totalPagesNew;
            let providerPages;

            for (let i = 0; i < reportData.length; i++) {
              /* Grab total number of pages */
              if (i === 0) {
                totalPagesStart = 0;
              } else {
                totalPagesStart = doc.internal.getNumberOfPages();
              }

              const providerHeader = `${reportData[i].providerName} (${reportData[i].providerLicense})`;
              const monthYear = `Month: ${this.$_monthNumToText(this.f_month)}     Year:${
                this.d_year
              }`;
              const providerAddress = `${reportData[i].providerAddress}`;

              if (i !== 0) {
                doc.addPage();
              }

              /* ---------- Provider and Month/Year ----------*/
              doc.setFontSize(12);
              doc.setFont("helvetica", "bold");
              doc.text(providerHeader, 10, 22);
              doc.setFontSize(12);
              doc.setFont("helvetica", "bold");
              doc.text(providerAddress, 10, 27);
              doc.setFontSize(12);
              doc.setFont("helvetica", "bold");
              doc.text(monthYear, 10, 34);

              doc.setFontSize(11);
              doc.setFont("helvetica", "normal");
              doc.text(
                "The table below contains the current teacher information we have for your Durham PreK classroom(s).\nPlease review the information and sign in the appropriate box below.",
                140,
                42,
                "center"
              );

              /* Classrooms table */
              doc.autoTable({
                margin: { top: 52, left: 10 },
                headStyles: {
                  lineWidth: 0.25,
                  lineColor: "#000000",
                  textColor: "#000000",
                  fillColor: "#FFFFFF"
                },
                bodyStyles: {
                  lineWidth: 0.25,
                  lineColor: "#000000",
                  textColor: "#000000"
                },
                columnStyles: {
                  0: {
                    cellWidth: 50
                  },
                  1: {
                    cellWidth: 40
                  },
                  2: {
                    cellWidth: 40
                  },
                  3: {
                    cellWidth: 40
                  },
                  4: {
                    cellWidth: 30
                  },
                  5: {
                    cellWidth: 80
                  }
                },
                theme: "grid",
                body: reportData[i].classrooms,
                columns: [
                  {
                    header: "Clasroom Name\n(ex: Explorers, Blue\nRoom, PreK, etc.)",
                    dataKey: "classroomName"
                  },
                  { header: "Teacher\nName", dataKey: "teacherName" },
                  { header: "Position Type", dataKey: "teacherPosition" },
                  { header: "Type of License", dataKey: "typeOfLicense" },
                  { header: "Years of\nExperience\non License", dataKey: "teacherExperience" },
                  { header: "Notes", dataKey: "notes" }
                ],
                didDrawPage() {
                  // Header
                  doc.setFontSize(14);
                  doc.setFont("helvetica", "bold");
                  doc.text("Durham PreK Teacher Confirmation Form", 142, 10, "center");
                  doc.setFontSize(12);
                  doc.setFont("helvetica", "bold");
                  doc.text(providerHeader, 10, 22);
                  doc.setFontSize(12);
                  doc.setFont("helvetica", "bold");
                  doc.text(providerAddress, 10, 27);
                  doc.setFontSize(12);
                  doc.setFont("helvetica", "bold");
                  doc.text(monthYear, 10, 34);
                }
              });

              let newStart = doc.lastAutoTable.finalY;
              if (newStart + 36 >= 200) {
                doc.addPage();
                doc.setFontSize(14);
                doc.setFont("helvetica", "bold");
                doc.text("Durham PreK Teacher Confirmation Form", 142, 10, "center");
                doc.setFontSize(12);
                doc.setFont("helvetica", "bold");
                doc.text(providerHeader, 10, 22);
                doc.setFontSize(12);
                doc.setFont("helvetica", "bold");
                doc.text(providerAddress, 10, 27);
                doc.setFontSize(12);
                doc.setFont("helvetica", "bold");
                doc.text(monthYear, 10, 34);
                newStart = 34;
              }

              /* ----------All Information Above Is Correct----------*/
              doc.setFontSize(11);
              doc.setFont("helvetica", "normal");
              doc.text("Please select one of the following", 142, newStart + 8, "center");

              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.rect(10, newStart + 14, 130, 36);
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.75);
              doc.rect(20, newStart + 18, 7, 7);
              doc.setFontSize(14);
              doc.setFont("helvetica", "bold");
              doc.text("All Information Above is Correct", 30, newStart + 22);
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text(
                "By signing below, I affirm that the teacher\ninformation listed above is correct.",
                30,
                newStart + 27
              );
              /* Signature */
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.line(30, newStart + 42, 86, newStart + 42);
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text("Signature", 50, newStart + 46);
              /* Date */
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.line(96, newStart + 42, 126, newStart + 42);
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text("Date", 108, newStart + 46);

              /* ----------I Need TO Make Corrections----------*/
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.rect(160, newStart + 14, 130, 36);
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.75);
              doc.rect(170, newStart + 18, 7, 7);
              doc.setFontSize(14);
              doc.setFont("helvetica", "bold");
              doc.text("I Need to Make Corrections", 180, newStart + 22);
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text(
                "I have had a teacher change this past month.\nPlease find a Staff Information Change Form attached.",
                180,
                newStart + 27
              );
              /* Signature */
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.line(180, newStart + 42, 236, newStart + 42);
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text("Signature", 200, newStart + 46);
              /* Date */
              doc.setDrawColor("#000000");
              doc.setLineWidth(0.25);
              doc.line(246, newStart + 42, 276, newStart + 42);
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              doc.text("Date", 258, newStart + 46);

              /* ---------- Page Numbering ----------*/
              /* Grab total number of pages */
              totalPagesNew = doc.internal.getNumberOfPages();

              providerPages = totalPagesNew - totalPagesStart;

              if (i === 0) {
                pageNumberingStart = 0;
              } else {
                pageNumberingStart = totalPagesNew - providerPages;
              }

              // For each page, print the page number and the total pages
              for (let p = 1; p <= providerPages; p++) {
                // Go to page i
                doc.setPage(pageNumberingStart + p);
                // Print Page 1 of 4 for example
                doc.setFontSize(8);
                doc.setFont("helvetica", "bold");
                doc.text(`Page ${String(p)} of ${String(providerPages)}`, 10, 200);
              }
            }
          }

          /* 18 ---------- Provider Teacher Payment Detail ----------*/
          if (this.id === 18) {
            doc = new jsPDF();
            filename = "ProviderTeacherPaymentDetail.pdf";
            let totalPagesStart;
            let pageNumberingStart;
            let newStart;
            let teacherTotal;
            let pageNumber = 1;
            let teacherTotalStart;
            let teacherTotalPage;
            let teacherIDPrevious;
            let teacherIDCurrent;
            let totalPagesNew;
            let providerPages;

            const reportHeader =
              "Child Care Services Association Teacher Payment Provider Reimbursement Detail";
            const reportMonth = `Payment Month: ${this.$_monthNumToText(this.f_month)} ${
              this.d_year
            }`;

            for (let i = 0; i < reportData.providers.length; i++) {
              teacherTotal = "";

              /* Grab total number of pages */
              if (i === 0) {
                totalPagesStart = 0;
              } else {
                totalPagesStart = doc.internal.getNumberOfPages();
              }

              if (i !== 0) {
                doc.addPage();
                pageNumber++;
              }

              /* ---------- Header----------*/
              doc.setFontSize(13);
              doc.setFont("helvetica", "bold");
              doc.text(reportHeader, 20, 10);
              doc.setFontSize(12);
              doc.setFont("helvetica", "bold");
              doc.text(reportMonth, 78, 16);

              /* ---------- Provider Details----------*/
              doc.setFontSize(11);
              doc.setFont("helvetica", "bold");
              doc.text(reportData.providers[i].d_nameFederal, 10, 28);
              doc.text(
                `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                10,
                33
              );
              doc.setFont("helvetica", "normal");
              doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
              doc.text(reportData.providers[i].cityStateZip, 10, 43);
              doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

              doc.setFont("helvetica", "bold");
              doc.text(
                `Total Payment: ${this.$_formatMoney(reportData.providers[i].totalAmount)}`,
                200,
                28,
                "right"
              );

              newStart = 54;

              teacherIDPrevious = "";
              teacherIDCurrent = "";
              teacherTotal = "";

              for (let r = 0; r < reportData.providers[i].payments.length; r++) {
                newStart += 6;

                if (newStart >= 271) {
                  doc.addPage();
                  pageNumber++;

                  /* ---------- Header----------*/
                  doc.setFontSize(13);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportHeader, 20, 10);
                  doc.setFontSize(12);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportMonth, 78, 16);

                  /* ---------- Provider Details----------*/
                  doc.setFontSize(11);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportData.providers[i].d_nameFederal, 10, 28);
                  doc.text(
                    `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                    10,
                    33
                  );
                  doc.setFont("helvetica", "normal");
                  doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
                  doc.text(reportData.providers[i].cityStateZip, 10, 43);
                  doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

                  newStart = 54;

                  if (reportData.providers[i].d_noteExternal) {
                    doc.text(reportData.providers[i].d_noteExternal, 10, newStart);
                    newStart = 80;
                  }
                }

                teacherIDCurrent = reportData.providers[i].payments[r].fk_teacherID;

                /* Teacher Row */
                if (!teacherIDPrevious || teacherIDPrevious !== teacherIDCurrent) {
                  if (teacherTotal || teacherTotal === 0) {
                    /* Teacher Payment Total */
                    doc.setPage(teacherTotalPage);
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.text(this.$_formatMoney(teacherTotal), 200, teacherTotalStart, "right");

                    doc.setPage(pageNumber);
                    doc.setFont("helvetica", "normal");
                    doc.setDrawColor("#000000");
                    doc.setLineWidth(0.25);
                    doc.line(15, newStart - 2, 200, newStart - 2);

                    newStart += 6;
                  }

                  teacherTotal = "";
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  doc.text(`${reportData.providers[i].payments[r].d_teacherName}`, 15, newStart);
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "bold");
                  doc.text("TOTAL", 180, newStart, "right");

                  teacherTotalStart = newStart;
                  teacherTotalPage = pageNumber;
                }

                newStart += 6;

                if (teacherIDPrevious === teacherIDCurrent) {
                  newStart -= 6;
                }

                /* ---------- Payment Row ----------*/
                if (newStart + 8 >= 274) {
                  doc.addPage();
                  pageNumber++;

                  /* ---------- Header----------*/
                  doc.setFontSize(13);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportHeader, 20, 10);
                  doc.setFontSize(12);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportMonth, 78, 16);

                  /* ---------- Provider Details----------*/
                  doc.setFontSize(11);
                  doc.setFont("helvetica", "bold");
                  doc.text(reportData.providers[i].d_nameFederal, 10, 28);
                  doc.text(
                    `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                    10,
                    33
                  );
                  doc.setFont("helvetica", "normal");
                  doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
                  doc.text(reportData.providers[i].cityStateZip, 10, 43);
                  doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

                  newStart = 60;
                }
                /* Classroom */
                if (reportData.providers[i].payments[r].d_classroomName) {
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "normal");
                  doc.text(
                    `Classroom: ${reportData.providers[i].payments[r].d_classroomName}`,
                    15,
                    newStart
                  );
                }
                /* Review */
                doc.setFontSize(10);
                doc.setFont("helvetica", "normal");
                doc.text(
                  `Month: ${this.$_monthNumToText(
                    reportData.providers[i].payments[r].f_reviewMonth
                  )} ${reportData.providers[i].payments[r].d_reviewYear}`,
                  75,
                  newStart
                );
                /* Payment */
                doc.setFontSize(10);
                doc.setFont("helvetica", "normal");
                doc.text(
                  this.$_formatMoney(reportData.providers[i].payments[r].d_amountTotal),
                  200,
                  newStart,
                  "right"
                );

                teacherTotal =
                  Number(teacherTotal) + Number(reportData.providers[i].payments[r].d_amountTotal);

                if (reportData.providers[i].payments[r].d_noteExternal) {
                  if (newStart + 20 >= 274) {
                    doc.addPage();
                    pageNumber++;

                    /* ---------- Header----------*/
                    doc.setFontSize(13);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportHeader, 20, 10);
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportMonth, 78, 16);

                    /* ---------- Provider Details----------*/
                    doc.setFontSize(11);
                    doc.setFont("helvetica", "bold");
                    doc.text(reportData.providers[i].d_nameFederal, 10, 28);
                    doc.text(
                      `${reportData.providers[i].d_nameLicense} (${reportData.providers[i].d_licenseNumber})`,
                      10,
                      33
                    );
                    doc.setFont("helvetica", "normal");
                    doc.text(reportData.providers[i].d_facilityStreet1, 10, 38);
                    doc.text(reportData.providers[i].cityStateZip, 10, 43);
                    doc.text(reportData.providers[i].d_facilityPhone, 10, 48);

                    newStart = 60;
                  }
                  doc.setFontSize(10);
                  doc.setFont("helvetica", "italic");
                  doc.text(reportData.providers[i].payments[r].d_noteExternal, 20, newStart + 6);
                  doc.setFont("helvetica", "normal");
                  newStart += 4;
                }

                teacherIDPrevious = reportData.providers[i].payments[r].fk_teacherID;
              }

              /* ---------- Page Numbering ----------*/
              /* Grab total number of pages */
              totalPagesNew = doc.internal.getNumberOfPages();

              providerPages = totalPagesNew - totalPagesStart;

              if (i === 0) {
                pageNumberingStart = 0;
              } else {
                pageNumberingStart = totalPagesNew - providerPages;
              }

              // For each page, print the page number and the total pages
              for (let p = 1; p <= providerPages; p++) {
                // Go to page i
                doc.setPage(pageNumberingStart + p);
                // Print Page 1 of 4 for example
                doc.setFontSize(8);
                doc.setFont("helvetica", "bold");
                doc.text(`Page ${String(p)} of ${String(providerPages)}`, 10, 288);
              }

              if (teacherTotal || teacherTotal === 0) {
                /* Client Payment Total */
                doc.setPage(teacherTotalPage);
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(this.$_formatMoney(teacherTotal), 200, teacherTotalStart, "right");

                doc.setFont("helvetica", "normal");
                doc.setDrawColor("#000000");
                doc.setLineWidth(0.25);
              }
            }
          }

          doc.save(filename);
        } else if (this.id === 7) {
          /* 7 ---------- Client Caseload ----------*/
          /* Initialize variables */
          await this.$store.dispatch("carePlanItemsStore/clearExport");
          let filename;
          let ws_name;
          let exportData;
          const user = this.$store.getters["usersStore/getAuthUser"];
          filename = "ClientCaseload.csv";
          ws_name = "ClientCaseload";
          const filters = {
            date1: this.date1,
            d_dateEnd: this.d_dateEnd,
            fk_userID: this.fk_userID,
            f_viewPrivateRecords: user.f_viewPrivateRecords,
            showTerminatedCarePlans: this.showTerminatedCarePlans,
            export: 2
          };
          await this.$store.dispatch("carePlanItemsStore/exportExcel", filters);
          exportData = this.$store.getters["carePlanItemsStore/getExport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);
            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          } else {
            message = {
              color: "error",
              text: "No data matches requested search criteria."
            };
            this.$store.commit("messagesStore/setMessage", message);
          }
        } else if (this.id === 8) {
          /* 8 ---------- SSRS ----------*/
          await this.$store.dispatch("paymentsStore/clearExport");
          /* Initialize variables */
          let filename;
          let ws_name;
          let exportData;
          filename = "SSRS.csv";
          ws_name = "SSRS";
          const filters = {
            f_month: this.f_month,
            d_year: this.d_year,
            export: 2,
            restrictAccountingCodes: this.restrictAccountingCodes,
            f_viewPrivateRecords: 1
          };
          await this.$store.dispatch("paymentsStore/exportExcel", filters);
          exportData = this.$store.getters["paymentsStore/getExport"];
          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);
            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          } else {
            message = {
              color: "error",
              text: "No data matches requested search criteria."
            };
            this.$store.commit("messagesStore/setMessage", message);
          }
        } else if (this.id === 9) {
          /* 9 ---------- Payment Based Client Contact Info ----------*/
          await this.$store.dispatch("carePlanItemsStore/clearExport");
          /* Initialize variables */
          let filename;
          let ws_name;
          let exportData;
          const user = this.$store.getters["usersStore/getAuthUser"];
          filename = "ClientContactInfo.csv";
          ws_name = "ClientContactInfo";
          const filters = {
            date3: this.date3,
            date4: this.date4,
            dateEnd: this.dateEnd,
            f_viewPrivateRecords: user.f_viewPrivateRecords,
            export: 3
          };
          await this.$store.dispatch("carePlanItemsStore/exportExcel", filters);
          exportData = this.$store.getters["carePlanItemsStore/getExport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);
            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          } else {
            message = {
              color: "error",
              text: "No data matches requested search criteria."
            };
            this.$store.commit("messagesStore/setMessage", message);
          }
        } else if (this.id === 11) {
          /* 11 ---------- Monthly Cross Check ----------*/
          const filters = {
            page: 1,
            section: 99,
            d_licenseNumber: "",
            d_nameLicense: "",
            fk_clientID: "",
            d_childIDv1: "",
            d_nameLast: "",
            fk_userID: "",
            fk_programID: "",
            fk_classroomID: "",
            fk_householdID: "",
            f_followup: "",
            f_status: "",
            noFollowup: "",
            selectedID: "",
            d_dateEnd: "",
            d_dateStart: "",
            date1: "",
            date2: "",
            carePlanStatus: "",
            clientID: "",
            f_viewPrivateRecords: "",
            fk_programOptionID: "",
            fk_reportID: this.id,
            f_month: this.f_month,
            d_year: this.d_year
          };
          this.$store.dispatch("carePlanItemsStore/storeFilters", filters);
          this.$router.push({ name: "carePlans" });
        } else if (this.id === 13) {
          /* 13 ----------------- School Information -----------------*/
          await this.$store.dispatch("paymentsStore/clearExport");
          /* Initialize variables */
          let filename;
          let ws_name;
          let exportData;
          filename = "SchoolInfo.csv";
          ws_name = "SchoolInfo";

          const filters = {
            date3: this.date3,
            date4: this.date4,
            dateStart: this.dateStart,
            export: 3
          };
          await this.$store.dispatch("paymentsStore/exportExcel", filters);
          exportData = this.$store.getters["paymentsStore/getExport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);
            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          } else {
            message = {
              color: "error",
              text: "No data matches requested search criteria."
            };
            this.$store.commit("messagesStore/setMessage", message);
          }
        } else if (this.id === 21) {
          /* 21 ------------ Teacher Review Cross Check ------------*/
            if(this.f_month && this.d_year) {
              let month = this.f_month.toString();
              let year = this.d_year;
              let startDate = `${year}-${month.padStart(2, "0")}-${"01"}`
              const filters = {
                fk_reportID: this.id,
                f_month: this.f_month,
                d_year: this.d_year,
                d_dateApproved: startDate
              };
              this.$store.dispatch("teacherReviewStore/storeFilters", filters);
              this.$router.push({ name: "teacherReviews" });
            }
        } else if (this.id === 24 || this.id === 25 || this.id === 26 || this.id === 29) {
          /* 24 ----------------- Task Assignments -----------------*/
          //await this.$store.dispatch("tasksStore/clearExport");
          /* Initialize variables */
          let filename;
          let ws_name;
          let exportData;
          let export_cd;
          if (this.id === 24) {
            filename = "ClientTaskAssignments.csv";
            ws_name = "ClientTaskAssignments";
            export_cd = 1;
          } else if (this.id === 25) {
            filename = "ProviderTaskAssignments.csv";
            ws_name = "ProviderTaskAssignments";
            export_cd = 2;
          } else if (this.id === 26) {
            filename = "TeacherTaskAssignments.csv";
            ws_name = "TeacherTaskAssignments";
            export_cd = 3;
          } else if (this.id === 29) {
            filename = "WaitingListTaskAssignments.csv";
            ws_name = "WaitingListTaskAssignments";
            export_cd = 4;
          }
          const filters = {
            date3: this.date3,
            date4: this.date4,
            dateStart: this.dateStart,
            export: export_cd
          };
          await this.$store.dispatch("tasksStore/exportExcel", filters);
          exportData = this.$store.getters["tasksStore/getExport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);
            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          } else {
            message = {
              color: "error",
              text: "No data matches requested search criteria."
            };
            this.$store.commit("messagesStore/setMessage", message);
          }
        } else {
          message = {
            color: "error",
            text: "No data matches requested search criteria."
          };
          this.$store.commit("messagesStore/setMessage", message);
        }

        this.cancelEntry();
      }
    },

    async pullClients(providerID) {
      this.clientID = "";
      this.carePlanID = "";
      await this.$store.dispatch("clientsStore/clearValueList");
      await this.$store.dispatch("carePlansStore/clearValueList");
      const data = {
        fk_providerID: providerID
      };
      await this.$store.dispatch("clientsStore/valueList", data);
    },

    async pullCarePlans(clientID) {
      this.carePlanID = "";
      await this.$store.dispatch("carePlansStore/clearValueList");
      const data = {
        fk_clientID: clientID
      };
      await this.$store.dispatch("carePlansStore/valueList", data);
    },

    async pullTeachers(providerID) {
      this.teacherID = "";
      await this.$store.dispatch("teachersStore/clearValueList");
      const data = {
        fk_providerID: providerID
      };
      await this.$store.dispatch("teachersStore/valueList", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
