<template>
  <v-app>
    <!-- Message -->
    <message />

    <!--Change Password Dialog-->
    <v-dialog v-model="passwordDialog" persistent max-width="600">
      <v-card flat>
        <v-form ref="updatePassword" v-model="valid" lazy-validation>
          <v-card-title class="text-h5 secondary--text">
            Change Password
            <v-spacer />
            <v-btn text rounded outlined @click="closeChangePassword()">
              <v-icon small left> fal fa-times </v-icon>Cancel
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="password"
                    prepend-icon="fal fa-lock"
                    label="Password"
                    placeholder=" "
                    persistent-placeholder
                    type="password"
                    required
                    :rules="rules.password"
                  />
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="passwordConfirmation"
                    prepend-icon="fal fa-lock"
                    label="Confirm Password"
                    placeholder=" "
                    persistent-placeholder
                    type="password"
                    required
                    :rules="rules.passwordConfirm"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="mx-4 pb-4">
            <v-spacer />
            <v-btn rounded block color="primary" @click="updatePassword()">
              <v-icon small left>
                fal fa-check
              </v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!--Navigation-->
    <v-navigation-drawer
      v-if="usersStore.authUser"
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
      :floating="true"
      class="accent"
      width="280"
    >
      <v-list rounded v-if="usersStore.appMode !== 2">
        <div :key="index" v-for="(item, index) in usersStore.authUser.navigation">
          <v-list-item
            v-if="item.f_section === 1 && usersStore.appMode !== 2"
            :key="item.id"
            :to="{ name: item.d_url }"
            active-class="primary--text font-weight-bold"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.d_icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.d_title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="item.j_subnav">
            <v-list-item
              v-if="
                currentRouteName !== item.j_subnav.name && currentRoutePath === item.j_subnav.path
              "
              :key="index"
              v-for="(subnav, index) in item.j_subnav.subnav"
              :to="{ name: subnav.url }"
              active-class="primary--text font-weight-bold"
            >
              <v-list-item-content>
                <v-list-item-title class="text-body-2 ml-4">
                  &#8226; {{ subnav.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>

      <!--Admin Navigation-->
      <v-list rounded v-if="usersStore.appMode === 2">
        <div :key="index" v-for="(item, index) in navigationStore.navigationAdmin">
          <v-list-item
            :key="item.id"
            :to="{ name: item.d_url }"
            active-class="primary--text font-weight-bold"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.d_icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.d_title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="item.j_subnav">
            <v-list-item
              v-if="
                currentRouteName !== item.j_subnav.name && currentRoutePath === item.j_subnav.path
              "
              :key="index"
              v-for="(subnav, index) in item.j_subnav.subnav"
              :to="{ name: subnav.url }"
              active-class="primary--text font-weight-bold"
            >
              <v-list-item-content>
                <v-list-item-title class="text-body-2 ml-4">
                  &#8226; {{ subnav.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!--Top Toolbar-->
    <v-app-bar
      v-if="usersStore.authUser"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      dark
      flat
      app
      fixed
    >
      <!--Progress Bar-->
      <v-progress-linear
        :active="progressStore.progress"
        :indeterminate="progressStore.progress"
        height="6"
        absolute
        top
        color="secondary"
      />

      <!--Progress Pop Up-->
      <v-dialog v-model="progressStore.progress" max-width="290" persistent hide-overlay>
        <v-card color="primary" dark>
          <v-card-text>
            Loading
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--Left Side Of Toolbar-->
      <v-toolbar-title class="ml-0 pl-0">
        <v-app-bar-nav-icon class="ml-0 pl-0" @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">{{ applicationName }}</span>
      </v-toolbar-title>

      <v-spacer />

      <!--User Name When Logged In-->
      <v-flex class="text-subtitle-1" text-md-right>
        {{ usersStore.authUser.name }}
      </v-flex>

      <!--User Profile Button-->
      <v-menu v-if="usersStore.authUser" open-on-click offset-y left>
        <template #activator="{ on }">
          <v-btn v-on="on" icon large>
            <v-avatar size="32px" tile>
              <v-icon color="white">
                fas fa-user-circle
              </v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click.native="openChangePassword()">
            <v-list-item-title class="clickable primary--text">
              <v-icon color="primary">
                fal fa-key
              </v-icon>
              Change Password
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="handleLogout">
            <v-list-item-title class="clickable primary--text">
              <v-icon color="primary">
                fal fa-sign-out-alt
              </v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!--Settings Button-->
      <v-btn
        v-if="usersStore.authUser.f_admin === 1 && usersStore.appMode !== 2"
        icon
        @click="switchAppModes(2)"
      >
        <v-icon color="white">
          fal fa-cog
        </v-icon>
      </v-btn>

      <!--Home Button-->
      <v-btn
        v-if="usersStore.authUser.f_admin === 1 && usersStore.appMode === 2"
        icon
        @click="switchAppModes()"
      >
        <v-icon color="white">
          fal fa-tachometer-alt
        </v-icon>
      </v-btn>

      <!-- Help Dialog Box -->
      <v-dialog fullscreen scrollable transition="dialog-bottom-transition" v-model="helpDialog">
        <v-card>
          <v-card-title class="text-h5 secondary--text"
            >Help - {{ help.d_title }}<v-spacer /><v-btn
              text
              rounded
              outlined
              @click.native="closeHelp"
              ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
            ></v-card-title
          >
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <div v-html="help.d_text" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <!--Content-->
    <v-main>
      <v-container fluid fill-height>
        <v-layout justify-center child-flex>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-main>

    <!--Footer-->
    <v-footer app class="pa-3" color="grey darken-2">
      <v-flex text-xs-left class="grey--text text--lighten-1">
        {{ applicationName }} - CCSA &copy; {{ new Date().getFullYear() }} v{{ applicationVersion }}
      </v-flex>
      <v-spacer />
      <v-btn small icon class="ma-0">
        <v-icon color="grey lighten-1" @click.native="launchHelp">
          fas fa-question-circle
        </v-icon>
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Message from "@/components/Message";
import { appName, siteVersion, getHeader } from "@/env";

export default {
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      helpTopicsStore: state => state.helpTopicsStore,
      messagesStore: state => state.messagesStore,
      navigationStore: state => state.navigationStore,
      progressStore: state => state.progressStore
    }),
    currentRouteName() {
      return this.$route.name;
    },
    currentRoutePath() {
      const currentPath = this.$route.path;
      const currentPathSplit = currentPath.split("/");
      return currentPathSplit[1];
    }
  },
  components: {
    Message
  },
  data() {
    return {
      applicationName: appName,
      applicationVersion: siteVersion,
      helpDialog: false,
      help: [],
      drawer: null,
      passwordDialog: false,
      password: "",
      passwordConfirmation: "",
      valid: "",
      rules: {
        password: [v => !!v || "required"],
        passwordConfirm: [
          v => !!v || "required",
          v => v === this.password || "Passwords must match"
        ]
      }
    };
  },
  props: {
    source: String
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.$route.name === "logout") {
        this.handleLogout();
      } else {
        const userObj = JSON.parse(window.localStorage.getItem("authUser"));

        if (userObj) {
          await this.$store.dispatch("usersStore/setAuthUser", userObj);
          /* Grab app mode from local storage */
          const appMode = JSON.parse(window.localStorage.getItem("appMode"));
          if (appMode == null) {
            const appMode = 1;
          }

          if (
            this.$route.params.clientUUID ||
            this.$route.params.providerUUID ||
            this.$route.params.householdID ||
            this.$route.params.teacherUUID ||
            this.$route.params.waitingListUUID
          ) {
          } else {
            this.switchAppModes(appMode);
          }
        } else {
          this.handleLogout();
        }
      }
    },

    async switchAppModes(mode) {
      /* Store authorized user in store */
      this.$store.dispatch("usersStore/setAppMode", mode);
      /* Admin Screen */
      if (mode === 2) {
        window.localStorage.setItem("appMode", 2);
        await this.$store.dispatch("navigationStore/fetchAdmin");
        this.$router.push({ name: "agencies" });
      } else {
        window.localStorage.removeItem("appMode");
        this.$router.push({ name: "dashboard" });
      }
    },

    launchHelp() {
      axios
        .get(`/api/helptopics/help/${this.$route.name}`, { headers: getHeader() })
        .then(response => {
          this.help.d_title = response.data.d_title;
          this.help.d_text = response.data.d_text;
          this.helpDialog = true;
        })
        .catch(error => {});
    },

    handleLogout() {
      /* Send user to index */
      this.$router.push({ name: "logout" });
    },

    closeHelp() {
      this.helpDialog = false;
    },

    openChangePassword() {
      this.valid = true;
      this.password = "";
      this.passwordConfirmation = "";
      this.passwordDialog = true;
      this.$refs.updatePassword.resetValidation();
    },

    closeChangePassword() {
      this.valid = true;
      this.password = "";
      this.passwordConfirmation = "";
      this.passwordDialog = false;
    },

    updatePassword() {
      if (this.$refs.updatePassword.validate()) {
        const authUser = this.$store.getters["usersStore/getAuthUser"];
        /* Grab data from update password form */
        const data = {
          id: authUser.id,
          password: this.password
        };
        /* POST user data to Auth API */
        axios
          .post("/api/user/password", data, { headers: getHeader() })
          .then(response => {
            /* Show success notification */
            const data = {
              color: "success",
              text: "Password was reset successfully"
            };
            this.$store.commit("messagesStore/setMessage", data);
          })
          .catch(error => {
            /* Show error notification */
            const data = {
              color: "error",
              text: "Password reset error."
            };
            this.$store.commit("messagesStore/setMessage", data);
          });
        this.passwordDialog = false;
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
