import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  programs: [],
  program: {},
  programsValueList: [],
  optionsValueList: [],
  entry: {
    show: '',
    valid: '',
    section: '',
    mode: '',
    tab: '',
    id: '',
    selectedCodeID: [],
    /* -----ADD ENTRY FIELDS BELOW-----*/
    d_name: '',
    d_parentFeeFPLThreshold: '',
    j_options: [
      {
        id: '',
        d_name: '',
      },
    ],
    program_options: [
      {
        id: '',
        fk_programID: '',
        fk_fundingSourceID: '',
        f_parentFee: '',
        f_examineTuition: '',
        d_name: '',
        d_fundingCode: '',
        d_parentFeePercentage: '',
        d_parentFeePercentage2: '',
        d_parentFeePercentage3: '',
        f_status: '',
        f_show: '',
      },
    ],
    program_coding: [
      {
        id: '',
        fk_programID: '',
        fk_programOptionID: '',
        d_description: '',
        d_additionalInfoUSCitizen: '',
        d_fpl: '',
        d_smi: '',
        d_ncPrekSmi: '',
        d_familyStructure: '',
        d_eligibilityReason: '',
        d_fundingCode: '',
        f_status: '',
        f_show: '',
      },
    ],
    d_note: '',
    f_parentFee: '',
    f_status: '',
  },
  filters: {
    page: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    d_name: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_OPTIONS_VALUE_LIST(state) {
    state.optionsValueList = [];
  },
  FETCH(state, { list }) {
    state.programs = list;
  },
  CREATE(state, { data }) {
    state.programs.data.unshift(data);
  },
  READ(state, { data }) {
    state.program = data;
  },
  UPDATE(state, { data }) {
    const idx = state.programs.data.map((item) => item.id).indexOf(data.id);
    state.programs.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.programs.data.map((item) => item.id).indexOf(id);
    state.programs.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_name = data.d_name;
    state.entry.d_parentFeeFPLThreshold = data.d_parentFeeFPLThreshold;
    state.entry.j_options = data.j_options;
    state.entry.program_options = data.program_options;
    state.entry.program_coding = data.program_coding;
    state.entry.d_note = data.d_note;
    state.entry.f_parentFee = data.f_parentFee;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.tab = 0;
    state.entry.id = '';
    state.entry.d_name = '';
    state.entry.d_parentFeeFPLThreshold = '';
    state.entry.j_options = [{
      id: '',
      d_name: '',
    }];
    state.entry.program_options = [{
      id: '',
      fk_programID: '',
      fk_fundingSourceID: '',
      d_name: '',
      d_fundingCode: '',
      d_parentFeePercentage: '',
      d_parentFeePercentage2: '',
      d_parentFeePercentage3: '',
      f_parentFee: '',
      f_examineTuition: '',
      f_status: 1,
      f_show: 1,
    }];
    state.entry.program_coding = [{
      id: '',
      fk_programID: '',
      fk_programOptionID: '',
      d_description: '',
      d_additionalInfoUSCitizen: '',
      d_fpl: '',
      d_smi: '',
      d_ncPrekSmi: '',
      d_familyStructure: '',
      d_eligibilityReason: '',
      d_fundingCode: '',
      f_status: 1,
      f_show: 1,
    }];
    state.entry.d_note = '';
    state.entry.f_parentFee = '';
    state.entry.f_status = true;
    state.entry.selectedCodeID=[];
  },
  ADD_OPTION(state) {
    const data = {
      id: '',
      fk_programID: state.entry.id,
      fk_fundingSourceID: '',
      d_name: '',
      d_fundingCode: '',
      d_parentFeePercentage: '',
      d_parentFeePercentage2: '',
      d_parentFeePercentage3: '',
      f_parentFee: 1,
      f_examineTuition: '',
      f_status: 1,
      f_show: 1,
    };
    state.entry.program_options.push(data);
  },
  REMOVE_OPTION(state, { data }) {
    state.entry.program_options[data.id].f_show = data.f_show;
  },
  ADD_CODING(state) {
    const data = {
      id: '',
      fk_programID: state.entry.id,
      fk_programOptionID: '',
      d_description: '',
      d_additionalInfoUSCitizen: '',
      d_fpl: '',
      d_smi: '',
      d_ncPrekSmi: '',
      d_familyStructure: '',
      d_eligibilityReason: '',
      d_fundingCode: '',
      f_status: 1,
      f_show: 1,
    };
    state.entry.program_coding.push(data);
  },
  REMOVE_CODING(state, { data }) {
    state.entry.program_coding[data.id].f_show = data.f_show;
  },
  SELECT_DESELECT_ALL_CODING(state) {
    if (state.entry.selectedCodeID.length > 0) {
      state.entry.selectedCodeID = [];
    } else {
      let i;
      for (i = 0; i < state.entry.program_coding.length; i++) {
        state.entry.selectedCodeID.push(state.entry.program_coding[i].id);
      }
    }
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  FETCH_VALUE_LIST(state, { list }) {
    state.programsValueList = list;
  },
  FETCH_VALUE_LIST_OPTIONS(state, { list }) {
    state.optionsValueList = list;
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearOptionsValueList: ({ commit }) => {
    commit('CLEAR_OPTIONS_VALUE_LIST');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/programs', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/programs/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/programs/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/programs/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/programs/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/programs/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get('/api/programsvaluelist', { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async valueListOptions({ commit }, data) {
    try {
      const response = await axios.post('/api/programsvaluelistoptions', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST_OPTIONS', { list: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addOption({ commit }) {
    commit('ADD_OPTION');
  },
  removeOption({ commit }, data) {
    commit('REMOVE_OPTION', { data });
  },
  addCoding({ commit }) {
    commit('ADD_CODING');
  },
  removeCoding({ commit }, data) {
    commit('REMOVE_CODING', { data });
  },
  async duplicateSelectedCode({ commit }, data) {
    try {
      const response = await axios.post('/api/programs/masscreatecoding', data, { headers: getHeader() });
      commit('SELECT_DESELECT_ALL_CODING');
      return response;
    } catch (error) {

    }
  },
  selectDeselectAllCoding({ commit }) {
    commit('SELECT_DESELECT_ALL_CODING');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getProgram: (state, getters) => state.program,
  getEntry: (state, getters) => state.entry,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getSelectedCodes: (state, getters) => state.entry.selectedCodeID,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
