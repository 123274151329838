import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Client from "@/views/Client.vue";
import Provider from "@/views/Provider.vue";
import Teacher from "@/views/Teacher.vue";
import ClientsList from "@/views/ClientsList.vue";
import WaitingListItem from "@/views/WaitingListItem.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Logout.vue")
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/applications",
    name: "applications",
    component: () => import(/* webpackChunkName: "applications" */ "@/views/ApplicationsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/agencies",
    name: "agencies",
    component: () => import(/* webpackChunkName: "agencies" */ "@/views/Agencies.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/users",
    name: "users",
    component: () => import(/* webpackChunkName: "users" */ "@/views/Users.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers",
    name: "providers",
    component: () => import(/* webpackChunkName: "providersList" */ "@/views/ProvidersList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/:providerUUID",
    name: "provider",
    component: Provider,
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/preferences/:providerUUID",
    name: "providerPreferences",
    component: () =>
      import(/* webpackChunkName: "providerPreferences" */ "@/views/ProviderPreferences.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/contacts/:providerUUID",
    name: "providerContacts",
    component: () =>
      import(/* webpackChunkName: "providerContacts" */ "@/views/ProviderContacts.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/applications/:providerUUID",
    name: "providerApplications",
    component: () =>
      import(/* webpackChunkName: "providerApplications" */ "@/views/ProviderApplications.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/reimbursementrates/:providerUUID",
    name: "providerRates",
    component: () =>
      import(/* webpackChunkName: "providerProviderTuition" */ "@/views/ProviderTuition.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/clients/:providerUUID",
    name: "providerClients",
    component: () =>
      import(/* webpackChunkName: "providerClients" */ "@/views/ProviderClients.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/payments/:providerUUID",
    name: "providerPayments",
    component: () =>
      import(/* webpackChunkName: "providerPayments" */ "@/views/ProviderPayments.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/teacherreviews/:providerUUID",
    name: "providerTeacherReviews",
    component: () =>
      import(
        /* webpackChunkName: "providerTeacherPayments" */ "@/views/ProviderTeacherReviews.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/teacherpayments/:providerUUID",
    name: "providerTeacherPayments",
    component: () =>
      import(
        /* webpackChunkName: "providerTeacherPayments" */ "@/views/ProviderTeacherPayments.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/benefits/:providerUUID",
    name: "providerBenefits",
    component: () =>
      import(/* webpackChunkName: "providerBenefits" */ "@/views/ProviderBenefits.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/classrooms/:providerUUID",
    name: "providerClassrooms",
    component: () =>
      import(/* webpackChunkName: "providerClassrooms" */ "@/views/ProviderClassrooms.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/attendance/:providerUUID",
    name: "providerAttendance",
    component: () =>
      import(/* webpackChunkName: "providerAttendance" */ "@/views/ProviderAttendance.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/documents/:providerUUID",
    name: "providerDocuments",
    component: () =>
      import(/* webpackChunkName: "providerDocuments" */ "@/views/ProviderDocuments.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/correspondence/:providerUUID",
    name: "providerCorrespondence",
    component: () =>
      import(/* webpackChunkName: "providerCorrespondence" */ "@/views/ProviderCorrespondence.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/complianceactions/:providerUUID",
    name: "providerActions",
    component: () =>
      import(/* webpackChunkName: "providerActions" */ "@/views/ProviderActions.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/notes/:providerUUID",
    name: "providerNotes",
    component: () => import(/* webpackChunkName: "providerNotes" */ "@/views/ProviderNotes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/tasks/:providerUUID",
    name: "providerTasks",
    component: () => import(/* webpackChunkName: "providerTasks" */ "@/views/ProviderTasks.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/providers/audit/:providerUUID",
    name: "providerAudit",
    component: () => import(/* webpackChunkName: "providerAudit" */ "@/views/ProviderAudit.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/facilities",
    name: "facilities",
    component: () => import(/* webpackChunkName: "facilities" */ "@/views/Facilities.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/facilities/:facilityUUID",
    name: "facility",
    component: () => import(/* webpackChunkName: "facility" */ "@/views/Facility.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/:householdID?",
    name: "clients",
    component: ClientsList,
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/detail/:clientUUID",
    name: "client",
    component: Client,
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/preferences/:clientUUID",
    name: "clientPreferences",
    component: () =>
      import(/* webpackChunkName: "clientPreferences" */ "@/views/ClientPreferences.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/household/:clientUUID",
    name: "clientHousehold",
    component: () =>
      import(/* webpackChunkName: "clientHousehold" */ "@/views/ClientHousehold.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/applications/:clientUUID",
    name: "clientApplications",
    component: () =>
      import(/* webpackChunkName: "clientApplications" */ "@/views/ClientApplications.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/provider/:clientUUID",
    name: "clientProvider",
    component: () => import(/* webpackChunkName: "clientProvider" */ "@/views/ClientProvider.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/eligibility/:clientUUID",
    name: "clientEligibility",
    component: () =>
      import(/* webpackChunkName: "clientEligibility" */ "@/views/ClientEligibility.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/careplans/:clientUUID",
    name: "clientCarePlans",
    component: () =>
      import(/* webpackChunkName: "clientCarePlans" */ "@/views/ClientCarePlans.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/payments/:clientUUID",
    name: "clientPayments",
    component: () => import(/* webpackChunkName: "clientPayments" */ "@/views/ClientPayments.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/attendance/:clientUUID",
    name: "clientAttendance",
    component: () =>
      import(/* webpackChunkName: "clientAttendance" */ "@/views/ClientAttendance.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/correspondence/:clientUUID",
    name: "clientCorrespondence",
    component: () =>
      import(/* webpackChunkName: "clientCorrespondence" */ "@/views/ClientCorrespondence.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/actions/:clientUUID",
    name: "clientActions",
    component: () => import(/* webpackChunkName: "clientActions" */ "@/views/ClientActions.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/notes/:clientUUID",
    name: "clientNotes",
    component: () => import(/* webpackChunkName: "clientNotes" */ "@/views/ClientNotes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/tasks/:clientUUID",
    name: "clientTasks",
    component: () => import(/* webpackChunkName: "clientTasks" */ "@/views/ClientTasks.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/documents/:clientUUID",
    name: "clientDocuments",
    component: () =>
      import(/* webpackChunkName: "clientDocuments" */ "@/views/ClientDocuments.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/audit/:clientUUID",
    name: "clientAudit",
    component: () => import(/* webpackChunkName: "clientAudit" */ "@/views/ClientAudit.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers",
    name: "teachers",
    component: () => import(/* webpackChunkName: "teachersList" */ "@/views/TeachersList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/:teacherUUID",
    name: "teacher",
    component: Teacher,
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/license/:teacherUUID",
    name: "teacherLicense",
    component: () => import(/* webpackChunkName: "teacherLicenses" */ "@/views/TeacherLicense.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/salary/:teacherUUID",
    name: "teacherSalary",
    component: () => import(/* webpackChunkName: "teacherSalaries" */ "@/views/TeacherSalary.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/education/:teacherUUID",
    name: "teacherEducation",
    component: () =>
      import(/* webpackChunkName: "teacherEducations" */ "@/views/TeacherEducation.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/documents/:teacherUUID",
    name: "teacherDocuments",
    component: () =>
      import(/* webpackChunkName: "teacherDocuments" */ "@/views/TeacherDocuments.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/reviews/:teacherUUID",
    name: "teacherTeacherReviews",
    component: () =>
      import(/* webpackChunkName: "teacherTeacherReviews" */ "@/views/TeacherTeacherReviews.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/payments/:teacherUUID",
    name: "teacherTeacherPayments",
    component: () =>
      import(/* webpackChunkName: "teacherTeacherPayments" */ "@/views/TeacherTeacherPayments.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/classrooms/:teacherUUID",
    name: "teacherClassrooms",
    component: () =>
      import(/* webpackChunkName: "teacherClassrooms" */ "@/views/TeacherClassrooms.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/correspondence/:teacherUUID",
    name: "teacherCorrespondence",
    component: () =>
      import(/* webpackChunkName: "teacherCorrespondence" */ "@/views/TeacherCorrespondence.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/actions/:teacherUUID",
    name: "teacherActions",
    component: () => import(/* webpackChunkName: "teacherActions" */ "@/views/TeacherActions.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/notes/:teacherUUID",
    name: "teacherNotes",
    component: () => import(/* webpackChunkName: "teacherNotes" */ "@/views/TeacherNotes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/tasks/:teacherUUID",
    name: "teacherTasks",
    component: () => import(/* webpackChunkName: "teacherTasks" */ "@/views/TeacherTasks.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teachers/audit/:teacherUUID",
    name: "teacherAudit",
    component: () => import(/* webpackChunkName: "teacherAudit" */ "@/views/TeacherAudit.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import(/* webpackChunkName: "tasksList" */ "@/views/TasksList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/notes",
    name: "notes",
    component: () => import(/* webpackChunkName: "notesList" */ "@/views/NotesList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/documents",
    name: "documents",
    component: () => import(/* webpackChunkName: "documentsList" */ "@/views/DocumentsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import(/* webpackChunkName: "paymentsList" */ "@/views/PaymentsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/payments/:paymentUUID",
    name: "payment",
    component: () => import(/* webpackChunkName: "payment" */ "@/views/Payment.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teacherpayments",
    name: "teacherPayments",
    component: () =>
      import(/* webpackChunkName: "teacherPaymentsList" */ "@/views/TeacherPaymentsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teacherreviews",
    name: "teacherReviews",
    component: () =>
      import(/* webpackChunkName: "teacherReviewsList" */ "@/views/TeacherReviewsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teacherrates",
    name: "teacherRates",
    component: () => import(/* webpackChunkName: "teacherRates" */ "@/views/TeacherRates.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import(/* webpackChunkName: "contactsList" */ "@/views/ContactsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/clientimports",
    name: "clientImports",
    component: () =>
      import(/* webpackChunkName: "clientImportsList" */ "@/views/ClientImportsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/careplans",
    name: "carePlans",
    component: () => import(/* webpackChunkName: "carePlansList" */ "@/views/CarePlansList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/attendance",
    name: "attendance",
    component: () => import(/* webpackChunkName: "attendanceList" */ "@/views/AttendanceList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/waitinglist",
    name: "waitinglist",
    component: () => import(/* webpackChunkName: "waitingList" */ "@/views/WaitingList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/waitinglist/item/:waitingListUUID",
    name: "waitingListItem",
    component: WaitingListItem,
    meta: { requiresAuth: true }
  },
  {
    path: "/waitinglist/item/correspondence/:waitingListUUID",
    name: "waitingListItemCorrespondence",
    component: () =>
      import(/* webpackChunkName: "waitingListCorrespondence" */ "@/views/WaitingListItemCorrespondence.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/waitinglist/item/actions/:waitingListUUID",
    name: "waitingListItemActions",
    component: () => import(/* webpackChunkName: "waitingListActions" */ "@/views/WaitingListItemActions.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/waitinglist/item/notes/:waitingListUUID",
    name: "waitingListItemNotes",
    component: () => import(/* webpackChunkName: "waitingListNotes" */ "@/views/WaitingListItemNotes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/waitinglist/item/tasks/:waitingListUUID",
    name: "waitingListItemTasks",
    component: () => import(/* webpackChunkName: "waitingListTasks" */ "@/views/WaitingListItemTasks.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/waitinglist/item/audit/:waitingListUUID",
    name: "waitingListItemAudit",
    component: () => import(/* webpackChunkName: "waitingListAudit" */ "@/views/WaitingListItemAudit.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/checkrequests",
    name: "checkRequests",
    component: () =>
      import(/* webpackChunkName: "checkRequestsList" */ "@/views/CheckRequestsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/checkrequests/:checkRequestUUID",
    name: "checkRequest",
    component: () => import(/* webpackChunkName: "checkRequest" */ "@/views/CheckRequest.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import(/* webpackChunkName: "reportsList" */ "@/views/ReportsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/fundingsources",
    name: "fundingSources",
    component: () => import(/* webpackChunkName: "fundingSources" */ "@/views/FundingSources.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/accountingcodes",
    name: "accountingCodes",
    component: () =>
      import(/* webpackChunkName: "accountingCodes" */ "@/views/AccountingCodes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/schoolzones",
    name: "schoolZones",
    component: () => import(/* webpackChunkName: "schoolZones" */ "@/views/SchoolZones.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/rates",
    name: "rates",
    component: () => import(/* webpackChunkName: "rates" */ "@/views/Rates.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/notetopics",
    name: "noteTopics",
    component: () => import(/* webpackChunkName: "noteTopics" */ "@/views/NoteTopics.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/counties",
    name: "counties",
    component: () => import(/* webpackChunkName: "counties" */ "@/views/Counties.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/helptopics",
    name: "helpTopics",
    component: () => import(/* webpackChunkName: "helpTopics" */ "@/views/HelpTopics.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/templates",
    name: "templates",
    component: () => import(/* webpackChunkName: "templates" */ "@/views/Templates.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/eligibilityrules",
    name: "eligibilityRules",
    component: () =>
      import(/* webpackChunkName: "eligibilityRules" */ "@/views/EligibilityRules.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/emailsettings",
    name: "emailSettings",
    component: () => import(/* webpackChunkName: "emailSettings" */ "@/views/EmailSettings.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/tasktypes",
    name: "taskTypes",
    component: () => import(/* webpackChunkName: "taskTypes" */ "@/views/TaskTypes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/mergecodes",
    name: "mergeCodes",
    component: () => import(/* webpackChunkName: "mergeCodes" */ "@/views/MergeCodes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/actiontypes",
    name: "actionTypes",
    component: () => import(/* webpackChunkName: "actionTypes" */ "@/views/ActionTypes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/documenttypes",
    name: "documentTypes",
    component: () => import(/* webpackChunkName: "documentTypes" */ "@/views/DocumentTypes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/programs",
    name: "programs",
    component: () => import(/* webpackChunkName: "programs" */ "@/views/Programs.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/correspondence",
    name: "correspondence",
    component: () =>
      import(/* webpackChunkName: "correspondenceList" */ "@/views/CorrespondenceList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/incomeclassifications",
    name: "incomeClassifications",
    component: () =>
      import(/* webpackChunkName: "incomeClassifications" */ "@/views/IncomeClassifications.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/ratings",
    name: "ratings",
    component: () => import(/* webpackChunkName: "ratings" */ "@/views/Ratings.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/agecategories",
    name: "ageCategories",
    component: () => import(/* webpackChunkName: "ageCategories" */ "@/views/AgeCategories.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/timeoptions",
    name: "timeOptions",
    component: () => import(/* webpackChunkName: "timeOptions" */ "@/views/TimeOptions.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/years",
    name: "years",
    component: () => import(/* webpackChunkName: "years" */ "@/views/Years.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/valuelists",
    name: "valueLists",
    component: () => import(/* webpackChunkName: "valueLists" */ "@/views/ValueLists.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/reportssettings",
    name: "reportsSettings",
    component: () =>
      import(/* webpackChunkName: "reportsSettings" */ "@/views/ReportsSettings.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/messageboarditems",
    name: "messageBoardItems",
    component: () =>
      import(
        /* webpackChunkName: "messageBoardSettings" */ "@/views/SettingsMessageBoardItems.vue"
      ),
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

/**
 * Protect routes
 */
router.beforeEach((to, from, next) => {
  let logout = 0;
  /* If going to logout route */
  if (to.name === "logout") {
    window.localStorage.removeItem("timer");
  } else if (to.name === "login" || to.name === "passwordreset") {
    /* If login route */
  } else {
    /* All other routes */
    /* Grab previous timer */
    let prevDate = JSON.parse(window.localStorage.getItem("timer"));
    /* If previous date is null */
    if (prevDate === null) {
      /* Set previous date with current timestamp */
      prevDate = new Date();
    } else {
      /* Turn previous date into date */
      prevDate = new Date(prevDate);
    }
    /* Grab current Date */
    const currentDate = new Date();
    /* Calculate difference between dates */
    const diff = (currentDate.getTime() - prevDate.getTime()) / 1000;
    /* If difference is greater than 4 hours */
    if (diff > 14400) {
      /* Set logout variable */
      logout = 1;
    } else {
      /* Update timer */
      window.localStorage.setItem("timer", JSON.stringify(new Date()));
    }
  }

  /* If logout variable was set */
  if (logout === 1) {
    next({ name: "logout" });
  } else if (to.meta.requiresAuth) {
    /* All routes that require authentication */
    const authUser = JSON.parse(window.localStorage.getItem("authUser"));
    if (authUser && authUser.access_token) {
      next();
    } else {
      next({ name: "logout" });
    }
  } else {
    next();
  }
});

export default router;
