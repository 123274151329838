<template>
  <div>

    <v-toolbar flat dense>

      <record-navigation
        :show="providersStore.providers.data && providersStore.providers.data.length > 0"
        :section="2"
        :currentIndex="providersStore.currentIndex"
        :lastRecord="providersStore.providers.total"
        :from="providersStore.providers.from"
        :to="providersStore.providers.to"
      />

      <v-spacer />

      <v-toolbar-items>
        <!--Bookmark-->
        <bookmark :key="providersStore.provider.id" :bookmark-section="1" />

        <!--Add Contact-->
        <contact-entry :contact-section="1" />

        <!--Add Tuition-->
        <tuition-entry />

        <!--Add Payment-->
        <v-tooltip v-if="1 == 2" top color="primary">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon
                @click="addPayment()"
                color="primary"
              >
                fal fa-money-bill-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Add Payment</span>
        </v-tooltip>

        <!--Add Classroom-->
        <classroom-entry />

        <!--Add Employee Benefits-->
        <benefit-entry :benefit-section="2"/>

        <!--Add Attendance-->
        <attendance-entry v-if="1 == 2" :attendance-section="2" />

        <!--Add Document-->
        <document-entry :document-section="2" />

        <!--Add Correspondence-->
        <correspondence-entry :corres-section="2" />

        <!--Add Action-->
        <action-entry :action-section="2" />

        <!--Add Note-->
        <note-entry :note-section="2" />

        <!--Task-->
        <task-entry :task-section="2" />

      </v-toolbar-items>
    </v-toolbar>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import RecordNavigation from '@/components/RecordNavigation';
import TaskEntry from '@/components/TaskEntry';
import NoteEntry from '@/components/NoteEntry';
import ContactEntry from '@/components/ContactEntry';
import Bookmark from '@/components/Bookmark';
import DocumentEntry from '@/components/DocumentEntry';
import ActionEntry from '@/components/ActionEntry';
import TuitionEntry from '@/components/TuitionEntry';
import ClassroomEntry from '@/components/ClassroomEntry';
import BenefitEntry from '@/components/BenefitEntry';
import CorrespondenceEntry from '@/components/CorrespondenceEntry';
import AttendanceEntry from '@/components/AttendanceEntry';

export default {
  name: 'ProviderFunctionBar',
  computed: {
    ...mapState({
      providersStore: (state) => state.providersStore,
    }),
  },
  components: {
    RecordNavigation,
    TaskEntry,
    NoteEntry,
    ContactEntry,
    Bookmark,
    ActionEntry,
    DocumentEntry,
    TuitionEntry,
    ClassroomEntry,
    BenefitEntry,
    CorrespondenceEntry,
    AttendanceEntry,
  },
  methods: {
    addPayment() {

    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
