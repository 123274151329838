import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  audits: [],
  audit: {},
  filters: {
    page: "",
    section: "",
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: "",
    fk_facilityID: "",
    fk_householdID: "",
    fk_clientID: "",
    fk_teacherID: "",
    fk_waitingListID: "",
    user_id: "",
    auditable_id: "",
    f_section: ""
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_AUDITS(state) {
    state.audits = [];
  },
  FETCH(state, { list }) {
    state.audits = list;
  },
  READ(state, { data }) {
    state.audit = data;
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearAudits: ({ commit }) => {
    commit("CLEAR_AUDITS");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/audits", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/audits/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  }
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getAudit: (state, getters) => state.audit,
  getFilterField(state) {
    return getField(state.filters);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
