import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  attendance: [],
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_providerID: '',
    d_year: '',
    d_note: '',
    f_month: '',
    f_followup: '',
    f_status: '',
    items: [
      {
        id: '',
        fk_attendanceID: '',
        fk_clientID: '',
        d_dateStartCarePlan: '',
        d_dateEndCarePlan: '',
        d_dateStartOverride: '',
        d_dateEndOverride: '',
        d_daysPresent: '',
        d_daysAbsent: '',
        d_daysClosed: '',
        d_daysTotal: '',
        d_noteExternal: '',
        d_noteInternal: '',
        d_amountCollected: '',
        d_parentFee: '',
        d_reasonAbsent: '',
        f_followup: '',
        f_status: 1,
        date1: '',
        date2: '',
        date3: '',
        date4: '',
      },
    ],
  },
  filters: {
    page: '',
    filtered: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: '',
    d_year: '',
    f_month: '',
    f_followup: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.attendance = list;
  },
  CREATE(state, { data }) {
    state.attendance.data.unshift(data);
  },
  UPDATE(state, { data }) {
    const idx = state.attendance.data.map((item) => item.id).indexOf(data.id);
    state.attendance.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.attendance.data.map((item) => item.id).indexOf(id);
    state.attendance.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.d_year = data.d_year;
    state.entry.d_note = data.d_note;
    state.entry.f_month = data.f_month;
    state.entry.f_followup = data.f_followup;
    state.entry.items = data.items;
    state.entry.f_status = data.f_status;
    state.entry.show = 1;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.d_year = '';
    state.entry.d_note = '';
    state.entry.f_month = '';
    state.entry.f_followup = '';
    state.entry.items = [{
      id: '',
      fk_attendanceID: '',
      fk_clientID: '',
      d_dateStartCarePlan: '',
      d_dateEndCarePlan: '',
      d_dateStartOverride: '',
      d_dateEndOverride: '',
      d_daysPresent: '',
      d_daysAbsent: '',
      d_daysClosed: '',
      d_daysTotal: '',
      d_noteExternal: '',
      d_noteInternal: '',
      d_amountCollected: '',
      d_parentFee: '',
      d_reasonAbsent: '',
      f_followup: '',
      f_status: 1,
      date1: '',
      date2: '',
      date3: '',
      date4: '',
    }];
    state.entry.f_status = 1;
  },
  ADD_ITEM(state) {
    const data = {
      id: '',
      fk_attendanceID: state.entry.id,
      fk_clientID: '',
      d_dateStartCarePlan: '',
      d_dateEndCarePlan: '',
      d_dateStartOverride: '',
      d_dateEndOverride: '',
      d_daysPresent: '',
      d_daysAbsent: '',
      d_daysClosed: '',
      d_daysTotal: '',
      d_noteExternal: '',
      d_noteInternal: '',
      d_amountCollected: '',
      d_parentFee: '',
      d_reasonAbsent: '',
      f_followup: '',
      f_status: 1,
      date1: '',
      date2: '',
      date3: '',
      date4: '',
    };
    state.entry.items.push(data);
  },
  REMOVE_ITEM(state, { data }) {
    state.entry.items[data.id].f_show = data.f_show;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/attendance', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/attendance/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/attendance/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/attendance/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/attendance/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/attendance/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addItem({ commit }) {
    commit('ADD_ITEM');
  },
  removeItem({ commit }, data) {
    commit('REMOVE_ITEM', { data });
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
