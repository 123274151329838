import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  users: [],
  usersValueList: [],
  authUser: null,
  appMode: null,
  entry: {
    show: false,
    valid: "",
    mode: "",
    id: "",
    d_nameFirst: "",
    d_nameLast: "",
    email: "",
    password: "",
    d_color: "",
    f_bcc: "",
    f_counselor: "",
    f_admin: "",
    f_carePlanEdit: "",
    f_carePlanStatus: "",
    f_attendanceEdit: "",
    f_attendanceStatus: "",
    f_paymentEdit: "",
    f_paymentStatus: "",
    f_paymentDuplicate: "",
    f_teacherReviewEdit: "",
    f_teacherReviewStatus: "",
    f_teacherPaymentEdit: "",
    f_teacherPaymentStatus: "",
    f_teacherPaymentDuplicate: "",
    f_viewPrivateRecords: "",
    f_delete: "",
    f_access: "",
    j_navSelected: [],
    j_reportsSelected: []
  },
  filters: {
    page: "",
    d_nameFirst: "",
    d_nameLast: ""
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.users = list;
  },
  CREATE(state, { data }) {
    state.users.data.unshift(data);
  },
  READ(state, { data }) {
    state.user = data;
  },
  UPDATE(state, { data }) {
    const idx = state.users.data.map(item => item.id).indexOf(data.id);
    state.users.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.users.data.map(item => item.id).indexOf(id);
    state.users.data.splice(idx, 1);
  },
  SET_AUTH_USER(state, userObj) {
    state.authUser = userObj;
  },
  SET_APP_MODE(state, mode) {
    state.appMode = mode;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_nameFirst = data.d_nameFirst;
    state.entry.d_nameLast = data.d_nameLast;
    state.entry.email = data.email;
    state.entry.password = "";
    state.entry.d_color = data.d_color;
    state.entry.f_bcc = data.f_bcc;
    state.entry.f_counselor = data.f_counselor;
    state.entry.f_admin = data.f_admin;
    state.entry.f_carePlanEdit = data.f_carePlanEdit;
    state.entry.f_carePlanStatus = data.f_carePlanStatus;
    state.entry.f_attendanceEdit = data.f_attendanceEdit;
    state.entry.f_attendanceStatus = data.f_attendanceStatus;
    state.entry.f_paymentEdit = data.f_paymentEdit;
    state.entry.f_paymentStatus = data.f_paymentStatus;
    state.entry.f_paymentDuplicate = data.f_paymentDuplicate;
    state.entry.f_teacherReviewEdit = data.f_teacherReviewEdit;
    state.entry.f_teacherReviewStatus = data.f_teacherReviewStatus;
    state.entry.f_teacherPaymentEdit = data.f_teacherPaymentEdit;
    state.entry.f_teacherPaymentStatus = data.f_teacherPaymentStatus;
    state.entry.f_teacherPaymentDuplicate = data.f_teacherPaymentDuplicate;
    state.entry.f_viewPrivateRecords = data.f_viewPrivateRecords;
    state.entry.f_delete = data.f_delete;
    state.entry.f_access = data.f_access;
    state.entry.j_navSelected = data.j_navSelected;
    state.entry.j_reportsSelected = data.j_reportsSelected;
    state.entry.show = true;
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.usersValueList = list;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/users", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/users/create", data, { headers: getHeader() });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/users/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/users/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/users/delete", data, { headers: getHeader() });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/users/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post("/api/usersvaluelist", data, { headers: getHeader() });
      commit("FETCH_VALUE_LIST", { list: response.data });
    } catch (error) {}
  },
  setAuthUser: ({ commit }, userObj) => {
    commit("SET_AUTH_USER", userObj);
  },
  setAppMode: ({ commit }, mode) => {
    commit("SET_APP_MODE", mode);
  }
};

const getters = {
  getAuthUser: (state, getters) => state.authUser,
  getUsers: (state, getters) => state.users,
  getEntry: (state, getters) => state.entry,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
