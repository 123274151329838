<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="carePlanSection === 1" :title-value="'Care Plans'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-clipboard-list-check</v-icon>
        <v-toolbar-title class="pl-2">Care Plans</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="800px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Provider License-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_licenseNumber"
                        label="Provider License #"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Provider Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLicense"
                        label="Provider Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Household ID-->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="fk_householdID"
                        label="Household ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Client ID-->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="fk_clientID"
                        label="Client ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>

                    <!--v1 Child ID-->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="d_childIDv1"
                        label="v1 Child ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Client Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLast"
                        label="Client Last Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Specialist-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="usersStore.usersValueList"
                        label="Specialist"
                        placeholder=" "
                        persistent-placeholder
                        item-text="name"
                        item-value="id"
                        v-model="fk_userID"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Program-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.programsValueList"
                        label="Program"
                        placeholder=" "
                        persistent-placeholder
                        item-text="d_name"
                        item-value="id"
                        v-model="fk_programID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullProgramOptions(fk_programID)"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      (fk_programID && programsStore.optionsValueList.length > 0) ||
                        (fk_programID && fk_programOptionID)
                    "
                  >
                    <!--Program Options-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.optionsValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Program Option"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_programOptionID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-else dense>
                    <text-display
                      :displayLabel="'Program Option'"
                      :displayString="'Select Program First'"
                    />
                  </v-row>

                  <v-row dense v-if="1 === 2">
                    <!--Classroom-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[10]"
                        item-text="d_name"
                        item-value="id"
                        label="Classroom"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_classroomID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Option Start Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu2"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateStart"
                            label="Care Plan Option Start Date (>= date entered)"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            @blur="date2 = $_parseDate(d_dateStart)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date2"
                          @input="menu2 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Check In Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateEnd"
                            label="Eligibility Check-In Date (>= date entered)"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            @blur="date1 = $_parseDate(d_dateEnd)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date1"
                          @input="menu = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Follow Up-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[23]"
                        item-text="d_name"
                        item-value="id"
                        label="Follow Up Reason"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_followup"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <!--No Follow Up-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >No Follow Up Required</label
                      >
                      <v-checkbox
                        v-model="noFollowup"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Care Plan Status-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.carePlanStatus"
                        item-text="value"
                        item-value="id"
                        label="Care Plan Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="carePlanStatus"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Care Plan Option Status-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.carePlanItemStatus"
                        item-text="value"
                        item-value="id"
                        label="Care Plan Option Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <export-excel
        :section="38"
        :enabled="
          carePlanItemsStore.carePlanItems.data && carePlanItemsStore.carePlanItems.data.length > 0
        "
      />

      <care-plan-item-mass-change />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="
        carePlanItemsStore.carePlanItems.data && carePlanItemsStore.carePlanItems.data.length > 0
      "
      :showTotal="true"
      :currentPage="carePlanItemsStore.carePlanItems.current_page"
      :lastPage="carePlanItemsStore.carePlanItems.last_page"
      :total="carePlanItemsStore.carePlanItems.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="
          carePlanItemsStore.carePlanItems.data && carePlanItemsStore.carePlanItems.data.length > 0
        "
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">Care Plan ID</th>
              <th class="text-left grey--text text--darken-1">Provider<br />License</th>
              <th class="text-left grey--text text--darken-1">Provider<br />Name</th>
              <th class="text-left grey--text text--darken-1">Program<br />Option</th>
              <th class="text-left grey--text text--darken-1">Certification<br />Start Date</th>
              <th class="text-left grey--text text--darken-1">Certification<br />End Date</th>
              <th class="text-left grey--text text--darken-1">Start Date</th>
              <th class="text-left grey--text text--darken-1">Eligibility<br />Check-In Date</th>
              <th class="text-left grey--text text--darken-1">Time</th>
              <th class="text-left grey--text text--darken-1">Follow Up</th>
              <th class="text-right grey--text text--darken-1">Status</th>
              <th>
                <v-btn rounded @click="selectDeselectAll()" color="primary" class="py-0"
                  ><v-icon small left>fal fa-check-double</v-icon>All</v-btn
                >
              </th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in carePlanItemsStore.carePlanItems.data" :key="item.id">
            <tr v-if="item.header">
              <th class="text-subtitle-2 text-left grey white--text" colspan="12">
                <span class="pr-4">{{ item.header }}</span>
                <v-chip v-if="item.care_plan.client.f_privateRecord === 1" color="error"
                  ><v-icon small left>fal fa-lock</v-icon>Private Record</v-chip
                >
                <v-tooltip v-if="carePlanSection === 1" top color="primary">
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="detail(item.care_plan.client.uuid)">
                      <v-icon color="white">
                        fal fa-arrow-alt-square-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Go To Client</span>
                </v-tooltip>
              </th>
            </tr>
            <tr class="clickable">
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.fk_carePlanID }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.care_plan.provider ? item.care_plan.provider.d_licenseNumber : "" }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.care_plan.provider ? item.care_plan.provider.d_nameLicense : "" }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.program_option ? item.program_option.d_name : "" }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatDate(item.care_plan.d_dateStart) }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatDate(item.care_plan.d_dateEnd) }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatDate(item.d_dateStart) }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatDate(item.d_dateEnd) }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.time_option ? item.time_option.d_name : "" }}
              </td>
              <td
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                <v-icon v-if="item.f_followup" left medium>fal fa-comment-alt-check</v-icon>
              </td>
              <td
                v-if="item.f_status === 4 && item.care_plan.f_status !== 4"
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-right error--text font-weight-bold"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_carePlanItemStatus(item.f_status) }}
              </td>
              <td
                v-else
                @click="
                  openEntry(
                    item.fk_carePlanID,
                    item.care_plan.fk_providerID,
                    item.care_plan.fk_clientID
                  )
                "
                class="text-right light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_carePlanItemStatus(item.f_status) }}
              </td>
              <td :class="item.followup ? item.followup.d_color : ''">
                <v-checkbox
                  dense
                  :key="index"
                  v-model="selectedID"
                  :value="item.id"
                  color="primary"
                  multiple
                  class="py-2"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Shows Care Plans that do not have care plan items -->
          <template v-if="carePlanSection === 2">
            <tbody v-for="item in carePlanItemsStore.carePlansNoItems.data" :key="item.id">
              <tr v-if="item.header">
                <th class="text-subtitle-2 text-left grey white--text" colspan="12">
                  <span class="pr-4">{{ item.header }}</span>
                  <v-chip v-if="item.client.f_privateRecord === 1" color="error"
                    ><v-icon small left>fal fa-lock</v-icon>Private Record</v-chip
                  >
                </th>
              </tr>
              <tr class="clickable">
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ item.id }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ item.provider ? item.provider.d_licenseNumber : "" }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ item.provider ? item.provider.d_nameLicense : "" }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ item.program_option ? item.program_option.d_name : "N/A" }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ $_formatDate(item.d_dateStart) }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ $_formatDate(item.d_dateEnd) }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ $_formatDate(item.d_dateStart) }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  {{ $_formatDate(item.d_dateEnd) }}
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                  N/A
                </td>
                <td
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-left light-blue--text text--darken-4"
                >
                </td>
                <td
                  v-if="item.f_status === 4 && item.f_status !== 4"
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-right error--text font-weight-bold"
                >
                  N/A
                </td>
                <td
                  v-else
                  @click="
                    openEntry(
                      item.id,
                      item.fk_providerID,
                      item.fk_clientID
                    )
                  "
                  class="text-right light-blue--text text--darken-4"
                >
                  N/A
                </td>
                <td>
                  <!--removed checkmark-->
                </td>
              </tr>
            </tbody>
          </template>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Care Plan Options Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import CarePlan from "@/components/CarePlan";
import CarePlanItemMassChange from "@/components/CarePlanItemMassChange";
import TextDisplay from "@/components/TextDisplay";
import ExportExcel from "@/components/ExportExcel";

const { mapFields } = createHelpers({
  getterType: "carePlanItemsStore/getFilterField",
  mutationType: "carePlanItemsStore/updateFilterField"
});

export default {
  name: "CarePlanItems",
  components: {
    TitleBar,
    Pagination,
    CarePlanItemMassChange,
    TextDisplay,
    ExportExcel,
    CarePlan
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      carePlanItemsStore: state => state.carePlanItemsStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore,
      programsStore: state => state.programsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "page",
      "section",

      "d_licenseNumber",
      "d_nameLicense",
      "fk_clientID",
      "d_childIDv1",
      "fk_programID",
      "fk_userID",
      "fk_classroomID",
      "fk_householdID",
      "d_nameLast",
      "f_followup",
      "f_status",
      "noFollowup",
      "selectedID",
      "date1",
      "d_dateEnd",
      "d_dateStart",
      "date2",
      "carePlanStatus",
      "clientID",
      "f_viewPrivateRecords",
      "fk_programOptionID",
      "fk_reportID",
      "f_month",
      "d_year"
    ])
  },
  watch: {
    date1() {
      this.d_dateEnd = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateStart = this.$_formatDate(this.date2);
    }
  },
  props: {
    /* All List = 1, Client Sublist = 2 */
    carePlanSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: "",
      menu2: "",
      filterMenu: false
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.carePlanSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Client */
      if (this.carePlanSection === 2) {
        const client = this.$store.getters["clientsStore/getClient"];
        this.clientID = client.id;
      } else {
      /* All list */
        this.clientID = "";
      }

      const user = this.$store.getters["usersStore/getAuthUser"];
      this.f_viewPrivateRecords = user.f_viewPrivateRecords;

      /* Grab filters */
      const filters = this.$store.getters["carePlanItemsStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("carePlanItemsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      const specialist = {
        f_counselor: 1
      };
      const data = {
        id: [23]
      };
      await this.$store.dispatch("valueListsStore/items", data);
      await this.$store.dispatch("usersStore/valueList", specialist);
      await this.$store.dispatch("programsStore/valueList");
    },

    async filter(page) {
      this.filterMenu = false;
      this.selectedID = [];
      this.page = page;
      this.initialize(1);
    },

    clearFilterColumns() {
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.fk_clientID = "";
      this.d_childIDv1 = "";
      this.fk_programID = "";
      this.d_nameLast = "";
      this.fk_userID = "";
      this.fk_classroomID = "";
      this.fk_householdID = "";
      this.f_followup = "";
      this.f_status = "";
      this.noFollowup = "";
      this.selectedID = [];
      this.d_dateEnd = "";
      this.date1 = "";
      this.d_dateStart = "";
      this.date2 = "";
      this.carePlanStatus = "";
      (this.fk_programOptionID = ""), (this.fk_reportID = "");
      this.f_month = "";
      this.d_year = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.carePlanSection !== this.section && this.section !== 99) {
        this.clearFilterColumns();
        this.$store.dispatch("carePlanItemsStore/clearCarePlanItems");
      }
      /* Save section in store */
      this.section = this.carePlanSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_licenseNumber ||
        this.d_nameLicense ||
        this.fk_userID ||
        this.fk_clientID ||
        this.d_childIDv1 ||
        this.fk_programID ||
        this.fk_programOptionID ||
        this.fk_householdID ||
        this.d_nameLast ||
        this.f_followup ||
        this.d_dateEnd ||
        this.d_dateStart ||
        this.f_status ||
        this.f_status === 0 ||
        this.noFollowup ||
        this.carePlanStatus ||
        this.carePlanStatus === 0 ||
        this.fk_reportID
      ) {
        return true;
      }
      return false;
    },

    async pullProgramOptions(id) {
      this.fk_programOptionID = "";
      const data = {
        fk_programID: id
      };
      await this.$store.dispatch("programsStore/valueListOptions", data);
    },

    /* async openEntry(id) {
      let data = {
        id : [9, 23]
      }
      await this.$store.dispatch('valueListsStore/items', data);
      await this.$store.dispatch('programsStore/valueList');
      await this.$store.dispatch('timeOptionsStore/valueList');
		  await this.$store.dispatch('carePlanItemsStore/entry', id);
    }, */

    async openEntry(id, fk_providerID, fk_clientID) {
      const data = {
        id: fk_clientID
      };
      await this.$store.dispatch("clientsStore/readByID", fk_clientID);
      const client = this.$store.getters["clientsStore/getClient"];
      const data2 = {
        fk_householdID: client.fk_householdID
      };
      await this.$store.dispatch("householdsStore/income", data2);
      await this.$store.dispatch("householdsStore/read", client.fk_householdID);
      const data3 = {
        id: [9, 23]
      };
      await this.$store.dispatch("valueListsStore/items", data3);
      await this.$store.dispatch("providersStore/valueList");
      await this.$store.dispatch("programsStore/valueList");
      await this.$store.dispatch("timeOptionsStore/valueList");
      await this.$store.dispatch("countiesStore/valueList");
      await this.$store.dispatch("providersStore/readByID", fk_providerID);
      // await this.$store.dispatch('carePlansStore/read', id);
      await this.$store.dispatch("carePlansStore/entry", id);
    },

    selectDeselectAll() {
      this.$store.dispatch("carePlanItemsStore/selectDeselectAll");
    },

    async detail(uuid) {
      const routeData = this.$router.resolve({ name: "client", params: { clientUUID: uuid } });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
tbody {
  border-bottom: 1px solid #a9a9a9;
}
</style>
