<template>
  <div>
    <v-tooltip v-if="taskSection === 2 || taskSection === 3 || taskSection === 5 || taskSection === 6" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-tasks
          </v-icon>
        </v-btn>
      </template>
      <span>Add Task</span>
    </v-tooltip>

    <!--Task Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="700">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Task Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Due Date-->
                <v-col cols="12" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_date"
                        label="Due Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Client-->
                <v-col cols="12" sm="12" v-if="!fk_providerID && !fk_teacherID && !fk_waitingListID">
                  <v-select
                    :items="clientsStore.clientsValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Client"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_clientID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="taskTypesStore.taskTypesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                  />
                </v-col>

                <!--Subject-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_subject"
                    label="Subject"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.not_empty"
                  />
                </v-col>

                <!--Task Note-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="d_note"
                    label="Note"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Author-->
                <v-col v-if="author" cols="12" sm="12" md="6">
                  <text-display :displayLabel="'Author'" :displayString="author.name" />
                </v-col>

                <!--Assigned To-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="usersStore.usersValueList"
                    item-text="name"
                    item-value="id"
                    label="Assigned To"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_userID_Assigned"
                    clearable
                    clear-icon="fal fa-times-circle"
                    :rules="rules.not_empty"
                  />
                </v-col>

                <!--Alert-->
                <v-col v-if="mode === 1" cols="12" sm="12" md="6">
                  <v-autocomplete
                    :items="valueListsStore.taskEmailAlert"
                    label="Email Alert"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_alert"
                    @input="f_alert = $event || 0"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    :items="valueListsStore.taskStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    required
                    :rules="f_status === 0 ? [] : rules.not_empty"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="tasksStore.filters.section === 1 || tasksStore.filters.section === 4"
                dense
              >
                <v-col cols="12" sm="12">
                  <v-btn
                    v-if="mode !== 1"
                    block
                    rounded
                    color="primary"
                    @click="
                      detail(
                        client ? 2 : fk_householdID > 0 ? 3 : provider ? 1 : teacher ? 5 : 6,

                        client ? client.uuid :
                        fk_householdID > 0 ? fk_householdID :
                        provider ? provider.uuid :
                        teacher ? teacher.uuid :
                        waitingListItem.uuid
                      )
                    "
                    ><v-icon small left>{{
                      client ? "fal fa-user-circle" :
                      provider ? "fal fa-school" :
                      teacher ? "fal fa-chalkboard-teacher" :
                      "fal fa-list-alt"
                    }}</v-icon
                    >{{
                      client ? "Go To Client" :
                      fk_householdID > 0 ? "Go To Household" :
                      provider ? "Go To Provider" :
                      teacher ? "Go To Teacher" :
                      "Go to Waiting List"
                    }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "tasksStore/getEntryField",
  mutationType: "tasksStore/updateEntryField"
});

export default {
  name: "TaskEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      tasksStore: state => state.tasksStore,
      taskTypesStore: state => state.taskTypesStore,
      usersStore: state => state.usersStore,
      valueListsStore: state => state.valueListsStore,
      clientsStore: state => state.clientsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",
      "id",
      "fk_providerID",
      "fk_householdID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "fk_userID_Assigned",
      "fk_userID_Author",
      "d_date",
      "f_alert",
      "d_subject",
      "d_note",
      "f_type",
      "f_status",
      "author",
      "provider",
      "client",
      "teacher",
      "waitingListItem",
      "date1"
    ])
  },
  props: {
    /* All List = 1, Provider = 2, Client = 3, Dashboard = 4, Teacher = 5, WaitingList = 6 */
    taskSection: {
      type: Number,
      required: false
    }
  },
  components: {
    TextDisplay
  },
  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      menu: false,
      rules: {
        not_empty: [v => !!v || ""]
      }
    };
  },
  methods: {
    async newEntry() {
      let data;
      /* Provider */
      if (this.taskSection === 2) {
        data = {
          f_type: 1
        };
      } else if (this.taskSection === 3) {
      /* Client */
        data = {
          f_type: 2
        };
      } else if (this.taskSection === 5) {
      /* Teacher */
        data = {
          f_type: 3
        };
      } else if (this.taskSection === 6) {
      /* Waiting List */
        data = {
          f_type: 4
        };
      }
      await this.$store.dispatch("taskTypesStore/valueList", data);

      const specialist = {
        f_counselor: ""
      };
      await this.$store.dispatch("usersStore/valueList", specialist);

      /* Provider Section */
      if (this.taskSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      } else if (this.taskSection === 3) {
      /* Client Section */
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_householdID = client.fk_householdID;
        (this.fk_providerID = ""), (this.fk_clientID = ""), (this.fk_teacherID = ""), (this.fk_waitingListID = "");

        const data3 = {
          fk_providerID: "",
          fk_teacherID: "",
          fk_householdID: client.fk_householdID
        };
        await this.$store.dispatch("clientsStore/valueList", data3);
      } else if (this.taskSection === 5) {
      /* Teacher Section */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_teacherID = teacher.id;
        this.fk_waitingListID = "";
      } else if (this.taskSection === 6) {
      /* Waiting List Section */
        const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = waitingListItem.id;
      }
      this.valid = true;
      this.section = this.taskSection;
      this.mode = 1;
      this.id = "";
      this.fk_userID_Assigned = "";
      this.fk_userID_Author = "";
      this.d_date = "";
      this.f_alert = 0;
      this.d_subject = "";
      this.d_note = "";
      this.f_type = "";
      this.f_status = 2;
      this.date1 = "";
      this.author = "";
      this.provider = "";
      this.client = "";
      this.teacher = "";
      this.waitingListItem = "";
      this.show = 1;

      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation();
      }
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["tasksStore/getEntry"];
        /* Create Task */
        if (this.mode === 1) {
          const user = this.$store.getters["usersStore/getAuthUser"];
          this.fk_userID_Author = user.id;
          await this.$store.dispatch("tasksStore/create", entry);
          if (entry.f_alert === 1 || entry.f_alert === 3) {
            const response = await this.$store.dispatch("correspondenceStore/email", entry);
            let message;
            if (response && response.status === 200) {
              message = {
                color: "success",
                text: "Email has been sent."
              };
            } else {
              message = {
                color: "error",
                text: "There is an issue with sending the email at this time."
              };
            }
            this.$store.commit("messagesStore/setMessage", message);
          }
        } else {
        /* Edit Task */
          await this.$store.dispatch("tasksStore/update", entry);
          if (entry.f_alert > 1 && entry.f_status !== 2) {
            entry.f_complete = 1;
            if (entry.f_status === 1) {
              entry.subjectHeader = "(Completed)"
            } else if (entry.f_status === 0) {
              entry.subjectHeader = "(Voided)"
            }
            await this.$store.dispatch("correspondenceStore/email", entry);
          }
        }
        this.cancelEntry();
      }
    },

    async detail(mode, uuid) {
      this.$store.dispatch("tasksStore/clearSection");
      /* If provider related task */
      if (mode === 1) {
        this.$router.push({ name: "provider", params: { providerUUID: uuid } });
      } else if (mode === 5) {
      /* If teacher related task */
        this.$router.push({ name: "teacher", params: { teacherUUID: uuid } });
      } else if (mode === 6) {
      /* If waiting list related task */
        this.$router.push({ name: 'waitingListItem', params: { waitingListUUID: uuid } });
      } else if (mode === 3) {
        this.$router.push({ name: "clients", params: { householdID: uuid } });
      } else {
      /* If client related task */
        this.$router.push({ name: "client", params: { clientUUID: uuid } });
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
