import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  clients: [],
  clientsSearch: [],
  clientsValueList: [],
  export: [],
  client: null,
  clientCorres: '',
  entry: {
    show: false,
    show2: false,
    valid: '',
    mode: '',
    id: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_householdID: '',
    fk_userID: '',
    d_childIDv1: '',
    d_nameLast: '',
    d_nameMiddle: '',
    d_nameFirst: '',
    d_dateBirth: '',
    d_childNumber: '',
    d_prekSeatID: '',
    d_applicationIDsma: '',
    d_gender: '',
    d_race: [],
    d_raceOther: '',
    d_weeklyHoursCare: [],
    d_verifiedDateOfBirth: '',
    d_additionalInfoHealthInsurance: '',
    d_additionalInfoUSCitizen: '',
    d_additionalInfoSpecialNeed: '',
    d_additionalInfoSpecialNeedDescribe: '',
    d_additionalInfoReceivingServices: '',
    d_dateServicePlanEnd: '',
    d_shareFinancial: '',
    d_shareFinancialName: '',
    d_shareFinancialRelationship: '',
    d_shareFinancialDescribe: '',
    d_language: '',
    d_dateCertificationStart: '',
    d_dateCertificationEnd: '',
    d_dateTerm: '',
    d_termReason: '',
    d_referralSource: '',
    d_referralDate: '',
    d_referralName: '',
    d_referralEmail: '',
    d_referralPhone: '',
    d_priorityService: [],
    d_priorityPickup: '',
    d_dualEligibility: '',
    d_otherEligiblePrograms: [],
    f_textingOptIn: '',
    d_textingPhone: '',
    d_textingName: '',
    f_privateRecord: '',
    date1: '',
    date2: '',
    date3: '',
    date4: '',
    date5: '',
    date6: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    id: '',
    fk_userID: '',
    fk_providerID: '',
    d_childIDv1: '',
    d_householdPhone: '',
    d_householdEmail: '',
    d_prekSeatID: '',
    d_applicationIDsma: '',
    fk_actionTypeID: '',
    fk_householdID: '',
    d_applicantIDv1: '',
    d_nameLast: '',
    d_nameFirst: '',
    d_nameDisplay: '',
    d_applicantNameLast: '',
    d_applicantNameFirst: '',
    d_priorityService: '',
    fk_counselorID: '',
    f_status: '',
    d_dateEnd: '',
    date1: '',
    primaryApplicant: '',
    f_viewPrivateRecords: '',
  },
  currentIndex: 0,
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_CLIENT(state) {
    state.client = '';
  },
  CLEAR_CLIENTS(state) {
    state.clients = [];
  },
  CLEAR_VALUE_LIST(state) {
    state.clientsValueList = [];
  },
  FETCH(state, { list }) {
    state.clients = list;
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.clientsValueList = list;
  },
  FETCH_SEARCH(state, { list }) {
    state.clientsSearch = list;
  },
  CREATE(state, { data }) {
    state.client = data;
  },
  READ(state, { data }) {
    state.client = data;
  },
  READ_FOR_CORRESPONDENCE(state, { data }) {
    state.clientCorres = data;
  },
  UPDATE(state, { data }) {
    state.client = data;
  },
  DELETE(state, { id }) {
    const idx = state.clients.data.map((item) => item.id).indexOf(id);
    state.clients.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_householdID = data.fk_householdID;
    state.entry.fk_userID = data.fk_userID;
    state.entry.d_childIDv1 = data.d_childIDv1;
    state.entry.d_nameLast = data.d_nameLast;
    state.entry.d_nameMiddle = data.d_nameMiddle;
    state.entry.d_nameFirst = data.d_nameFirst;
    state.entry.date1 = data.d_dateBirth;
    state.entry.d_childNumber = data.d_childNumber;
    state.entry.d_prekSeatID = data.d_prekSeatID;
    state.entry.d_applicationIDsma = data.d_applicationIDsma;
    state.entry.d_gender = data.d_gender;
    state.entry.d_race = data.d_race;
    state.entry.d_raceOther = data.d_raceOther;
    state.entry.d_weeklyHoursCare = data.d_weeklyHoursCare;
    state.entry.d_verifiedDateOfBirth = data.d_verifiedDateOfBirth;
    state.entry.d_additionalInfoHealthInsurance = data.d_additionalInfoHealthInsurance;
    state.entry.d_additionalInfoUSCitizen = data.d_additionalInfoUSCitizen;
    state.entry.d_additionalInfoSpecialNeed = data.d_additionalInfoSpecialNeed;
    state.entry.d_additionalInfoSpecialNeedDescribe = data.d_additionalInfoSpecialNeedDescribe;
    state.entry.d_additionalInfoReceivingServices = data.d_additionalInfoReceivingServices;
    state.entry.d_shareFinancial = data.d_shareFinancial;
    state.entry.d_shareFinancialName = data.d_shareFinancialName;
    state.entry.d_shareFinancialRelationship = data.d_shareFinancialRelationship;
    state.entry.d_shareFinancialDescribe = data.d_shareFinancialDescribe;
    state.entry.d_language = data.d_language;
    state.entry.date2 = data.d_dateCertificationStart;
    state.entry.date3 = data.d_dateCertificationEnd;
    state.entry.date4 = data.d_dateTerm;
    state.entry.d_termReason = data.d_termReason;
    state.entry.d_referralSource = data.d_referralSource;
    state.entry.date5 = data.d_referralDate;
    state.entry.date6 = data.d_dateServicePlanEnd;
    state.entry.d_referralName = data.d_referralName;
    state.entry.d_referralEmail = data.d_referralEmail;
    state.entry.d_referralPhone = data.d_referralPhone;
    state.entry.d_priorityService = data.d_priorityService;
    state.entry.d_priorityPickup = data.d_priorityPickup;
    state.entry.d_dualEligibility = data.d_dualEligibility;
    state.entry.d_otherEligiblePrograms = data.d_otherEligiblePrograms;
    state.entry.f_textingOptIn = data.household.f_textingOptIn;
    state.entry.d_textingPhone = data.household.d_textingPhone;
    state.entry.d_textingName = data.household.d_textingName;
    state.entry.f_privateRecord = data.f_privateRecord;
    //state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_householdID = '';
    state.entry.fk_userID = '';
    state.entry.d_childIDv1 = '';
    state.entry.d_nameLast = '';
    state.entry.d_nameMiddle = '';
    state.entry.d_nameFirst = '';
    state.entry.d_dateBirth = '';
    state.entry.d_childNumber = '';
    state.entry.d_prekSeatID = '';
    state.entry.d_applicationIDsma = '';
    state.entry.d_gender = '';
    state.entry.d_race = [];
    state.entry.d_raceOther = '';
    state.entry.d_weeklyHoursCare = [];
    state.entry.d_verifiedDateOfBirth = '';
    state.entry.d_additionalInfoHealthInsurance = '';
    state.entry.d_additionalInfoUSCitizen = '';
    state.entry.d_additionalInfoSpecialNeed = '';
    state.entry.d_additionalInfoSpecialNeedDescribe = '';
    state.entry.d_additionalInfoReceivingServices = '';
    state.entry.d_dateServicePlanEnd = '';
    state.entry.d_shareFinancial = '';
    state.entry.d_shareFinancialName = '';
    state.entry.d_shareFinancialRelationship = '';
    state.entry.d_shareFinancialDescribe = '';
    state.entry.d_language = '';
    state.entry.d_dateCertificationStart = '';
    state.entry.d_dateCertificationEnd = '';
    state.entry.d_dateTerm = '';
    state.entry.d_termReason = '';
    state.entry.date1 = '';
    state.entry.date2 = '';
    state.entry.date3 = '';
    state.entry.date4 = '';
    state.entry.date5 = '';
    state.entry.date6 = '';
    state.entry.d_referralSource = '';
    state.entry.d_referralName = '';
    state.entry.d_referralEmail = '';
    state.entry.d_referralPhone = '';
    state.entry.d_priorityService = [];
    state.entry.d_priorityPickup = '';
    state.entry.d_dualEligibility = '';
    state.entry.d_otherEligiblePrograms = [];
    state.entry.f_textingOptIn = '';
    state.entry.d_textingPhone = '';
    state.entry.d_textingName = '';
    state.entry.f_privateRecord = '';
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  CURRENT_INDEX(state, { uuid }) {
    const idx = state.clients.data.map((item) => item.uuid).indexOf(uuid);
    state.currentIndex = idx;
  },
  READ_BY_INDEX(state, { index }) {
    state.client = state.clients.data[index];
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearClient: ({ commit }) => {
    commit('CLEAR_CLIENT');
  },
  clearClients: ({ commit }) => {
    commit('CLEAR_CLIENTS');
  },
  clearValueList: ({ commit }) => {
    commit('CLEAR_VALUE_LIST');
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/clients', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {}
  },
  async fetchSearch({ commit }, data) {
    try {
      const response = await axios.post('/api/clients', data, { headers: getHeader() });
      commit('FETCH_SEARCH', { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/clients/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/clients/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {}
  },
  async readByID({ commit }, id) {
    try {
      const response = await axios.get(`/api/clientsbyid/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {}
  },
  async readForCorrespondence({ commit }, id) {
    try {
      const response = await axios.get(`/api/clientsforcorrespondence/${id}`, {
        headers: getHeader(),
      });
      commit('READ_FOR_CORRESPONDENCE', { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/clients/update/${data.id}`, data, {
        headers: getHeader(),
      });
      commit('UPDATE', { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/clients/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/clients/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {}
  },
  async currentIndex({ commit }, uuid) {
    try {
      commit('CURRENT_INDEX', { uuid });
    } catch (error) {}
  },
  async readByIndex({ commit }, index) {
    try {
      commit('READ_BY_INDEX', { index });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post('/api/clients', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data });
    } catch (error) {}
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/clientsvaluelist', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {}
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getClients: (state, getters) => state.clients,
  getClient: (state, getters) => state.client,
  getClientCorres: (state, getters) => state.clientCorres,
  getClientsSearch: (state, getters) => state.clientsSearch,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
