<template>

<div>

  <clients :clientSection="1" />

</div>

</template>

<script>
import Clients from '@/components/Clients';

export default {
  name: 'ClientsList',
  components: {
    Clients,
  },
};
</script>

<style scoped>

</style>
