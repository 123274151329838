<template>
  <div>
    <v-tooltip v-if="carePlanSection !== 1" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-hands-heart
          </v-icon>
        </v-btn>
      </template>
      <span>Add Care Plan</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1300">
      <v-card flat class="white" height="850">
        <v-card-title class="text-h5 secondary--text"
          >Care Plan Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Details</v-tab>
                <v-tab>Options</v-tab>

                <!--Details-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Left Side-->
                          <v-col cols="12" sm="12" md="5">
                            <v-row dense>
                              <!--Client Name-->
                              <v-col cols="12" sm="12" md="6">
                                <text-display
                                  :displayLabel="'Client'"
                                  :displayString="
                                    clientsStore.client && mode === 1
                                      ? clientsStore.client.d_nameLast +
                                        ', ' +
                                        clientsStore.client.d_nameFirst
                                      : client.d_nameLast + ', ' + client.d_nameFirst
                                  "
                                />
                              </v-col>

                              <!--Client Birth Date-->
                              <v-col cols="12" sm="12" md="6">
                                <text-display
                                  :displayLabel="'Date Of Birth'"
                                  :displayString="
                                    clientsStore.client && mode === 1
                                      ? $_formatDate(clientsStore.client.d_dateBirth)
                                      : $_formatDate(client.d_dateBirth)
                                  "
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Certification Date Start-->
                              <v-col cols="12" sm="5">
                                <v-menu
                                  :close-on-content-click="false"
                                  v-model="menu"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template #activator="{ on }">
                                    <v-text-field
                                      v-on="on"
                                      v-model="d_dateStart"
                                      label="Certification Start Date"
                                      placeholder=" "
                                      persistent-placeholder
                                      prepend-icon="fal fa-calendar-alt"
                                      required
                                      @blur="date1 = $_parseDate(d_dateStart)"
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="date1"
                                    @input="menu = false"
                                    color="primary"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>

                              <!--Certification End Start-->
                              <v-col cols="12" sm="5">
                                <v-menu
                                  :close-on-content-click="false"
                                  v-model="menu2"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template #activator="{ on }">
                                    <v-text-field
                                      v-on="on"
                                      v-model="d_dateEnd"
                                      label="Certification End Date"
                                      placeholder=" "
                                      persistent-placeholder
                                      prepend-icon="fal fa-calendar-alt"
                                      required
                                      @blur="date2 = $_parseDate(d_dateEnd)"
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="date2"
                                    @input="menu2 = false"
                                    color="primary"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Status-->
                              <v-col cols="12" sm="12" md="10">
                                <v-select
                                  :items="valueListsStore.carePlanStatus"
                                  item-text="value"
                                  item-value="id"
                                  label="Status"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="f_status"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Termination Date-->
                              <v-col cols="12" sm="5">
                                <v-menu
                                  :close-on-content-click="false"
                                  v-model="menu3"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template #activator="{ on }">
                                    <v-text-field
                                      v-on="on"
                                      v-model="d_dateTermination"
                                      label="Termination Date"
                                      placeholder=" "
                                      persistent-placeholder
                                      prepend-icon="fal fa-calendar-alt"
                                      required
                                      @blur="date3 = $_parseDate(d_dateTermination)"
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="date3"
                                    @input="menu3 = false"
                                    color="primary"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Agreements Signed-->
                              <v-col cols="12" sm="12" md="4">
                                <v-select
                                  :items="valueListsStore.yesNo"
                                  item-text="value"
                                  item-value="value"
                                  label="Agreements Signed"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="d_agreementsSigned"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Right Side-->
                          <v-col cols="12" sm="12" md="7">
                            <!--Provider-->
                            <v-row dense>
                              <v-col cols="12" sm="12">
                                <v-autocomplete
                                  :items="providersStore.providersValueList"
                                  label="Provider"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="d_display"
                                  item-value="id"
                                  v-model="fk_providerID"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                  @input="pullProviderDetail(fk_providerID)"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Provider License-->
                              <v-col v-if="providersStore.provider" cols="12" sm="12" md="6">
                                <text-display
                                  :displayLabel="'License Number'"
                                  :displayString="providersStore.provider.d_licenseNumber"
                                />
                              </v-col>

                              <!--Provider Star-->
                              <v-col v-if="providersStore.provider" cols="12" sm="12" md="6">
                                <text-display
                                  :displayLabel="'Rating'"
                                  :displayString="providersStore.provider.rating.d_name"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Provider Status-->
                              <v-col v-if="providersStore.provider" cols="12" sm="12" md="6">
                                <text-display
                                  :displayLabel="'Status'"
                                  :displayString="
                                    $_switchProviderStatus(providersStore.provider.f_status)
                                  "
                                />
                              </v-col>
                            </v-row>

                            <!--Provider Classrooms-->
                            <v-row
                              dense
                              v-if="
                                providersStore.provider &&
                                  providersStore.provider.classrooms &&
                                  providersStore.provider.classrooms.length > 0
                              "
                            >
                              <v-col cols="12" sm="12">
                                <v-autocomplete
                                  :items="providersStore.provider.classrooms"
                                  label="Classroom"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="d_name"
                                  item-value="id"
                                  v-model="fk_classroomID"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Placeholder-->
                              <v-col cols="12" sm="12" md="3">
                                <v-select
                                  :items="valueListsStore.yesNo"
                                  item-text="value"
                                  item-value="id"
                                  label="Placeholder"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="f_placeholder"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                              <!--Remove Care Plan-->
                              <v-col cols="12" sm="12" md="3">
                                <v-select
                                  v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                                  :items="[{text: 'Yes', value: '0'}]"
                                  item-text="text"
                                  item-value="value"
                                  label="Remove Care Plan?"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="f_show"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>

                            <!--Provider Alerts-->
                            <v-row
                              v-if="providersStore.provider && providersStore.provider.actions"
                              dense
                            >
                              <v-col cols="12" sm="12">
                                <v-alert
                                  :key="index"
                                  v-for="(item, index) in providersStore.provider.actions"
                                  :value="true"
                                  type="error"
                                  icon="fal fa-exclamation-triangle"
                                >
                                  {{ item.type.d_name }}
                                </v-alert>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row class="pb-4">
                          <v-col cols="12" sm="12">
                            <span class="text-caption grey--text"
                              >Created: {{ $_formatTimestamp(created_at) }} &#8226; Modified:
                              {{ $_formatTimestamp(updated_at) }}</span
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Items-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="12">
                            <v-alert
                              dense
                              :type="
                                f_status === 1 || f_status === 2 || f_status === 3
                                  ? 'warning'
                                  : f_status === 4
                                  ? 'success'
                                  : 'error'
                              "
                              >Care Plan Status - {{ $_switchCarePlanStatus(f_status) }}</v-alert
                            >
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-spacer />
                          <v-btn
                            rounded
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="addItem()"
                          >
                            <v-icon small left>fal fa-plus</v-icon>New</v-btn
                          >
                        </v-row>

                        <v-row dense :key="index" v-for="(item, index) in items">
                          <template v-if="item.f_show === 1">
                            <v-col cols="12" sm="12">
                              <v-row dense>
                                <!--Start Date-->
                                <v-col cols="12" sm="12" md="3">
                                  <v-text-field
                                    v-model="item.date1"
                                    label="Start Date"
                                    placeholder=" "
                                    persistent-placeholder
                                    :rules="rules.date"
                                    @blur="item.date1 = $_formatStringDate(item.date1);
                                    updateAge(index, item.date3, item.date1)"
                                  >
                                  </v-text-field>
                                </v-col>

                                <!--Eligibility Check-In Date-->
                                <v-col cols="12" sm="12" md="3">
                                  <v-text-field
                                    v-model="item.date2"
                                    label="Eligibility Check-In Date"
                                    placeholder=" "
                                    persistent-placeholder
                                    :rules="rules.date"
                                    @blur="item.date2 = $_formatStringDate(item.date2);
                                    itemsDate(index, item.date2)"
                                  >
                                  </v-text-field>
                                </v-col>

                                <v-spacer />

                                <v-col v-if="1 === 2" cols="12" sm="12" md="1">
                                  <v-btn text icon color="primary" @click="editItem(index)">
                                    <v-icon>fal fa-edit</v-icon>
                                  </v-btn>
                                </v-col>

                                <v-col cols="12" sm="12" md="1">
                                  <v-btn text icon color="primary" @click="duplicateItem(index)">
                                    <v-icon>fal fa-copy</v-icon>
                                  </v-btn>
                                </v-col>

                                <v-col
                                  v-if="usersStore.authUser.f_delete === 1"
                                  cols="12"
                                  sm="12"
                                  md="1"
                                >
                                  <v-btn text icon color="red" @click="openDeleteDialog(index, 2)">
                                    <v-icon>fal fa-trash</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>

                              <v-row dense>
                                <!--Program-->
                                <v-col cols="12" sm="5">
                                  <v-autocomplete
                                    :items="programsStore.programsValueList"
                                    label="Program"
                                    placeholder=" "
                                    persistent-placeholder
                                    item-text="d_name"
                                    item-value="id"
                                    v-model="item.fk_programID"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                    @input="pullProgramOption(index, item.fk_programID)"
                                  />
                                </v-col>

                                <!--Program Option-->
                                <v-col cols="12" sm="4">
                                  <v-autocomplete
                                    :items="item.options ? item.options : ''"
                                    label="Option"
                                    placeholder=" "
                                    persistent-placeholder
                                    item-text="d_name"
                                    item-value="id"
                                    v-model="item.fk_programOptionID"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                    @input="analyzeIncome(index)"
                                  />
                                </v-col>

                                <!--Status-->
                                <v-col cols="12" sm="12" md="3">
                                  <v-autocomplete
                                    :items="valueListsStore.carePlanItemStatus"
                                    label="Status"
                                    placeholder=" "
                                    persistent-placeholder
                                    item-text="value"
                                    item-value="id"
                                    v-model="item.f_status"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                  />
                                </v-col>
                              </v-row>

                              <v-row dense>
                                <!--Reason For Eligibilty-->
                                <v-col cols="12" sm="12" md="5">
                                  <v-select
                                    :items="valueListsStore.valueListItems[9]"
                                    item-text="d_name"
                                    item-value="d_name"
                                    label="Reason For Eligibility"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model="item.d_eligibilityReason"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                  />
                                </v-col>

                                <!--Time-->
                                <v-col cols="12" sm="12" md="3">
                                  <v-select
                                    :items="timeOptionsStore.timeOptionsValueList"
                                    item-text="d_name"
                                    item-value="id"
                                    label="Time"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model="item.fk_timeOptionID"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                    @change="pullTimeOption(index)"
                                  />
                                </v-col>

                                <!--Follow Up Reason-->
                                <v-col cols="12" sm="12" md="4">
                                  <v-select
                                    :items="valueListsStore.valueListItems[23]"
                                    item-text="d_name"
                                    item-value="id"
                                    label="Follow Up"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model="item.f_followup"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                  />
                                </v-col>
                              </v-row>

                              <v-row dense>
                                <!--County-->
                                <v-col cols="12" md="3">
                                  <v-autocomplete
                                    :items="countiesStore.countiesValueList"
                                    item-text="d_name"
                                    item-value="id"
                                    label="County"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model="item.fk_countyID_Residence"
                                    readonly
                                    append-icon=""
                                  />
                                </v-col>

                                <!--Income-->
                                <v-col cols="12" sm="12" md="2">
                                  <text-display
                                    :displayLabel="'Income'"
                                    :displayString="$_formatMoney(item.d_income)"
                                  />
                                </v-col>

                                <!--Family Size-->
                                <v-col cols="12" sm="12" md="1">
                                  <text-display
                                    :displayLabel="'Family Size'"
                                    :displayNumber="item.d_familySize"
                                  />
                                </v-col>

                                <!--Parent Fee-->
                                <v-col cols="12" sm="12" md="1">
                                  <text-display
                                    :displayLabel="'Parent Fee'"
                                    :displayString="$_formatMoney(item.d_parentFee)"
                                  />
                                </v-col>

                                <!--Daily Fee-->
                                <v-col cols="12" sm="12" md="1">
                                  <text-display
                                    :displayLabel="'Daily Fee'"
                                    :displayString="$_formatMoney(item.d_dailyFee)"
                                  />
                                </v-col>

                                <!--Primary Applicant Work Hours-->
                                <v-col cols="12" sm="12" md="2">
                                  <text-display
                                    :displayLabel="'APP Work Hours'"
                                    :displayString="item.d_workHoursPrimaryApplicant"
                                  />
                                </v-col>

                                <!--Other Parent Work Hours-->
                                <v-col cols="12" sm="12" md="2">
                                  <text-display
                                    :displayLabel="'OPG Work Hours'"
                                    :displayString="item.d_workHoursOtherParent"
                                  />
                                </v-col>
                              </v-row>

                              <v-row dense class="pb-4">
                                <!--Child DOB-->
                                <v-col cols="12" sm="12" md="1">
                                  <text-display
                                    :displayLabel="'Date Of Birth'"
                                    :displayString="item.date3"
                                  />
                                </v-col>

                                <!--Child Age-->
                                <v-col cols="12" sm="12" md="2" align="center">
                                  <text-display
                                    v-if="item.d_age || item.d_age == 0"
                                    :displayLabel="'Age At Start Date'"
                                    :displayNumber="item.d_age"
                                  />
                                  <text-display
                                    v-else
                                    :displayLabel="'Age At Start Date'"
                                    :displayString="''"
                                  />
                                </v-col>

                                <!--Child Number-->
                                <v-col cols="12" sm="12" md="2">
                                  <text-display text-align="center"
                                    :displayLabel="'Child Number'"
                                    :displayNumber="item.d_childNumber"
                                  />
                                </v-col>

                                <!--FPL-->
                                <v-col cols="12" sm="12" md="1">
                                  <text-display
                                    :displayLabel="'FPL'"
                                    :displayString="$_formatPercentage(item.d_fpl) ?? '0%'"
                                  />
                                </v-col>

                                <!--SMI-->
                                <v-col cols="12" sm="12" md="2">
                                  <text-display
                                    v-if="item.fk_programID == 3"
                                    :displayLabel="'SMI - NC PreK'"
                                    :displayString="$_formatPercentage(item.d_smi) ?? '0%'"
                                  />
                                  <text-display
                                    v-else
                                    :displayLabel="'SMI'"
                                    :displayString="$_formatPercentage(item.d_smi) ?? '0%'"
                                  />
                                </v-col>

                                <!--Primary Applicant School Status-->
                                <v-col cols="12" sm="12" md="2">
                                  <text-display
                                    :displayLabel="'APP School Status'"
                                    :displayString="item.d_schoolStatusPrimaryApplicant"
                                  />
                                </v-col>

                                <!--Other Parent School Status-->
                                <v-col cols="12" sm="12" md="2">
                                  <text-display
                                    :displayLabel="'OPG School Status'"
                                    :displayString="item.d_schoolStatusOtherParent"
                                  />
                                </v-col>
                              </v-row>

                              <v-row v-if="item.created_at" class="pb-4">
                                <v-col cols="12" sm="12">
                                  <span class="text-caption grey--text"
                                    >Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                                    Modified: {{ $_formatTimestamp(item.updated_at) }}</span
                                  >
                                </v-col>
                              </v-row>

                              <v-divider />
                              <v-divider />
                              <v-divider class="pb-4" />
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="f_show == 0 ? openDeleteDialog(id, 1) : saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Dialog Box -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="deleteScope == 2" class="text-h5">Delete Care Plan Option?</span>
          <span v-else class="text-h5">Delete Care Plan Record?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDeleteDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col v-if="deleteScope == 2" cols="12" sm="12">
                The Option will be deleted from the Care Plan. This change will take affect when the form is submitted.
              </v-col>
              <v-col v-else cols="12" sm="12">
                The care plan will be deleted from the system!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="deleteScope == 2"
            block
            rounded
            color="error"
            @click.native="removeCarePlanItem(recordID)"
            >Delete</v-btn
          >
          <v-btn
            v-else
            block
            rounded
            color="error"
            @click.native="removeCarePlan(recordID)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "carePlansStore/getEntryField",
  mutationType: "carePlansStore/updateEntryField"
});

export default {
  name: "CarePlan",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      carePlansStore: state => state.carePlansStore,
      providersStore: state => state.providersStore,
      clientsStore: state => state.clientsStore,
      programsStore: state => state.programsStore,
      valueListsStore: state => state.valueListsStore,
      timeOptionsStore: state => state.timeOptionsStore,
      householdsStore: state => state.householdsStore,
      countiesStore: state => state.countiesStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",
      "id",

      "fk_providerID",
      "fk_clientID",
      "fk_classroomID",
      "d_dateStart",
      "d_dateEnd",
      "d_dateTermination",
      "f_placeholder",
      "d_eligibilityReason",
      "d_agreementsSigned",
      "f_followup",
      "f_status",
      "f_show",
      "client",
      "provider",
      "date1",
      "date2",
      "date3",
      "time_option",
      "originalStatus",
      "created_at",
      "updated_at"
    ]),
    ...mapMultiRowFields("carePlansStore", ["entry.j_programs", "entry.items"])
  },
  components: {
    TextDisplay
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateEnd = this.$_formatDate(this.date2);
    },
    date3() {
      this.d_dateTermination = this.$_formatDate(this.date3);
    }
  },
  props: {
    /* All List = 1, Provider Sublist = 2, Client Sublist */
    carePlanSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      edit: false,
      tab: 0,
      deleteDialog: false,
      deleteScope: '', //1 - carePlan, 2 - carePlanItem
      recordID: '',
      rules: {
        date: [v => (v && /(?=(?:.*\d){8})/.test(v)) || "Date is required (MM/DD/YYYY)"],
      },
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("carePlansStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();
      const client = this.$store.getters["clientsStore/getClient"];
      const data = {
        fk_householdID: client.fk_householdID
      };
      await this.$store.dispatch("householdsStore/income", data);
      const data2 = {
        id: [9, 23]
      };
      await this.$store.dispatch("providersStore/clearProvider");
      await this.$store.dispatch("valueListsStore/items", data2);
      await this.$store.dispatch("householdsStore/read", client.fk_householdID);
      await this.$store.dispatch("timeOptionsStore/valueList");
      await this.$store.dispatch("countiesStore/valueList");
      await this.$store.dispatch("providersStore/valueList");
      await this.$store.dispatch("programsStore/valueList");

      this.tab = 0;
      this.fk_clientID = client.id;
      this.mode = 1;
      this.show = true;

      this.addItem();
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        let error = 0;
        let errorMessage;
        const user = this.$store.getters["usersStore/getAuthUser"];
        const entry = await this.$store.getters["carePlansStore/getEntry"];
        /* Check for Non-Admin users trying to save any status other than 'pending' and 'submitted for approval' */
        if (user.f_carePlanStatus !== 1) {
          for (let i = 0; i < entry.items.length; i++) {
            if (entry.items[i].originalStatus === 4 && entry.items[i].f_status !== 4) {
              error = 1;
              errorMessage = 'Care Plan Option is "Approved" and can\'t be changed.';
              break;
            } else if (
              entry.items[i].originalStatus !== entry.items[i].f_status &&
              entry.items[i].f_status > 2
            ) {
              error = 1;
              errorMessage =
                'Care Plan Option status is restricted to "Pending" or "Submitted For Review".';
              break;
            }
          }
        }
        if (error !== 1) {
          /* Create Care Plan */
          if (this.mode === 1) {
            await this.$store.dispatch("carePlansStore/create", entry);
          } else {
            /* Edit Task */
            await this.$store.dispatch("carePlansStore/update", entry);
          }
          this.cancelEntry();
        } else {
          const data = {
            color: "error",
            text: errorMessage
          };
          this.$store.commit("messagesStore/setMessage", data);
        }
      }
    },

    openDeleteDialog(id, scope) {
      this.deleteScope = scope;
      this.recordID = id;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteScope = '';
      this.deleteDialog = false;
      this.recordID = '';
    },

    async removeCarePlan(id) {
      this.closeDeleteDialog();
      const data = {
          id: id
        };
      const response = await this.$store.dispatch("carePlansStore/delete", data);
      let message;
      if (response && response.status === 200) {
        message = {
          color: "success",
          text: "Care Plan record has been deleted."
        };
        //close the care plan Details window
        this.cancelEntry();

        /* Grab filters and current client data */
        const filters = this.$store.getters["carePlanItemsStore/getFilters"];
        const client = this.$store.getters["clientsStore/getClient"];
        /* Records based on filters and client data */
        await this.$store.dispatch("carePlanItemsStore/fetch", filters);
        await this.$store.dispatch("clientsStore/readByID", client.id);
      } else {
        message = {
          color: "error",
          text: "There is an issue with deleting the care plan at this time."
        };
      }
      this.$store.commit("messagesStore/setMessage", message);
    },

    removeCarePlanItem(id) {
      this.removeItem(id);
      this.closeDeleteDialog();
    },

    async pullProviderDetail(id) {
      this.fk_classroomID = "";
      await this.$store.dispatch("providersStore/readByID", id);
    },

    async pullProgramOption(index, id) {
      const data = {
        id,
        index
      };
      await this.$store.dispatch("programsStore/read", id);
      await this.$store.dispatch("carePlansStore/programOptions", data);
      this.calculateIncomeClassification(index);
      this.calculateParentFee(index);
    },

    async pullTimeOption(index) {
      const timeOptionID = this.items[index].fk_timeOptionID;
      await this.$store.dispatch("timeOptionsStore/read", timeOptionID);
      const timeOption = await this.$store.getters["timeOptionsStore/getTimeOption"];
      const data = {
        index,
        time_option: timeOption
      };
      await this.$store.dispatch("carePlansStore/updateItemTimeOption", data);
      this.calculateIncomeClassification(index);
      this.calculateParentFee(index);
    },

    async addItem() {
      const client = this.$store.getters["clientsStore/getClient"];
      const householdIncome = this.$store.getters["householdsStore/getHouseholdIncome"];
      const household = this.$store.getters["householdsStore/getHousehold"];

      const data2 = {
        fk_householdID: client.fk_householdID
      };

      await this.$store.dispatch("employmentStore/fetch", data2);
      const employment = await this.$store.getters["employmentStore/getEmployment"];
      let workHoursPrimaryApplicant = "";
      let workHoursOtherParent = "";
      let schoolStatusPrimaryApplicant = "";
      let schoolStatusOtherParent = "";
      for (var i = 0; i < employment.length; i++) {
        /* Primary Applicant */
        if (
          employment[i].applicant.f_type === 1 &&
          employment[i].f_status === 1 &&
          employment[i].d_hoursPerWeek
        ) {
          workHoursPrimaryApplicant =
            Number(workHoursPrimaryApplicant) + Number(employment[i].d_hoursPerWeek);
        }

        /* OPG */
        if (
          employment[i].applicant.f_type === 2 &&
          employment[i].f_status === 1 &&
          employment[i].d_hoursPerWeek
        ) {
          workHoursOtherParent =
            Number(workHoursOtherParent) + Number(employment[i].d_hoursPerWeek);
        }
      }

      await this.$store.dispatch("educationStore/fetch", data2);
      const education = await this.$store.getters["educationStore/getEducation"];
      for (var i = 0; i < education.length; i++) {
        /* Primary Applicant */
        if (
          education[i].applicant.f_type === 1 &&
          education[i].f_status === 1 &&
          education[i].d_enrolled
        ) {
          schoolStatusPrimaryApplicant = education[i].d_enrolled;
        }

        /* OPG */
        if (
          education[i].applicant.f_type === 2 &&
          education[i].f_status === 1 &&
          education[i].d_enrolled
        ) {
          schoolStatusOtherParent = education[i].d_enrolled;
        }
      }

      const data = {
        d_income: householdIncome,
        fk_countyID_Residence: household.fk_countyID_Residence,
        date3: this.$_formatDate(client.d_dateBirth),
        d_dateBirth: client.d_dateBirth,
        d_familySize: household.d_familySize,
        d_childNumber: client.d_childNumber ? client.d_childNumber : 1,
        d_workHoursPrimaryApplicant: workHoursPrimaryApplicant,
        d_workHoursOtherParent: workHoursOtherParent,
        d_schoolStatusPrimaryApplicant: schoolStatusPrimaryApplicant,
        d_schoolStatusOtherParent: schoolStatusOtherParent
      };
      await this.$store.dispatch("carePlansStore/addItem", data);
    },

    removeItem(id) {
      this.$store.dispatch("carePlansStore/removeItem", id);
    },

    duplicateItem(id) {
      this.$store.dispatch("carePlansStore/duplicateItem", id);
    },

    editItem(id) {},

    async analyzeIncome(index) {
      this.calculateIncomeClassification(index);
      this.calculateParentFee(index);
    },

    updateAge(index, dateBirth, dateStart) {
      this.items[index].d_dateBirth = dateBirth ? this.$_parseDate(dateBirth) : "";
      this.items[index].d_dateStart = dateStart ? this.$_parseDate(dateStart) : "";
      this.items[index].d_age = Number(
        this.$_calculatedAge(dateBirth, this.items[index].d_dateStart)
      );
    },

    async itemsDate(index, date) {
      this.items[index].d_dateEnd = this.$_parseDate(date);
      this.calculateIncomeClassification(index);
    },

    async calculateIncomeClassification(index) {
      const dateStart = this.items[index].d_dateStart;
      const dateEnd = this.items[index].d_dateEnd;
      const income = this.items[index].d_income;
      if (dateStart && dateEnd && income) {
        let data = {
          d_date_start: dateStart,
          d_date_end: dateEnd,
          d_familySize: this.items[index].d_familySize,
          f_type: 1
        };
        await this.$store.dispatch("incomeClassificationsStore/query", data);
        const fpl = await this.$store.getters["incomeClassificationsStore/getIncomeClassification"];

        const fplAmount = income / fpl.d_incomeEnd;

        let smiType = 2; //SMI
        if (this.items[index].fk_programID === 3) {
          smiType = 3; //NC PreK SMI
        }

        data = {
          d_date_start: dateStart,
          d_date_end: dateEnd,
          d_familySize: this.items[index].d_familySize,
          f_type: smiType
        };
        await this.$store.dispatch("incomeClassificationsStore/query", data);
        const smi = await this.$store.getters["incomeClassificationsStore/getIncomeClassification"];
        const smiAmount = income / smi.d_incomeEnd;

        this.items[index].d_fpl = Math.round(fplAmount * 100) / 100;
        this.items[index].d_smi = smiAmount;
      } else {
        this.items[index].d_fpl = 0.00;
        this.items[index].d_smi = 0.0000;
      }
    },

    async calculateParentFee(index) {
      const entry = await this.$store.getters["carePlansStore/getEntry"];
      const client = this.$store.getters["clientsStore/getClient"];
      await this.$store.dispatch("providersStore/readByID", this.fk_providerID);
      const provider = this.$store.getters["providersStore/getProvider"];
      let data;
      let parentFee;
      if (entry.items[index].fk_programOptionID) {
        await this.$store.dispatch("programsStore/read", this.items[index].fk_programID);
        const program = this.$store.getters["programsStore/getProgram"];
        await this.$store.dispatch(
          "programOptionsStore/read",
          this.items[index].fk_programOptionID
        );
        const programOption = this.$store.getters["programOptionsStore/getProgramOption"];
        const fpl = entry.items[index].d_fpl;
        const time = entry.items[index].time_option
          ? entry.items[index].time_option.d_percentage
          : 1;
        const income = entry.items[index].d_income / 12;
        const childNumber = entry.items[index].d_childNumber ? entry.items[index].d_childNumber : 1;
        const parentFeeFPLThreshold = program.d_parentFeeFPLThreshold
          ? program.d_parentFeeFPLThreshold
          : 0;

        //console.log('fpl: ' + fpl)
        //console.log('time: ' + time)
        //console.log('income: ' + income)
        //console.log('parent fee threshhold: ' + parentFeeFPLThreshold)
        //console.log('child number: ' + childNumber)
        //console.log('parefent fee 1: ' + programOption.d_parentFeePercentage)
        //console.log('parefent fee 2: ' + programOption.d_parentFeePercentage2)
        //console.log('parefent fee 3: ' + programOption.d_parentFeePercentage3)

        if (
          programOption.d_parentFeePercentage > 0 ||
          programOption.d_parentFeePercentage2 > 0 ||
          programOption.d_parentFeePercentage3 > 0
        ) {
          if (program.d_parentFeeFPLThreshold > 0 && fpl < program.d_parentFeeFPLThreshold) {
            parentFee = 0.0;
          } else if (childNumber === 1) {
            parentFee = income * programOption.d_parentFeePercentage * Number(time);
          } else if (childNumber === 2) {
            parentFee = income * programOption.d_parentFeePercentage2 * Number(time);
          } else if (childNumber > 2) {
            parentFee = income * programOption.d_parentFeePercentage3 * Number(time);
          }
        } else {
          parentFee = 0.0;
        }
      } else {
        parentFee = 0.0;
      }
      data = {
        index,
        parentFee
      };
      await this.$store.dispatch("carePlansStore/updateItemParentFee", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
.theme--light.v-divider {
  border-color: rgba(149, 117, 205, 1) !important;
}
</style>
