<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon @click="newEntry()" color="primary">
            fal fa-bus-school
          </v-icon>
        </v-btn>
      </template>
      <span>Add Classroom</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1350">
      <v-card flat class="white" height="800">
        <v-card-title class="text-h5 secondary--text"
          >Classroom Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Name-->
                <v-col cols="12" sm="12" md="10">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Type-->
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    :items="valueListsStore.valueListItems[1]"
                    item-text="d_name"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="2">
                  <v-select
                    :items="valueListsStore.yesNo"
                    item-text="value"
                    item-value="id"
                    label="Active"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Show
                <v-col cols="12" sm="12" md="2">
                  <v-select
                    :items="valueListsStore.yesNo"
                    item-text="value"
                    item-value="id"
                    label="Show"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_show"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>-->
                <!--Remove Classroom-->
                <v-col cols="12" sm="12" md="2">
                  <v-select
                    v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                    :items="[{text: 'Yes', value: '0'}]"
                    item-text="text"
                    item-value="value"
                    label="Remove Classroom?"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_show"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--History-->
                <v-col cols="12" sm="12">
                  <span class="grey--text text--darken-1 text-caption pb-0">BK History</span>
                  <v-row dense>
                    <v-spacer />
                    <v-btn rounded dark color="green lighten-1" class="mb-2" @click="addHistory()">
                      <v-icon small left>fal fa-plus</v-icon>New</v-btn
                    >
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-card flat :key="index" v-for="(item, index) in history">
                        <v-container fluid>
                          <v-row dense v-if="item.f_show === 1">
                            <!--BK License-->
                            <v-col cols="12" sm="12" md="1">
                              <v-select
                                :items="valueListsStore.yesNo"
                                item-text="value"
                                item-value="id"
                                label="BK License"
                                placeholder=" "
                                persistent-placeholder
                                v-model="item.f_bk"
                                clearable
                                clear-icon="fal fa-times-circle"
                              />
                            </v-col>

                            <!--Start Date-->
                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="item.date1"
                                label="Start Date"
                                placeholder=" "
                                persistent-placeholder
                                @blur="item.date1 = $_formatStringDate(item.date1);
                                item.d_dateStart = $_parseDate(item.date1)"
                              />
                            </v-col>

                            <!--Start Date-->
                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="item.date2"
                                label="End Date"
                                placeholder=" "
                                persistent-placeholder
                                @blur="item.date2 = $_formatStringDate(item.date2);
                                item.d_dateEnd = $_parseDate(item.date2)"
                              />
                            </v-col>

                            <!--Teacher Name-->
                            <!--<v-col cols="12" sm="12" md="3">
                              <v-text-field
                                v-model="item.d_teacherName"
                                label="Teacher Name"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                hide-details
                              />
                            </v-col>-->
                            <v-col cols="12" sm="12" md="3">
                              <v-autocomplete
                                :items="teachersStore.teachersValueList"
                                item-text="d_nameDisplay"
                                item-value="id"
                                label="Teacher Name"
                                placeholder=" "
                                persistent-placeholder
                                v-model="item.fk_teacherID"
                                clearable
                                clear-icon="fal fa-times-circle"
                                @blur="item.d_teacherName =
                                  teachersStore.teachersValueList.find(arr => arr.id == item.fk_teacherID)['d_nameDisplay']"
                                :rules="rules.teacher"
                              />
                            </v-col>

                            <!--Teacher Role-->
                            <v-col cols="12" sm="12" md="2">
                              <v-autocomplete
                                :items="valueListsStore.valueListItems[34]"
                                item-text="d_name"
                                item-value="d_sort"
                                label="Teaching Role"
                                placeholder=" "
                                persistent-placeholder
                                v-model="item.f_role"
                                clearable
                                clear-icon="fal fa-times-circle"
                                :rules="rules.role"
                              />
                            </v-col>

                            <!--Status-->
                            <v-col cols="12" sm="12" md="1">
                              <v-select
                                :items="valueListsStore.yesNo"
                                item-text="value"
                                item-value="id"
                                label="Active"
                                placeholder=" "
                                persistent-placeholder
                                v-model="item.f_status"
                                clearable
                                clear-icon="fal fa-times-circle"
                              />
                            </v-col>

                            <!--Show-->
                            <v-col
                              v-if="usersStore.authUser.f_delete === 1"
                              cols="12"
                              sm="12"
                              md="1"
                            >
                              <v-btn text icon color="red" @click="removeHistory(index)">
                                <v-icon>fal fa-trash</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>

                        <v-divider />
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "classroomsStore/getEntryField",
  mutationType: "classroomsStore/updateEntryField"
});

export default {
  name: "ClassroomEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      teachersStore: state => state.teachersStore,
      classroomsStore: state => state.classroomsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_providerID",
      "fk_teacherID",
      "d_name",
      "f_type",
      "f_status",
      "f_show"
    ]),
    ...mapMultiRowFields("classroomsStore", ["entry.history"])
  },
  data() {
    return {
      menu: false,
      rules: {
        teacher: [v => !!v || "Teacher is required"],
        role: [v => !!v || "Role is required"],
      },
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("classroomsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      const provider = this.$store.getters["providersStore/getProvider"];
      this.fk_providerID = provider.id;
      const data = {
        id: [1, 34]
      };
      await this.$store.dispatch("valueListsStore/items", data);
      await this.$store.dispatch("teachersStore/valueList");

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["classroomsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("classroomsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("classroomsStore/update", entry);
        }
        this.cancelEntry();
        /* Reload List */
        this.$store.dispatch("progressStore/set", true);
        const filters = this.$store.getters["classroomsStore/getFilters"];
        await this.$store.dispatch("classroomsStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    },

    addHistory() {
      this.$store.dispatch("classroomsStore/addHistory");
    },

    removeItem(id) {
      this.$store.dispatch("classroomsStore/removeItem", id);
    },

    removeHistory(id) {
      let data;
      if (this.history[id].f_show === 1 || this.history[id].f_show === true) {
        data = {
          id,
          f_show: 0
        };
      } else {
        data = {
          id,
          f_show: 1
        };
      }
      this.$store.dispatch("classroomsStore/removeHistory", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
