<template>
  <div>
    <v-list
      v-if="show === true"
      class="py-2"
    >
      <template v-for="(item, index) in listData">

        <v-list-item
          :key="index"
          @click="openDetail(item.uuid)"
          class="my-1"
        >
          <v-list-item-content>

            <!----------Line 1---------->
            <v-list-item-title
              :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'white--text'"
            >

              <!--ID-->
              <span
                v-if="showid === true"
              >
                {{ item.id }} :
              </span>

              <!--d_nameLast-->
              <span
                v-if="item.d_nameLast"
              >
                {{ item.d_nameLast }}, {{ item.d_nameFirst }}
              </span>

              <v-chip v-if="item.f_privateRecord === 1" color="error"><v-icon small left>fal fa-lock</v-icon>Private Record</v-chip>

            </v-list-item-title>

            <!----------Line 2---------->
            <v-list-item-title
              v-if="section === 3"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Users-->
              <span
                v-if="section === 3"
              >
                {{ item.email }}
              </span>
            </v-list-item-title>

            <!----------Line 3---------->
            <!--Timestamps-->
            <v-list-item-title
              class="text-caption grey--text"
              :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
            >
              Created: {{ $_formatTimestamp(item.created_at) }}
              &#8226; Modified: {{ $_formatTimestamp(item.updated_at) }}
            </v-list-item-title>

          </v-list-item-content>

          <!----------Rught Side Of List Actions---------->
          <v-list-item-action>

              <!--Application Status-->
              <v-list-item-action-text v-if="section === 17"><span class="text-body-1" :class="item.f_status === 1 || item.f_status === true ?
              'primary--text': 'font-weight-bold green--text text--lighten-1'">{{$_applicationStatus(item
                .f_status)}}</span></v-list-item-action-text>

              <!--Waiting List Status-->
              <v-list-item-action-text v-if="section === 26"><span class="text-body-1" :class="item.f_status === 1 || item.f_status === true ?
              'font-weight-bold orange--text text--lighten-1': 'font-weight-bold green--text text--lighten-1'">{{(item.status.d_name)}}
              </span></v-list-item-action-text>

              <!--Status-->
              <v-list-item-action-text v-else-if="item.f_status == 0 || item.f_status"><span class="text-body-1" :class="item.f_status === 1 || item.f_status === true ?
              'font-weight-bold green--text text--lighten-1': 'error--text'">{{$_activeStatus(item
                .f_status)}}</span></v-list-item-action-text>

              <!--Access-->
              <v-list-item-action-text v-else-if="item.f_status == 0 || item.f_access"><span class="text-body-1" :class="item.f_access === 1 || item.f_access === true ?
              'font-weight-bold green--text text--lighten-1': 'error--text'">{{$_activeStatus(item
                .f_access)}}</span></v-list-item-action-text>

            </v-list-item-action>
        </v-list-item>

        <v-divider />

      </template>
    </v-list>

    <v-list
      v-else
      class="py-2"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="error--text text-subtitle-1 text-xs-center">
            No {{ title }} Exist
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'ListToDetail',
  mixins: [mixin],
  props: {
    show: Boolean,
    showid: Boolean,
    listData: Array,
    title: String,
    section: Number,
  },
  methods: {
    openDetail(uuid) {
      this.$emit('open-detail', uuid);
    },
  },

};
</script>

<style>

</style>
