import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  teacherPayments: [],
  export: [],
  entry: {
    show: false,
    show2: false,
    valid: "",
    mode: "",
    id: "",
    isDuplicate: "",
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_teacherReviewID: "",
    fk_providerID: "",
    fk_classroomHistoryID: "",
    fk_teacherID: "",
    fk_teacherLicenseID: "",
    fk_fundingSourceID: "",
    f_reviewMonth: "",
    d_reviewYear: "",
    f_month: "",
    d_year: "",
    d_date: "",
    d_providerTaxID: "",
    d_providerNameLicense: "",
    d_providerNameFederal: "",
    d_providerLicenseNumber: "",
    d_classroomNumber: "",
    d_classroomName: "",
    d_teacherName: "",
    d_supplementAmountTotal: "",
    d_backPayAmountTotal: "",
    d_amountOverride: "",
    d_amountTotal: "",
    d_noteExternal: "",
    d_noteInternal: "",
    d_startDate: "",
    d_yearsExperience: "",
    d_licenseIssueDate: "",
    d_licenseExpirationDate: "",
    d_licenseType: "",
    d_fundingCodeSource: "",
    d_fundingCodeSuffix: "",
    d_fundingCode: "",
    f_bk: "",
    f_adequate: "",
    f_monthSalaryCheck: "",
    f_followup: "",
    f_type: "",
    f_status: "",
    f_show: "",
    created_at: "",
    updated_at: ""
  },
  filters: {
    page: "",
    section: "",
    /* -----ADD FILTER FIELDS BELOW-----*/
    id: "",
    fk_providerID: "",
    fk_teacherID: "",
    d_licenseNumber: "",
    d_nameLicense: "",
    teacherID: "",
    d_nameFirst: "",
    d_nameLast: "",
    f_followup: "",
    noFollowup: "",
    f_status: "",
    f_month: "",
    d_year: "",
    dateStart: "",
    dateEnd: "",
    date3: "",
    date4: "",
    selectedID: [],
    f_reviewMonth: "",
    d_reviewYear: ""
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_PAYMENTS(state) {
    state.teacherPayments = [];
  },
  CLEAR_EXPORT(state) {
    state.export = [];
  },
  FETCH(state, { list }) {
    state.teacherPayments = list;
  },
  CREATE(state, { data }) {
    state.teacherPayments.data.push(data);
  },
  UPDATE(state, { data }) {
    const idx = state.teacherPayments.data.map(item => item.id).indexOf(data.id);
    const { header } = state.teacherPayments.data[idx];
    data.header = header;
    state.teacherPayments.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.teacherPayments.data.map(item => item.id).indexOf(id);
    state.teacherPayments.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  ENTRY(state, { data }) {
    state.entry.show = true;
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_teacherReviewID = data.fk_teacherReviewID;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_classroomHistoryID = data.fk_classroomHistoryID;
    state.entry.fk_teacherID = data.fk_teacherID;
    state.entry.fk_teacherLicenseID = data.fk_teacherLicenseID;
    state.entry.fk_fundingSourceID = data.fk_fundingSourceID;
    state.entry.f_reviewMonth = data.f_reviewMonth;
    state.entry.d_reviewYear = data.d_reviewYear;
    state.entry.f_month = data.f_month;
    state.entry.d_year = data.d_year;
    state.entry.d_providerTaxID = data.d_providerTaxID;
    state.entry.d_providerNameLicense = data.d_providerNameLicense;
    state.entry.d_providerNameFederal = data.d_providerNameFederal;
    state.entry.d_providerLicenseNumber = data.d_providerLicenseNumber;
    state.entry.d_classroomNumber = data.d_classroomNumber;
    state.entry.d_classroomName = data.d_classroomName;
    state.entry.d_teacherName = data.d_teacherName;
    state.entry.d_supplementAmountTotal = data.d_supplementAmountTotal;
    state.entry.d_backPayAmountTotal = data.d_backPayAmountTotal;
    state.entry.d_amountOverride = data.d_amountOverride;
    state.entry.d_amountTotal = data.d_amountTotal;
    state.entry.d_noteExternal = data.d_noteExternal;
    state.entry.d_noteInternal = data.d_noteInternal;
    state.entry.d_startDate = data.d_startDate;
    state.entry.d_yearsExperience = data.d_yearsExperience;
    state.entry.d_licenseIssueDate = data.d_licenseIssueDate;
    state.entry.d_licenseExpirationDate = data.d_licenseExpirationDate;
    state.entry.d_licenseType = data.d_licenseType;
    state.entry.d_fundingCodeSource = data.d_fundingCodeSource;
    state.entry.d_fundingCodeSuffix = data.d_fundingCodeSuffix;
    state.entry.d_fundingCode = data.d_fundingCode;
    state.entry.f_bk = data.f_bk;
    state.entry.f_adequate = data.f_adequate;
    state.entry.f_monthSalaryCheck = data.f_monthSalaryCheck;
    state.entry.f_followup = data.f_followup;
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.created_at = data.created_at;
    state.entry.updated_at = data.updated_at;
    state.entry.tab = 0;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = "";
    state.entry.id = "";
    state.entry.isDuplicate = 0;
    state.entry.fk_teacherReviewID = "";
    state.entry.fk_providerID = "";
    state.entry.fk_classroomHistoryID = "";
    state.entry.fk_teacherID = "";
    state.entry.fk_teacherLicenseID = "";
    state.entry.fk_fundingSourceID = "";
    state.entry.f_reviewMonth = "";
    state.entry.d_reviewYear = "";
    state.entry.f_month = "";
    state.entry.d_year = "";
    state.entry.d_providerTaxID = "";
    state.entry.d_providerNameLicense = "";
    state.entry.d_providerNameFederal = "";
    state.entry.d_providerLicenseNumber = "";
    state.entry.d_classroomNumber = "";
    state.entry.d_classroomName = "";
    state.entry.d_teacherName = "";
    state.entry.d_supplementAmountTotal = "";
    state.entry.d_backPayAmountTotal = "";
    state.entry.d_amountOverride = "";
    state.entry.d_amountTotal = "";
    state.entry.d_noteExternal = "";
    state.entry.d_noteInternal = "";
    state.entry.d_startDate = "";
    state.entry.d_yearsExperience = "";
    state.entry.d_licenseIssueDate = "";
    state.entry.d_licenseExpirationDate = "";
    state.entry.d_licenseType = "";
    state.entry.d_fundingCodeSource = "";
    state.entry.d_fundingCodeSuffix = "";
    state.entry.d_fundingCode = "";
    state.entry.f_bk = "";
    state.entry.f_adequate = "";
    state.entry.f_monthSalaryCheck = "";
    state.entry.f_followup = "";
    state.entry.f_type = "";
    state.entry.f_status = "";
    state.entry.f_show = "";
    state.entry.created_at = "";
    state.entry.updated_at = "";
  },
  DUPLICATE(state, { data }) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = "";
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_teacherReviewID = data.fk_teacherReviewID;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_classroomHistoryID = data.fk_classroomHistoryID;
    state.entry.fk_teacherID = data.fk_teacherID;
    state.entry.fk_teacherLicenseID = data.fk_teacherLicenseID;
    state.entry.fk_fundingSourceID = data.fk_fundingSourceID;
    state.entry.f_reviewMonth = data.f_reviewMonth;
    state.entry.d_reviewYear = data.d_reviewYear;
    state.entry.f_month = data.f_month;
    state.entry.d_year = data.d_year;
    state.entry.d_providerTaxID = data.d_providerTaxID;
    state.entry.d_providerNameLicense = data.d_providerNameLicense;
    state.entry.d_providerNameFederal = data.d_providerNameFederal;
    state.entry.d_providerLicenseNumber = data.d_providerLicenseNumber;
    state.entry.d_classroomNumber = data.d_classroomNumber;
    state.entry.d_classroomName = data.d_classroomName;
    state.entry.d_teacherName = data.d_teacherName;
    state.entry.d_supplementAmountTotal = data.d_supplementAmountTotal;
    state.entry.d_backPayAmountTotal = data.d_backPayAmountTotal;
    state.entry.d_amountOverride = data.d_amountOverride;
    state.entry.d_amountTotal = data.d_amountTotal;
    state.entry.d_noteExternal = data.d_noteExternal;
    state.entry.d_noteInternal = data.d_noteInternal;
    state.entry.d_startDate = data.d_startDate;
    state.entry.d_yearsExperience = data.d_yearsExperience;
    state.entry.d_licenseIssueDate = data.d_licenseIssueDate;
    state.entry.d_licenseExpirationDate = data.d_licenseExpirationDate;
    state.entry.d_licenseType = data.d_licenseType;
    state.entry.d_fundingCodeSource = data.d_fundingCodeSource;
    state.entry.d_fundingCodeSuffix = data.d_fundingCodeSuffix;
    state.entry.d_fundingCode = data.d_fundingCode;
    state.entry.f_bk = data.f_bk;
    state.entry.f_adequate = data.f_adequate;
    state.entry.f_monthSalaryCheck = data.f_monthSalaryCheck;
    state.entry.f_followup = "";
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.created_at = "";
    state.entry.updated_at = "";
    state.entry.show = true;
    state.entry.isDuplicate = 1;
  },
  RESET_MASS_SELECTION(state) {
    state.filters.selectedID = [];
  },
  SELECT_DESELECT_ALL(state) {
    if (state.filters.selectedID.length > 0) {
      state.filters.selectedID = [];
    } else {
      let i;
      for (i = 0; i < state.teacherPayments.data.length; i++) {
        state.filters.selectedID.push(state.teacherPayments.data[i].id);
      }
    }
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearteacherPayments: ({ commit }) => {
    commit("CLEAR_PAYMENTS");
  },
  clearExport: ({ commit }) => {
    commit("CLEAR_EXPORT");
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post("/api/teacherpayments", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherpayments/create", data, {
        headers: getHeader()
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/teacherpayments/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/teacherpayments/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async massUpdate({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherpayments/massupdate", data, {
        headers: getHeader()
      });
      return response;
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherpayments/delete", data, {
        headers: getHeader()
      });
      commit("DELETE", { id: data.id });
      return response;
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/teacherpayments/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async duplicate({ commit }, id) {
    try {
      const response = await axios.get(`/api/teacherpayments/${id}`, { headers: getHeader() });
      commit("DUPLICATE", { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post("/api/teacherpayments", data, { headers: getHeader() });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  resetMassSelection({ commit }) {
    commit("RESET_MASS_SELECTION");
  },
  selectDeselectAll({ commit }) {
    commit("SELECT_DESELECT_ALL");
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  }
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
