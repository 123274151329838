<template>
  <div>

    <!--Title Bar-->
    <title-bar v-if="messageBoardSection === 1" :title-value="'Message Board'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-thumbtack</v-icon>
        <v-toolbar-title class="pl-2">Message Board</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!--Pagination  -->
    <pagination
      :show="messageBoardItemsStore.messageBoardItems.data && messageBoardItemsStore.messageBoardItems.data.length > 0"
      :showTotal="messageBoardSection === 2 ? false : true"
      :currentPage="messageBoardItemsStore.messageBoardItems.current_page"
      :lastPage="messageBoardItemsStore.messageBoardItems.last_page"
      :total="messageBoardItemsStore.messageBoardItems.total"
      v-on:paginate-event="filter($event)" />

    <div v-if="progressStore.progress !== true">

      <!--Task List-->
      <v-list v-if="messageBoardItemsStore.messageBoardItems.data && messageBoardItemsStore.messageBoardItems.data.length > 0" two-line>
        <template v-for="(item, index) in messageBoardItemsStore.messageBoardItems.data">
          <v-list-item
            :key="item.id"
            @click="openEntry(item.id)"
            class="my-1"
          >
            <v-list-item-content>
              <v-row dense>
                <v-col cols="12" sm="12">

                  <div v-html="item.d_body" />
                  <span class="text-caption grey--text"
                      >Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                      Modified: {{ $_formatTimestamp(item.updated_at) }}</span
                    >
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <!--No Recrods Found Message-->
      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center">No Message Board Items Exist</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import Pagination from '@/components/Pagination';
import moment from 'moment-timezone';

const { mapFields } = createHelpers({
  getterType: 'messageBoardItemsStore/getFilterField',
  mutationType: 'messageBoardItemsStore/updateFilterField',
});

export default {
  name: 'MessageBoardItems',
  components: {
    TitleBar,
    Pagination,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      messageBoardItemsStore: (state) => state.messageBoardItemsStore,
      progressStore: (state) => state.progressStore,
    }),
    ...mapFields([
      'page',
      'section',

      'd_dateStart',
      'date1',
      'f_status',
      'dateCurrent',
    ]),
  },
  props: {
    /* All List = 1, Dashboard = 2, Settings = 3 */
    messageBoardSection: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      /* If not running from dashboard */
      if (this.messageBoardSection === 1) {
        this.$store.dispatch('progressStore/set', true);
      }

      await this.changeSectionCheck();

      /* Build current date for dashboard message board search */
      this.dateCurrent = moment().tz('EST').format('YYYY-MM-DD');

      /* Pull tasks list */
      const filters = this.$store.getters['messageBoardItemsStore/getFilters'];
      await this.$store.dispatch('messageBoardItemsStore/fetch', filters);

      this.$store.dispatch('progressStore/set', false);
    },

    async filter(page) {
		  this.filterMenu = false;
		  this.page = page;
		  this.initialize();
    },

    async changeSectionCheck() {
      if (this.messageBoardSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch('messageBoardItemsStore/clearMessageBoardItems');
      }
      /* Save section in store */
      this.section = this.messageBoardSection;
    },

    clearFilterColumns() {
      this.page = 1;
		  this.d_dateStart = '';
      this.f_status = '';
      this.dateCurrent = '';
    },

    async openEntry(id) {

    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
