<template>
  <div>

    <!--Table-->
    <v-simple-table
      v-if="educationStore.education && educationStore.education.length > 0 && $_checkSection(educationStore.education)"
      :fixed-header="true"
      class="py-0 my-0 blue-grey lighten-5"
    >
      <template #default>
        <thead>
          <tr>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">School</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Type</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Expected Completion</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Enrollment</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Financial Aid</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Tuition</th>
            <th class="text-right grey--text text--darken-1 blue-grey lighten-5">Total</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in educationStore.education" v-if="section === 1 && item.applicant.f_type === 1 || section === 2 && item.applicant.f_type === 2"  :key="item.id">
          <tr v-bind:style="index > 0 ? 'border-top: solid thin #dae1e7;' : ''">
            <td style="border:none" class="test text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ item.d_schoolName }}</td>
            <td style="border:none" class="test text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ item.d_type }}</td>
            <td style="border:none" class="test text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ $_formatDate(item.d_expectedCompletion) }}</td>
            <td style="border:none" class="test text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ item.d_enrolled }}</td>
            <td style="border:none" class="test text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ $_formatMoney(item.d_financialAidAmount) }}</td>
            <td style="border:none" class="test text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ $_formatMoney(item.d_tuitionAmount) }}</td>
            <td style="border:none" class="test text-right" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ totalIncome(item.d_financialAidAmount, item.d_tuitionAmount) }}</td>
          </tr>
          <tr>
            <td colspan="7" style="vertical-align: text-top; height:5px"><span class="text-caption grey--text"
                >Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                Modified: {{ $_formatTimestamp(item.updated_at) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-list v-else class="py-0 blue-grey lighten-5">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="error--text text-subtitle-2 text-xs-center">No Education
            Exists</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'Education',
  mixins: [mixin],
  computed: {
    ...mapState({
      educationStore: (state) => state.educationStore,
    }),
  },
  props: {
    /* Primary = 1, Other Parent = 2 */
    section: {
      type: Number,
      required: false,
    },
    householdID: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {

    };
  },
  methods: {

    totalIncome(aid, tuition) {
      return this.$_formatMoney(aid - tuition);
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
