<template>
  <div flat dense v-if="show" class="my-2">

    <!--First-->
    <v-btn :disabled="(currentIndex + from) <= 1" icon @click="$_recordNavigation(section,1,0)">
        <v-icon color="primary">
        fal fa-step-backward
        </v-icon>
    </v-btn>

    <!--Previous-->
    <v-btn :disabled="(currentIndex + from) <= 1"
            icon
            @click="$_recordNavigation(section,2,currentIndex - 1)">
        <v-icon color="primary">
        fal fa-caret-left
        </v-icon>
    </v-btn>

    <!--Display Of Current Record And Total Records-->
    <span>{{currentIndex + from}}
            of {{lastRecord}}</span>

    <!--Next-->
    <v-btn :disabled="(currentIndex + from) ===
    lastRecord" icon @click="$_recordNavigation(section,3,currentIndex + 1)">
        <v-icon color="primary">
        fal fa-caret-right
        </v-icon>
    </v-btn>

    <!--Last-->
    <v-btn :disabled="(currentIndex + from) ===
    lastRecord" icon @click="$_recordNavigation(section,4,lastRecord)">
        <v-icon color="primary">
        fal fa-step-forward
        </v-icon>
    </v-btn>
  </div>

</template>

<script>
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'RecordNavigation',
  mixins: [mixin],
  props: {
    show: Boolean,
    section: Number,
    currentIndex: Number,
    lastRecord: Number,
    from: Number,
    to: Number,
  },
  methods: {

  },

};
</script>

<style>
 .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
  button:focus {outline:0;}
</style>
