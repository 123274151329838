<template>
  <div>
    <v-tooltip v-if="actionSection === 2 || actionSection === 3 || actionSection === 4 || actionSection === 5" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-exclamation-triangle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Alert</span>
    </v-tooltip>

    <!--Action Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Alert Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Date-->
                <v-col cols="12" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_date"
                        label="Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date"
                        @blur="date1 = $_parseDate(d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="actionTypesStore.actionTypesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_actionTypeID"
                    :rules="rules.type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Note-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="d_note"
                    label="Note"
                    placeholder=" "
                    :rules="rules.note"
                    persistent-placeholder
                  />
                </v-col>

                <!--Show On Detail-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.yesNo"
                    label="Show On Detail"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_showOnDetail"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.actionStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    :rules="rules.status"
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "actionsStore/getEntryField",
  mutationType: "actionsStore/updateEntryField"
});

export default {
  name: "ActionEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      actionsStore: state => state.actionsStore,
      actionTypesStore: state => state.actionTypesStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",

      "id",
      "fk_providerID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "fk_userID",
      "fk_actionTypeID",
      "d_date",
      "d_note",
      "f_showOnDetail",
      "f_status",
      "date1"
    ])
  },
  props: {
    /* All List = 1, Provider = 2, Client = 3, Teacher = 4, Waiting List = 5 */
    actionSection: {
      type: Number,
      required: false
    }
  },

  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      menu: false,
      rules: {
        date: [v => !!v || "Date is required"],
        type: [v => !!v || "Alert Type is required"],
        note: [v => !!v || "Alert Description is required"],
        status: [v => !!v || /^[0-1]?$/.test(v) || "Status is required"],
      }
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("actionsStore/resetEntry");
      if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
    },

    async newEntry() {
      this.resetEntry();

      await this.loadValueLists();

      /* Provider Section */
      if (this.actionSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
      }
      /* Client Section */
      else if (this.actionSection === 3) {
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_clientID = client.id;
      }
      /* Teacher Section */
      else if (this.actionSection === 4) {
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_teacherID = teacher.id;
      }
      /* Waiting List Section */
      else if (this.actionSection === 5) {
        const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
        this.fk_waitingListID = waitingListItem.id;
      }
      this.show = true;
    },

    async loadValueLists() {
      let data;
      /* Provider */
      if (this.actionSection === 2) {
        data = {
          f_type: 1
        };
      } else if (this.actionSection === 3) {
      /* Client */
        data = {
          f_type: 2
        };
      } else if (this.actionSection === 4) {
      /* Teacher */
        data = {
          f_type: 3
        };
      } else if (this.actionSection === 5) {
      /* Waiting List */
        data = {
          f_type: 4
        };
      }
      await this.$store.dispatch("actionTypesStore/valueList", data);
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["actionsStore/getEntry"];
        /* Create Action */
        if (this.mode === 1) {
          const user = this.$store.getters["usersStore/getAuthUser"];
          this.fk_userID = user.id;
          await this.$store.dispatch("actionsStore/create", entry);
        } else {
        /* Edit Action */
          await this.$store.dispatch("actionsStore/update", entry);
        }
        if (this.actionSection === 2) {
          const provider = this.$store.getters["providersStore/getProvider"];
          await this.$store.dispatch("providersStore/read", provider.uuid);
        }
        else if (this.actionSection === 3) {
          const client = this.$store.getters["clientsStore/getClient"];
          await this.$store.dispatch("clientsStore/read", client.uuid);
        }
        else if (this.actionSection === 4) {
          const teacher = this.$store.getters["teachersStore/getTeacher"];
          await this.$store.dispatch("teachersStore/read", teacher.uuid);
        }
        else if (this.actionSection === 5) {
          const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
          await this.$store.dispatch("waitingListStore/read", waitingListItem.uuid);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
