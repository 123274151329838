import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  employment: [],
  primary: [],
  otherParent: [],
  entry: {
    show: '',
    valid: '',
    mode: '',
    items: [{
      id: '',
      fk_householdID: '',
      fk_applicantID: '',
      d_employer: '',
      d_position: '',
      d_employerCity: '',
      d_document: '',
      d_income: '',
      d_incomePeriod: '',
      d_hoursPerWeek: '',
      d_factor: '',
      f_status: '',
    }],
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.employment = list;
  },
  FETCH_PRIMARY(state, { list }) {
    state.primary = list;
  },
  FETCH_OTHER_PARENT(state, { list }) {
    state.otherParent = list;
  },
  UPDATE(state, { list }) {
    state.employment = list;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.items = data;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.items = [{
      id: '',
      fk_householdID: '',
      fk_applicantID: '',
      d_employer: '',
      d_position: '',
      d_employerCity: '',
      d_document: '',
      d_income: '',
      d_incomePeriod: '',
      d_hoursPerWeek: '',
      d_factor: '',
      f_status: 1,
    }];
  },
  ADD_ITEM(state, { data }) {
    const item = {
      id: '',
      fk_householdID: data.fk_householdID,
      fk_applicantID: '',
      d_employer: '',
      d_position: '',
      d_employerCity: '',
      d_document: '',
      d_income: '',
      d_incomePeriod: '',
      d_hoursPerWeek: '',
      d_factor: '',
      f_status: 1,
    };
    state.entry.items.unshift(item);
  },
  SET_FACTOR(state, { data }) {
    state.entry.items[data.index].d_factor = data.value;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/employment', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchPrimary({ commit }, data) {
    try {
      const response = await axios.post('/api/employment', data, { headers: getHeader() });
      commit('FETCH_PRIMARY', { list: response.data });
    } catch (error) {

    }
  },
  async fetchOtherParent({ commit }, data) {
    try {
      const response = await axios.post('/api/employment', data, { headers: getHeader() });
      commit('FETCH_OTHER_PARENT', { list: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.post('/api/employment/update', data, { headers: getHeader() });
      commit('UPDATE', { list: response.data });
    } catch (error) {

    }
  },
  async entry({ commit }, data) {
    try {
      const response = await axios.post('/api/employment', data, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  addItem({ commit }, data) {
    commit('ADD_ITEM', { data });
  },
  setFactor({ commit }, data) {
    commit('SET_FACTOR', { data });
  },
};

const getters = {
  getEmployment: (state, getters) => state.employment,
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
