<template>
  <div>
    <!--Add Bookmark Note Popup-->
    <v-dialog v-model="show" persistent max-width="500">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Bookmark Memo<v-spacer /><v-btn text rounded outlined @click="show=false"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="bookmarkMemoForm" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_note"
                    :rules="rules.note"
                    counter="50"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" :disabled="disabled" @click="saveBookmark()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click.shift.exact="showMemo()" @click.exact="saveBookmark()">
          <v-icon v-if="bookmarksStore.bookmark && bookmarksStore.bookmark.f_status === 1" color="amber darken-1">fas fa-bookmark</v-icon>
          <v-icon v-else
            color="primary"
          >
            fal fa-bookmark
          </v-icon>
        </v-btn>
      </template>
      <span v-if="bookmarksStore.bookmark && bookmarksStore.bookmark.f_status === 1">
        Bookmark
      </span>
      <span v-else>
        Bookmark ("shift-click" to add memo)
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'Bookmark',
  mixins: [mixin],
  computed: {
    ...mapState({
      bookmarksStore: (state) => state.bookmarksStore,
    }),
  },
  props: {
    /* Provider = 1, Client = 2, Teacher = 3, WaitingList = 4*/
    bookmarkSection: {
      type: Number,
      required: false,
    },
  },

  watch: {
    d_note() {
      if (this.d_note && this.$refs.bookmarkMemoForm.validate()) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },

  data() {
    return {
      d_note: '',
      show: false,
      disabled: true,
      rules:{
        note: [v => v.length <= 50 || 'Max 50 characters'],
      }
    };
  },
  methods: {
    showMemo() {
      if (this.bookmarksStore.bookmark && this.bookmarksStore.bookmark.f_status === 1) {
        this.show = false;
      } else {
        this.show = true;
      }
    },

    async saveBookmark() {
      const bookmark = this.$store.getters['bookmarksStore/getBookmark'];

      const data = {
        id: bookmark.id,
      };

      /* Provider */
      if (this.bookmarkSection === 1) {
        const provider = this.$store.getters['providersStore/getProvider'];
        data.fk_providerID = provider.id;
        data.fk_clientID = '';
        data.fk_teacherID = '';
        data.fk_waitingListID = '';
      }
      /* Client */
      else if (this.bookmarkSection === 2) {
        const client = this.$store.getters['clientsStore/getClient'];
        data.fk_providerID = '';
        data.fk_clientID = client.id;
        data.fk_teacherID = '';
        data.fk_waitingListID = '';
      }
      /* Teacher */
      else if (this.bookmarkSection === 3) {
        const teacher = this.$store.getters['teachersStore/getTeacher']; //this needs to populate properly
        data.fk_providerID = '';
        data.fk_clientID = '';
        data.fk_teacherID = teacher.id;
        data.fk_waitingListID = '';
      }
      /* Waiting List */
      else if (this.bookmarkSection === 4) {
        const waitingListItem = this.$store.getters['waitingListStore/getWaitingListItem']; //this needs to populate properly
        data.fk_providerID = '';
        data.fk_clientID = '';
        data.fk_teacherID = '';
        data.fk_waitingListID = waitingListItem.id;
      }

      const user = this.$store.getters['usersStore/getAuthUser'];

      data.fk_userID = user.id;
      if (this.d_note) data.d_note = this.d_note;

      /* Update */
      if (bookmark) {
        await this.$store.dispatch('bookmarksStore/update', data);
      }
      /**/
      else {
        await this.$store.dispatch('bookmarksStore/create', data);
      }
      this.d_note = '';
      if (this.show) this.show = false;
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
