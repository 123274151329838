import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  valueLists: [],
  valueListsCategories: [],
  valueListItems: [],
  months: [
    {
      id: 1,
      value: "January"
    },
    {
      id: 2,
      value: "February"
    },
    {
      id: 3,
      value: "March"
    },
    {
      id: 4,
      value: "April"
    },
    {
      id: 5,
      value: "May"
    },
    {
      id: 6,
      value: "June"
    },
    {
      id: 7,
      value: "July"
    },
    {
      id: 8,
      value: "August"
    },
    {
      id: 9,
      value: "September"
    },
    {
      id: 10,
      value: "October"
    },
    {
      id: 11,
      value: "November"
    },
    {
      id: 12,
      value: "December"
    }
  ],
  classificationTypes: [
    {
      id: 1,
      value: "FPL"
    },
    {
      id: 2,
      value: "SMI"
    },
    {
      id: 3,
      value: "SMI NC-PreK"
    }
  ],
  correspondenceTypes: [
    {
      id: 1,
      value: "Email"
    },
    {
      id: 2,
      value: "Letter"
    }
  ],
  attendanceItemStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Denied"
    },
    {
      id: 3,
      value: "Approved"
    }
  ],
  teacherReviewStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Denied"
    },
    {
      id: 3,
      value: "Approved"
    }
  ],
  clientImportStatus: [
    {
      id: 1,
      value: "Pending Import"
    },
    {
      id: 2,
      value: "Imported - New Household"
    },
    {
      id: 5,
      value: "Imported - Match Household"
    },
    {
      id: 3,
      value: "Error"
    },
    {
      id: 4,
      value: "Skipped"
    },
    {
      id: 6,
      value: "Ignore"
    }
  ],
  correspondenceSections: [
    {
      id: 1,
      value: "Provider"
    },
    {
      id: 2,
      value: "Client"
    },
    {
      id: 3,
      value: "Teacher"
    },
    {
      id: 4,
      value: "Waiting List"
    }
  ],
  generalSections: [
    {
      id: 1,
      value: "Provider"
    },
    {
      id: 2,
      value: "Client"
    },
    {
      id: 3,
      value: "Teacher"
    },
    {
      id: 4,
      value: "Waiting List"
    }
  ],
  facilityStatus: [
    {
      id: 1,
      value: "Active"
    },
    {
      id: 2,
      value: "Terminated"
    }
  ],
  genericEmail: [
    {
      id: 1,
      value: "scholarship@childcareservices.org"
    },
    {
      id: 2,
      value: "familyscholarship@childcareservices.org"
    }
  ],
  carePlanStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Submitted For Review"
    },
    {
      id: 3,
      value: "Returned For Correction"
    },
    {
      id: 4,
      value: "Approved"
    },
    {
      id: 5,
      value: "Denied"
    },
    {
      id: 6,
      value: "Terminated"
    }
  ],
  carePlanStatusNoAdmin: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Submitted For Review"
    }
  ],
  carePlanItemStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Submitted For Review"
    },
    {
      id: 3,
      value: "Returned For Correction"
    },
    {
      id: 4,
      value: "Approved"
    },
    {
      id: 5,
      value: "Denied"
    }
  ],
  carePlanItemStatusNonAdmin: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Submitted For Review"
    }
  ],
  facilityTypes: [
    {
      id: 1,
      value: "Center"
    },
    {
      id: 2,
      value: "Family CC Home"
    },
    {
      id: 3,
      value: "Unlicensed Program"
    }
  ],
  checkRequestStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 0,
      value: "Canceled"
    },
    {
      id: 2,
      value: "Approved"
    }
  ],
  activeStatus: [
    {
      id: 1,
      value: "Active"
    },
    {
      id: 0,
      value: "Inactive"
    }
  ],
  applicationStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Complete"
    }
  ],
  actionStatus: [
    {
      id: 1,
      value: "Open"
    },
    {
      id: 0,
      value: "Completed"
    }
  ],
  actionTypes: [
    {
      id: 1,
      value: "Provider"
    },
    {
      id: 2,
      value: "Client"
    },
    {
      id: 3,
      value: "Teacher"
    },
    {
      id: 4,
      value: "Waiting List"
    }
  ],
  clientStatus: [
    {
      id: 1,
      value: "Active"
    },
    {
      id: 0,
      value: "Inactive"
    }
  ],
  ratingTypes: [
    {
      id: 1,
      value: "All"
    },
    {
      id: 2,
      value: "Provider Only"
    }
  ],
  providerStatus: [
    {
      id: 1,
      value: "Prospective"
    },
    {
      id: 2,
      value: "Active"
    },
    {
      id: 3,
      value: "Under Review"
    },
    {
      id: 4,
      value: "Inactive"
    },
    {
      id: 5,
      value: "Denied"
    }
  ],
  paymentStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Error"
    },
    {
      id: 3,
      value: "Approved"
    },
    {
      id: 0,
      value: "Voided"
    }
  ],
  teacherPaymentStatus: [
    {
      id: 1,
      value: "Pending"
    },
    {
      id: 2,
      value: "Error"
    },
    {
      id: 3,
      value: "Approved"
    },
    {
      id: 0,
      value: "Voided"
    }
  ],
  paymentType: [
    {
      id: 1,
      value: "Payment"
    },
    {
      id: 2,
      value: "Adjustment"
    }
  ],
  yesNo: [
    {
      id: 1,
      value: "Yes"
    },
    {
      id: 0,
      value: "No"
    }
  ],
  yesNoNa: [
    {
      id: 1,
      value: "Yes"
    },
    {
      id: 0,
      value: "No"
    },
    {
      id: null,
      value: "N/A"
    }
  ],
  providerLocations: [
    {
      id: 1,
      value: "Location"
    },
    {
      id: 2,
      value: "Business"
    }
  ],
  overageType: [
    {
      id: 1,
      value: "Flat"
    },
    {
      id: 2,
      value: "Percentage"
    },
    {
      id: 3,
      value: "Full"
    }
  ],
  waitingListStatus: [
    {
      id: 1,
      value: "Active"
    },
    {
      id: 2,
      value: "Awarded"
    },
    {
      id: 9,
      value: "Family Declined"
    }
  ],
  contactStatus: [
    {
      id: 1,
      value: "Active"
    },
    {
      id: 0,
      value: "Inactive"
    }
  ],
  contactMethodTypes: [
    {
      id: 1,
      value: "Phone"
    },
    {
      id: 2,
      value: "Mobile"
    },
    {
      id: 3,
      value: "Email"
    },
    {
      id: 4,
      value: "Fax"
    }
  ],
  taskStatus: [
    {
      id: 2,
      value: "Open"
    },
    {
      id: 1,
      value: "Completed"
    },
    {
      id: 0,
      value: "Voided"
    }
  ],
  taskEmailAlert: [
    {
      id: 1,
      value: "Send Assignment Alert"
    },
    {
      id: 2,
      value: "Receive Completion Alert"
    },
    {
      id: 3,
      value: "Apply All Alerts"
    }
  ],
  taskTypes: [
    {
      id: 1,
      value: "Provider"
    },
    {
      id: 2,
      value: "Client"
    },
    {
      id: 3,
      value: "Teacher"
    },
    {
      id: 4,
      value: "Waiting List"
    }
  ],
  systemSection: [
    {
      id: 1,
      value: "Client"
    },
    {
      id: 2,
      value: "Client Household"
    },
    {
      id: 3,
      value: "Client Care Plan"
    },
    {
      id: 4,
      value: "Provider"
    },
    {
      id: 5,
      value: "Client Care Plan Option"
    },
    {
      id: 6,
      value: "Client Care Plan Option"
    }
  ],
  letterheadType: [
    {
      id: 1,
      value: "CCSA"
    },
    {
      id: 2,
      value: "Durham PreK"
    }
  ],
  auditSectionsClient: [
    {
      id: 6,
      value: "Applicant"
    },
    {
      id: 7,
      value: "Applications"
    },
    {
      id: 10,
      value: "Bookmark"
    },
    {
      id: 11,
      value: "Care Plan Item"
    },
    {
      id: 12,
      value: "Care Plan"
    },
    {
      id: 15,
      value: "Client"
    },
    {
      id: 16,
      value: "Contact Methods"
    },
    {
      id: 18,
      value: "Correspondence"
    },
    {
      id: 20,
      value: "Dependent"
    },
    {
      id: 22,
      value: "Document"
    },
    {
      id: 23,
      value: "Education"
    },
    {
      id: 24,
      value: "Employment"
    },
    {
      id: 28,
      value: "Household"
    },
    {
      id: 29,
      value: "Income"
    },
    {
      id: 35,
      value: "Note"
    },
    {
      id: 36,
      value: "Payment"
    },
    {
      id: 45,
      value: "Task"
    }
  ],
  auditSectionsProvider: [
    {
      id: 3,
      value: "Alert"
    },
    {
      id: 8,
      value: "Attendance"
    },
    {
      id: 10,
      value: "Bookmark"
    },
    {
      id: 13,
      value: "Classroom History"
    },
    {
      id: 14,
      value: "Classroom"
    },
    {
      id: 16,
      value: "Contact Method"
    },
    {
      id: 17,
      value: "Contact"
    },
    {
      id: 18,
      value: "Correspondence"
    },
    {
      id: 22,
      value: "Document"
    },
    {
      id: 35,
      value: "Note"
    },
    {
      id: 36,
      value: "Payment"
    },
    {
      id: 40,
      value: "Provider"
    },
    {
      id: 45,
      value: "Task"
    },
    {
      id: 48,
      value: "Tuition"
    }
  ],
  auditSectionsTeacher: [
    {
      id: 3,
      value: "Alert"
    },
    {
      id: 10,
      value: "Bookmark"
    },
    {
      id: 13,
      value: "Classroom History"
    },
    {
      id: 14,
      value: "Classroom"
    },
    {
      id: 16,
      value: "Contact Method"
    },
    {
      id: 17,
      value: "Contact"
    },
    {
      id: 18,
      value: "Correspondence"
    },
    {
      id: 22,
      value: "Document"
    },
    {
      id: 35,
      value: "Note"
    },
    {
      id: 36,
      value: "Payment"
    },
    {
      id: 45,
      value: "Task"
    },
    {
      id: 54,
      value: "Teacher"
    }
  ],
  auditSectionsWaitingList: [
    {
      id: 3,
      value: "Alert"
    },
    {
      id: 10,
      value: "Bookmark"
    },
    {
      id: 18,
      value: "Correspondence"
    },
    {
      id: 35,
      value: "Note"
    },
    {
      id: 45,
      value: "Task"
    },
    {
      id: 52,
      value: "Waiting List"
    },
  ],
  schoolTypes: [
    {
      id: 1,
      value: "K-12"
    },
    {
      id: 2,
      value: "Undergraduate"
    },
    {
      id: 3,
      value: "Graduate, Master's"
    },
    {
      id: 4,
      value: "Graduate, PhD"
    },
    {
      id: 5,
      value: "Post-Graduate"
    }
  ],
  insuranceCoverage: [
    {
      id: 1,
      text: "Fully Paid by Program",
      value: "Fully Paid"
    },
    {
      id: 2,
      text: "Partially Paid by Program",
      value: "Partially Paid"
    },
    {
      id: 3,
      text: "Not Paid by Program",
      value: "Not Paid"
    }
  ],
  nonTeachingDays: [
    {
      id: 1,
      text: "6 or More Days Per Year",
      value: ">=6"
    },
    {
      id: 2,
      text: "1 to 5 Days Per Year",
      value: "1-5"
    },
    {
      id: 3,
      text: "Not Offered",
      value: "Not Offered"
    }
  ],
  benefitsStart: [
    {
      id: 1,
      text: "At Beginning of Employment",
      value: "0 days"
    },
    {
      id: 2,
      text: "At 30 Days",
      value: "30 days"
    },
    {
      id: 3,
      text: "At 60 Days",
      value: "60 days"
    },
    {
      id: 4,
      text: "At 90 Days",
      value: "90 days"
    },
    {
      id: 5,
      text: "At 6 Months",
      value: "6 months"
    },
    {
      id: 6,
      text: "At 1+ Year",
      value: "1+ year"
    },
    {
      id: 7,
      text: "N/A",
      value: "N/A"
    }
  ],
  entry: {
    show: "",
    valid: "",
    mode: "",
    id: "",

    fk_valueListID: "",
    d_name: "",
    d_value: "",
    d_sort: "",
    d_color: "",
    f_status: ""
  },
  filters: {
    page: "",
    d_name: ""
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.valueLists = list;
  },
  CREATE(state, { data }) {
    const idx = state.valueLists.data.map(item => item.id).indexOf(data.fk_valueListID);
    state.valueLists.data[idx].items.push(data);
  },
  CREATE_LIST(state, { data }) {
    state.valueLists.data.push(data);
  },
  READ(state, { data }) {
    state.valueList = data;
  },
  UPDATE(state, { data }) {
    const idx = state.valueLists.data.map(item => item.id).indexOf(data.fk_valueListID);
    const idx2 = state.valueLists.data[idx].items.map(item => item.id).indexOf(data.id);
    state.valueLists.data[idx].items.splice(idx2, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.valueLists.data.map(item => item.id).indexOf(id);
    state.valueLists.data.splice(idx, 1);
  },
  CATEGORY(state, { list }) {
    state.valueListsCategories = list;
  },
  FETCH_ITEMS(state, { list }) {
    state.valueListItems = list;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_valueListID = data.fk_valueListID;
    state.entry.d_name = data.d_name;
    state.entry.d_value = data.d_value;
    state.entry.d_sort = data.d_sort;
    state.entry.d_color = data.d_color;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.fk_valueListID = "";
    state.entry.d_name = "";
    state.entry.d_value = "";
    state.entry.d_sort = "";
    state.entry.d_color = "";
    state.entry.f_status = 1;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  }
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/valuelists", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/valuelists/create", data, { headers: getHeader() });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async createList({ commit }, data) {
    try {
      const response = await axios.post("/api/valuelists/create", data, { headers: getHeader() });
      commit("CREATE_LIST", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/valuelists/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/valuelists/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/valuelists/delete", data, { headers: getHeader() });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/valuelists/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async categories({ commit }) {
    try {
      const response = await axios.get("/api/valuelistscategories", { headers: getHeader() });
      commit("CATEGORY", { list: response.data });
    } catch (error) {}
  },
  async items({ commit }, data) {
    try {
      const response = await axios.post("/api/valuelists/items", data, { headers: getHeader() });
      commit("FETCH_ITEMS", { list: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  }
};

const getters = {
  getValueLists: (state, getters) => state.valueLists,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
