<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-graduation-cap
          </v-icon>
        </v-btn>
      </template>
      <span>Add Degree/Certificate</span>
    </v-tooltip>

    <!--Education Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="800">
      <v-card flat class="white" height="350">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid grid-list-md>
            <!--Education Info-->
              <v-card flat height="100">
                <v-container fluid class="pa-0 mt-5 px-2">
                  <v-row dense>
                    <v-row dense>
                      <!--School-->
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="d_schoolName"
                          label="School"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--Program of Study-->
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="d_degreeSought"
                          label="Program of Study"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--Matriculation-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[33]"
                          label="School Type"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="d_type"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <!--Enrollment Status-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[21]"
                          label="Enrollment"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="d_enrolled"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--Expected Completion-->
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model="d_expectedCompletion"
                          label="Expected Completion"
                          placeholder=" "
                          persistent-placeholder
                          @blur="d_expectedCompletion = $_formatStringDate(d_expectedCompletion);
                                  date1 = $_parseDate(d_expectedCompletion);"
                          prepend-icon="fal fa-calendar-alt"
                          required
                          hint="MM/DD/YYYY"

                        />
                      </v-col>

                      <!-- Completion-->
                      <v-col cols="12" sm="12" md="2">
                        <v-autocomplete
                          :items="valueListsStore.yesNo"
                          label="Completed?"
                          placeholder=" "
                          persistent-placeholder
                          item-text="value"
                          item-value="id"
                          v-model="f_completion"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--Status-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          v-if="mode !== 1"
                          :items="valueListsStore.activeStatus"
                          label="Status"
                          placeholder=" "
                          persistent-placeholder
                          item-text="value"
                          item-value="id"
                          v-model="f_status"
                        />
                      </v-col>

                      <!--Removal-->
                      <v-col v-if="usersStore.authUser.f_delete === 1" cols="12" sm="12" md="3">
                        <v-select
                          v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                          :items="[{text: 'Yes', value: '0'}]"
                          item-text="text"
                          item-value="value"
                          label="Remove Record?"
                          placeholder=" "
                          persistent-placeholder
                          v-model="f_show"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </v-card>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { createHelpers} from "vuex-map-fields";
  import { mixin } from "@/mixins/mixin.js";
  import TitleBar from '@/components/TitleBar';
  import TextDisplay from "@/components/TextDisplay";

  const { mapFields } = createHelpers({
    getterType: "educationStore/getEntryField",
    mutationType: "educationStore/updateEntryField"
  });

  export default {
    name: "TeacherEducationEdit",
    mixins: [mixin],
    computed: {
      ...mapState({
        educationStore: state => state.educationStore,
        usersStore: state => state.usersStore,
        valueListsStore: state => state.valueListsStore
      }),
      ...mapFields([
        "show",
        "valid",
        "mode",
        "id",

        "fk_teacherID",
        "d_schoolName",
        "d_enrolled",
        "d_type",
        "d_degreeSought",
        "d_expectedCompletion",
        "f_completion",
        "date1",
        "f_status",

        "f_show"
      ]),
    },
    components: {
      TitleBar,
      TextDisplay
    },
    data() {
      return {
        rules: {
          date: [v => !!v || "Date is required (MM/DD/YYYY)"],
          only_digits: [
            //apply validation if the field is populated !v
            v => !v || /^\d+$/.test(v) || "Enter Digits Only"
          ]
        }
      };
    },
    watch: {
      date1() {
        this.d_expectedCompletion = this.$_formatDate(this.date1);
      }
    },
    methods: {
      resetEntry() {
        this.$store.dispatch("educationStore/resetEntry");
        if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
      },

      async newEntry() {
        this.resetEntry();

        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_teacherID = teacher.id;
        this.valid = true;
        this.mode = 1;
        this.id = "";
        this.f_status = 1;
        this.show = true;
      },

      cancelEntry() {
        this.show = false;
        this.resetEntry();
      },

      async saveEntry() {
        if (this.$refs.entryForm.validate()) {
          const entry = this.$store.getters["educationStore/getEntry"];
          /* Create Record */
          if (this.mode === 1) {
            await this.$store.dispatch("educationStore/create", entry);
          } else {
          /* Edit Record */
            //take entry data and update item data
            await this.$store.dispatch("educationStore/updateItem", entry);

            let entry2 = this.$store.getters["educationStore/getEntry"];
            await this.$store.dispatch("educationStore/update", entry2);
          }
          this.cancelEntry();

           /* Reload List */
          this.$store.dispatch("progressStore/set", true);
          const teacher = this.$store.getters["teachersStore/getTeacher"];
          const data2 = {
            fk_teacherID: teacher.id
          };
          await this.$store.dispatch("educationStore/fetch", data2);
          this.$store.dispatch("progressStore/set", false);
        }
      }
    }
  };
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
