<template>
  <v-snackbar
    v-model="show"
    :timeout=8000
    :top=true
    :color="color"
  >{{text}}
  </v-snackbar>
</template>

<script>

export default {
  name: 'Message',
  data() {
    return {
      show: false,
      color: '',
      text: '',
    };
  },
  created() {
    this.$store.watch((state) => state.messagesStore.message, () => {
      const msg = this.$store.state.messagesStore.message;
      if (msg !== '') {
        this.color = this.$store.state.messagesStore.message.color;
        this.text = this.$store.state.messagesStore.message.text;
	      this.show = true;
      }
    });
  },
};
</script>

<style scoped>

</style>
