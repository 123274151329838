<template>
  <v-container
    fluid
    fill-height>
    <v-row dense
      align="center"
      justify="center">
      <v-col
        cols="12" sm="8"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card
          flat
        >
          <v-row dense ma-3
          >
            <v-img contain
                  :src="require('@/assets/logo_login.png')"></v-img>
          </v-row>
          <v-spacer />
          <v-card-text>
            <v-form @submit.prevent="onLogin()">
              <v-text-field prepend-icon="fas fa-user-circle"
                            v-model="email"
                            label="Login"
                            type="text"
              >
              </v-text-field>
              <v-text-field id="password"
                            prepend-icon="fas fa-lock"
                            v-model="password"
                            label="Password"
                            type="password"
              >
              </v-text-field>
              <v-btn
                rounded
                color="primary"
                block
                type="submit"
              >
                Login
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { clientID, clientSecret } from '@/env';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapState({

    }),
  },
  methods: {
    /* ---------- Login ----------*/
    onLogin() {
      if (!this.email.includes('@')) {
        this.email += '@childcareservices.org';
      }

      /* Build initial data for token request */
      const data = {
        client_id: clientID,
        client_secret: clientSecret,
        grant_type: 'password',
        username: this.email,
        password: this.password,
      };
      const authUser = {};
      /* POST user data to Auth API */
      // eslint-disable-next-line
				axios
      /* POST to OAuth service to obtain token */
        .post('/oauth/token', data)
      // eslint-disable-next-line
					.then((response) => {
          /* Grab response tokens and put the info in local storage */
          authUser.access_token = response.data.access_token;
          authUser.refresh_token = response.data.refresh_token;
          authUser.expires_in = response.data.expires_in;
          const headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${authUser.access_token}`,
          };
          /* GET User */
          // eslint-disable-next-line
						axios
            .get('/api/user', { headers })
          // eslint-disable-next-line
							.then((response) => {
              if (response.data.f_access === 1) {
                /* push auth tokens into user json */
	              response.data.access_token = authUser.access_token;
	              response.data.refresh_token = authUser.refresh_token;
	              response.data.expires_in = authUser.expires_in;

	              /* Store auth user */
	              window.localStorage.setItem('authUser', JSON.stringify(response.data));
                /* Store authorized user in store */
                this.$store.dispatch('usersStore/setAuthUser', response.data);
                /* Redirect user to dashboard */
                this.$router.push({ name: 'dashboard', params: { section: 'dashboard' } });
              } else {
                /* Show login error */
	              this.openSnackbar(1);
              }
            })
            .catch((error) => {
              /* Show login error */
	            this.openSnackbar(2);
            });
        })
        .catch((error) => {
          /* Show login error */
	        this.openSnackbar(3);
        });
    },

	  openSnackbar(code) {
    	let data;
		  if (code === 1) {
			  data = {
				  color: 'error',
				  text: 'Login error - access has expired. Please contact support.',
			  };
		  } else if (code === 2) {
			  data = {
				  color: 'error',
				  text: 'Login error - Please contact support.',
			  };
		  } else if (code === 3) {
			  data = {
				  color: 'error',
				  text: 'Login error - email and password combination are incorrect.',
			  };
		  } else if (code === 4) {
			  data = {
				  color: 'green lighten-1',
				  text: 'Password reset email sent successfully.',
			  };
		  } else if (code === 5) {
			  data = {
				  color: 'error',
				  text: 'There was an error with the request. Please contact support if the issue continues.',
			  };
		  }
		  this.$store.commit('messagesStore/setMessage', data);
	  },
  },
};
</script>

<style scoped>
  .rounded-card {
    border-radius: 8px;
  }
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
