<template>
  <div>
    <v-tooltip top :color="enabled === true ? 'success' : 'grey lighten-1'">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="exportExcel()">
          <v-icon :color="enabled === true ? 'success' : 'grey lighten-1'">
            fas fa-file-csv
          </v-icon>
        </v-btn>
      </template>
      <span>Export CSV</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as XLSX from "xlsx";

export default {
  name: "ExportExcel",
  props: {
    section: Number,
    enabled: Boolean
  },
  computed: {
    ...mapState({
      paymentsStore: state => state.paymentsStore
    })
  },

  methods: {
    async exportExcel() {
      if (this.enabled === true) {
        /* Initialize variables */
        let filename;
        let ws_name;
        let exportData;
        let filters;
        let currentDate = new Date();
        let reportDate =  currentDate.getFullYear() + '_' +
                          (currentDate.getMonth() + 1) + "_" +
                          currentDate.getDate();

        /* ----------Providers----------*/
        if (this.section === 4) {
          filename = "Providers.csv";
          ws_name = "Providers";
          filters = this.$store.getters["providersStore/getFilters"];
          await this.$store.dispatch("providersStore/exportExcel", filters);
          exportData = this.$store.getters["providersStore/getExport"];
        }
        /* ----------Providers Tuition----------*/
        if (this.section === 4.4) {
          filename = "ProviderTuition.csv";
          ws_name = "ProviderTuition";
          filters = this.$store.getters["tuitionStore/getFilters"]; //BG (63-65) 6/11/2021
          await this.$store.dispatch("tuitionStore/exportExcel", filters);
          exportData = this.$store.getters["tuitionStore/getExport"];
        }
        /* ----------Clients----------*/
        if (this.section === 5) {
          filename = "Clients.csv";
          ws_name = "Clients";
          filters = this.$store.getters["clientsStore/getFilters"];
          const user = this.$store.getters["usersStore/getAuthUser"];
          filters.f_viewPrivateRecords = user.f_viewPrivateRecords;
          await this.$store.dispatch("clientsStore/exportExcel", filters);
          exportData = this.$store.getters["clientsStore/getExport"];
        }
        /* ----------Teachers----------*/
        if (this.section === 6) {
          filename = "Teachers_" + reportDate + ".csv";
          ws_name = "Teachers_" + reportDate;
          filters = this.$store.getters["teachersStore/getFilters"];
          await this.$store.dispatch("teachersStore/exportExcel", filters);
          exportData = this.$store.getters["teachersStore/getExport"];
        }
        /* ----------Attendance----------*/
        if (this.section === 19) {
          filename = "Attendance.csv";
          ws_name = "Attendance";
          filters = this.$store.getters["attendanceItemsStore/getFilters"];
          await this.$store.dispatch("attendanceItemsStore/exportExcel", filters);
          exportData = this.$store.getters["attendanceItemsStore/getExport"];
        }
        /* ----------Contacts----------*/
        if (this.section === 21) {
          filename = "Contacts.csv";
          ws_name = "Contacts";
          filters = this.$store.getters["contactsStore/getFilters"];
          await this.$store.dispatch("contactsStore/exportExcel", filters);
          exportData = this.$store.getters["contactsStore/getExport"];
        }
        /* ----------Payments----------*/
        if (this.section === 22) {
          filename = "Payments.csv";
          ws_name = "Payments";
          filters = this.$store.getters["paymentsStore/getFilters"];
          await this.$store.dispatch("paymentsStore/exportExcel", filters);
          exportData = this.$store.getters["paymentsStore/getExport"];
        }
        /* ----------Care Plans----------*/
        if (this.section === 38) {
          filename = "CarePlans.csv";
          ws_name = "CarePlans";
          filters = this.$store.getters["carePlanItemsStore/getFilters"];
          await this.$store.dispatch("carePlanItemsStore/exportExcel", filters);
          exportData = this.$store.getters["carePlanItemsStore/getExport"];
        }
        /* ----------Teacher Reviews----------*/
        if (this.section === 44) {
          filename = "TeacherReviews_" + reportDate + ".csv";
          ws_name = "TeacherReviews_" + reportDate;
          filters = this.$store.getters["teacherReviewStore/getFilters"];
          await this.$store.dispatch("teacherReviewStore/exportExcel", filters);
          exportData = this.$store.getters["teacherReviewStore/getExport"];
        }
        /* ----------Teacher Payments----------*/
        if (this.section === 45) {
          filename = "TeacherPayments_" + reportDate + ".csv";
          ws_name = "TeacherPayments_" + reportDate;
          filters = this.$store.getters["teacherPaymentsStore/getFilters"];
          await this.$store.dispatch("teacherPaymentsStore/exportExcel", filters);
          exportData = this.$store.getters["teacherPaymentsStore/getExport"];
        }
        /* ----------Waiting List----------*/
        if (this.section === 46) {
          filename = "WaitingList_" + reportDate + ".csv";
          ws_name = "WaitingList_" + reportDate;
          filters = this.$store.getters["waitingListStore/getFilters"];
          await this.$store.dispatch("waitingListStore/exportExcel", filters);
          exportData = this.$store.getters["waitingListStore/getExport"];
        }

        /* If data exists to export */
        if (exportData) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(exportData);

          /* Add worksheet to workbook */
          XLSX.utils.book_append_sheet(wb, ws, ws_name);

          /* Create Excel file */
          XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
        } else {
          /* No data found */
          /* Show message */
          const message = {
            color: "error",
            text: "No data exists to export"
          };
          this.$store.commit("messagesStore/setMessage", message);
        }
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
