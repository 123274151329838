import { getHeader } from '@/env';

const getDefaultState = () => ({
  programOption: {},
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  READ(state, { data }) {
    state.programOption = data;
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/programoptions/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getProgramOption: (state, getters) => state.programOption,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
