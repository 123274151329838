import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  notes: [],
  note: {},
  entry: {
    show: '',
    valid: '',
    section: '',
    mode: '',
    id: '',
    fk_providerID: '',
    fk_householdID: '',
    fk_clientID: '',
    fk_teacherID: '',
    fk_waitingListID: '',
    fk_userID: '',
    d_date: '',
    d_subject: '',
    d_note: '',
    f_pin: '',
    f_type: '',
    f_show: '',
    date1: '',
    author: '',
    client: '',
    provider: '',
    teacher: '',
    waitingListItem: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: '',
    fk_householdID: '',
    fk_clientID: '',
    d_date: '',
    date1: '',
    f_type: '',
    fk_userID: '',
    clientID: '',
    householdID: '',
    teacherID: '',
    d_childIDv1: '',
    d_licenseNumber: '',
    d_nameLicense: '',
    applicantNameLastWL: '',
    applicantNameFirstWL: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_NOTES(state) {
    state.notes = [];
  },
  FETCH(state, { list }) {
    state.notes = list;
  },
  CREATE(state, { data }) {
    state.notes.data.unshift(data);
  },
  READ(state, { data }) {
    state.note = data;
  },
  UPDATE(state, { data }) {
    const idx = state.notes.data.map((item) => item.id).indexOf(data.id);
    state.notes.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.notes.data.map((item) => item.id).indexOf(id);
    state.notes.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_householdID = data.fk_householdID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.fk_teacherID = data.fk_teacherID;
    state.entry.fk_userID = data.fk_userID;
    state.entry.date1 = data.d_date;
    state.entry.d_subject = data.d_subject;
    state.entry.d_note = data.d_note;
    state.entry.f_pin = data.f_pin;
    state.entry.f_type = data.f_type;
    state.entry.f_show = '';
    state.entry.author = data.author;
    state.entry.client = data.client;
    state.entry.provider = data.provider;
    state.entry.teacher = data.teacher;
    state.entry.waitingListItem = data.waitingListItem;
    state.entry.show = true;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearNotes: ({ commit }) => {
    commit('CLEAR_NOTES');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/notes', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/notes/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/notes/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/notes/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/notes/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id, section) {
    try {
      const response = await axios.get(`/api/notes/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getNote: (state, getters) => state.note,
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
