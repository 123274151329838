import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  clientImports: [],
  clientImport: {},
  entry: {
    show: "",
    valid: "",
    mode: "",
    id: "",
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_householdID: "",
    fk_applicantID_Primary: "",
    fk_applicantID_OPG: "",
    fk_dependentID1: "",
    fk_dependentID2: "",
    fk_dependentID3: "",
    fk_dependentID4: "",
    fk_dependentID5: "",
    //fk_dependentID6: "",
    fk_clientID: "",
    d_clientFirstName: "",
    d_clientLastName: "",
    d_clientDOB: "",
    d_gender: "",
    d_usCitizen: "",
    d_race: "",
    d_hispanicLatino: "",
    d_raceEthnicity: "",
    d_language: "",
    d_specialNeed: "",
    d_healthInsuranceCoverage: "",
    d_servicePriority: "",
    d_additionalFinancialSupport: "",
    d_supportersName: "",
    d_streetAddress: "",
    d_city: "",
    d_state: "",
    d_zipCode: "",
    d_county: "",
    d_familySize: "",
    d_familyStructure: "",
    d_primaryFirstName: "",
    d_primaryLastName: "",
    d_primaryDOB: "",
    d_primaryRaceEthnicity: "",
    d_primaryPhone1: "",
    d_primaryPhone2: "",
    d_primaryEMailAddress: "",
    d_primaryRelationshipToChild: "",
    d_opgFirstName: "",
    d_opgLastName: "",
    d_opgDOB: "",
    d_opgRaceEthnicity: "",
    d_opgPhone1: "",
    d_opgPhone2: "",
    d_opgEMailAddress: "",
    d_opgRelationshipToChild: "",
    d_dependent1Name: "",
    d_dependent1DOB: "",
    d_dependent1RelationshipToApplicant: "",
    d_dependent1RelatedTo: "",
    d_dependent2Name: "",
    d_dependent2DOB: "",
    d_dependent2RelationshipToApplicant: "",
    d_dependent2RelatedTo: "",
    d_dependent3Name: "",
    d_dependent3DOB: "",
    d_dependent3RelationshipToApplicant: "",
    d_dependent3RelatedTo: "",
    d_dependent4Name: "",
    d_dependent4DOB: "",
    d_dependent4RelationshipToApplicant: "",
    d_dependent4RelatedTo: "",
    d_dependent5Name: "",
    d_dependent5DOB: "",
    d_dependent5RelationshipToApplicant: "",
    d_dependent5RelatedTo: "",
    //d_dependent6Name: "",
    //d_dependent6DOB: "",
    //d_dependent6RelationshipToApplicant: "",
    //d_dependent6RelatedTo: "",
    d_employer: "",
    d_units: "",
    d_income: "",
    d_documentation: "",
    d_placeholder: "",
    d_placeholderSeatID: "",
    d_applicationIDsma: "",
    d_message: "",
    f_ignoreDuplicateCheck: "",
    f_status: 1,
    f_show: ""
  },
  upload: {
    show: ""
  },
  filters: {
    page: "",
    /* -----ADD FILTER FIELDS BELOW-----*/
    d_clientFirstName: "",
    d_clientLastName: "",
    f_status: "",
    d_dateImport: "",
    date1: ""
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_CLIENT_IMPORTS(state) {
    state.clientImports = [];
  },
  FETCH(state, { list }) {
    state.clientImports = list;
  },
  CREATE(state, { data }) {
    state.clientImports.data.unshift(data);
  },
  READ(state, { data }) {
    state.clientImport = data;
  },
  UPDATE(state, { data }) {
    const idx = state.clientImports.data.map(item => item.id).indexOf(data.id);
    state.clientImports.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.clientImports.data.map(item => item.id).indexOf(id);
    state.clientImports.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_householdID = data.fk_householdID;
    state.entry.fk_applicantID_Primary = data.fk_applicantID_Primary;
    state.entry.fk_applicantID_OPG = data.fk_applicantID_OPG;
    state.entry.fk_dependentID1 = data.fk_dependentID1;
    state.entry.fk_dependentID2 = data.fk_dependentID2;
    state.entry.fk_dependentID3 = data.fk_dependentID3;
    state.entry.fk_dependentID4 = data.fk_dependentID4;
    state.entry.fk_dependentID5 = data.fk_dependentID5;
    //state.entry.fk_dependentID6 = data.fk_dependentID6;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.d_clientFirstName = data.d_clientFirstName;
    state.entry.d_clientLastName = data.d_clientLastName;
    state.entry.d_clientDOB = data.d_clientDOB;
    state.entry.d_gender = data.d_gender;
    state.entry.d_usCitizen = data.d_usCitizen;
    state.entry.d_race = data.d_race;
    state.entry.d_hispanicLatino = data.d_hispanicLatino;
    state.entry.d_raceEthnicity = data.d_raceEthnicity;
    state.entry.d_language = data.d_language;
    state.entry.d_specialNeed = data.d_specialNeed;
    state.entry.d_healthInsuranceCoverage = data.d_healthInsuranceCoverage;
    state.entry.d_servicePriority = data.d_servicePriority;
    state.entry.d_additionalFinancialSupport = data.d_additionalFinancialSupport;
    state.entry.d_supportersName = data.d_supportersName;
    state.entry.d_streetAddress = data.d_streetAddress;
    state.entry.d_city = data.d_city;
    state.entry.d_state = data.d_state;
    state.entry.d_zipCode = data.d_zipCode;
    state.entry.d_county = data.d_county;
    state.entry.d_familySize = data.d_familySize;
    state.entry.d_familyStructure = data.d_familyStructure;
    state.entry.d_primaryFirstName = data.d_primaryFirstName;
    state.entry.d_primaryLastName = data.d_primaryLastName;
    state.entry.d_primaryDOB = data.d_primaryDOB;
    state.entry.d_primaryRaceEthnicity = data.d_primaryRaceEthnicity;
    state.entry.d_primaryPhone1 = data.d_primaryPhone1;
    state.entry.d_primaryPhone2 = data.d_primaryPhone2;
    state.entry.d_primaryEMailAddress = data.d_primaryEMailAddress;
    state.entry.d_primaryRelationshipToChild = data.d_primaryRelationshipToChild;
    state.entry.d_opgFirstName = data.d_opgFirstName;
    state.entry.d_opgLastName = data.d_opgLastName;
    state.entry.d_opgDOB = data.d_opgDOB;
    state.entry.d_opgRaceEthnicity = data.d_opgRaceEthnicity;
    state.entry.d_opgPhone1 = data.d_opgPhone1;
    state.entry.d_opgPhone2 = data.d_opgPhone2;
    state.entry.d_opgEMailAddress = data.d_opgEMailAddress;
    state.entry.d_opgRelationshipToChild = data.d_opgRelationshipToChild;
    state.entry.d_dependent1Name = data.d_dependent1Name;
    state.entry.d_dependent1DOB = data.d_dependent1DOB;
    state.entry.d_dependent1RelationshipToApplicant = data.d_dependent1RelationshipToApplicant;
    state.entry.d_dependent1RelatedTo = data.d_dependent1RelatedTo;
    state.entry.d_dependent2Name = data.d_dependent2Name;
    state.entry.d_dependent2DOB = data.d_dependent2DOB;
    state.entry.d_dependent2RelationshipToApplicant = data.d_dependent2RelationshipToApplicant;
    state.entry.d_dependent2RelatedTo = data.d_dependent2RelatedTo;
    state.entry.d_dependent3Name = data.d_dependent3Name;
    state.entry.d_dependent3DOB = data.d_dependent3DOB;
    state.entry.d_dependent3RelationshipToApplicant = data.d_dependent3RelationshipToApplicant;
    state.entry.d_dependent3RelatedTo = data.d_dependent3RelatedTo;
    state.entry.d_dependent4Name = data.d_dependent4Name;
    state.entry.d_dependent4DOB = data.d_dependent4DOB;
    state.entry.d_dependent4RelationshipToApplicant = data.d_dependent4RelationshipToApplicant;
    state.entry.d_dependent4RelatedTo = data.d_dependent4RelatedTo;
    state.entry.d_dependent5Name = data.d_dependent5Name;
    state.entry.d_dependent5DOB = data.d_dependent5DOB;
    state.entry.d_dependent5RelationshipToApplicant = data.d_dependent5RelationshipToApplicant;
    state.entry.d_dependent5RelatedTo = data.d_dependent5RelatedTo;
    //state.entry.d_dependent6Name = data.d_dependent6Name;
    //state.entry.d_dependent6DOB = data.d_dependent6DOB;
    //state.entry.d_dependent6RelationshipToApplicant = data.d_dependent6RelationshipToApplicant;
    //state.entry.d_dependent6RelatedTo = data.d_dependent6RelatedTo;
    state.entry.d_employer = data.d_employer;
    state.entry.d_units = data.d_units;
    state.entry.d_income = data.d_income;
    state.entry.d_documentation = data.d_documentation;
    state.entry.d_placeholder = data.d_placeholder;
    state.entry.d_placeholderSeatID = data.d_placeholderSeatID;
    state.entry.d_applicationIDsma = data.d_applicationIDsma;
    state.entry.d_message = data.d_message;
    state.entry.f_ignoreDuplicateCheck = data.f_ignoreDuplicateCheck;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.show = true;
  },

  RESET_ENTRY(state) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.fk_householdID = "";
    state.entry.fk_applicantID_Primary = "";
    state.entry.fk_applicantID_OPG = "";
    state.entry.fk_dependentID1 = "";
    state.entry.fk_dependentID2 = "";
    state.entry.fk_dependentID3 = "";
    state.entry.fk_dependentID4 = "";
    state.entry.fk_dependentID5 = "";
    //state.entry.fk_dependentID6 = "";
    state.entry.fk_clientID = "";
    state.entry.d_clientFirstName = "";
    state.entry.d_clientLastName = "";
    state.entry.d_clientDOB = "";
    state.entry.d_gender = "";
    state.entry.d_usCitizen = "";
    state.entry.d_race = "";
    state.entry.d_hispanicLatino = "";
    state.entry.d_raceEthnicity = "";
    state.entry.d_language = "";
    state.entry.d_specialNeed = "";
    state.entry.d_healthInsuranceCoverage = "";
    state.entry.d_servicePriority = "";
    state.entry.d_additionalFinancialSupport = "";
    state.entry.d_supportersName = "";
    state.entry.d_streetAddress = "";
    state.entry.d_city = "";
    state.entry.d_state = "";
    state.entry.d_zipCode = "";
    state.entry.d_county = "";
    state.entry.d_familySize = "";
    state.entry.d_familyStructure = "";
    state.entry.d_primaryFirstName = "";
    state.entry.d_primaryLastName = "";
    state.entry.d_primaryDOB = "";
    state.entry.d_primaryRaceEthnicity = "";
    state.entry.d_primaryPhone1 = "";
    state.entry.d_primaryPhone2 = "";
    state.entry.d_primaryEMailAddress = "";
    state.entry.d_primaryRelationshipToChild = "";
    state.entry.d_opgFirstName = "";
    state.entry.d_opgLastName = "";
    state.entry.d_opgDOB = "";
    state.entry.d_opgRaceEthnicity = "";
    state.entry.d_opgPhone1 = "";
    state.entry.d_opgPhone2 = "";
    state.entry.d_opgEMailAddress = "";
    state.entry.d_opgRelationshipToChild = "";
    state.entry.d_dependent1Name = "";
    state.entry.d_dependent1DOB = "";
    state.entry.d_dependent1RelationshipToApplicant = "";
    state.entry.d_dependent1RelatedTo = "";
    state.entry.d_dependent2Name = "";
    state.entry.d_dependent2DOB = "";
    state.entry.d_dependent2RelationshipToApplicant = "";
    state.entry.d_dependent2RelatedTo = "";
    state.entry.d_dependent3Name = "";
    state.entry.d_dependent3DOB = "";
    state.entry.d_dependent3RelationshipToApplicant = "";
    state.entry.d_dependent3RelatedTo = "";
    state.entry.d_dependent4Name = "";
    state.entry.d_dependent4DOB = "";
    state.entry.d_dependent4RelationshipToApplicant = "";
    state.entry.d_dependent4RelatedTo = "";
    state.entry.d_dependent5Name = "";
    state.entry.d_dependent5DOB = "";
    state.entry.d_dependent5RelationshipToApplicant = "";
    state.entry.d_dependent5RelatedTo = "";
    //state.entry.d_dependent6Name = "";
    //state.entry.d_dependent6DOB = "";
    //state.entry.d_dependent6RelationshipToApplicant = "";
    //state.entry.d_dependent6RelatedTo = "";
    state.entry.d_employer = "";
    state.entry.d_units = "";
    state.entry.d_income = "";
    state.entry.d_documentation = "";
    state.entry.d_placeholder = "";
    state.entry.d_placeholderSeatID = "";
    state.entry.d_applicationIDsma = "";
    state.entry.d_message = "";
    state.entry.f_ignoreDuplicateCheck = "";
    state.entry.f_status = 1;
    state.entry.f_show = 1;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateUploadField(state, field) {
    updateField(state.upload, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearClientImports: ({ commit }) => {
    commit("CLEAR_CLIENT_IMPORTS");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/clientimports", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/clientimports/createupdate", data, {
        headers: getHeader()
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/clientimports/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.post("/api/clientimports/createupdate", data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/clientimports/delete", data, {
        headers: getHeader()
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/clientimports/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async process({ commit }, data) {
    try {
      const response = await axios.post("/api/clientimports/process", data, {
        headers: getHeader()
      });
      if (data.id > 0) {
        commit("UPDATE", { data: response.data });
      }
      //commit("UPDATE", { data: response.data });
    } catch (error) {}
  },

  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  }
};

const getters = {
  getClientImport: (state, getters) => state.clientImport,
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getUpload: (state, getters) => state.upload,
  getEntryField(state) {
    return getField(state.entry);
  },
  getUploadField(state) {
    return getField(state.upload);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
