const state = {
  message: {},
};

const mutations = {
  setMessage(state, message) {
    state.message = message;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
