<template>
  <div>
    <!--Label-->
    <span class="grey--text text--darken-1 text-caption pb-0">{{displayLabel}}</span><br>
    <!--Error Text-->
    <span v-if="errorText" class="error white--text text-body-1 px-2" :class="displayBold === true ? 'font-weight-bold' : ''">{{displayString}}</span>
    <!--Error Text 2-->
    <span v-else-if="errorText2" class="error--text text-body-1" :class="displayBold === true ? 'font-weight-bold' : ''">{{displayString}}</span>
    <!--Text-->
    <span v-else-if="displayString" class="light-blue--text text--darken-4 text-body-1" :class="displayBold === true ? 'font-weight-bold' : ''">{{displayString}}</span>
    <!--Number-->
    <span v-else-if="displayNumber || displayNumber === 0" class="light-blue--text text--darken-4 text-body-1" :class="displayBold === true ? 'font-weight-bold' : ''">{{displayNumber}}</span>
    <!--Array To Text-->
    <span v-else-if="displayArray" class="light-blue--text text--darken-4 text-body-1" :class="displayBold === true ? 'font-weight-bold' : ''">{{arrayToText}}</span>
    <span v-else class="mb-4"><br></span>
  </div>
</template>

<script>
export default {
  name: 'TextDisplay',
  props: {
    displayLabel: {
      type: String,
      required: false,
    },
    displayString: {
      type: String,
      required: false,
    },
    displayNumber: {
      type: Number,
      required: false,
    },
    displayArray: {
      type: Array,
      required: false,
    },
    displayBold: {
      type: Boolean,
      required: false,
    },
    errorText: {
      type: Boolean,
      required: false,
    },
    errorText2: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    arrayToText() {
      if (Array.isArray(this.displayArray)) {
        return this.displayArray.join();
      }

      return this.displayArray;
    },
  },
};
</script>

<style>

</style>
