import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  documentTypes: [],
  documentType: {},
  documentTypesValueList: [],
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    d_name: '',
    d_sort: '',
    f_type: '',
    f_status: '',
  },
  filters: {
    page: '',
    d_name: '',
    f_type: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.documentTypes = list;
  },
  CREATE(state, { data }) {
    state.documentTypes.data.unshift(data);
  },
  READ(state, { data }) {
    state.documentType = data;
  },
  UPDATE(state, { data }) {
    const idx = state.documentTypes.data.map((item) => item.id).indexOf(data.id);
    state.documentTypes.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.documentTypes.data.map((item) => item.id).indexOf(id);
    state.documentTypes.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_name = data.d_name;
    state.entry.d_sort = data.d_sort;
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.documentTypesValueList = list;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/documenttypes', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/documenttypes/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/documenttypes/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/documenttypes/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/documenttypes/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/documenttypes/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/documenttypes/valuelist', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getDocumentTypes: (state, getters) => state.documentTypes,
  getDocumentTypesValueList: (state, getters) => state.documentTypesValueList,
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
