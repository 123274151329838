<template>
  <v-container class="py-2" fluid>
    <v-row dense>
      <v-col cols="12" sm="12">
        <div class="text-h4 secondary--text">{{titleValue}}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    titleValue: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
