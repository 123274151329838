import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  reviewItems: [],
  export: [],
  entry: {
    show: false,
    show2: false,
    valid: "",
    mode: "",
    id: "",
    isDuplicate: "",
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_providerID: "",
    fk_classroomHistoryID: "",
    fk_teacherID: "",
    fk_teacherLicenseID: "",
    d_providerNameLicense: "",
    providerPreKSetting: "",
    d_classroomNumber: "",
    d_classroomName: "",
    f_month: "",
    d_year: "",
    d_teacherName: "",
    f_bk: "",
    d_startDate: "",
    d_yearsExperience: "",
    d_licenseIssueDate: "",
    d_licenseExpirationDate: "",
    d_licenseType: "",
    licenseType: "",
    f_licenseEligibility: "",
    f_paystubSubmitted: "",
    f_supplementAuthorized: "",
    d_supplementAmount: "",
    d_noteExternal: "",
    d_noteInternal: "",
    d_dateApproved: "",
    f_confirmationFormReceived: "",
    f_followup: "",
    f_status: "",
    f_show: "",
    created_at: "",
    updated_at: ""
  },
  filters: {
    page: "",
    section: "",
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: "",
    fk_teacherID: "",
    teacherID: "",
    d_licenseNumber: "",
    d_nameLicense: "",
    d_nameFirst: "",
    d_nameLast: "",
    f_month: "",
    d_year: "",
    f_followup: "",
    f_status: "",
    noFollowup: "",
    f_adequate: "",
    d_licenseType: "",
    noLicense: "",
    selectedID: [],
    fk_reportID: "",
    dateStart: "",
    dateEnd: "",
    date3: "",
    date4: "",
    f_monthSalaryCheck: "",
    alert: "",
    providerPrekSetting: "",
    d_dateApproved: "",
    date5: ""
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_REVIEW_ITEMS(state) {
    state.reviewItems = [];
  },
  FETCH(state, { list }) {
    state.reviewItems = list;
  },
  CREATE(state, { data }) {
    state.reviewItems.data.push(data);
  },
  UPDATE(state, { data }) {
    const idx = state.reviewItems.data.map(item => item.id).indexOf(data.id);
    const { header } = state.reviewItems.data[idx];
    data.header = header;
    state.reviewItems.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.reviewItems.data.map(item => item.id).indexOf(id);
    state.reviewItems.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_classroomHistoryID = data.fk_classroomHistoryID;
    state.entry.fk_teacherID = data.fk_teacherID;
    state.entry.fk_teacherLicenseID = data.fk_teacherLicenseID;
    state.entry.d_providerNameLicense = data.d_providerNameLicense;
    state.entry.providerPreKSetting = data.providerPreKSetting;
    state.entry.d_classroomNumber = data.d_classroomNumber;
    state.entry.d_classroomName = data.d_classroomName;
    state.entry.f_month = data.f_month;
    state.entry.d_year = data.d_year;
    state.entry.d_teacherName = data.d_teacherName;
    state.entry.f_bk = data.f_bk;
    state.entry.d_startDate = data.d_startDate;
    state.entry.d_yearsExperience = data.d_yearsExperience;
    state.entry.d_licenseIssueDate = data.d_licenseIssueDate;
    state.entry.d_licenseExpirationDate = data.d_licenseExpirationDate;
    state.entry.d_licenseType = data.d_licenseType;
    state.entry.licenseType = data.license_type ? data.license_type.d_name : "";
    state.entry.f_adequate = data.f_adequate;
    state.entry.f_monthSalaryCheck = data.f_monthSalaryCheck;
    state.entry.f_licenseEligibility = data.f_licenseEligibility;
    state.entry.f_paystubSubmitted = data.f_paystubSubmitted;
    state.entry.f_supplementAuthorized = data.f_supplementAuthorized;
    state.entry.d_supplementAmount = data.d_supplementAmount;
    state.entry.d_noteExternal = data.d_noteExternal;
    state.entry.d_noteInternal = data.d_noteInternal;
    state.entry.d_dateApproved = data.d_dateApproved;
    state.entry.f_confirmationFormReceived = data.f_confirmationFormReceived;
    state.entry.f_followup = data.f_followup;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.created_at = data.created_at;
    state.entry.updated_at = data.updated_at;
    state.entry.show = 1;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = "";
    state.entry.id = "";
    state.entry.fk_providerID = "";
    state.entry.fk_classroomHistoryID = "";
    state.entry.fk_teacherID = "";
    state.entry.fk_teacherLicenseID = "";
    state.entry.d_providerNameLicense = "";
    state.entry.providerPreKSetting = "";
    state.entry.d_classroomNumber = "";
    state.entry.d_classroomName = "";
    state.entry.f_month = "";
    state.entry.d_year = "";
    state.entry.d_teacherName = "";
    state.entry.f_bk = "";
    state.entry.d_startDate = "";
    state.entry.d_yearsExperience = "";
    state.entry.d_licenseIssueDate = "";
    state.entry.d_licenseExpirationDate = "";
    state.entry.d_licenseType = "";
    state.entry.licenseType = "";
    state.entry.f_adequate = "";
    state.entry.f_monthSalaryCheck = "";
    state.entry.f_licenseEligibility = "";
    state.entry.f_paystubSubmitted = "";
    state.entry.f_supplementAuthorized = "";
    state.entry.d_supplementAmount = "";
    state.entry.d_noteExternal = "";
    state.entry.d_noteInternal = "";
    state.entry.d_dateApproved = "";
    state.entry.f_confirmationFormReceived = "";
    state.entry.f_followup = "";
    state.entry.f_status = "";
    state.entry.f_show = "";
    state.entry.created_at = "";
    state.entry.updated_at = "";
  },
  RESET_MASS_SELECTION(state) {
    state.filters.selectedID = [];
  },
  SELECT_DESELECT_ALL(state) {
    if (state.filters.selectedID.length > 0) {
      state.filters.selectedID = [];
    } else {
      let i;
      for (i = 0; i < state.reviewItems.data.length; i++) {
        state.filters.selectedID.push(state.reviewItems.data[i].id);
      }
    }
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearReviewItems: ({ commit }) => {
    commit("CLEAR_REVIEW_ITEMS");
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post("/api/teacherreviews", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherreviews/create", data, {
        headers: getHeader()
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/teacherreviews/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/teacherreviews/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async massUpdate({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherreviews/massupdate", data, {
        headers: getHeader()
      });
      return response;
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherreviews/delete", data, {
        headers: getHeader()
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/teacherreviews/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post("/api/teacherreviews", data, { headers: getHeader() });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  resetMassSelection({ commit }) {
    commit("RESET_MASS_SELECTION");
  },
  selectDeselectAll({ commit }) {
    commit("SELECT_DESELECT_ALL");
  },
  storeFilters: ({ commit }, data) => {
    commit("STORE_FILTERS", { data });
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  }
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
