import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  attendanceItems: [],
  export: [],
  entry: {
    show: false,
    show2: false,
    valid: '',
    mode: '',
    id: '',
    tab: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_attendanceID: '',
    fk_clientID: '',
    d_dateStartCarePlan: '',
    d_dateEndOverride: '',
    d_daysPresent: '',
    d_daysAbsent: '',
    d_daysClosed: '',
    d_dateEndCarePlan: '',
    d_dateStartOverride: '',
    d_daysTotal: '',
    d_daysProrated: '',
    d_noteExternal: '',
    d_noteInternal: '',
    d_parentFeeNotCollected: '',
    d_parentFee: '',
    d_reasonAbsent: '',
    d_dateApproved: '',
    f_followup: '',
    f_status: '',
    header: '',
    date1: '',
    date2: '',
    date3: '',
    date4: '',
    f_show: '',
    attendance: '',
    client: '',
    care_plan_item: '',
    noFollowup: '',
    created_at: '',
    updated_at: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: '',
    d_licenseNumber: '',
    d_nameLicense: '',
    d_childIDv1: '',
    fk_clientID: '',
    fk_householdID: '',
    d_prekSeatID: '',
    d_nameFirst: '',
    d_nameLast: '',
    f_month: '',
    d_year: '',
    f_followup: '',
    f_status: '',
    noFollowup: '',
    selectedID: [],
    d_dateStart: '',
    d_dateEnd: '',
    date1: '',
    date2: '',
    fk_programID: '',
    fk_programOptionID: '',
    clientID: '',
    f_viewPrivateRecords: '',
    dateStart: '',
    dateEnd: '',
    date3: '',
    date4: '',
    date5: '', //BG 6/21/2021 added date filter field for d_dateApproved
    d_daysAbsent: '',
    d_daysTotal: '',
    attendanceEntered: '',
    attendanceNotEntered: '',
    f_placeholder: '',
    fk_reportID: '',
    actualDatesStartEntered: '',
    actualDatesEndEntered: '',
    parentFeeNotCollected: '',
    d_dateApproved: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_ATTENDANCE_ITEMS(state) {
    state.attendanceItems = [];
  },
  FETCH(state, { list }) {
    state.attendanceItems = list;
  },
  CREATE(state, { data }) {
    state.attendanceItems.data.push(data);
  },
  UPDATE(state, { data }) {
    const idx = state.attendanceItems.data.map((item) => item.id).indexOf(data.id);
    const { header } = state.attendanceItems.data[idx];
    data.header = header;
    state.attendanceItems.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.attendanceItems.data.map((item) => item.id).indexOf(id);
    state.attendanceItems.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_attendanceID = data.fk_attendanceID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.date1 = data.d_dateStartCarePlan;
    state.entry.date4 = data.d_dateEndOverride;
    state.entry.d_daysPresent = data.d_daysPresent;
    state.entry.d_daysAbsent = data.d_daysAbsent;
    state.entry.d_daysClosed = data.d_daysClosed;
    state.entry.date2 = data.d_dateEndCarePlan;
    state.entry.date3 = data.d_dateStartOverride;
    state.entry.d_daysTotal = data.d_daysTotal;
    state.entry.d_daysProrated = data.d_daysProrated;
    state.entry.d_noteExternal = data.d_noteExternal;
    state.entry.d_noteInternal = data.d_noteInternal;
    state.entry.d_parentFeeNotCollected = data.d_parentFeeNotCollected;
    state.entry.d_parentFee = data.d_parentFee;
    state.entry.d_reasonAbsent = data.d_reasonAbsent;
    state.entry.d_dateApproved = data.d_dateApproved;
    state.entry.f_followup = data.f_followup;
    state.entry.f_status = data.f_status;
    state.entry.header = data.header;
    state.entry.attendance = data.attendance;
    state.entry.client = data.client;
    state.entry.care_plan_item = data.care_plan_item;
    state.entry.created_at = data.created_at;
    state.entry.updated_at = data.updated_at;
    state.entry.show = 1;
    state.entry.tab = 0;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.tab = 0;
    state.entry.fk_attendanceID = '';
    state.entry.fk_clientID = '';
    state.entry.d_dateStartCarePlan = '';
    state.entry.d_dateEndOverride = '';
    state.entry.d_daysPresent = '';
    state.entry.d_daysAbsent = '';
    state.entry.d_daysClosed = '';
    state.entry.d_daysProrated = '';
    state.entry.d_dateEndCarePlan = '';
    state.entry.d_dateStartOverride = '';
    state.entry.d_daysTotal = '';
    state.entry.d_noteExternal = '';
    state.entry.d_noteInternal = '';
    state.entry.d_parentFeeNotCollected = '';
    state.entry.d_parentFee = '';
    state.entry.d_reasonAbsent = '';
    state.entry.d_dateApproved = '';
    state.entry.f_followup = '';
    state.entry.date1 = '';
    state.entry.date2 = '';
    state.entry.date3 = '';
    state.entry.date4 = '';
    state.entry.f_status = 1;
    state.entry.header = '';
    state.entry.attendance = '';
    state.entry.client = '';
    state.entry.care_plan_item = '';
    state.entry.noFollowup = '';
    state.entry.created_at = '';
    state.entry.updated_at = '';
  },
  RESET_MASS_SELECTION(state) {
    state.filters.selectedID = [];
  },
  SELECT_DESELECT_ALL(state) {
    if (state.filters.selectedID.length > 0) {
      state.filters.selectedID = [];
    } else {
      let i;
      for (i = 0; i < state.attendanceItems.data.length; i++) {
        state.filters.selectedID.push(state.attendanceItems.data[i].id);
      }
    }
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearAttendanceItems: ({ commit }) => {
    commit('CLEAR_ATTENDANCE_ITEMS');
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/attendanceitems', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/attendanceitems/create', data, {
        headers: getHeader(),
      });
      commit('CREATE', { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/attendanceitems/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/attendanceitems/update/${data.id}`, data, {
        headers: getHeader(),
      });
      commit('UPDATE', { data: response.data });
    } catch (error) {}
  },
  async massUpdate({ commit }, data) {
    try {
      const response = await axios.post('/api/attendanceitems/massupdate', data, {
        headers: getHeader(),
      });
      return response;
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/attendanceitems/delete', data, {
        headers: getHeader(),
      });
      commit('DELETE', { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/attendanceitems/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post('/api/attendanceitems', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data });
    } catch (error) {}
  },
  resetMassSelection({ commit }) {
    commit('RESET_MASS_SELECTION');
  },
  selectDeselectAll({ commit }) {
    commit('SELECT_DESELECT_ALL');
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
