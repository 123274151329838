import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  reports: [],
  report: {},
  reportsValueList: [],
  entry: {
    show: "",
    valid: "",
    section: "",
    mode: "",
    id: "",
    d_name: "",
    d_note: "",
    d_sort: "",
    d_icon: "",
    d_iconColor: "",
    f_status: ""
  },
  filters: {
    page: "",
    section: "",

    d_name: "",
    fk_userID: "",
    f_status: ""
  },
  datePicker: {
    show: "",
    valid: "",
    id: "",
    fk_carePlanItemID: "",
    f_month: "",
    d_year: "",
    missingClients: "",
    title: "",
    message: "",
    progress: "",
    d_dateEnd: "",
    date1: "",
    fk_userID: "",
    fk_providerID: "",
    providerID: "",
    clientID: "",
    carePlanID: "",
    dateStart: "",
    dateEnd: "",
    date3: "",
    date4: "",
    restrictAccountingCodes: "",
    showTerminatedCarePlans: false,
    teacherID: ""
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_REPORTS(state) {
    state.reports = [];
  },
  CLEAR_REPORT(state) {
    state.report = {};
  },
  FETCH(state, { list }) {
    state.reports = list;
  },
  CREATE(state, { data }) {
    state.reports.data.unshift(data);
  },
  READ(state, { data }) {
    state.report = data;
  },
  UPDATE(state, { data }) {
    const idx = state.reports.data.map(item => item.id).indexOf(data.id);
    state.reports.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.reports.data.map(item => item.id).indexOf(id);
    state.reports.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_name = data.d_name;
    state.entry.d_note = data.d_note;
    state.entry.d_sort = data.d_sort;
    state.entry.d_icon = data.d_icon;
    state.entry.d_iconColor = data.d_iconColor;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.mode = 2;
    state.entry.valid = "";
    state.entry.id = "";
    state.entry.d_name = "";
    state.entry.d_note = "";
    state.entry.d_sort = "";
    state.entry.d_icon = "";
    state.entry.d_iconColor = "";
    state.entry.f_status = 1;
  },
  DATE_PICKER(state, { data }) {
    state.datePicker.valid = true;
    state.datePicker.id = data.id;
    state.datePicker.f_month = data.f_month;
    state.datePicker.d_year = data.d_year;
    state.datePicker.title = data.title;
    state.datePicker.message = data.message;
    state.datePicker.fk_providerID = data.fk_providerID;
    state.datePicker.dateStart = "";
    state.datePicker.dateEnd = "";
    state.datePicker.restrictAccountingCodes = data.restrictAccountingCodes;
    state.datePicker.progress = false;
    state.datePicker.show = true;
  },
  RESET_DATE_PICKER(state) {
    state.datePicker.valid = "";
    state.datePicker.id = "";
    state.datePicker.fk_carePlanItemID = "";
    state.datePicker.f_month = "";
    state.datePicker.d_year = "";
    state.datePicker.missingClients = "";
    state.datePicker.title = "";
    state.datePicker.message = "";
    state.datePicker.progress = "";
    state.datePicker.d_dateEnd = "";
    state.datePicker.date1 = "";
    state.datePicker.fk_userID = "";
    state.datePicker.fk_providerID = "";
    state.datePicker.providerID = "";
    state.datePicker.clientID = "";
    state.datePicker.carePlanID = "";
    state.datePicker.dateStart = "";
    state.datePicker.dateEnd = "";
    state.datePicker.date3 = "";
    state.datePicker.date4 = "";
    state.datePicker.restrictAccountingCodes = "";
    state.datePicker.showTerminatedCarePlans = false;
    state.datePicker.teacherID = "";
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.reportsValueList = list;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateDatePickerField(state, field) {
    updateField(state.datePicker, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearReports: ({ commit }) => {
    commit("CLEAR_REPORTS");
  },
  clearReport: ({ commit }) => {
    commit("CLEAR_REPORT");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/reports", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/reports/create", data, { headers: getHeader() });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/reports/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/reports/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/reports/delete", data, { headers: getHeader() });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/reports/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async runReport({ commit }, data) {
    try {
      const response = await axios.post("/api/reports/runreport", data, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post("/api/reports/valuelist", data, { headers: getHeader() });
      commit("FETCH_VALUE_LIST", { list: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  resetDatePicker({ commit }) {
    commit("RESET_DATE_PICKER");
  },
  datePicker({ commit }, data) {
    commit("DATE_PICKER", { data });
  }
};

const getters = {
  getReport: (state, getters) => state.report,
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getDatePicker: (state, getters) => state.datePicker,
  getReportsValueList: (state, getters) => state.reportsValueList,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getDatePickerField(state) {
    return getField(state.datePicker);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
