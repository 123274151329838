import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  bookmarks: [],
  bookmark: {
    id: '',
  },
  entry: {
    show: '',
    valid: '',
    section: '',
    mode: '',
    id: '',
    fk_providerID: '',
    fk_clientID: '',
    fk_teacherID: '',
    fk_waitingListID: '',
    fk_userID: '',
    d_note:'',
    f_status: '',
  },
  filters: {
    page: '',
    section: '',

    fk_providerID: '',
    fk_clientID: '',
    fk_teacherID: '',
    fk_waitingListID: '',
    fk_userID: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_BOOKMARKS(state) {
    state.bookmarks = [];
  },
  FETCH(state, { list }) {
    state.bookmarks = list;
  },
  CREATE(state, { data }) {
    state.bookmark = data;
  },
  READ(state, { data }) {
    state.bookmark = data;
  },
  UPDATE(state, { data }) {
    state.bookmark = data;
  },
  DELETE(state, { id }) {
    const idx = state.bookmarks.data.map((item) => item.id).indexOf(id);
    state.bookmarks.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.fk_teacherID = data.fk_teacherID;
    state.entry.fk_waitingListID = data.fk_waitingListID;
    state.entry.fk_userID = data.fk_userID;
    state.entry.d_note = data.d_note;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.fk_clientID = '';
    state.entry.fk_teacherID = '';
    state.entry.fk_waitingListID = '';
    state.entry.fk_userID = '';
    state.entry.d_note = '';
    state.entry.f_status = '';
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearBookmarks: ({ commit }) => {
    commit('CLEAR_BOOKMARKS');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/bookmarks', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/bookmarks/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/bookmarks/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async detail({ commit }, data) {
    try {
      const response = await axios.post('/api/bookmarks/detail', data, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/bookmarks/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/bookmarks/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/bookmarks/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getBookmark: (state, getters) => state.bookmark,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
