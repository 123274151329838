import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  carePlans: [],
  carePlan: {
    provider: {
      county: {},
    },
  },
  carePlansValueList: [],
  entry: {
    show: '',
    valid: '',
    section: '',
    mode: '',
    id: '',
    fk_providerID: '',
    fk_clientID: '',
    fk_classroomID: '',
    d_dateStart: '',
    d_dateEnd: '',
    d_dateTermination: '',
    f_placeholder: '',
    d_eligibilityReason: '',
    d_agreementsSigned: '',
    date1: '',
    date2: '',
    date3: '',
    originalStatus: '',
    created_at: '',
    updated_at: '',
    items: [
      {
        id: '',
        fk_carePlanID: '',
        fk_programID: '',
        fk_programOptionID: '',
        fk_timeOptionID: '',
        fk_countyID_Residence: '',
        d_dateStart: '',
        d_dateEnd: '',
        d_income: '',
        d_fpl: '',
        d_smi: '',
        d_familySize: '',
        d_childNumber: '',
        d_workHoursPrimaryApplicant: '',
        d_workHoursOtherParent: '',
        d_dateBirth: '',
        d_age: '',
        d_schoolStatusPrimaryApplicant: '',
        d_schoolStatusOtherParent: '',
        d_parentFee: '',
        d_dailyFee: '',
        d_eligibilityReason: '',
        f_followup: '',
        f_status: 1,
        f_show: 1,
        options: [],
        date1: '',
        date2: '',
        date3: '',
        created_at: '',
        updated_at: '',
        originalStatus: '',
      },
    ],
    client: {},
    provider: {},
    f_status: '',
    f_show: 1,
  },
  filters: {
    page: '',
    fk_providerID: '',
    fk_clientID: '',
    d_licenseNumber: '',
    d_nameLicense: '',
    d_childIDv1: '',
    fk_householdID: '',
    d_applicantIDv1: '',
    primaryApplicant: '',
    d_nameLast: '',
    d_nameFirst: '',
    fk_counselorID: '',
    d_dateStart: '',
    date1: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_VALUE_LIST(state) {
    state.carePlansValueList = [];
  },
  CLEAR_CARE_PLAN(state) {
    state.carePlan = {
      provider: {
        county: {},
      },
    };
  },
  FETCH(state, { list }) {
    state.carePlans = list;
  },
  CREATE(state, { data }) {
    state.carePlans.data.unshift(data);
  },
  READ(state, { data }) {
    state.carePlan = data;
  },
  UPDATE(state, { data }) {
    const idx = state.carePlans.data.map((item) => item.id).indexOf(data.id);
    state.carePlans.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    if (state.carePlans.data) {
      const idx = state.carePlans.data.map((item) => item.id).indexOf(id);
      state.carePlans.data.splice(idx, 1);
    }
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.fk_classroomID = data.fk_classroomID;
    state.entry.date1 = data.d_dateStart;
    state.entry.date2 = data.d_dateEnd;
    (state.entry.date3 = data.d_dateTermination), (state.entry.f_placeholder = data.f_placeholder);
    state.entry.d_eligibilityReason = data.d_eligibilityReason;
    state.entry.d_agreementsSigned = data.d_agreementsSigned;
    state.entry.items = data.items;
    state.entry.client = data.client;
    state.entry.provider = data.provider;
    state.entry.f_status = data.f_status;
    state.entry.originalStatus = data.f_status;
    state.entry.created_at = data.created_at;
    state.entry.updated_at = data.updated_at;
    state.entry.show = 1;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.fk_clientID = '';
    state.entry.fk_classroomID = '';
    state.entry.date1 = '';
    state.entry.date2 = '';
    state.entry.date3 = '';
    state.entry.f_placeholder = 0;
    state.entry.d_eligibilityReason = '';
    state.entry.d_agreementsSigned = '';
    state.entry.originalStatus = '';
    state.entry.items = [];
    state.entry.client = '';
    state.entry.provider = '';
    state.entry.created_at = '';
    state.entry.updated_at = '';
    state.entry.f_status = 1;
    state.entry.f_show = 1;
  },
  ADD_ITEM(state, { data }) {
    const newData = {
      id: '',
      fk_carePlanID: '',
      fk_programID: '',
      fk_programOptionID: '',
      fk_timeOptionID: '',
      fk_countyID_Residence: data.fk_countyID_Residence,
      d_dateStart: '',
      d_dateEnd: '',
      d_income: data.d_income,
      d_fpl: '',
      d_smi: '',
      d_familySize: data.d_familySize,
      d_childNumber: data.d_childNumber,
      d_workHoursPrimaryApplicant: data.d_workHoursPrimaryApplicant,
      d_workHoursOtherParent: data.d_workHoursOtherParent,
      d_dateBirth: data.d_dateBirth,
      d_age: '',
      d_schoolStatusPrimaryApplicant: data.d_schoolStatusPrimaryApplicant,
      d_schoolStatusOtherParent: data.d_schoolStatusOtherParent,
      d_parentFee: '',
      d_dailyFee: '',
      d_eligibilityReason: '',
      f_followup: '',
      f_status: 1,
      f_show: 1,
      options: [],
      date1: '',
      date2: '',
      date3: data.date3,
      originalStatus: '',
      created_at: '',
      updated_at: '',
    };
    state.entry.items.unshift(newData);
  },
  REMOVE_ITEM(state, { id }) {
    state.entry.items[id].f_show = 0;
  },
  DUPLICATE_ITEM(state, { id }) {
    const item = state.entry.items[id];
    const data = {
      id: '',
      fk_carePlanID: item.fk_carePlanID,
      fk_programID: item.fk_programID,
      fk_programOptionID: item.fk_programOptionID,
      fk_timeOptionID: item.fk_timeOptionID,
      fk_countyID_Residence: item.fk_countyID_Residence,
      d_dateStart: item.d_dateStart,
      d_dateEnd: item.d_dateEnd,
      d_income: item.d_income,
      d_fpl: item.d_fpl,
      d_smi: item.d_smi,
      d_familySize: item.d_familySize,
      d_childNumber: item.d_childNumber,
      d_workHoursPrimaryApplicant: item.d_workHoursPrimaryApplicant,
      d_workHoursOtherParent: item.d_workHoursOtherParent,
      d_dateBirth: item.d_dateBirth,
      d_age: item.d_age,
      d_schoolStatusPrimaryApplicant: item.d_schoolStatusPrimaryApplicant,
      d_schoolStatusOtherParent: item.d_schoolStatusOtherParent,
      d_parentFee: item.d_parentFee,
      d_dailyFee: item.d_dailyFee,
      d_eligibilityReason: item.d_eligibilityReason,
      f_followup: item.f_followup,
      f_status: 1,
      f_show: 1,
      options: item.options,
      date1: item.date1,
      date2: item.date2,
      date3: item.date3,
      originalStatus: 1,
      created_at: '',
      updated_at: '',
    };
    state.entry.items.unshift(data);
  },
  SET_PROGRAM_OPTIONS(state, { data }) {
    state.entry.items[data.index].options = data.list;
  },
  UPDATE_ITEM_TIME_OPTION(state, { data }) {
    state.entry.items[data.index].time_option = data.time_option;
  },
  UPDATE_ITEM_PARENT_FEE(state, { data }) {
    state.entry.items[data.index].d_parentFee = data.parentFee;
    state.entry.items[data.index].d_dailyFee = data.parentFee ? data.parentFee / 21.67 : 0.00;
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.carePlansValueList = list;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filter, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearValueList: ({ commit }) => {
    commit('CLEAR_VALUE_LIST');
  },
  clearCarePlan: ({ commit }) => {
    commit('CLEAR_CARE_PLAN');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/careplans', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/careplans/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/careplans/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/careplans/update/${data.id}`, data, {
        headers: getHeader(),
      });
      commit('UPDATE', { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/careplans/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
      return response;
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/careplans/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {}
  },
  async programOptions({ commit }, data) {
    try {
      const response = await axios.post('/api/programoptions/valuelist', data, {
        headers: getHeader(),
      });
      const newData = {
        list: response.data,
        index: data.index,
      };
      commit('SET_PROGRAM_OPTIONS', { data: newData });
    } catch (error) {}
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/careplans/valuelist', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
  addItem({ commit }, data) {
    commit('ADD_ITEM', { data });
  },
  removeItem({ commit }, id) {
    commit('REMOVE_ITEM', { id });
  },
  duplicateItem({ commit }, id) {
    commit('DUPLICATE_ITEM', { id });
  },
  updateItemTimeOption({ commit }, data) {
    commit('UPDATE_ITEM_TIME_OPTION', { data });
  },
  updateItemParentFee({ commit }, data) {
    commit('UPDATE_ITEM_PARENT_FEE', { data });
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getCarePlan: (state, getters) => state.carePlan,
  getCarePlanEntry: (state, getters) => state.entry,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filter);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
