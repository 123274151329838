<template>
  <div>
    <!-- New Teacher Button -->
    <v-tooltip v-if="teacherSection === 1" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="openEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add teacher</span>
    </v-tooltip>

    <!-- New Teacher Button -->
    <v-tooltip v-else top color="primary">
      <template #activator="{ on }">
        <v-btn v-on="on" rounded outlined @click="openEntry()">
          <v-icon small left>fal fa-pencil-alt</v-icon>Edit
        </v-btn>
      </template>
      <span>Edit teacher</span>
    </v-tooltip>

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1000">
      <v-card flat class="white" height="1250">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid grid-list-md>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>General</v-tab>
                <v-tab>Contact</v-tab>
                <v-tab v-if="teacherSection === 1">License</v-tab>
                <v-tab v-if="teacherSection === 1">Education</v-tab>
                <v-tab v-if="teacherSection === 2">BK License</v-tab>

                <!--General-->
                <v-tab-item>
                  <v-card flat height="1000">
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="4" md="9">
                            <v-row dense>
                              <!--Teacher ID-->
                              <v-col cols="12" sm="6" md="1">
                                <text-display
                                  v-if="teacherSection === 2"
                                  :displayLabel="'ID'"
                                  :displayNumber="
                                    teachersStore.teacher ? teachersStore.teacher.id : ''
                                  "
                                />
                              </v-col>

                              <!--Teaching Position-->
                              <v-col cols="12" sm="6" md="7">
                                <v-autocomplete
                                  :items="valueListsStore.valueListItems[31]"
                                  label="Teaching Position"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="d_name"
                                  item-value="d_value"
                                  v-model="f_type"
                                  :rules="rules.position"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                              <!--Substitute-->
                              <v-col cols="12" sm="6" md="3">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Substitute"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="id"
                                  v-model="f_substitute"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <!--First Name-->
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_nameFirst"
                                  label="First Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.nameFirst"
                                >
                                </v-text-field>
                              </v-col>
                              <!--Last Name-->
                              <v-col cols="12" sm="12" md="5">
                                <v-text-field
                                  v-model="d_nameLast"
                                  label="Last Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.nameLast"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Street Address-->
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_streetAddress"
                                  label="Street Address"
                                  placeholder=" "
                                  persistent-placeholder
                                >
                                </v-text-field>
                              </v-col>
                              <!--City-->
                              <v-col cols="12" sm="12" md="5">
                                <v-text-field
                                  v-model="d_city"
                                  label="City"
                                  placeholder=" "
                                  persistent-placeholder
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <!--State-->
                              <v-col cols="12" md="1">
                                <v-text-field
                                  v-model="d_state"
                                  label="State"
                                  placeholder=" "
                                  persistent-placeholder
                                  required
                                  @blur="d_state = $_formatStateInitials(d_state)"
                                >
                                </v-text-field>
                              </v-col>
                              <!--Zipcode-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="d_zipCode"
                                  label="Zipcode"
                                  placeholder=" "
                                  persistent-placeholder
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <!--Tenure Date Start-->
                              <v-col cols="12" sm="5">
                                <v-menu
                                  :close-on-content-click="false"
                                  v-model="menu"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template #activator="{ on }">
                                    <v-text-field
                                      v-on="on"
                                      v-model="d_dateStartTenure"
                                      label="Actual Start Date"
                                      placeholder=" "
                                      persistent-placeholder
                                      prepend-icon="fal fa-calendar-alt"
                                      required
                                      @blur="date1 = $_parseDate(d_dateStartTenure)"
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="date1"
                                    @input="menu = false"
                                    color="primary"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <!--Status-->
                              <v-col cols="12" sm="6" md="3">
                                <v-autocomplete
                                  :items="valueListsStore.activeStatus"
                                  label="Status"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="id"
                                  v-model="f_status"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <!--Race-->
                              <v-col cols="12" sm="12" class="px-0">
                                <span class="grey--text text--darken-1 text-caption">Race</span>
                                <v-radio-group row dense class="my-0 py-0">
                                  <v-checkbox
                                    dense
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[12]"
                                    v-model="d_race"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    multiple
                                    class="pr-2 py-0 my-0"
                                  ></v-checkbox>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                            <v-row>
                              <!--Other Race-->
                              <v-col cols="12" sm="12" md="5">
                                <v-text-field
                                  v-model="d_raceOther"
                                  label="Other Race"
                                  placeholder=" "
                                  persistent-placeholder
                                >
                                </v-text-field>
                              </v-col>
                              <!--Gender-->
                              <v-col cols="12" sm="6" md="3">
                                <v-autocomplete
                                  :items="valueListsStore.valueListItems[32]"
                                  label="Gender"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="d_name"
                                  item-value="d_name"
                                  v-model="d_gender"
                                  :blur="clearGenderOther()"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                              <v-col cols="12" sm="6" md="3" v-if="d_gender == 'Other'">
                                <v-text-field
                                  v-model="d_genderOther"
                                  label=""
                                  placeholder=" "
                                  persistent-placeholder
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Teacher Confirmation Forms-->
                              <v-col cols="12" sm="12" md="11" class="px-0 py-0">
                                <v-text-field
                                  v-model="d_teacherConfirmationNotes"
                                  label="Teacher Confirmation Form Note"
                                  placeholder=" "
                                  persistent-placeholder
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-divider vertical />

                          <v-col cols="12" sm="6" md="2">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                <!--Language-->
                                <v-col cols="12" sm="12" class="pl-2">
                                  <span class="grey--text text--darken-1 text-caption"
                                    >Language(s) Spoken</span
                                  >
                                  <v-radio-group dense row class="my-0 py-0">
                                    <v-checkbox
                                      dense
                                      :key="index"
                                      v-for="(item, index) in valueListsStore.valueListItems[15]"
                                      v-model="j_language"
                                      :label="item.d_name"
                                      :value="item.d_name"
                                      color="primary"
                                      multiple
                                      class="pr-2 py-0"
                                      :class="index > 0 ? 'my-0' : ''"
                                    ></v-checkbox>
                                  </v-radio-group>
                                </v-col>
                                <!--Other Language-->
                                <v-col cols="12" sm="12" md="12" class="py-0">
                                  <v-text-field
                                    v-model="d_languageOther"
                                    label="Other Language"
                                    placeholder=" "
                                    persistent-placeholder
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Contact-->
                <v-tab-item>
                  <v-card flat height="800">
                    <v-container fluid class="pa-0 mt-5 px-2">
                      <v-row dense justify="end">
                        <v-btn
                          rounded
                          dark
                          color="green lighten-1"
                          class="mb-2"
                          @click="addContact()"
                        >
                          <v-icon small left>fal fa-plus</v-icon>Add</v-btn
                        >
                      </v-row>
                      <v-row dense :key="index" v-for="(item, index) in j_contact">
                        <v-col cols="12" sm="12" md="3">
                          <v-select
                            :items="valueListsStore.valueListItems[6]"
                            item-text="d_name"
                            item-value="d_name"
                            label="Contact Type"
                            placeholder=" "
                            persistent-placeholder
                            v-model="item.d_type"
                            clear-icon="fal fa-times-circle"
                            required
                          />
                        </v-col>

                        <v-col cols="12" sm="12" md="8">
                          <v-text-field
                            v-model="item.d_value"
                            label="Contact Info"
                            placeholder=" "
                            persistent-placeholder
                            required
                          >
                          </v-text-field>
                        </v-col>

                        <v-col v-if="usersStore.authUser.f_delete === 1" cols="12" sm="12" md="1">
                          <v-btn text icon color="red" @click="removeContact(index)">
                            <v-icon>fal fa-trash</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>

                <!--License-->
                <v-tab-item v-if="teacherSection === 1">
                  <v-card flat height="800">
                    <v-container fluid class="pa-0 mt-5 px-2">
                      <v-row dense v-if="mode === 1">
                        <!-- BK License -->
                        <v-col cols="12" sm="12" md="3">
                          <v-autocomplete
                            :items="valueListsStore.yesNo"
                            label="Currently Possesses BK License?"
                            placeholder=" "
                            persistent-placeholder
                            item-text="value"
                            item-value="id"
                            v-model="f_bk"
                            clearable
                            clear-icon="fal fa-times-circle"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-divider class="pb-4"/>
                        </v-col>
                      </v-row>
                      <v-row dense justify="end">
                        <v-btn
                          rounded
                          dark
                          color="green lighten-1"
                          class="mb-2"
                          @click="addLicense()"
                        >
                          <v-icon small left>fal fa-plus</v-icon>Add</v-btn
                        >
                      </v-row>
                      <v-row dense :key="index" v-for="(item, index) in j_license">
                        <v-row dense>
                          <!--License Number-->
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="item.d_name"
                              label="License Number"
                              placeholder=" "
                              persistent-placeholder
                              required
                              :rules="rules.licenseNumber"
                            >
                            </v-text-field>
                          </v-col>
                          <!--License Type-->
                          <v-col cols="12" sm="12" md="4">
                            <v-select
                              :items="valueListsStore.valueListItems[30]"
                              label="License Type"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_value"
                              clearable
                              v-model="item.f_type"
                              clear-icon="fal fa-times-circle"
                              required
                              :rules="rules.licenseType"
                            />
                          </v-col>
                          <!--Status-->
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :items="valueListsStore.activeStatus"
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="item.f_status"
                            />
                          </v-col>

                          <v-col v-if="usersStore.authUser.f_delete === 1" cols="12" sm="12" md="1">
                            <v-btn text icon color="red" @click="removeLicense(index)">
                              <v-icon>fal fa-trash</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Issue Date-->
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="item.d_issueDate"
                              label="Issue Date"
                              placeholder=" "
                              persistent-placeholder
                              prepend-icon="fal fa-calendar-alt"
                              required
                              @blur="
                                item.d_issueDate = $_formatStringDate(item.d_issueDate);
                                item.date1 = $_parseDate(item.d_issueDate);
                              "
                              :rules="rules.date"
                              hint="MM/DD/YYYY"
                            />
                          </v-col>
                          <!--Expiration Date-->
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="item.d_expirationDate"
                              label="Expiration Date"
                              placeholder=" "
                              persistent-placeholder
                              prepend-icon="fal fa-calendar-alt"
                              required
                              @blur="
                                item.d_expirationDate = $_formatStringDate(item.d_expirationDate);
                                item.date2 = $_parseDate(item.d_expirationDate);
                              "
                              :rules="rules.date"
                              hint="MM/DD/YYYY"
                            />
                          </v-col>
                          <!--Years Experience-->
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="item.d_yearsExperience"
                              label="Years of Experience"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.only_digits"
                              hint="##"
                            />
                          </v-col>
                        </v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-divider class="pb-4" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>

                <!--Education-->
                <v-tab-item v-if="teacherSection === 1">
                  <v-card flat height="800">
                    <v-container fluid class="pa-0 mt-5 px-2">
                      <v-row dense justify="end">
                        <v-btn
                          rounded
                          dark
                          color="green lighten-1"
                          class="mb-2"
                          @click="addEducation()"
                        >
                          <v-icon small left>fal fa-plus</v-icon>Add</v-btn
                        >
                      </v-row>
                      <v-row dense :key="index" v-for="(item, index) in j_education">
                        <v-row dense>
                          <!--School Name-->
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="item.d_schoolName"
                              label="Institution Name"
                              placeholder=" "
                              persistent-placeholder
                              required
                            >
                            </v-text-field>
                          </v-col>
                          <!--Degree-->
                          <v-col cols="12" sm="12" md="7">
                            <v-text-field
                              v-model="item.d_degreeSought"
                              label="Program of Study"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                          <v-col v-if="usersStore.authUser.f_delete === 1" cols="12" sm="12" md="1">
                            <v-btn text icon color="red" @click="removeEducation(index)">
                              <v-icon>fal fa-trash</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Expected Completion Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="item.d_expectedCompletion"
                              label="Expected Completion"
                              placeholder=" "
                              persistent-placeholder
                              prepend-icon="fal fa-calendar-alt"
                              required
                              @blur="
                                item.d_expectedCompletion = $_formatStringDate(
                                  item.d_expectedCompletion
                                );
                                item.date3 = $_parseDate(item.d_expectedCompletion);
                              "
                              hint="MM/DD/YYYY"
                            />
                          </v-col>
                          <!-- Completion-->
                          <v-col cols="12" sm="12" md="2">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Completed?"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="item.f_completion"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Enrolled-->
                          <v-col cols="12" md="3">
                            <span class="grey--text text--darken-1 text-caption pb-0"
                              >Enrolled</span
                            >
                            <v-radio-group
                              v-model="item.d_enrolled"
                              row
                              class="my-0"
                              :readonly="f_status === 2"
                            >
                              <v-radio
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[21]"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                class="pr-2"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                          <!--Matriculation-->
                          <v-col cols="12" md="9">
                            <span class="grey--text text--darken-1 text-caption pb-0"
                              >School Type</span
                            >
                            <v-radio-group v-model="item.d_type" row class="my-0">
                              <v-radio
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[33]"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                class="pr-2"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Status-->
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :items="valueListsStore.activeStatus"
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="item.f_status"
                            />
                          </v-col>
                        </v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-divider class="pb-4" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="teacherSection === 2">
                  <v-card flat height="800">
                    <v-container fluid class="pa-0 mt-5 px-2">
                      <v-row dense>
                        <!-- BK License -->
                        <v-col cols="12" sm="12" md="3">
                          <v-autocomplete
                            :items="valueListsStore.yesNo"
                            label="Currently Possesses BK License?"
                            placeholder=" "
                            persistent-placeholder
                            item-text="value"
                            item-value="id"
                            v-model="f_bk"
                            clearable
                            clear-icon="fal fa-times-circle"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="teacherSection === 2">
                  <v-card flat height="800">
                    <v-container fluid class="pa-0 mt-5 px-2">
                      <v-row dense>
                        <!-- BK License -->
                        <v-col cols="12" sm="12" md="3">
                          <v-autocomplete
                            :items="valueListsStore.yesNo"
                            label="Currently Possesses BK License?"
                            placeholder=" "
                            persistent-placeholder
                            item-text="value"
                            item-value="id"
                            v-model="f_bk"
                            clearable
                            clear-icon="fal fa-times-circle"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "teachersStore/getEntryField",
  mutationType: "teachersStore/updateEntryField"
});

export default {
  name: "TeacherEdit",
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: state => state.teachersStore,
      usersStore: state => state.usersStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",

      "d_nameFirst",
      "d_nameLast",
      "d_streetAddress",
      "d_city",
      "d_state",
      "d_zipCode",
      "j_contact",
      "d_dateStartTenure",
      "date1",
      "f_substitute",
      "f_bk",
      "j_language",
      "d_languageOther",
      "d_race",
      "d_raceOther",
      "f_type",
      "d_gender",
      "d_genderOther",
      "d_teacherConfirmationNotes",
      "f_status"
    ]),
    ...mapMultiRowFields("teachersStore", [
      "entry.j_contact",
      "entry.j_license",
      "entry.j_education"
    ])
  },
  components: {
    TextDisplay
  },
  props: {
    /* All List = new entry = 1, edit entry = 2 */
    teacherSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      tab: 0,
      menu: false,
      rules: {
        nameFirst: [v => !!v || "First Name is required"],
        nameLast: [v => !!v || "Last Name is required"],
        position: [v => !!v || "Position is required"],
        licenseNumber: [v => !!v || "License Number is required."],
        licenseType: [v => !!v || "License Type is required."],
        date: [v => !!v || "Date is required (MM/DD/YYYY)"],
        only_digits: [
          //apply validation if the field is populated !v
          v => !v || /^\d+$/.test(v) || "Enter Digits Only"
        ]
      }
    };
  },
  watch: {
    date1() {
      this.d_dateStartTenure = this.$_formatDate(this.date1);
    },
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("teachersStore/resetEntry");
      if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
    },

    async openEntry() {
      this.resetEntry();

      const data2 = {
        id: [6, 12, 15, 21, 30, 31, 32, 33]
      };
      await this.$store.dispatch("valueListsStore/items", data2);

      if (this.teacherSection === 1) {
        this.mode = 1;
      } else {
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        await this.$store.dispatch("teachersStore/entry", teacher.uuid);
        this.mode = 2;
      }

      this.tab = 0;
      this.show = true;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    addContact() {
      this.$store.dispatch("teachersStore/addContact");
    },

    removeContact(id) {
      this.$store.dispatch("teachersStore/removeContact", id);
    },

    addLicense() {
      this.$store.dispatch("teachersStore/addLicense");
    },

    removeLicense(id) {
      this.$store.dispatch("teachersStore/removeLicense", id);
    },

    addEducation() {
      this.$store.dispatch("teachersStore/addEducation");
    },

    removeEducation(id) {
      this.$store.dispatch("teachersStore/removeEducation", id);
    },

    clearGenderOther() {
      if (this.d_gender !== "Other") {
        this.d_genderOther = "";
      }
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["teachersStore/getEntry"];
        /* Create Record */
        if (this.mode === 1) {
          await this.$store.dispatch("teachersStore/create", entry);
        } else {
          /* Edit Record */
          await this.$store.dispatch("teachersStore/update", entry);
        }
        this.cancelEntry();

        /* Reload List */
        this.$store.dispatch("progressStore/set", true);
        const filters = this.$store.getters["teachersStore/getFilters"];
        await this.$store.dispatch("teachersStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.language_column {
  column-count: 2;
}
button {
  outline: none;
}
</style>
