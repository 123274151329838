import Vue from "vue";
import Vuex from "vuex";

import usersStore from "@/store/modules/users";
import agenciesStore from "@/store/modules/agencies";
import helpTopicsStore from "@/store/modules/helptopics";
import fundingSourcesStore from "@/store/modules/fundingsources";
import accountingCodesStore from "@/store/modules/accountingcodes";
import schoolZonesStore from "@/store/modules/schoolzones";
import noteTopicsStore from "@/store/modules/notetopics";
import countiesStore from "@/store/modules/counties";
import ratesStore from "@/store/modules/rates";
import applicantsStore from "@/store/modules/applicants";
import applicationsStore from "@/store/modules/applications";
import tasksStore from "@/store/modules/tasks";
import bookmarksStore from "@/store/modules/bookmarks";
import reportsStore from "@/store/modules/reports";
import paymentsStore from "@/store/modules/payments";
import checkRequestsStore from "@/store/modules/checkrequests";
import classroomsStore from "@/store/modules/classrooms";
import contactsStore from "@/store/modules/contacts";
import taskTypesStore from "@/store/modules/tasktypes";
import facilitiesStore from "@/store/modules/facilities";
import providersStore from "@/store/modules/providers";
import benefitsStore from "@/store/modules/benefits";
import clientsStore from "@/store/modules/clients";
import teachersStore from "@/store/modules/teachers";
import teacherReviewStore from "@/store/modules/teacherreview";
import teacherSalaryStore from "@/store/modules/teachersalary";
import teacherLicenseStore from "@/store/modules/teacherlicense";
import teacherPaymentsStore from "@/store/modules/teacherpayments";
import teacherRatesStore from "@/store/modules/teacherrates";
import contactMethodsStore from "@/store/modules/contactmethods";
import messagesStore from "@/store/modules/messages";
import notesStore from "@/store/modules/notes";
import actionsStore from "@/store/modules/actions";
import actionTypesStore from "@/store/modules/actiontypes";
import waitingListStore from "@/store/modules/waitinglist";
import correspondenceStore from "@/store/modules/correspondence";
import templatesStore from "@/store/modules/templates";
import carePlansStore from "@/store/modules/careplans";
import documentsStore from "@/store/modules/documents";
import documentTypesStore from "@/store/modules/documenttypes";
import educationStore from "@/store/modules/education";
import employmentStore from "@/store/modules/employment";
import navigationStore from "@/store/modules/navigation";
import dependantsStore from "@/store/modules/dependants";
import programsStore from "@/store/modules/programs";
import incomeStore from "@/store/modules/income";
import incomeClassificationsStore from "@/store/modules/incomeclassifications";
import householdsStore from "@/store/modules/households";
import progressStore from "@/store/modules/progress";
import valueListsStore from "@/store/modules/valuelists";
import ratingsStore from "@/store/modules/ratings";
import ageCategoriesStore from "@/store/modules/agecategories";
import timeOptionsStore from "@/store/modules/timeoptions";
import yearsStore from "@/store/modules/years";
import tuitionStore from "@/store/modules/tuition";
import attendanceStore from "@/store/modules/attendance";
import attendanceItemsStore from "@/store/modules/attendanceitems";
import carePlanItemsStore from "@/store/modules/careplanitems";
import programOptionsStore from "@/store/modules/programoptions";
import mergeCodesStore from "@/store/modules/mergecodes";
import mergeCodeSectionsStore from "@/store/modules/mergecodesections";
import messageBoardItemsStore from "@/store/modules/messageboarditems";
import auditsStore from "@/store/modules/audits";
import clientImportsStore from "@/store/modules/clientimports";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    usersStore,
    agenciesStore,
    helpTopicsStore,
    fundingSourcesStore,
    accountingCodesStore,
    schoolZonesStore,
    noteTopicsStore,
    countiesStore,
    ratesStore,
    applicantsStore,
    applicationsStore,
    tasksStore,
    bookmarksStore,
    reportsStore,
    paymentsStore,
    checkRequestsStore,
    classroomsStore,
    contactsStore,
    taskTypesStore,
    facilitiesStore,
    providersStore,
    benefitsStore,
    clientsStore,
    teachersStore,
    teacherReviewStore,
    teacherSalaryStore,
    teacherLicenseStore,
    teacherPaymentsStore,
    teacherRatesStore,
    contactMethodsStore,
    messagesStore,
    notesStore,
    actionsStore,
    actionTypesStore,
    waitingListStore,
    correspondenceStore,
    templatesStore,
    carePlansStore,
    documentsStore,
    documentTypesStore,
    educationStore,
    employmentStore,
    navigationStore,
    householdsStore,
    dependantsStore,
    programsStore,
    incomeStore,
    incomeClassificationsStore,
    ratingsStore,
    progressStore,
    valueListsStore,
    ageCategoriesStore,
    timeOptionsStore,
    yearsStore,
    tuitionStore,
    attendanceStore,
    attendanceItemsStore,
    carePlanItemsStore,
    programOptionsStore,
    mergeCodesStore,
    mergeCodeSectionsStore,
    messageBoardItemsStore,
    auditsStore,
    clientImportsStore
  },
  strict: debug
});
