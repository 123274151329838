import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  teachers: [],
  teachersSearch: [],
  teachersValueList: [],
  export: [],
  teacher: null,
  teacherCorres: "",
  entry: {
    show: false,
    valid: "",
    mode: "",
    id: "",
    /* -----ADD ENTRY FIELDS BELOW-----*/
    d_nameFirst: "",
    d_nameLast: "",
    d_streetAddress: "",
    d_city: "",
    d_state: "",
    d_zipCode: "",
    j_contact: [
      {
        d_value: "",
        d_type: ""
      }
    ],
    j_language: [],
    d_languageOther: "",
    d_race: [],
    d_raceOther: "",
    f_substitute: "",
    d_dateStartTenure: "",
    date1: "",
    f_bk: "",
    j_license: [
      {
        d_name: "",
        f_type: "",
        d_issueDate: "",
        date1: "",
        d_expirationDate: "",
        date2: "",
        d_yearsExperience: "",
        f_status: ""
      }
    ],
    j_education: [
      {
        d_schoolName: "",
        d_degreeSought: "",
        d_expectedCompletion: "",
        f_completion: "",
        date3: "",
        d_enrolled: "",
        d_type: "",
        f_status: ""
      }
    ],
    f_type: "",
    d_gender: "",
    genderOther: "",
    d_teacherConfirmationNotes: "",
    f_status: "",
    f_show: ""
  },
  filters: {
    page: "",
    section: "",
    /* -----ADD FILTER FIELDS BELOW-----*/
    id: "",
    fk_userID: "",
    d_nameFirst: "",
    d_nameLast: "",
    d_nameDisplay: "",
    f_type: "",
    f_substitute: "",
    fk_actionTypeID: "",
    noAlerts: false,
    f_status: "",
    providerID: "",
    providerPrekSetting: "",
  },
  currentIndex: 0
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_teacher(state) {
    state.teacher = "";
  },
  CLEAR_teacherS(state) {
    state.teachers = [];
  },
  CLEAR_VALUE_LIST(state) {
    state.teachersValueList = [];
  },
  FETCH(state, { list }) {
    state.teachers = list;
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.teachersValueList = list;
  },
  FETCH_SEARCH(state, { list }) {
    state.teachersSearch = list;
  },
  CREATE(state, { data }) {
    state.teacher = data;
  },
  READ(state, { data }) {
    state.teacher = data;
  },
  UPDATE(state, { data }) {
    state.teacher = data;
  },
  DELETE(state, { id }) {
    const idx = state.teachers.data.map(item => item.id).indexOf(id);
    state.teachers.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.id = data.id;
    state.entry.d_nameFirst = data.d_nameFirst;
    state.entry.d_nameLast = data.d_nameLast;
    state.entry.d_streetAddress = data.d_streetAddress;
    state.entry.d_city = data.d_city;
    state.entry.d_state = data.d_state;
    state.entry.d_zipCode = data.d_zipCode;
    state.entry.j_contact = data.j_contact;
    state.entry.date1 = data.d_dateStartTenure;
    state.entry.f_substitute = data.f_substitute;
    state.entry.f_bk = data.f_bk;
    state.entry.j_language = data.j_language;
    state.entry.d_languageOther = data.d_languageOther;
    state.entry.d_race = data.d_race;
    state.entry.d_raceOther = data.d_raceOther;
    state.entry.j_license = data.j_license;
    state.entry.d_gender = data.d_gender;
    state.entry.d_genderOther = data.d_genderOther;
    state.entry.d_teacherConfirmationNotes = data.d_teacherConfirmationNotes;
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = "";
    state.entry.id = "";
    state.entry.d_nameFirst = "";
    state.entry.d_nameLast = "";
    state.entry.d_streetAddress = "";
    state.entry.d_city = "";
    state.entry.d_state = "";
    state.entry.d_zipCode = "";
    state.entry.j_contact = [];
    state.entry.j_language = [];
    state.entry.d_languageOther = "";
    state.entry.d_race = [];
    state.entry.d_raceOther = "";
    state.entry.date1 = "";
    state.entry.f_substitute = 0;
    state.entry.f_bk = "";
    state.entry.j_license = [];
    state.entry.j_education = [];
    state.entry.d_gender = "";
    state.entry.genderOther = "";
    state.entry.d_teacherConfirmationNotes = "";
    state.entry.f_type = "";
    state.entry.f_status = 1;
    state.entry.f_show = "";
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  CURRENT_INDEX(state, { uuid }) {
    const idx = state.teachers.data.map(item => item.uuid).indexOf(uuid);
    state.currentIndex = idx;
  },
  READ_BY_INDEX(state, { index }) {
    state.teacher = state.teachers.data[index];
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  ADD_CONTACT(state) {
    const data = {
      d_type: "",
      d_value: ""
    };
    state.entry.j_contact.push(data);
  },
  REMOVE_CONTACT(state, { id }) {
    state.entry.j_contact.splice(id, 1);
  },
  ADD_LICENSE(state) {
    const data = {
      d_name: "",
      f_type: "",
      d_issueDate: "",
      date1: "",
      d_expirationDate: "",
      date2: "",
      d_yearsExperience: "",
      f_status: 1
    };
    state.entry.j_license.push(data);
  },
  REMOVE_LICENSE(state, { id }) {
    state.entry.j_license.splice(id, 1);
  },
  ADD_EDUCATION(state) {
    const data = {
      d_schoolName: "",
      d_degreeSought: "",
      d_expectedCompletion: "",
      f_completion: 0,
      date3: "",
      d_enrolled: "",
      d_type: "",
      f_status: 1
    };
    state.entry.j_education.push(data);
  },
  REMOVE_EDUCATION(state, { id }) {
    state.entry.j_education.splice(id, 1);
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearteacher: ({ commit }) => {
    commit("CLEAR_teacher");
  },
  clearteachers: ({ commit }) => {
    commit("CLEAR_teacherS");
  },
  clearValueList: ({ commit }) => {
    commit("CLEAR_VALUE_LIST");
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post("/api/teachers", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async fetchSearch({ commit }, data) {
    try {
      const response = await axios.post("/api/teachers", data, { headers: getHeader() });
      commit("FETCH_SEARCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/teachers/create", data, { headers: getHeader() });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/teachers/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async readByID({ commit }, id) {
    try {
      const response = await axios.get(`/api/teachersbyid/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/teachers/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/teachers/delete", data, { headers: getHeader() });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/teachers/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async currentIndex({ commit }, uuid) {
    try {
      commit("CURRENT_INDEX", { uuid });
    } catch (error) {}
  },
  async readByIndex({ commit }, index) {
    try {
      commit("READ_BY_INDEX", { index });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post("/api/teachers", data, { headers: getHeader() });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post("/api/teachersvaluelist", data, { headers: getHeader() });
      commit("FETCH_VALUE_LIST", { list: response.data });
    } catch (error) {}
  },
  storeFilters: ({ commit }, data) => {
    commit("STORE_FILTERS", { data });
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  addContact({ commit }) {
    commit("ADD_CONTACT");
  },
  removeContact({ commit }, id) {
    commit("REMOVE_CONTACT", { id });
  },
  addLicense({ commit }) {
    commit("ADD_LICENSE");
  },
  removeLicense({ commit }, id) {
    commit("REMOVE_LICENSE", { id });
  },
  addEducation({ commit }) {
    commit("ADD_EDUCATION");
  },
  removeEducation({ commit }, id) {
    commit("REMOVE_EDUCATION", { id });
  }
};

const getters = {
  getTeachers: (state, getters) => state.teachers,
  getTeacher: (state, getters) => state.teacher,
  getTeachersSearch: (state, getters) => state.teachersSearch,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
