<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="taskSection === 1" :title-value="'Tasks'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-tasks</v-icon>
        <v-toolbar-title class="pl-2">Tasks</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="taskSection !== 4" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense v-if="taskSection === 1">
                <!--Provider License-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_licenseNumber"
                    label="Provider License #"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="taskSection === 1">
                <!--Provider Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_nameLicense"
                    label="Provider Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="taskSection === 1">
                <!--Teacher Name-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="teachersStore.teachersValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Teacher Name"
                    placeholder=" "
                    persistent-placeholder
                    v-model="teacherID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="taskSection === 1">
                <!--Waiting List Applicant First Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="applicantNameFirstWL"
                    label="Applicant First Name (Waiting List)"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="taskSection === 1">
                <!--Waiting List Applicant Last Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="applicantNameLastWL"
                    label="Applicant Last Name (Waiting List)"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="taskSection === 1">
                <!--Household ID-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="householdID"
                    label="Household ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="taskSection === 1">
                <!--Client ID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="clientID"
                    label="Client ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
                <!--v1 Child ID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_childIDv1"
                    label="v1 Child ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Due Date-->
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_date"
                        label="Due Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="taskTypesStore.taskTypesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Assigned To-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="usersStore.usersValueList"
                    item-text="name"
                    item-value="id"
                    label="Assigned To"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_userID_Assigned"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Author-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="usersStore.usersValueList"
                    item-text="name"
                    item-value="id"
                    label="Author"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_userID_Author"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.taskStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="tasksStore.tasks.data && tasksStore.tasks.data.length > 0"
      :showTotal="taskSection === 4 ? false : true"
      :currentPage="tasksStore.tasks.current_page"
      :lastPage="tasksStore.tasks.last_page"
      :total="tasksStore.tasks.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Task List-->
      <v-list v-if="tasksStore.tasks.data && tasksStore.tasks.data.length > 0" two-line>
        <template v-for="(item, index) in tasksStore.tasks.data">
          <v-list-item
            :key="item.id"
            @click="
              openEntry(
                item.client ? item.client.fk_householdID : '',
                item.id,
                item.client ? item.client.f_privateRecord : 0,
                0
              )
            "
            class="my-1"
          >
            <v-list-item-content>
              <v-list-item-title class="light-blue--text text--darken-4">{{
                $_formatDate(item.d_date)
              }}</v-list-item-title>
              <v-list-item-title v-if="item.type" class="light-blue--text text--darken-4">{{
                item.type.d_name
              }}</v-list-item-title>
              <v-list-item-title v-if="item.client" class="light-blue--text text--darken-4"
                >Client: {{ item.fk_clientID }} - {{ item.client.d_nameDisplay }}</v-list-item-title
              >
              <v-list-item-title v-if="item.provider" class="light-blue--text text--darken-4"
                >Provider: {{ item.provider.d_display }}</v-list-item-title
              >
              <v-list-item-title v-if="item.teacher" class="light-blue--text text--darken-4"
                >Teacher: {{ item.teacher.d_nameDisplay }}</v-list-item-title
              ><v-list-item-title v-if="item.waitingListItem" class="light-blue--text text--darken-4"
                >Waiting List: {{ item.waitingListItem.id }} - {{ item.waitingListItem.d_nameFirst }} {{ item.waitingListItem.d_nameLast }}</v-list-item-title
              >
              <v-list-item-title class="light-blue--text text--darken-4"
                >Subject: {{ item.d_subject }}</v-list-item-title
              >
              <v-list-item-title class="light-blue--text text--darken-4"
                >Author: {{ item.author.name }}</v-list-item-title
              >
              <v-list-item-title v-if="taskSection !== 4" class="light-blue--text text--darken-4"
                >Assigned To: {{ item.assignee.name }}</v-list-item-title
              >
              <v-list-item-title class="text-caption grey--text text--darken-1"
                >Created: {{ $_formatTimestamp(item.created_at) }} &#8226; Modified:
                {{ $_formatTimestamp(item.updated_at) }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action v-if="taskSection !== 4">
              <v-list-item-action-text
                ><span
                  :class="
                    item.f_status === 1 || item.f_status === 0 || item.f_status === false
                      ? 'text-body-1 font-weight-bold green--text text--lighten-1'
                      : 'text-body-1 primary--text'
                  "
                  >{{ $_taskStatus(item.f_status) }}</span
                ></v-list-item-action-text
              >
              <v-list-item-action-text v-if="item.client && item.client.f_privateRecord === 1"
                ><v-chip color="error"
                  ><v-icon small>fal fa-lock</v-icon></v-chip
                ></v-list-item-action-text
              >
            </v-list-item-action>
            <!--Duplicate Item Record-->
            <v-tooltip v-if="taskSection !== 4" left color="primary">
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click.stop="
                    openEntry(
                      item.client ? item.client.fk_householdID : '',
                      item.id,
                      item.client ? item.client.f_privateRecord : 0,
                      1
                    )
                  "
                >
                  <v-icon small color="primary">
                    fal fa-clone
                  </v-icon>
                </v-btn>
              </template>
              <span>Duplicate Task</span>
            </v-tooltip>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <!--No Recrods Found Message-->
      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Tasks Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";

const { mapFields } = createHelpers({
  getterType: "tasksStore/getFilterField",
  mutationType: "tasksStore/updateFilterField"
});

export default {
  name: "Tasks",
  components: {
    TitleBar,
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      tasksStore: state => state.tasksStore,
      taskTypesStore: state => state.taskTypesStore,
      teachersStore: state => state.teachersStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_providerID",
      "fk_householdID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "fk_userID",
      "fk_userID_Assigned",
      "fk_userID_Author",
      "d_date",
      "date1",
      "f_type",
      "f_status",
      "clientID",
      "householdID",
      "teacherID",
      "d_childIDv1",
      "d_licenseNumber",
      "d_nameLicense",
      "applicantNameLastWL",
      "applicantNameFirstWL",
    ])
  },
  props: {
    /* All List = 1, Provider = 2, Client = 3, Dashboard = 4, Teacher = 5, Waiting List = 6 */
    taskSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      filterMenu: false,
      menu: false
    };
  },
  methods: {
    async initialize() {
      /* If not running from dashboard */
      if (this.taskSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Provider Section */
      if (this.taskSection === 2) {
        /* Grab provider details based on provider UUID passed in route parameter */
        // await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
        this.fk_userID = "";
      } else if (this.taskSection === 3) {
      /* Client Section */
        /* Grab client details based on client UUID passed in route parameter */
        // await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_providerID = "";
        this.fk_householdID = client.fk_householdID;
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
        this.fk_userID = "";
      } else if (this.taskSection === 4) {
      /* Dashboard */
        this.f_status = 2;
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
        const user = this.$store.getters["usersStore/getAuthUser"];
        this.fk_userID = user.id;
      } else if (this.taskSection === 5) {
      /* Teacher Section */
        /* Grab teacher details based on teacher UUID passed in route parameter */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = teacher.id;
        this.fk_waitingListID = "";
        this.fk_userID = "";
      } else if (this.taskSection === 6) {
      /* Waiting List Section */
        /* Grab waiting list details based on waiting list UUID passed in route parameter */
        const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = waitingListItem.id;
        this.fk_userID = "";
      } else {
      /* All list */
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
        this.fk_userID = "";
      }

      /* Pull tasks list */
      const filters = this.$store.getters["tasksStore/getFilters"];
      await this.$store.dispatch("tasksStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists(householdID) {
      let vlData;
      /* Provider */
      if (this.taskSection === 2) {
        vlData = {
          f_type: 1
        };
      } else if (this.taskSection === 3) {
      /* Client */
        vlData = {
          f_type: 2
        };
      } else if (this.taskSection === 5) {
      /* Teacher */
        vlData = {
          f_type: 3
        };
      } else if (this.taskSection === 6) {
      /* Waiting List */
        vlData = {
          f_type: 4
        };
      } else {
      /* All */
        vlData = {
          f_type: ""
        };
      }
      await this.$store.dispatch("taskTypesStore/valueList", vlData);
      const specialist = {
        f_counselor: ""
      };
      await this.$store.dispatch("usersStore/valueList", specialist);
      const data = {
        fk_providerID: "",
        fk_householdID: householdID
      };
      await this.$store.dispatch("clientsStore/valueList", data);
      await this.$store.dispatch("teachersStore/valueList");
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    clearFilterColumns() {
      this.fk_userID_Assigned = "";
      this.fk_userID_Author = "";
      this.date1 = "";
      this.f_type = "";
      this.f_status = "";
      this.clientID = "";
      this.householdID = "";
      this.teacherID = "";
      this.d_childIDv1 = "";
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.applicantNameLastWL = "",
      this.applicantNameFirstWL = ""
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists(this.fk_householdID);
      if (this.taskSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("tasksStore/clearTasks");
      }
      /* Save section in store */
      this.section = this.taskSection;
    },

    showFiltered() {
      if (
        this.fk_userID_Assigned ||
        this.fk_userID_Author ||
        this.d_date ||
        this.f_type ||
        this.f_status ||
        this.f_status === 0 ||
        this.clientID ||
        this.householdID ||
        this.teacherID ||
        this.d_childIDv1 ||
        this.d_licenseNumber ||
        this.d_nameLicense ||
        this.applicantNameLastWL ||
        this.applicantNameFirstWL
      ) {
        return true;
      }
      return false;
    },

    async openEntry(householdID, id, privateRecord, duplicateFlag) {
      const user = this.$store.getters["usersStore/getAuthUser"];
      if (privateRecord === 0 || (privateRecord === 1 && user.f_viewPrivateRecords === 1)) {
        await this.loadValueLists(householdID);
        if (duplicateFlag === 1) {
          await this.$store.dispatch("tasksStore/duplicate", id);
        } else {
          await this.$store.dispatch("tasksStore/entry", id);
        }
      } else {
        const data = {
          color: "error",
          text: "Access to this record is prohibited."
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
