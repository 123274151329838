import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  contactMethods: [],
  contactMethod: {},
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    fk_householdID: '',
    d_owner: '',
    d_ownerOther: '',
    d_type: '',
    d_value: '',
    j_contactMethods: [
      {
        d_type: '',
        d_value: '',
        f_preferred: 0,
      },
    ],
    f_primary: '',
    f_status: '',
    f_show: '',
  },
  filters: {
    page: '',
    d_type: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.contactMethods = list;
  },
  CREATE(state, { data }) {
    state.contactMethods.data.push(data);
  },
  READ(state, { data }) {
    state.contactMethod = data;
  },
  UPDATE(state, { data }) {
    const idx = state.contactMethods.data.map((item) => item.id).indexOf(data.id);
    state.contactMethods.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.contactMethods.data.map((item) => item.id).indexOf(id);
    state.contactMethods.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_householdID = data.fk_householdID;
    state.entry.d_owner = data.d_owner;
    state.entry.d_ownerOther = data.d_ownerOther;
    state.entry.d_type = data.d_type;
    state.entry.d_value = data.d_value;
    state.entry.j_contactMethods = data.j_contactMethods;
    //update items with missing f_preferred parameters
    state.entry.j_contactMethods.forEach(item => {
      if(!item.f_preferred) {
        item.f_preferred = 0;
      }
    });
    state.entry.f_primary = data.f_primary;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_householdID = '';
    state.entry.d_owner = '';
    state.entry.d_ownerOther = '';
    state.entry.d_type = '';
    state.entry.d_value = '';
    state.entry.j_contactMethods = [{
      d_type: '',
      d_value: '',
      f_preferred: 0,
    }];
    state.entry.f_primary = '';
    state.entry.f_status = 1;
  },
  ADD_CONTACT_METHOD(state) {
    const data = {
      d_type: '',
      d_value: '',
      f_preferred: 0,
    };
    state.entry.j_contactMethods.push(data);
  },
  REMOVE_CONTACT_METHOD(state, { id }) {
    state.entry.j_contactMethods.splice(id, 1);
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/contactmethods', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/contactmethods/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/contactmethods/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/contactmethods/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/contactmethods/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/contactmethods/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addContactMethod({ commit }) {
    commit('ADD_CONTACT_METHOD');
  },
  removeContactMethod({ commit }, id) {
    commit('REMOVE_CONTACT_METHOD', { id });
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getContactMethod: (state, getters) => state.contactMethod,
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
