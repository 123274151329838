import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  waitingList: [],
  waitingListValueList: [],
  waitingListItem: {},
  export: [],
  entry: {
    show: '',
    valid: '',
    section: '',
    id: '',
    fk_counselorID: '',
    d_wlsID: '',
    d_waitListPullID: '',
    j_priority: '',
    f_incomeRank: '',
    d_dateLastScreening: '',
    date1: '',
    d_statusLastScreening: '',
    d_dateWLSupdateFromScreening: '',
    date2: '',
    d_type: '',
    d_nameInitialCounselor: '',
    d_caseType: '',
    d_nameFirst: '',
    d_nameLast: '',
    d_dateBirth: '',
    date3: '',
    d_gender: '',
    d_age: '',
    d_languagePrimary: '',
    d_relationshipToChild: '',
    d_residenceStreet: '',
    d_residenceCity: '',
    d_residenceCounty: '',
    d_residenceState: '',
    d_residencePostalCode: '',
    d_emailLogin: '',
    d_phonePrimary: '',
    d_phoneSecondary: '',
    d_adultsInHousehold: '',
    d_familySize: '',
    d_income: '',
    d_incomeAdjusted: '',
    d_incomeFamily: '',
    d_statusEmployment: '',
    d_employer: '',
    d_employerOther: '',
    d_affiliateUNCCH: '',
    d_affiliateUNCCHother: '',
    d_nameFirstCaller: '',
    d_nameLastCaller: '',
    d_emailCaller: '',
    d_note: '',
    f_ccsaScholarshipWL: '',
    f_subsidyHomelessWL: '',
    f_enrolledNeedsAssistance: '',
    f_ccsaScholarshipReceiving: '',
    f_ccsaWLConsent: '',
    j_children: [
      {
        d_name: '',
        d_dateBirth: '',
        date4: '',
        d_gender: '',
        f_IEPIFSP: '',
        f_specialNeeds: '',
        d_subsidy: '',
      },
    ],
    f_status: '',
  },
  upload: {
    show: ''
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    d_wlsID: '',
    d_priority: '',
    f_incomeRank: '',
    d_nameFirst: '',
    d_nameLast: '',
    d_residenceCounty: '',
    fk_counselorID: '',
    d_nameInitialCounselor: '',
    fk_actionTypeID: '',
    f_status: '',
  },
  currentIndex: 0
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_WAITINGLIST(state) {
    state.waitingList = [];
  },
  FETCH(state, { list }) {
    state.waitingList = list;
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.waitingListValueList = list;
  },
  CREATE(state, { data }) {
    state.waitingList.data.unshift(data);
  },
  READ(state, { data }) {
    state.waitingListItem = data;
  },
  UPDATE(state, { data }) {
    state.waitingListItem = data;
  },
  DELETE(state, { id }) {
    const idx = state.waitingList.data.map((item) => item.id).indexOf(id);
    state.waitingList.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_counselorID = data.fk_userID;
    state.entry.d_wlsID = data.d_wlsID;
    state.entry.d_waitListPullID = data.d_waitListPullID;
    state.entry.j_priority = data.j_priority;
    state.entry.f_incomeRank = data.f_incomeRank;
    state.entry.date1 = data.d_dateLastScreening;
    state.entry.d_statusLastScreening = data.d_statusLastScreening;
    state.entry.date2 = data.d_dateWLSupdateFromScreening;
    state.entry.d_type = data.d_type;
    state.entry.d_nameInitialCounselor = data.d_nameInitialCounselor;
    state.entry.d_caseType = data.d_caseType;
    state.entry.d_nameFirst = data.d_nameFirst;
    state.entry.d_nameLast = data.d_nameLast;
    state.entry.d_residenceStreet = data.d_residenceStreet;
    state.entry.d_residenceCity = data.d_residenceCity;
    state.entry.d_residenceCounty = data.d_residenceCounty;
    state.entry.d_residenceState = data.d_residenceState;
    state.entry.d_residencePostalCode = data.d_residencePostalCode;
    state.entry.date3 = data.d_dateBirth;
    state.entry.d_gender = data.d_gender;
    state.entry.d_age = data.d_age;
    state.entry.d_languagePrimary = data.d_languagePrimary;
    state.entry.d_relationshipToChild = data.d_relationshipToChild;
    state.entry.d_emailLogin = data.d_emailLogin;
    state.entry.d_phonePrimary = data.d_phonePrimary;
    state.entry.d_phoneSecondary = data.d_phoneSecondary;
    state.entry.d_adultsInHousehold = data.d_adultsInHousehold;
    state.entry.j_children = data.j_children;
    state.entry.d_familySize = data.d_familySize;
    state.entry.d_income = data.d_income;
    state.entry.d_incomeAdjusted = data.d_incomeAdjusted;
    state.entry.d_incomeFamily = data.d_incomeFamily;
    state.entry.d_statusEmployment = data.d_statusEmployment;
    state.entry.d_employer = data.d_employer;
    state.entry.d_employerOther = data.d_employerOther;
    state.entry.d_affiliateUNCCH = data.d_affiliateUNCCH;
    state.entry.d_affiliateUNCCHother = data.d_affiliateUNCCHother;
    state.entry.d_nameFirstCaller = data.d_nameFirstCaller;
    state.entry.d_nameLastCaller = data.d_nameLastCaller;
    state.entry.d_emailCaller = data.d_emailCaller;
    state.entry.d_note = data.d_note;
    state.entry.f_ccsaScholarshipWL = data.f_ccsaScholarshipWL;
    state.entry.f_subsidyHomelessWL = data.f_subsidyHomelessWL;
    state.entry.f_enrolledNeedsAssistance = data.f_enrolledNeedsAssistance;
    state.entry.f_ccsaScholarshipReceiving = data.f_ccsaScholarshipReceiving;
    state.entry.f_ccsaWLConsent = data.f_ccsaWLConsent;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_counselorID = '';
    state.entry.d_wlsID = '';
    state.entry.d_waitListPullID = '';
    state.entry.j_priority = '';
    state.entry.f_incomeRank = '';
    state.entry.d_dateLastScreening = '';
    state.entry.date1 = '';
    state.entry.d_statusLastScreening = '';
    state.entry.d_dateWLSupdateFromScreening = '';
    state.entry.date2 = '';
    state.entry.d_type = '';
    state.entry.d_nameInitialCounselor = '';
    state.entry.d_caseType = '';
    state.entry.d_nameFirst = '';
    state.entry.d_nameLast = '';
    state.entry.d_residenceStreet = '';
    state.entry.d_residenceCity = '';
    state.entry.d_residenceCounty = '';
    state.entry.d_residenceState = '';
    state.entry.d_residencePostalCode = '';
    state.entry.d_dateBirth = '';
    state.entry.date3 = '';
    state.entry.d_gender = '';
    state.entry.d_age = '';
    state.entry.d_languagePrimary = '';
    state.entry.d_relationshipToChild = '';
    state.entry.d_emailLogin = '';
    state.entry.d_phonePrimary = '';
    state.entry.d_phoneSecondary = '';
    state.entry.d_adultsInHousehold = '';
    state.entry.j_children = [{
      d_name: '',
      d_dateBirth: '',
      date4: '',
      d_gender: '',
      f_IEPIFSP: '',
      f_specialNeeds: '',
      d_subsidy: '',
    }];
    state.entry.d_familySize = '';
    state.entry.d_income = '';
    state.entry.d_incomeAdjusted = '';
    state.entry.d_incomeFamily = '';
    state.entry.d_statusEmployment = '';
    state.entry.d_employer = '';
    state.entry.d_employerOther = '';
    state.entry.d_affiliateUNCCH = '';
    state.entry.d_affiliateUNCCHother = '';
    state.entry.d_nameFirstCaller = '';
    state.entry.d_nameLastCaller = '';
    state.entry.d_emailCaller = '';
    state.entry.d_note = '';
    state.entry.f_ccsaScholarshipWL = '';
    state.entry.f_subsidyHomelessWL = '';
    state.entry.f_enrolledNeedsAssistance = '';
    state.entry.f_ccsaScholarshipReceiving = '';
    state.entry.f_ccsaWLConsent = '';
    state.entry.f_status = '';
  },
  ADD_CHILD(state) {
    const data = {
      d_name: '',
      d_dateBirth: '',
      date4: '',
      d_gender: '',
      f_IEPIFSP: '',
      f_specialNeeds: '',
      d_subsidy: '',
    };
    state.entry.j_children.push(data);
  },
  REMOVE_CHILD(state, { id }) {
    state.entry.j_children.splice(id, 1);
  },
  CURRENT_INDEX(state, { uuid }) {
    const idx = state.waitingList.data.map(item => item.uuid).indexOf(uuid);
    state.currentIndex = idx;
  },
  READ_BY_INDEX(state, { index }) {
    state.waitingListItem = state.waitingList.data[index];
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateUploadField(state, field) {
    updateField(state.upload, field);
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearWaitingList: ({ commit }) => {
    commit("CLEAR_WAITINGLIST");
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/waitinglist', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get('/api/waitinglistvaluelist', { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/waitinglist/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/waitinglist/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/waitinglist/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/waitinglist/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async currentIndex({ commit }, uuid) {
    try {
      commit("CURRENT_INDEX", { uuid });
    } catch (error) {}
  },
  async readByIndex({ commit }, index) {
    try {
      commit("READ_BY_INDEX", { index });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post("/api/waitinglist", data, { headers: getHeader() });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addChild({ commit }) {
    commit('ADD_CHILD');
  },
  removeChild({ commit }, id) {
    commit('REMOVE_CHILD', { id });
  },
};

const getters = {
  getWaitingList: (state, getters) => state.waitingList,
  getWaitingListItem: (state, getters) => state.waitingListItem,
  getFilters: (state, getters) => state.filters,
  getUpload: (state, getters) => state.upload,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getUploadField(state) {
    return getField(state.upload);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
