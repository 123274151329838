<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="clientSection === 1" :title-value="'Clients'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-user-circle</v-icon>
        <v-toolbar-title class="pl-2">{{
          clientSection === 3 ? "Bookmarked Clients" : "Clients"
        }}</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="clientSection !== 3" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="600px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <!--Clear Filter Button -->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Child ID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="id"
                    label="Child ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--v1 Child ID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_childIDv1"
                    label="v1 Child ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--SMA ApplicantionID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_applicationIDsma"
                    label="SMA Application ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--PreK Seat ID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_prekSeatID"
                    label="PreK Seat ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--v1 ApplicantID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_applicantIDv1"
                    label="v1 Applicant ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--HouseholdID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="fk_householdID"
                    label="Household ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Household Phone-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_householdPhone"
                    label="Household Phone"
                    @blur="formatPhone(d_householdPhone)"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Household Email-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_householdEmail"
                    label="Household Email"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Last Name-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_nameLast"
                    label="Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--First Name-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_nameFirst"
                    label="First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Parent/Guardian Last Name-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_applicantNameLast"
                    label="Parent/Guardian Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Parent/Guardian First Name-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_applicantNameFirst"
                    label="Parent/Guardian First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
               <!--Alerts-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="actionTypesStore.actionTypesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Alert"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_actionTypeID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Specialist ID-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="usersStore.usersValueList"
                    label="Specialist"
                    placeholder=" "
                    persistent-placeholder
                    item-text="name"
                    item-value="id"
                    v-model="fk_counselorID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Priority Service-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[11]"
                    label="Service Priority"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_name"
                    item-value="d_name"
                    v-model="d_priorityService"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Client Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.carePlanStatus"
                    label="Care Plan Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateEnd"
                        label="Eligibility Check-In Date (within 30 days prior to and including date entered)"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        @blur="date1 = $_parseDate(d_dateEnd)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <export-excel
        :section="5"
        :enabled="clientsStore.clients.data && clientsStore.clients.data.length > 0"
      />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="clientsStore.clients.data && clientsStore.clients.data.length > 0"
      :showTotal="clientSection === 3 ? false : true"
      :currentPage="clientsStore.clients.current_page"
      :lastPage="clientsStore.clients.last_page"
      :total="clientsStore.clients.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-to-detail
        :show="clientsStore.clients.data && clientsStore.clients.data.length > 0 ? true : false"
        :list-data="clientsStore.clients.data"
        :title="'Clients'"
        :section="5"
        :showid="true"
        @open-detail="detail($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListToDetail from "@/components/ListToDetail";
import ExportExcel from "@/components/ExportExcel";

const { mapFields } = createHelpers({
  getterType: "clientsStore/getFilterField",
  mutationType: "clientsStore/updateFilterField"
});

export default {
  name: "Clients",
  components: {
    TitleBar,
    Pagination,
    ListToDetail,
    ExportExcel
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      clientsStore: state => state.clientsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore,
      actionTypesStore: state => state.actionTypesStore
    }),
    ...mapFields([
      "page",
      "section",

      "id",
      "fk_userID",
      "fk_providerID",
      "d_childIDv1",
      "d_householdPhone",
      "d_householdEmail",
      "d_prekSeatID",
      "fk_actionTypeID",
      "fk_householdID",
      "d_applicationIDsma",
      "d_applicantIDv1",
      "d_nameLast",
      "d_nameFirst",
      "d_nameDisplay",
      "d_applicantNameLast",
      "d_applicantNameFirst",
      "d_priorityService",
      "fk_counselorID",
      "f_status",
      "date1",
      "d_dateEnd",
      "primaryApplicant",
      "f_viewPrivateRecords"
    ])
  },
  watch: {
    date1() {
      this.d_dateEnd = this.$_formatDate(this.date1);
    }
  },
  props: {
    /* All = 1, Provider = 2, Dashboard = 3 */
    clientSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      progress: true,
      filterMenu: false,
      menu: false
    };
  },
  methods: {
    async initialize() {
      /* Show progress bar if not running from dashboard */
      if (this.clientSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* If provider section */
      if (this.clientSection === 2) {
        /* Grab provider detail based on uuid passed in url parameter */
        // await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
        /* Hard code filter of provider ID to restrict clients by provider */
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_userID = "";
        this.fk_providerID = provider.id;
      } else if (this.clientSection === 3) {
      /* If showing on dashboard */
        /* Hard code filter of authenticated user to grab bookmarked clients */
        const user = this.$store.getters["usersStore/getAuthUser"];
        this.fk_userID = user.id;
        this.fk_providerID = "";
      } else {
      /* All */
        if (this.$route.params.householdID > 0) {
          this.fk_householdID = this.$route.params.householdID;
        }
        /* Clear hard coded filters */
        this.fk_userID = "";
        this.fk_providerID = "";
      }
      const user = this.$store.getters["usersStore/getAuthUser"];
      this.f_viewPrivateRecords = user.f_viewPrivateRecords;

      /* Pull client list */
      const filters = this.$store.getters["clientsStore/getFilters"];
      await this.$store.dispatch("clientsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      /* Grab users marked a counselor */
      const specialist = {
        f_counselor: 1
      };
      await this.$store.dispatch("usersStore/valueList", specialist);
      const data2 = {
        id: [11]
      };
      await this.$store.dispatch("valueListsStore/items", data2);
      const f_type = {
          f_type: 2
        };
      await this.$store.dispatch("actionTypesStore/valueList", f_type);
    },

    formatPhone(phoneInput) {
      if (this.d_householdPhone)
      {
        let formatPhone = phoneInput.replace(/(\D+)/g, "");
        let newVal = formatPhone.slice(0,3) + "-" + formatPhone.slice(3,6) + "-" + formatPhone.slice(6);
        this.d_householdPhone = newVal;
      }
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.id = "";
      this.d_childIDv1 = "";
      this.d_householdPhone = "";
      this.d_householdEmail = "";
      this.d_prekSeatID = "";
      this.d_applicationIDsma = "";
      this.fk_householdID = "";
      this.d_applicantIDv1 = "";
      this.d_nameLast = "";
      this.d_nameFirst = "";
      this.d_nameDisplay = "";
      this.fk_actionTypeID = "";
      this.d_applicantNameLast = "";
      this.d_applicantNameFirst = "";
      this.d_priorityService = "";
      this.fk_counselorID = "";
      this.f_status = "";
      this.d_dateEnd = "";
      this.date1 = "";
      this.primaryApplicant = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.clientSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("clientsStore/clearClients");
      }
      /* Save section in store */
      this.section = this.clientSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.id ||
        this.d_childIDv1 ||
        this.d_householdPhone ||
        this.d_householdEmail ||
        this.d_prekSeatID ||
        this.d_applicationIDsma ||
        this.fk_householdID ||
        this.d_applicantIDv1 ||
        this.d_nameLast ||
        this.d_nameFirst ||
        this.d_nameDisplay ||
        this.fk_actionTypeID ||
        this.d_applicantNameLast ||
        this.d_applicantNameFirst ||
        this.d_priorityService ||
        this.fk_counselorID ||
        this.f_status ||
        this.f_status === 0 ||
        this.d_dateEnd ||
        this.primaryApplicant
      ) {
        return true;
      }
      return false;
    },

    detail(uuid) {
      this.$store.dispatch("clientsStore/currentIndex", uuid);
      this.$router.push({ name: "client", params: { clientUUID: uuid } });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
