import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  benefits: [],
  benefit: null,
  entry: {
    show: false,
    valid: '',
    mode: '',
    tab: 0,
    isDuplicate: 0,
    id: '',
    fk_providerID: '',
    d_admin: '',
    d_position: '',
    d_schoolYear: '',
    j_insurance: [
      {
        d_type: 'Health Insurance',
        d_value: ''
      },
      {
        d_type: 'Dental Insurance',
        d_value: ''
      },
      {
        d_type: 'Vision Insurance',
        d_value: ''
      }
    ],
    f_addDependents: '',
    d_noteInsurance: '',
    f_sickDaysUsedForDependantCare: '',
    j_paidTimeOff: [
      {
        d_type: 'Sick',
        d_value: ''
      },
      {
        d_type: 'Vacation',
        d_value: ''
      },
      {
        d_type: 'Holiday',
        d_value: ''
      },
      {
        d_type: 'Teacher Work Days',
        d_value: ''
      }
    ],
    j_effectiveInterval: [
      {
        d_type: 'Health Insurance',
        d_value: ''
      },
      {
        d_type: 'Retirement',
        d_value: ''
      },
      {
        d_type: 'Sick Leave',
        d_value: ''
      },
      {
        d_type: 'Vacation Leave',
        d_value: ''
      }
    ],
    j_benefitsAdditional: [],
    f_employeeRetirementContributions: '',
    j_benefitsOther: [
      {
        d_value: ''
      }
    ],
    d_note: '',
    f_show: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: '',
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.benefits = list;
  },
  CREATE(state, { data }) {
    state.benefits.data.push(data);
  },
  READ(state, { data }) {
    state.benefit = data;
  },
  UPDATE(state, { data }) {
    const idx = state.benefits.data.map(item => item.id).indexOf(data.id);
    state.benefits.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.benefits.data.map((item) => item.id).indexOf(id);
    state.benefits.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.d_admin = data.d_admin;
    state.entry.d_position = data.d_position;
    state.entry.d_schoolYear = data.d_schoolYear;
    state.entry.j_insurance = data.j_insurance;
    state.entry.f_addDependents = data.f_addDependents;
    state.entry.d_noteInsurance = data.d_noteInsurance;
    state.entry.f_sickDaysUsedForDependantCare = data.f_sickDaysUsedForDependantCare;
    state.entry.j_paidTimeOff = data.j_paidTimeOff;
    state.entry.j_effectiveInterval = data.j_effectiveInterval;
    state.entry.j_benefitsAdditional = data.j_benefitsAdditional;
    state.entry.f_employeeRetirementContributions = data.f_employeeRetirementContributions;
    state.entry.j_benefitsOther = data.j_benefitsOther;
    state.entry.d_note = data.d_note;
    state.entry.f_show = data.f_show;
    state.entry.tab = 0;
    state.entry.isDuplicate = 0;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.d_admin = '';
    state.entry.d_position = '';
    state.entry.d_schoolYear = '';
    state.entry.j_insurance = [
      {
        d_type: 'Health Insurance',
        d_value: ''
      },
      {
        d_type: 'Dental Insurance',
        d_value: ''
      },
      {
        d_type: 'Vision Insurance',
        d_value: ''
      }
    ];
    state.entry.f_addDependents = '';
    state.entry.d_noteInsurance = '';
    state.entry.f_sickDaysUsedForDependantCare = '';
    state.entry.j_paidTimeOff = [
      {
        d_type: 'Sick',
        d_value: ''
      },
      {
        d_type: 'Vacation',
        d_value: ''
      },
      {
        d_type: 'Holiday',
        d_value: ''
      },
      {
        d_type: 'Teacher Work Days',
        d_value: ''
      }
    ];
    state.entry.j_effectiveInterval = [
      {
        d_type: 'Health Insurance',
        d_value: ''
      },
      {
        d_type: 'Retirement',
        d_value: ''
      },
      {
        d_type: 'Sick Leave',
        d_value: ''
      },
      {
        d_type: 'Vacation Leave',
        d_value: ''
      }
    ];
    state.entry.j_benefitsAdditional = [];
    state.entry.f_employeeRetirementContributions = '';
    state.entry.j_benefitsOther = [
      {
        d_value: ''
      }
    ];
    state.entry.d_note = '';
    state.entry.f_show = true;
    state.entry.tab = 0;
    state.entry.isDuplicate = 0;
  },
  DUPLICATE(state, { data }) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = '';
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.d_admin = data.d_admin;
    state.entry.d_position = data.d_position;
    state.entry.d_schoolYear = data.d_schoolYear;
    state.entry.j_insurance = data.j_insurance;
    state.entry.f_addDependents = data.f_addDependents;
    state.entry.d_noteInsurance = data.d_noteInsurance;
    state.entry.f_sickDaysUsedForDependantCare = data.f_sickDaysUsedForDependantCare;
    state.entry.j_paidTimeOff = data.j_paidTimeOff;
    state.entry.j_effectiveInterval = data.j_effectiveInterval;
    state.entry.j_benefitsAdditional = data.j_benefitsAdditional;
    state.entry.f_employeeRetirementContributions = data.f_employeeRetirementContributions;
    state.entry.j_benefitsOther = data.j_benefitsOther;
    state.entry.d_note = data.d_note;
    state.entry.f_show = data.f_show;
    state.entry.created_at = '';
    state.entry.updated_at = '';
    state.entry.tab = 0;
    state.entry.isDuplicate = 1;
    state.entry.show = true;
  },
  ADD_BENEFIT_OTHER(state) {
    const data = {
      d_value: ''
    };
    state.entry.j_benefitsOther.push(data);
  },
  REMOVE_BENEFIT_OTHER(state, {id}) {
    state.entry.j_benefitsOther.splice(id, 1);
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/benefits', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/benefits/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/benefits/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/benefits/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async duplicate({ commit }, id) {
    try {
      const response = await axios.get(`/api/benefits/${id}`, { headers: getHeader() });
      commit('DUPLICATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/benefits/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/benefits/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
      return response;
    } catch (error) {

    }
  },
  addBenefitOther({ commit }) {
    commit('ADD_BENEFIT_OTHER');
  },
  removeBenefitOther({ commit }, id) {
    commit('REMOVE_BENEFIT_OTHER', { id });
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
};

const getters = {
  getbenefit: (state, getters) => state.benefit,
  getFilters: (state, getters) => state.filters,
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
