<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fa-thin fa-children
          </v-icon>
        </v-btn>
      </template>
      <span>Add Dependent</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Dependent Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row v-if="mode === 2" dense>
                <!--Create Client-->
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    :items="valueListsStore.yesNo"
                    item-value="id"
                    item-text="value"
                    label="Create Client"
                    placeholder=" "
                    persistent-placeholder
                    v-model="createClient"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Birth Date-->
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateBirth"
                        label="Date of Birth"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_dateBirth)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Name-->
                <v-col v-if="createClient !== 1" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                    required
                  >
                  </v-text-field>
                </v-col>

                <!--First Name-->
                <v-col v-if="createClient === 1" cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_nameFirst"
                    label="First Name"
                    placeholder=" "
                    persistent-placeholder
                    required
                  >
                  </v-text-field>
                </v-col>
                <!--Last Name-->
                <v-col v-if="createClient === 1" cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_nameLast"
                    label="Last Name"
                    placeholder=" "
                    persistent-placeholder
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="createClient !== 1" dense>
                <!--Relationship-->
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    :items="valueListsStore.valueListItems[7]"
                    item-text="d_name"
                    item-value="d_name"
                    label="Relationship"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_relationship"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                  />
                </v-col>
              </v-row>

              <v-row v-if="createClient !== 1" dense>
                <!--Related To-->
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    :items="valueListsStore.valueListItems[4]"
                    item-text="d_name"
                    item-value="d_name"
                    label="Related To"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_parent"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                  />
                </v-col>
              </v-row>

              <v-row v-if="createClient !== 1" dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>

              <!--Removal-->
              <v-row v-if="usersStore.authUser.f_delete === 1">
                <v-col cols="12" sm="12" md="3">
                  <v-select
                    v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                    :items="[{text: 'Yes', value: '0'}]"
                    item-text="text"
                    item-value="value"
                    label="Remove Record?"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_show"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "dependantsStore/getEntryField",
  mutationType: "dependantsStore/updateEntryField"
});

export default {
  name: "DependantEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      dependantsStore: state => state.dependantsStore,
      usersStore: state => state.usersStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_householdID",
      "d_name",
      "d_relationship",
      "d_parent",
      "d_dateBirth",
      "dateBirth",
      "f_status",
      "f_show",
      "date1",
      "createClient",
      "d_nameFirst",
      "d_nameLast"
    ])
  },
  data() {
    return {
      menu: false
    };
  },
  watch: {
    dateBirth() {
      this.d_dateBirth = this.$_formatDate(this.dateBirth);
    },
    date1() {
      this.d_dateBirth = this.$_formatDate(this.date1);
    },
    d_name() {
      var name = this.d_name.split(" ");
      this.d_nameFirst = name[0];
      this.d_nameLast = name[1];
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("dependantsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();
      const data2 = {
        id: [4, 7]
      };
      await this.$store.dispatch("valueListsStore/items", data2);
      const client = this.$store.getters["clientsStore/getClient"];

      this.fk_householdID = client.fk_householdID;
      this.f_status = 1;
      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["dependantsStore/getEntry"];
        /* Create Dependent */
        if (this.mode === 1) {
          await this.$store.dispatch("dependantsStore/create", entry);
          /*if (entry.createClient == 1) {
            await this.$store.dispatch("dependantsStore/createClient", entry);
          } else {
            await this.$store.dispatch("dependantsStore/create", entry);
          }*/
        } else {
        /* Edit Dependent */
          if (entry.createClient == 1) {
            this.$store.dispatch("progressStore/set", true);
            //Remove Dependent Record
            await this.$store.dispatch("dependantsStore/delete", entry);
            //Create Client Record
            await this.$store.dispatch("dependantsStore/createClient", entry);
            this.$store.dispatch("progressStore/set", false);
          } else {
            await this.$store.dispatch("dependantsStore/update", entry);

            /* reload the dependent list page */
            this.$store.dispatch("progressStore/set", true);
            /* Grab client detail */
              const client = this.$store.getters['clientsStore/getClient'];
              const data = {
                clientID: client.id,
                fk_householdID: client.fk_householdID,
              };
              /* Grab dependants list */
              await this.$store.dispatch('dependantsStore/fetch', data);
            this.$store.dispatch("progressStore/set", false);
          }
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
