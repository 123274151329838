import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  education: [],
  primary: [],
  otherParent: [],
  entry: {
    show: '',
    valid: '',
    mode: '',

    /* ADD Teacher Education Entry fields */
    id: '',
    fk_teacherID: '',
    d_schoolName: '',
    d_enrolled: '',
    d_type: '',
    d_degreeSought: '',
    d_expectedCompletion: '',
    f_completion: '',
    date1: '',
    f_status: '',
    f_show: '',

    /* Household and Application Education Items */
    items: [{
      id: '',
      fk_householdID: '',
      fk_applicantID: '',
      d_schoolName: '',
      d_degreeSought: '',
      d_expectedCompletion: '',
      f_completion: '',
      d_enrolled: '',
      d_type: '',
      d_financialAidAmount: '',
      d_financialAidPeriod: '',
      d_tuitionAmount: '',
      d_tuitionAmountPeriod: '',
      f_status: '',
      date1: '',
    }],
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.education = list;
  },
  FETCH_PRIMARY(state, { list }) {
    state.primary = list;
  },
  FETCH_OTHER_PARENT(state, { list }) {
    state.otherParent = list;
  },
  CREATE(state, { data }) {
    state.education.data.unshift(data);
  },
  UPDATE(state, { list }) {
    state.education = list;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.items = data;

    state.entry.id = data[0].id;
    state.entry.fk_teacherID = data[0].fk_teacherID;
    state.entry.d_schoolName = data[0].d_schoolName;
    state.entry.d_enrolled = data[0].d_enrolled;
    state.entry.d_type = data[0].d_type;
    state.entry.d_degreeSought = data[0].d_degreeSought;
    state.entry.date1 = data[0].d_expectedCompletion;
    state.entry.f_completion = data[0].f_completion;
    state.entry.f_status = data[0].f_status;
    state.entry.f_show = data[0].f_show;

    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';

    state.entry.id = '';
    state.entry.fk_teacherID = '';
    state.entry.d_schoolName = '';
    state.entry.d_enrolled = '';
    state.entry.d_type = '';
    state.entry.d_degreeSought = '';
    state.entry.d_expectedCompletion = '';
    state.entry.f_completion = false;
    state.entry.date1 = '';
    state.entry.f_status = true;
    state.entry.f_show = true;

    state.entry.items = [{
      id: '',
      fk_householdID: '',
      fk_applicantID: '',
      d_schoolName: '',
      d_degreeSought: '',
      d_expectedCompletion: '',
      f_completion: 0,
      d_enrolled: '',
      d_type: '',
      d_financialAidAmount: '',
      d_financialAidPeriod: '',
      d_tuitionAmount: '',
      d_tuitionAmountPeriod: '',
      f_status: 1,
      f_show: 1,
      date1: '',
    }];
  },
  ADD_ITEM(state, { data }) {
    const item = {
      id: '',
      fk_householdID: data.fk_householdID,
      fk_applicantID: '',
      d_schoolName: '',
      d_degreeSought: '',
      d_expectedCompletion: '',
      f_completion: 0,
      d_enrolled: '',
      d_type: '',
      d_financialAidAmount: '',
      d_financialAidPeriod: '',
      d_tuitionAmount: '',
      d_tuitionAmountPeriod: '',
      f_status: 1,
      f_show: 1,
      date1: '',
    };
    state.entry.items.unshift(item);
  },
  UPDATE_ITEM(state, { data }) {
    state.entry.items[0].d_schoolName = data.d_schoolName;
    state.entry.items[0].d_degreeSought = data.d_degreeSought;
    state.entry.items[0].d_expectedCompletion = data.date1;
    state.entry.items[0].f_completion = data.f_completion;
    state.entry.items[0].d_enrolled = data.d_enrolled;
    state.entry.items[0].d_type = data.d_type;
    state.entry.items[0].f_status = data.f_status;
    state.entry.items[0].f_show = data.f_show;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/education', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchPrimary({ commit }, data) {
    try {
      const response = await axios.post('/api/education', data, { headers: getHeader() });
      commit('FETCH_PRIMARY', { list: response.data });
    } catch (error) {

    }
  },
  async fetchOtherParent({ commit }, data) {
    try {
      const response = await axios.post('/api/education', data, { headers: getHeader() });
      commit('FETCH_OTHER_PARENT', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/education/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.post('/api/education/update', data, { headers: getHeader() });
      commit('UPDATE', { list: response.data });
    } catch (error) {

    }
  },
  async entry({ commit }, data) {
    try {
      const response = await axios.post('/api/education', data, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addItem({ commit }, data) {
    commit('ADD_ITEM', { data });
  },
  updateItem({ commit }, data) {
    commit('UPDATE_ITEM', { data });
  },
};

const getters = {
  getEducation: (state, getters) => state.education,
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
