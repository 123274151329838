import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  households: [],
  household: {},
  householdIncome: '',
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_countyID_Residence: '',
    fk_countyID_Mailing: '',
    d_residenceStreet1: '',
    d_residenceStreet2: '',
    d_residenceCity: '',
    d_residenceState: '',
    d_residencePostalCode: '',
    d_mailingStreet1: '',
    d_mailingStreet2: '',
    d_mailingCity: '',
    d_mailingState: '',
    d_mailingPostalCode: '',
    d_familyStructure: '',
    d_familyStructureOther: '',
    d_familySize: '',
    id1: '',
    fk_householdID1: '',
    d_wlsID1: '',
    d_nameFirst1: '',
    d_nameMiddle1: '',
    d_nameLast1: '',
    d_dateBirth1: '',
    d_relationship1: '',
    j_race1: [],
    d_raceOther1: '',
    id2: '',
    fk_householdID2: '',
    d_nameFirst2: '',
    d_nameMiddle2: '',
    d_nameLast2: '',
    d_dateBirth2: '',
    d_relationship2: '',
    j_race2: [],
    d_raceOther2: '',
    date1: '',
    date2: '',
    f_sameAddress: '',
  },
  filters: {
    page: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.households = list;
  },
  CREATE(state, { data }) {
    state.households.data.unshift(data);
  },
  READ(state, { data }) {
    state.household = data;
  },
  UPDATE(state, { data }) {
    state.household = data;
  },
  DELETE(state, { id }) {
    const idx = state.households.data.map((item) => item.id).indexOf(id);
    state.households.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_countyID_Residence = data.fk_countyID_Residence;
    state.entry.fk_countyID_Mailing = data.fk_countyID_Mailing;
    state.entry.d_residenceStreet1 = data.d_residenceStreet1;
    state.entry.d_residenceStreet2 = data.d_residenceStreet2;
    state.entry.d_residenceCity = data.d_residenceCity;
    state.entry.d_residenceState = data.d_residenceState;
    state.entry.d_residencePostalCode = data.d_residencePostalCode;
    state.entry.d_mailingStreet1 = data.d_mailingStreet1;
    state.entry.d_mailingStreet2 = data.d_mailingStreet2;
    state.entry.d_mailingCity = data.d_mailingCity;
    state.entry.d_mailingState = data.d_mailingState;
    state.entry.d_mailingPostalCode = data.d_mailingPostalCode;
    state.entry.d_familyStructure = data.d_familyStructure;
    state.entry.d_familyStructureOther = data.d_familyStructureOther;
    state.entry.d_familySize = data.d_familySize;
    state.entry.id1 = data.primary_applicant.id;
    state.entry.fk_householdID1 = data.primary_applicant.fk_householdID;
    state.entry.d_wlsID1 = data.primary_applicant.d_wlsID;
    state.entry.d_nameFirst1 = data.primary_applicant.d_nameFirst;
    state.entry.d_nameMiddle1 = data.primary_applicant.d_nameMiddle;
    state.entry.d_nameLast1 = data.primary_applicant.d_nameLast;
    state.entry.d_relationship1 = data.primary_applicant.d_relationship;
    state.entry.j_race1 = data.primary_applicant.j_race;
    state.entry.d_raceOther1 = data.primary_applicant.d_raceOther;
    state.entry.date1 = data.primary_applicant.d_dateBirth;
    state.entry.f_sameAddress = data.f_sameAddress === 1;
    state.entry.id2 = data.other_parent.id;
    state.entry.fk_householdID2 = data.other_parent.fk_householdID;
    state.entry.d_nameFirst2 = data.other_parent.d_nameFirst;
    state.entry.d_nameMiddle2 = data.other_parent.d_nameMiddle;
    state.entry.d_nameLast2 = data.other_parent.d_nameLast;
    state.entry.d_relationship2 = data.other_parent.d_relationship;
    state.entry.j_race2 = data.other_parent.j_race;
    state.entry.d_raceOther2 = data.other_parent.d_raceOther;
    state.entry.date2 = data.other_parent.d_dateBirth;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_countyID_Residence = '';
    state.entry.fk_countyID_Mailing = '';
    state.entry.d_residenceStreet1 = '';
    state.entry.d_residenceStreet2 = '';
    state.entry.d_residenceCity = '';
    state.entry.d_residenceState = '';
    state.entry.d_residencePostalCode = '';
    state.entry.d_mailingStreet1 = '';
    state.entry.d_mailingStreet2 = '';
    state.entry.d_mailingCity = '';
    state.entry.d_mailingState = '';
    state.entry.d_mailingPostalCode = '';
    state.entry.d_familyStructure = '';
    state.entry.d_familyStructureOther = '';
    state.entry.d_familySize = '';
    state.entry.id1 = '';
    state.entry.fk_householdID1 = '';
    state.entry.d_wlsID1 = '';
    state.entry.d_nameFirst1 = '';
    state.entry.d_nameMiddle1 = '';
    state.entry.d_nameLast1 = '';
    state.entry.d_relationship1 = '';
    state.entry.j_race1 = [];
    state.entry.d_raceOther1 = '';
    state.entry.date1 = '';
    state.entry.id2 = '';
    state.entry.fk_householdID2 = '';
    state.entry.d_nameFirst2 = '';
    state.entry.d_nameMiddle2 = '';
    state.entry.d_nameLast2 = '';
    state.entry.d_relationship2 = '';
    state.entry.j_race2 = [];
    state.entry.d_raceOther2 = '';
    state.entry.date2 = '';
    state.entry.f_sameAddress = '';
  },
  SET_INCOME(state, { data }) {
    state.householdIncome = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/households', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/households/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/households/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/households/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/households/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/households/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async income({ commit }, data) {
    try {
      const response = await axios.post('/api/households/income', data, { headers: getHeader() });
      commit('SET_INCOME', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getHousehold: (state, getters) => state.household,
  getHouseholdIncome: (state, getters) => state.householdIncome,
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
