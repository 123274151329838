import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  dependants: [],
  dependant: {},
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    fk_householdID: '',
    d_name: '',
    d_relationship: '',
    d_parent: '',
    d_dateBirth: '',
    dateBirth: '',
    f_status: '',
    f_show: '',
    date1: '',
    createClient: '',
    d_nameLast: '',
    d_nameFirst: '',
  },
  filters: {
    page: '',
    d_type: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.dependants = list;
  },
  CREATE(state, { data }) {
    state.dependants.unshift(data);
  },
  READ(state, { data }) {
    state.dependant = data;
  },
  UPDATE(state, { data }) {
    const idx = state.dependants.map((item) => item.id).indexOf(data.id);
    state.dependants.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.dependants.map((item) => item.id).indexOf(id);
    state.dependants.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_householdID = data.fk_householdID;
    state.entry.d_name = data.d_name;
    state.entry.d_relationship = data.d_relationship;
    state.entry.d_parent = data.d_parent;
    state.entry.dateBirth = data.d_dateBirth;
    state.entry.date1 = data.d_dateBirth;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.createClient = 0;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_householdID = '';
    state.entry.d_name = '';
    state.entry.d_relationship = '';
    state.entry.d_parent = '';
    state.entry.d_dateBirth = '';
    state.entry.date1 = '';
    state.entry.createClient = 0;
    state.entry.d_nameLast = '';
    state.entry.d_nameFirst = '';
    state.entry.f_status = 1;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/dependants', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/dependants/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async createClient({ commit }, data) {
    try {
      const response = await axios.post('/api/dependants/client', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/dependants/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/dependants/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/dependants/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/dependants/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getDependant: (state, getters) => state.dependant,
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntry: (state, getters) => state.entry,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
