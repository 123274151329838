<template>
  <div>
    <v-list v-if="show === true" class="py-2">
      <template v-for="(item, index) in listData">
        <v-list-item
          :key="index"
          @click="
            openEntry(
              /*ID of record*/
              section === 5.2 && item.f_status === 3 ? item.uuid : item.id,

              /*Status of record*/
              item.f_status ? item.f_status : '',

              /*Household of record*/
              section === 24 ? item.fk_householdID : '',

              /*Private record flag*/
              section === 24 && item.client
                ? item.client.f_privateRecord
                : section === 17 && item.f_privateRecord
                ? item.f_privateRecord
                : 0
            )
          "
          class="my-1"
        >
          <v-list-item-icon v-if="item.d_icon">
            <v-icon :color="item.d_iconColor" left>{{ item.d_icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <!----------Line 1---------->
            <v-list-item-title
              class="text-wrap"
              :class="
                $vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'white--text'
              "
            >
              <!--ID-->
              <span v-if="showid === true"> {{ item.id }} : </span>

              <!--Owner-->
              <span v-if="section === 5.1">
                {{ item.d_ownerOther ? item.d_ownerOther : item.d_owner }}
              </span>

              <!--name-->
              <span v-else-if="item.name">
                {{ item.name }}
              </span>

              <!--d_name-->
              <span v-else-if="item.d_name">
                {{ item.d_name }}
              </span>

              <!--d_nameLast-->
              <span v-else-if="item.d_nameLast">
                <span v-if="section === 17">Primary Applicant: </span>{{ item.d_nameLast }},
                {{ item.d_nameFirst }}
                <v-chip v-if="item.f_privateRecord === 1" color="error"
                  ><v-icon small left>fal fa-lock</v-icon>Private Record</v-chip
                >
              </span>

              <!--d_type-->
              <span v-else-if="item.d_type">
                {{ item.d_type }}
              </span>

              <!--d_title-->
              <span v-else-if="item.d_title">
                {{ item.d_title }}
              </span>

              <!--Settings - Message Board-->
              <span v-else-if="section === 41 && item.d_dateStart">
                {{ $_formatDate(item.d_dateStart) }} - {{ $_formatDate(item.d_dateEnd) }}
              </span>

              <span v-else-if="section === 41 && item.f_alwaysShow === 1">
                Always Show
              </span>

              <!--d_date-->
              <span v-else-if="item.d_date">
                <v-icon v-if="item.f_pin" color="yellow">
                    fa-regular fa-thumbtack
                </v-icon>
                {{ $_formatDate(item.d_date) }}
              </span>

              <!--d_dateStart-->
              <span v-else-if="item.d_dateStart">
                {{ $_formatDate(item.d_dateStart) }}
              </span>

              <!--Attendance List-->
              <span v-else-if="section === 19.1">
                {{ item.attendance.provider.d_licenseNumber }} -
                {{ item.attendance.provider.d_nameLicense }}
              </span>

              <!--Provider Attendance List-->
              <span v-else-if="section === 19.2">
                {{ $_monthNumToText(item.f_month) }} - {{ item.d_year }}
              </span>

              <!--d_year-->
              <span v-else-if="item.d_year">
                {{ item.d_year }}
              </span>

              <!--Merge Codes-->
              <span v-else-if="section === 40">
                {{ item.d_code }}
              </span>

              <!--Correspondence-->
              <span v-if="section === 5.6 || section === 4.11">
                - {{ item.f_type === 1 ? "Email - " + item.d_to : "Letter" }}
              </span>

              <!--Client Imports-->
              <span v-else-if="section === 42">
                Client: {{ item.d_clientLastName }}, {{ item.d_clientFirstName }}
              </span>
            </v-list-item-title>

            <!----------Line 2---------->
            <v-list-item-title
              v-if="
                section === 3 ||
                  section === 24 ||
                  section === 4.1 ||
                  section === 8 ||
                  section === 11 ||
                  section === 14 ||
                  section === 18 ||
                  section === 21 ||
                  section === 4.11 ||
                  section === 5.1 ||
                  section === 5.2 ||
                  section === 5.6 ||
                  section === 17 ||
                  section === 28 ||
                  section === 32 ||
                  section === 4.5 ||
                  section === 19.1 ||
                  section === 34 ||
                  section === 41 ||
                  section === 42
              "
              :class="
                $vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'white--text'
              "
            >
              <!--Users-->
              <span v-if="section === 3">
                {{ item.email }}
              </span>

              <!--Funding Sources-->
              <span v-else-if="section === 8">
                {{ item.d_code }}
              </span>

              <!--Note Types, Task Types, Document Types-->
              <span v-else-if="section === 11 || section === 18 || section === 28">
                {{ $_switchGeneralSection(item.f_type) }}
              </span>

              <!--Note Types-->
              <span v-else-if="section === 14">
                {{ $_switchGeneralSection(item.f_section) }}
                <span v-if="item.f_type">
                  <!--BG 6-3-2021 Adding type to Template settings list-->
                  -- {{ correspondenceTypes[item.f_type - 1].value }}
                </span>
                <span v-if="item.d_language"> -- {{ item.d_language }}</span>
                <!--Added Language BG 6/4/2021-->
              </span>

              <!--Notes & Providers-->
              <span v-else-if="section === 24 || section === 4.1">
                {{ item.type.d_name }}
              </span>

              <!--Contacts-->
              <span v-else-if="section === 21">
                <div v-if="item.f_primary === 1">
                  Primary Contact
                </div>
                {{ item.d_title }}
                <div v-if="
                  item.f_director === 1 &&
                  item.provider.d_contractedStatus &&
                  item.provider.d_contractedStatus.includes('DPK')">
                  <i>DPK Director</i>
                </div>
              </span>

              <!--Contact Method Value-->
              <div v-else-if="section === 5.1 && item.j_contactMethods">
                <!--list non-preferred contact methods-->
                <div v-for="(contact, index) in item.j_contactMethods" :key="index + 'a'">
                  <span v-if="contact.f_preferred">{{ contact.d_type }}: {{ contact.d_value }}
                    <v-icon style="vertical-align:top"
                            color="yellow"
                            size="17">
                      fa-light fa-asterisk
                    </v-icon>
                  </span>
                </div>
                <!--list non-preferred contact methods-->
                <div v-for="(contact, index) in item.j_contactMethods" :key="index + 'b'">
                  <span v-if="!contact.f_preferred">{{ contact.d_type }}: {{ contact.d_value }}</span>
                </div>
              </div>

              <!--Dependant Parent-->
              <span v-else-if="section === 5.2">
                {{ item.d_parent }}
              </span>

              <!--Age Categories-->
              <span v-else-if="section === 32"> {{ item.d_ageStart }} - {{ item.d_ageEnd }} </span>

              <!--Classrooms-->
              <span v-else-if="section === 34 && item.active_history && item.active_history.length > 0">
                {{ $_formatDate(item.active_history[0].d_dateStart) }} -
                {{ item.active_history[0].d_teacherName }} -
                {{ item.active_history[0].f_bk === 1 ? "BK License" : "No BK License" }}
              </span>

              <!--Provider Tuition-->
              <span v-else-if="section === 4.5">
                {{ item.rating ? item.rating.d_name : "" }}
              </span>

              <!--Applications-->
              <span v-else-if="section === 17">
                <div v-for="(child, index) in item.j_applyingChild" :key="index">
                  <span>{{ child.d_nameLast }}, {{ child.d_nameFirst }}</span>
                </div>
              </span>

              <!--Attendance List-->
              <span v-else-if="section === 19.1">
                {{ $_monthNumToText(item.attendance.f_month) }} - {{ item.attendance.d_year }}
              </span>

              <!--Correspondence-->
              <span v-else-if="section === 5.6 || section === 4.11">
                {{ item.f_type === 1 ? item.d_subject : item.template.d_name }}
              </span>

              <!--Settings - Message Board-->
              <span v-else-if="section === 41">
                {{ item.d_subject }}
              </span>

              <!--Client Imports-->
              <span v-else-if="section === 42">
                Applicant: {{ item.d_primaryLastName }}, {{ item.d_primaryFirstName }}
              </span>
            </v-list-item-title>

            <!----------Line 2b---------->
            <v-list-item-title
              v-if="section === 24 || section === 5.2 || section === 19.1 || section === 21"
              :class="
                $vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'white--text'
              "
            >
              <!--Notes-->
              <span v-if="section === 24 && item.client && clientID > 0">
                <span v-if="item.client.id !== clientID">
                  Client: {{ item.client.d_nameLast }}, {{ item.client.d_nameFirst }}
                  <br>
                </span>
              </span>

              <span v-if="section === 24">
                {{ item.d_subject }}
              </span>

              <!--Relationship-->
              <span v-if="section === 5.2">
                {{ item.d_relationship }}
              </span>

              <!--Attendance List-->
              <span v-else-if="section === 19.1">
                {{ item.client.d_nameLast }}, {{ item.client.d_nameFirst }}
              </span>

              <!--Contacts-->
              <div v-if="section === 21 && item.d_type">
                <span>{{ item.d_type }}</span>
              </div>
            </v-list-item-title>

            <!----------Line 2b---------->
            <v-list-item-title
              v-if="section === 24"
              :class="
                $vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'white--text'
              "
            >
              <!--Notes-->
              <span v-if="section === 24"> Author: {{ item.author.name }} </span>
            </v-list-item-title>

            <!----------Line 3---------->
            <!--Timestamps-->
            <v-list-item-title
              v-if="section !== 37"
              class="text-caption grey--text"
              :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
            >
              ID: {{ item.id }} Created: {{ $_formatTimestamp(item.created_at) }} &#8226; Modified:
              {{ $_formatTimestamp(item.updated_at) }}
            </v-list-item-title>
          </v-list-item-content>

          <!----------Right Side Of List Actions---------->
          <v-list-item-action>
            <v-list-item-action-text v-if="section === 24"
              ><v-chip v-if="item.client && item.client.f_privateRecord === 1" color="error"
                ><v-icon small>fal fa-lock</v-icon></v-chip
              ></v-list-item-action-text
            >

            <!--Correspondence-->
            <v-list-item-action-text v-if="section === 5.6 || section === 4.11"
              ><span
                class="text-body-1"
                :class="
                  item.f_status === 1 || item.f_status === true
                    ? 'font-weight-bold green--text text--lighten-1'
                    : 'error--text'
                "
                >{{ item.f_status === 1 && item.f_type === 1 ? "Sent" : "" }}</span
              ></v-list-item-action-text
            >

            <!--Application Status-->
            <v-list-item-action-text v-else-if="section === 17"
              ><span
                class="text-body-1"
                :class="
                  item.f_status === 1 || item.f_status === true
                    ? 'primary--text'
                    : 'font-weight-bold green--text text--lighten-1'
                "
                >{{ $_applicationStatus(item.f_status) }}</span
              >
                <v-tooltip
                  v-if="usersStore.authUser.f_admin === 1"
                  left color="error"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="deleteRecord(item.id,
                          'The application and all related household information for ' +
                          item.d_nameFirst + ' ' + item.d_nameLast) + ' '"
                      >
                        <v-icon small color="error">
                          fal fa-trash
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Remove Application</span>
                  </v-tooltip>
              </v-list-item-action-text
            >

            <!--Alert Status-->
            <v-list-item-action-text v-else-if="section === 19.1"
              ><span
                class="text-body-1"
                :class="
                  item.f_status <= 2
                    ? 'primary--text'
                    : 'font-weight-bold green--text text--lighten-1'
                "
                >{{ $_attendanceStatus(item.f_status) }}</span
              ></v-list-item-action-text
            >

            <!--Alert Status-->
            <v-list-item-action-text v-else-if="section === 4.1"
              ><span
                class="text-body-1"
                :class="
                  item.f_status === 1 || item.f_status === true
                    ? 'primary--text'
                    : 'font-weight-bold green--text text--lighten-1'
                "
                >{{ $_actionStatus(item.f_status) }}</span
              ></v-list-item-action-text
            >

            <!--Alert Status-->
            <v-list-item-action-text v-else-if="section === 5.2 && item.f_status === 3"
              ><span class="text-body-1 font-weight-bold green--text text--lighten-1"
                >Client</span
              ></v-list-item-action-text
            >

            <!--Client Imports-->
            <v-list-item-action-text v-else-if="section === 42"
              ><span
                class="text-body-1"
                :class="
                  item.f_status === 1 || item.f_status === true
                    ? 'primary--text'
                    : item.f_status === 3
                    ? 'error--text'
                    : item.f_status === 4
                    ? 'deep-orange--text text--accent-2'
                    : 'font-weight-bold green--text text--lighten-1'
                "
                >{{ $_clientImportStatus(item.f_status) }}</span
              ></v-list-item-action-text
            >

            <!--Status-->
            <v-list-item-action-text
              v-else-if="
                (item.f_status == 0 && section !== 24 && section !== 37 && section !== 42) ||
                  (item.f_status &&
                    section !== 24 &&
                    section != 5.2 &&
                    section !== 37 &&
                    section !== 42)
              "
              ><span
                class="text-body-1"
                :class="
                  item.f_status === 1 || item.f_status === true
                    ? 'font-weight-bold green--text text--lighten-1'
                    : 'error--text'
                "
                >{{ $_activeStatus(item.f_status) }}</span
              ></v-list-item-action-text
            >

            <!--Access-->
            <v-list-item-action-text v-else-if="item.f_access == 0 || item.f_access"
              ><span
                class="text-body-1"
                :class="
                  item.f_access === 1 || item.f_access === true
                    ? 'font-weight-bold green--text text--lighten-1'
                    : 'error--text'
                "
                >{{ $_activeStatus(item.f_access) }}</span
              ></v-list-item-action-text
            >

            <!--Provider Clients-->
            <v-list-item-action-text v-else-if="section === 5"
              ><span
                class="text-body-1"
                :class="
                  item.provider[0].pivot.f_status === 1 || item.provider[0].pivot.f_status === true
                    ? 'primary--text'
                    : 'font-weight-bold green--text text--lighten-1'
                "
                >{{ $_activeStatus(item.provider[0].pivot.f_status) }}</span
              ></v-list-item-action-text
            >

            <!--Active Status-->
            <v-list-item-action-text
              v-else-if="item.f_status === 1 && section !== 24 && section !== 37 && section !== 42"
              ><span
                class="text-body-1"
                :class="
                  item.f_status === 1 || item.f_status === true
                    ? 'primary--text'
                    : 'font-weight-bold green--text text--lighten-1'
                "
                >{{ $_activeStatus(item.f_status) }}</span
              ></v-list-item-action-text
            >

            <!--Primary Contact-->
            <v-list-item-action-text v-if="section === 5.1 && item.f_primary === 1"
              ><span class="text-body-1 font-weight-bold light-blue--text text--darken-4"
                >Primary</span
              ></v-list-item-action-text
            >
          </v-list-item-action>

          <!--Duplicate Item Record-->
          <v-tooltip v-if="section === 14 || section === 4.5" left color="primary">
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click.stop="openEntry(item.id, '', '', '', 1)">
                <v-icon small color="primary">
                  fal fa-clone
                </v-icon>
              </v-btn>
            </template>
            <span v-if="section === 14">Duplicate Template</span>
            <span v-if="section === 4.5">Duplicate Tuition</span>
          </v-tooltip>

          <!--Process Import Record-->
          <v-tooltip v-if="section === 42 && item.f_status === 1" top color="primary">
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click.stop="processImport(item.id)">
                <v-icon small color="primary">
                  fal fa-play
                </v-icon>
              </v-btn>
            </template>
            <span>Process Import</span>
          </v-tooltip>

          <v-tooltip
            v-if="
              (section === 42 && item.f_status === 2 && item.client) ||
                (section === 42 && item.f_status === 4 && item.client) ||
                (section === 42 && item.f_status === 5 && item.client)
            "
            top
            color="primary"
          >
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click.stop="clientDetail(item.client.uuid)">
                <v-icon color="primary">
                  fal fa-arrow-alt-square-right
                </v-icon>
              </v-btn>
            </template>
            <span>Go To Client</span>
          </v-tooltip>
        </v-list-item>
      </template>
    </v-list>

    <v-list v-else class="py-2">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="error--text text-subtitle-1 text-xs-center">
            No {{ title }} Exist
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mixin } from "@/mixins/mixin.js";
import { mapState } from "vuex";
import TextDisplay from "@/components/TextDisplay";

export default {
  name: "ListBasic",
  mixins: [mixin],
  components: {
    TextDisplay
  },
  computed: {
    //BG 6/3/2021
    ...mapState({
      usersStore: state => state.usersStore,
      correspondenceTypes: state => state.valueListsStore.correspondenceTypes
    })
  },
  props: {
    show: Boolean,
    showid: Boolean,
    listData: Array,
    title: String,
    section: Number,
    clientID: Number
  },
  methods: {
    openEntry(id, status, fk_householdID, f_privateRecord, duplicateFlag) {
      /* Bookmarks on Dashboard */
      if (this.section === 1.1) {
      } else if (this.section === 5.2) {
        this.$emit("open-detail", { id, status });
      } else if (this.section === 24) {
        this.$emit("open-entry", { id, fk_householdID, f_privateRecord });
      } else if (duplicateFlag) {
        this.$emit("open-entry-duplicate", id);
      } else {
        this.$emit("open-entry", id);
      }
    },
    deleteRecord(id, message) {
      if (this.section === 17) {
        this.$emit("open-delete-dialog", { id, message });
      }
    },
    processImport(id) {
      this.$emit("process-import", id);
    },
    clientDetail(uuid) {
      this.$emit("client-detail", uuid);
    }
  }
};
</script>

<style>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
