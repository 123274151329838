<template>
  <div>
    <task-entry />

    <v-container fluid class="pt-0">
      <v-row dense>
        <!--Left Side-->
        <v-col cols="12" sm="12" md="9">
          <v-row dense>
            <!--Search-->
            <v-col cols="12" md="3">
              <v-card flat>
                <v-toolbar dense flat color="secondary" dark>
                  <v-icon>fal fa-search</v-icon>
                  <v-toolbar-title class="pl-2">Search</v-toolbar-title>
                </v-toolbar>
              </v-card>

              <v-card flat class="blue-grey lighten-5">
                <v-card-text>
                  <v-form @submit.prevent="search()">
                    <v-container fluid>
                      <v-row dense>
                        <!--Client ID-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.childID"
                            label="Client ID"
                          >
                          </v-text-field>
                        </v-col>

                        <!--PreK Seat ID-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.prekseatID"
                            label="PreK Seat ID"
                          >
                          </v-text-field>
                        </v-col>

                        <!--Household ID-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.householdID"
                            label="Household ID"
                          >
                          </v-text-field>
                        </v-col>

                        <!--Household Phone-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.householdPhone"
                            :blur="quickSearch.householdPhone = $_formatPhone(quickSearch.householdPhone)"
                            label="Household Phone"
                          >
                          </v-text-field>
                        </v-col>

                        <!--Household Email-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.householdEmail"
                            label="Household Email"
                          >
                          </v-text-field>
                        </v-col>

                        <!--Client Name-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.childName"
                            label="Client Name"
                          >
                          </v-text-field>
                        </v-col>

                        <!--Primary Applicant Name-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.parentName"
                            label="Primary Applicant Name"
                          >
                          </v-text-field>
                        </v-col>

                        <!--Provider License-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.providerLicenseID"
                            label="Provider License #"
                          >
                          </v-text-field>
                        </v-col>

                        <!--Provider Name-->
                        <v-col cols="12" sm="12" class="pa-2">
                          <v-text-field
                            hide-details
                            background-color="white"
                            placeholder=" "
                            persistent-placeholder
                            filled
                            v-model="quickSearch.providerName"
                            label="Provider Name"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12">
                          <v-btn rounded block color="primary" type="submit"
                            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>

            <!--Message Board-->
            <v-col cols="12" sm="12" md="9">
              <message-board-items :messageBoardSection="2" />
            </v-col>
          </v-row>

          <v-row dense>
            <!--Reports-->
            <v-col cols="12" sm="12" md="3">
              <reports :reportSection="2" />
            </v-col>

            <!--Bookmarks-->
            <v-col cols="12" sm="12" md="9">
              <bookmarks :bookmarkSection="2"></bookmarks>
            </v-col>
          </v-row>
        </v-col>

        <!--Right-->
        <v-col cols="12" sm="12" md="3">
          <v-row dense>
            <v-col cols="12" sm="12">
              <!--Tasks-->
              <tasks :taskSection="4" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import Tasks from "@/components/Tasks";
import TaskEntry from "@/components/TaskEntry";
import Reports from "@/components/Reports";
import Bookmarks from "@/components/Bookmarks";
import MessageBoardItems from "@/components/MessageBoardItems";

export default {
  name: "Dashboard",
  mixins: [mixin],
  components: {
    Tasks,
    TaskEntry,
    Reports,
    Bookmarks,
    MessageBoardItems
  },
  beforeCreate() {
    this.$store.dispatch("providersStore/clearProviders");
    this.$store.dispatch("clientsStore/clearClients");
    this.$store.dispatch("notesStore/clearNotes");
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      quickSearch: {
        childID: "",
        prekseatID: "",
        householdID: "",
        householdPhone: "",
        householdEmail: "",
        childName: "",
        parentName: "",
        providerLicenseID: "",
        providerName: ""
      }
    };
  },
  methods: {
    initialize() {
      this.quickSearch.childID = "";
      this.quickSearch.prekseatID = "";
      this.quickSearch.householdID = "";
      this.quickSearch.householdPhone = "";
      this.quickSearch.householdEmail = "";
      this.quickSearch.childName = "";
      this.quickSearch.parentName = "";
      this.quickSearch.providerLicenseID = "";
      this.quickSearch.providerName = "";
    },

    async search() {
      /* Client Search */
      if (
        this.quickSearch.childID ||
        this.quickSearch.prekseatID ||
        this.quickSearch.householdID ||
        this.quickSearch.householdPhone ||
        this.quickSearch.householdEmail ||
        this.quickSearch.childName ||
        this.quickSearch.parentName
      ) {
        const user = this.$store.getters["usersStore/getAuthUser"];

        const data = {
          page: 1,
          section: 1,

          id: this.quickSearch.childID,
          fk_userID: "",
          d_prekSeatID: this.quickSearch.prekseatID,
          fk_providerID: "",
          d_childIDv1: "",
          fk_householdID: this.quickSearch.householdID,
          d_householdPhone: this.quickSearch.householdPhone,
          d_householdEmail: this.quickSearch.householdEmail,
          d_applicantIDv1: "",
          d_nameLast: "",
          d_nameFirst: "",
          d_nameDisplay: this.quickSearch.childName,
          fk_counselorID: "",
          f_status: "",
          primaryApplicant: this.quickSearch.parentName,
          f_viewPrivateRecords: user.f_viewPrivateRecords
        };

        await this.$store.dispatch("clientsStore/fetchSearch", data);

        const clients = this.$store.getters["clientsStore/getClientsSearch"];

        if (clients.data.length > 0) {
          this.$store.dispatch("clientsStore/storeFilters", data);
        }

        if (clients.data.length === 0) {
          const message = {
            color: "error",
            text: "No clients match the search criteria"
          };
          this.$store.commit("messagesStore/setMessage", message);
        } else if (clients.data.length > 1) {
          this.$router.push({ name: "clients" });
        } else {
          this.$router.push({ name: "client", params: { clientUUID: clients.data[0].uuid } });
        }
      } else if (this.quickSearch.providerName || this.quickSearch.providerLicenseID) {
      /* Provider Search */
        const data = {
          page: 1,
          section: 1,

          fk_userID: "",
          d_licenseNumber: this.quickSearch.providerLicenseID,
          d_nameLicense: this.quickSearch.providerName,
          fk_countyID: "",
          f_type: "",
          fk_ratingID: "",
          f_status: ""
        };

        await this.$store.dispatch("providersStore/fetchSearch", data);

        const providers = this.$store.getters["providersStore/getProvidersSearch"];

        if (providers.data.length > 0) {
          this.$store.dispatch("providersStore/storeFilters", data);
        }

        if (providers.data.length === 0) {
          const message = {
            color: "error",
            text: "No providers match the search criteria"
          };
          this.$store.commit("messagesStore/setMessage", message);
        } else if (providers.data.length > 1) {
          this.$router.push({ name: "providers" });
        } else {
          this.$router.push({ name: "provider", params: { providerUUID: providers.data[0].uuid } });
        }
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
