<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="teachersStore.teacher.d_nameLast + ', ' + teachersStore.teacher.d_nameFirst" />

      <!--Function Bar-->
      <teacher-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>
          <!----------Classroom---------->
          <v-col cols="12" sm="12" md="2">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-bus-school</v-icon>
                <v-toolbar-title class="pl-2">Classroom</v-toolbar-title>
                <v-spacer />
                <v-tooltip top color="primary">
                  <template #activator="{ on }">
                  <v-btn icon v-on="on" @click="goToProvider(teachersStore.teacher.classroom_history && teachersStore.teacher.classroom_history[0].classroom.provider
                                                            ? teachersStore.teacher.classroom_history[0].classroom.provider.uuid : '')">
                    <v-icon
                    color="white"
                    >
                    fal fa-arrow-alt-square-right
                    </v-icon>
                  </v-btn>
                  </template>
                  <span>Go To Provider</span>
                </v-tooltip>
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="94%" v-if="teachersStore.teacher.classroom_history && teachersStore.teacher.classroom_history[0]">
              <v-card-text>
                <v-row dense>
                  <!--Classroom Provider License Number-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Provider License #'" :displayString="teachersStore.teacher.classroom_history[0].classroom.provider.d_licenseNumber" />
                  </v-col>
                  <!--Classroom Provider Name-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Provider Name'" :displayString="teachersStore.teacher.classroom_history[0].classroom.provider.d_nameLicense" />
                  </v-col>
                  <!--Classroom Name-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Class'" :displayString="teachersStore.teacher.classroom_history[0].classroom.d_name" />
                  </v-col>
                  <!--Classroom Type-->
                  <v-col cols="12" sm="12" v-for="(classroomType, index) in valueListsStore.valueListItems[1]"
                        :key="index">
                    <text-display v-if="teachersStore.teacher.classroom_history[0].classroom.f_type == classroomType.id"
                      :displayLabel="'Type'"
                      :displayString="classroomType.d_name" />
                  </v-col>
                  <!--Classroom Date Start-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Start Date'" :displayString="$_formatDate(teachersStore.teacher.classroom_history[0].d_dateStart)" />
                  </v-col>
                  <!--Classroom Date End-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'End Date'" :displayString="$_formatDate(teachersStore.teacher.classroom_history[0].d_dateEnd)" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card flat v-else>
              <v-card-text>
                <span class="error--text text-subtitle-1 text-xs-center">No Classrooms Exists</span>
              </v-card-text>
            </v-card>
          </v-col>

          <!----------Teacher Details---------->
          <v-col cols="12" sm="12" md="7">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-chalkboard-teacher</v-icon>
                <v-toolbar-title class="pl-2">Details</v-toolbar-title>
                <v-spacer />

                <teacher-entry :teacher-section="2"/>

              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text>

                <div v-if="teachersStore.teacher.actions && teachersStore.teacher.actions.length > 0">
                  <v-alert
                    :key="index" v-for="(item, index) in teachersStore.teacher.actions"
                    :value="true"
                    type="error"
                    icon="fal fa-exclamation-triangle"
                  >
                    {{item.type.d_name}}
                  </v-alert>
                </div>

                <v-row dense>
                  <v-col cols="12" sm="6" md="2">
                    <text-display :displayLabel="'Teacher ID'" :displayNumber="teachersStore.teacher.id"  />
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Teacher Status'" :displayString="$_activeStatus(teachersStore.teacher.f_status)" />
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'BK License'" :displayString="$_yesNo(teachersStore.teacher.f_bk)" />
                  </v-col>

                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Substitute'" :displayString="$_yesNo(teachersStore.teacher.f_substitute)" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <text-display :displayLabel="'Name'" :displayString="$_fullName(teachersStore.teacher.d_nameFirst, teachersStore.teacher.d_nameMiddle,teachersStore.teacher.d_nameLast)" />
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Position'" :displayString="teachersStore.teacher.position.d_name" />
                  </v-col>

                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Actual Start'" :displayString="$_formatDate(teachersStore.teacher.d_dateStartTenure)" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <text-display v-if="Array.isArray(teachersStore.teacher.d_race)" :displayLabel="'Race'" :displayArray="teachersStore.teacher.d_race" />
                    <text-display v-else :displayLabel="'Race'" :displayString="teachersStore.teacher.d_race" />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <text-display :displayLabel="'Race (Other)'" :displayString="teachersStore.teacher.d_raceOther" />
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <text-display :displayLabel="'Gender'" :displayString="teachersStore.teacher.d_genderOther ?
                                    teachersStore.teacher.d_genderOther : teachersStore.teacher.d_gender"  />
                  </v-col>
                </v-row>

                <v-divider class="py-1"></v-divider>
                <v-row dense>
                  <v-col cols="12" sm="6" md="12">
                    <text-display v-if="Array.isArray(teachersStore.teacher.j_language)" :displayLabel="'Language(s)'" :displayArray="teachersStore.teacher.j_language" />
                    <text-display v-else :displayLabel="'Language(s)'" :displayString="teachersStore.teacher.j_language" />
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <text-display :displayLabel="'Language(s) (Other)'" :displayString="teachersStore.teacher.d_languageOther" />
                  </v-col>
                </v-row>

                <v-divider class="py-1"></v-divider>

                <!--Address-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Location Address'" :displayString="teachersStore.teacher.d_streetAddress" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <text-display :displayLabel="'City'" :displayString="teachersStore.teacher.d_city" />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <text-display :displayLabel="'State'" :displayString="teachersStore.teacher.d_state" />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <text-display :displayLabel="'Zip'" :displayString="teachersStore.teacher.d_zipCode" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider class="py-1"></v-divider>

                <!--Contact-->
                <v-row dense>
                  <v-col cols="12" sm="12"  md="3">
                    <v-row dense v-for="(contact, index1) in teachersStore.teacher.j_contact"
                          v-bind:key="index1">
                      <v-col cols="12" sm="12" v-if="(contact.d_type && !contact.d_type.includes('mail'))">
                        <text-display :displayLabel="contact.d_type" :displayString="contact.d_value" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="12"  md="6">
                    <v-row dense v-for="(contact, index2) in teachersStore.teacher.j_contact"
                          v-bind:key="index2">
                      <v-col cols="12" sm="12" v-if="(contact.d_type && contact.d_type.includes('mail'))">
                        <text-display :displayLabel="contact.d_type" :displayString="contact.d_value" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>

            <!--License-->
            <v-card flat>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-card flat>
                    <v-toolbar dense flat color="secondary" dark>
                      <v-icon>fal fa-id-card</v-icon>
                      <v-toolbar-title class="pl-2">Active License</v-toolbar-title>
                    </v-toolbar>
                  </v-card>
                  <v-card flat class="blue-grey lighten-5">
                    <v-card-text v-if="teachersStore.teacher.licenses && teachersStore.teacher.licenses.length">
                      <div v-for="(license, index) in teachersStore.teacher.licenses"
                          :key="index">
                        <v-divider v-if="index > 0 && license.f_status === 1" class="py-1"/>
                        <v-row dense v-if="license.f_status === 1">
                          <v-col cols="12" sm="12" md="3">
                            <text-display :displayLabel="'License Number'" :displayString="license.d_name" />
                          </v-col>
                          <v-col cols="12" sm="12" md="2">
                            <text-display :displayLabel="'License Type'" :displayString="license.type.d_name" />
                          </v-col>
                          <v-col align="center" cols="12" sm="12" md="2">
                            <text-display :displayLabel="'Yrs. Experience'" :displayNumber="license.d_yearsExperience" />
                          </v-col>
                          <v-col align="center" cols="12" sm="12" md="3">
                            <text-display :displayLabel="'Issued'" :displayString="$_formatDate(license.d_issueDate)" />
                          </v-col>
                          <v-col align="center" cols="12" sm="12" md="2">
                            <text-display :displayLabel="'Expiration'" :displayString="$_formatDate(license.d_expirationDate)" />
                          </v-col>
                          <!--<v-col v-if="$_dateExpired(license.d_expirationDate)"
                            align="center" cols="12" sm="12" md="1">
                            <v-icon color="error">fal fa-xmark</v-icon>
                          </v-col>
                          <v-col v-else align="center" cols="12" sm="12" md="1">
                            <v-icon color="primary">fal fa-check</v-icon>
                          </v-col>-->
                        </v-row>
                      </div>
                    </v-card-text>
                    <v-card-text v-else>
                      <v-row dense>
                        <v-col align="center" cols="12" sm="12" md="12">
                          <span class="error--text text-subtitle-1 text-xs-center">No Active Licenses Exist</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
               </v-col>
              </v-row>
            </v-card>

            <!--Education-->
            <v-card flat>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-card flat>
                    <v-toolbar dense flat color="secondary" dark>
                      <v-icon>fal fa-graduation-cap</v-icon>
                      <v-toolbar-title class="pl-2">Education</v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                  </v-card>
                  <v-card flat class="blue-grey lighten-5">
                    <v-card-text v-if="teachersStore.teacher.active_education && teachersStore.teacher.active_education.length">
                      <div v-for="(education, index) in teachersStore.teacher.active_education"
                        :key="index">
                        <v-row dense>
                          <v-col cols="12" sm="12" md="3">
                            <text-display :displayLabel="'Institution'" :displayString="education.d_schoolName" />
                          </v-col>
                          <v-col cols="12" sm="12" md="2">
                            <text-display :displayLabel="'Degree'" :displayString="education.d_type" />
                          </v-col>
                          <v-col align="center" cols="12" sm="12" md="3">
                            <text-display :displayLabel="'Program of Study'" :displayString="education.d_degreeSought" />
                          </v-col>
                          <v-col align="center" cols="12" sm="12" md="2">
                            <text-display :displayLabel="'Enrollment'" :displayString="education.d_enrolled" />
                          </v-col>
                          <v-col align="center" cols="12" sm="12" md="2">
                            <!--<text-display v-if="$_dateExpired(education.d_expectedCompletion)"
                              :displayLabel="'Completion'"
                              :displayString="$_formatDate(education.d_expectedCompletion)" />
                            <text-display v-else
                              :displayLabel="'Pending Completion'"
                              :displayString="$_formatDate(education.d_expectedCompletion)" /> -->
                            <text-display
                              :displayLabel="'Completion Status'"
                              :displayString ="education.f_completion ? 'Completed' : 'Pending'"/>
                          </v-col>
                        </v-row>
                        <v-divider v-if="index < teachersStore.teacher.active_education.length - 1" class="py-1">
                        </v-divider>
                      </div>
                    </v-card-text>
                    <v-card-text v-else>
                      <v-row dense>
                        <v-col align="center" cols="12" sm="12" md="12">
                          <span class="error--text text-subtitle-1 text-xs-center">No Education Records Exist</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!--Notes-->
          <v-col cols="12" sm="12" md="3">
            <notes :key="teachersStore.teacher.id" :noteSection="6" />
          </v-col>
        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TeacherFunctionBar from '@/components/TeacherFunctionBar';
import Notes from '@/components/Notes';
import TextDisplay from '@/components/TextDisplay';
import TeacherEntry from '@/components/TeacherEntry';
import Education from '@/components/Education';
import EducationEntry from '@/components/EducationEntry';
import TeacherLicenseEntry from '@/components/TeacherLicenseEntry';

export default {
  name: 'Teacher',
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      teachersStore: (state) => state.teachersStore,
      teacherLicenseStore: (state) => state.teacherLicenseStore,
      educationStore: (state) => state.educationStore,
      progressStore: (state) => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
  },
  components: {
    TitleBar,
	  TeacherFunctionBar,
    Notes,
    TextDisplay,
    TeacherEntry,
    Education,
    EducationEntry,
    TeacherLicenseEntry
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('teachersStore/read', this.$route.params.teacherUUID);
      /* Refresh bookmark */
      const user = this.$store.getters['usersStore/getAuthUser'];
      const teacher = this.$store.getters['teachersStore/getTeacher'];
      const data = {
        fk_teacherID: teacher.id,
        fk_userID: user.id,
      };
      //await this.$store.dispatch('teacherLicenseStore/fetch', data);
      //await this.$store.dispatch('educationStore/fetch', data);
      await this.$store.dispatch('bookmarksStore/detail', data);

      /* Pull valueListsStore items for Entry forms */
      const data2 = {
          id: [1, 6, 12, 15, 18, 21, 30, 31, 32, 33]
        };
      await this.$store.dispatch("valueListsStore/items", data2);
      this.$store.dispatch('progressStore/set', false);
    },

    goToProvider(uuid) {
      const routeData = this.$router.resolve({ name: 'provider', params: { providerUUID: uuid } });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
