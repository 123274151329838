import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  applications: [],
  application: null,
  entry: {
    show: false,
    valid1: true,
    valid2: true,
    valid3: true,
    valid4: true,
    valid5: true,
    valid6: true,
    section: '',
    mode: '',
    id: '',
    fk_clientID: '',
    fk_countyID_Residence: '',
    fk_countyID_Mailing: '',
    d_wlsID: '',
    d_nameFirst: '',
    d_nameMiddle: '',
    d_nameLast: '',
    d_dateBirth: '',
    dateBirth: '',
    f_privateRecord: 0,
    d_primaryRelationship: '',
    j_primaryRace: [],
    d_primaryRaceOther: '',
    j_employmentPrimary: [{
      d_employer: '',
      d_position: '',
      d_employerCity: '',
      d_income: '',
      d_incomePeriod: '',
      d_hoursPerWeek: '',
    }],
    j_schoolPrimary: [{
      d_schoolName: '',
      d_degreeSought: '',
      d_expectedCompletion: '',
      d_enrolled: '',
      d_type: '',
      d_financialAidAmount: '',
      d_financialAidPeriod: '',
      d_tuitionAmount: '',
      d_tuitionAmountPeriod: '',
    }],
    j_otherIncomePrimary: [{
      d_type: '',
      d_amount: '',
      d_period: '',
    }],
    d_proofEmployment: '',
    d_proofSchool: '',
    d_proofOtherIncome: '',
    j_documents: [{
      d_type: '',
      d_path: '',
    }],
    j_contactDetails: [{
      d_owner: '',
      d_type: '',
      d_value: '',
    }],
    d_nameFirstOtherParent: '',
    d_nameMiddleOtherParent: '',
    d_nameLastOtherParent: '',
    d_dateBirthOtherParent: '',
    dateBirthOtherParent: '',
    d_otherParentRelationship: '',
    j_otherParentRace: [],
    d_otherParentRaceOther: '',
    j_employmentOtherParent: [{
      d_employer: '',
      d_position: '',
      d_employerCity: '',
      d_income: '',
      d_incomePeriod: '',
      d_hoursPerWeek: '',
    }],
    j_schoolOtherParent: [{
      d_schoolName: '',
      d_degreeSought: '',
      d_expectedCompletion: '',
      d_enrolled: '',
      d_type: '',
      d_financialAidAmount: '',
      d_financialAidPeriod: '',
      d_tuitionAmount: '',
      d_tuitionAmountPeriod: '',
    }],
    j_otherIncomeOtherParent: [{
      d_type: '',
      d_amount: '',
      d_period: '',
    }],
    d_residenceStreet1: '',
    d_residenceStreet2: '',
    d_residenceCity: '',
    d_residenceState: '',
    d_residencePostalCode: '',
    d_mailingStreet1: '',
    d_mailingStreet2: '',
    d_mailingCity: '',
    d_mailingState: '',
    d_mailingPostalCode: '',
    j_householdDependants: [{
      d_name: '',
      d_relationship: '',
      d_parent: '',
      d_dateBirth: '',
    }],
    d_familyStructure: '',
    d_familyStructureOther: '',
    d_familySize: '',
    j_applyingChild: [{
      d_nameFirst: '',
      d_nameMiddle: '',
      d_nameLast: '',
      d_dateBirth: '',
      d_gender: '',
      d_weeklyHoursCare: [],
      d_race: [],
      d_raceOther: '',
      d_verifiedDateOfBirth: '',
      d_additionalInfoHealthInsurance: '',
      d_additionalInfoUSCitizen: '',
      d_additionalInfoSpecialNeed: '',
      d_additionalInfoSpecialNeedDescribe: '',
      d_additionalInfoReceivingServices: '',
      d_dateServicePlanEnd: '',
      d_shareFinancial: '',
      d_shareFinancialName: '',
      d_shareFinancialRelationship: '',
      d_shareFinancialDescribe: '',
      akf_facilityID: '',
      d_facilityName: '',
      d_facilityDirector: '',
      d_facilityPhone: '',
      d_facilityChildEnrolled: '',
      d_dateFacilityChildEnrolled: '',
    }],
    d_date: '',
    date1: '',
    d_agreementUpdatePrimary: '',
    d_agreementUpdateOtherParent: '',
    d_participateAgreement1: '',
    d_participateAgreement2: '',
    d_participateAgreement3: '',
    d_participateAgreement4: '',
    d_participateAgreement5: '',
    d_participateAgreement6: '',
    d_participateAgreement7: '',
    d_participateAgreement8: '',
    d_participateAgreement9: '',
    d_signaturePrimary: '',
    d_signaturePrimaryDate: '',
    signaturePrimaryDate: '',
    d_signatureOtherParent: '',
    d_signatureOtherParentDate: '',
    signatureOtherParentDate: '',
    d_note: '',
    d_mailingAddressSame: '',
    f_clientCreated: '',
    f_status: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_householdID: '',
    d_nameFirst: '',
    d_nameLast: '',
    applyingChildFirst: '',
    applyingChildLast: '',
    d_wlsID: '',
    f_status: '',
    f_viewPrivateRecords: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_APPLICATIONS(state) {
    state.applications = [];
  },
  FETCH(state, { list }) {
    state.applications = list;
  },
  CREATE(state, { data }) {
    state.applications.data.unshift(data);
    state.entry.id = data.id;
  },
  READ(state, { data }) {
    state.application = data;
  },
  UPDATE(state, { data }) {
    const idx = state.applications.data.map((item) => item.id).indexOf(data.id);
    state.applications.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.applications.data.map((item) => item.id).indexOf(id);
    state.applications.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid1 = true;
    state.entry.valid2 = true;
    state.entry.valid3 = true;
    state.entry.valid4 = true;
    state.entry.valid5 = true;
    state.entry.valid6 = true;
    state.entry.id = data.id;
    state.entry.fk_countyID_Residence = data.fk_countyID_Residence;
    state.entry.fk_countyID_Mailing = data.fk_countyID_Mailing;
    state.entry.d_wlsID = data.d_wlsID;
    state.entry.d_nameFirst = data.d_nameFirst;
    state.entry.d_nameMiddle = data.d_nameMiddle;
    state.entry.d_nameLast = data.d_nameLast;
    state.entry.dateBirth = data.d_dateBirth;
    state.entry.f_privateRecord = data.f_privateRecord;
    state.entry.d_primaryRelationship = data.d_primaryRelationship;
    state.entry.j_primaryRace = data.j_primaryRace;
    state.entry.d_primaryRaceOther = data.d_primaryRaceOther;
    state.entry.j_employmentPrimary = data.j_employmentPrimary;
    state.entry.j_schoolPrimary = data.j_schoolPrimary;
    state.entry.j_otherIncomePrimary = data.j_otherIncomePrimary;
    state.entry.j_documents = data.j_documents;
    state.entry.d_proofSchool = data.d_proofSchool;
    state.entry.d_proofEmployment = data.d_proofEmployment;
    state.entry.d_proofOtherIncome = data.d_proofOtherIncome;
    state.entry.j_contactDetails = data.j_contactDetails;
    state.entry.d_nameFirstOtherParent = data.d_nameFirstOtherParent;
    state.entry.d_nameMiddleOtherParent = data.d_nameMiddleOtherParent;
    state.entry.d_nameLastOtherParent = data.d_nameLastOtherParent;
    state.entry.dateBirthOtherParent = data.d_dateBirthOtherParent;
    state.entry.d_otherParentRelationship = data.d_otherParentRelationship;
    state.entry.j_employmentOtherParent = data.j_employmentOtherParent;
    state.entry.j_schoolOtherParent = data.j_schoolOtherParent;
    state.entry.j_otherIncomeOtherParent = data.j_otherIncomeOtherParent;
    state.entry.j_otherParentRace = data.j_otherParentRace;
    state.entry.d_otherParentRaceOther = data.d_otherParentRaceOther;
    state.entry.d_residenceStreet1 = data.d_residenceStreet1;
    state.entry.d_residenceStreet2 = data.d_residenceStreet2;
    state.entry.d_residenceCity = data.d_residenceCity;
    state.entry.d_residenceState = data.d_residenceState;
    state.entry.d_residencePostalCode = data.d_residencePostalCode;
    state.entry.d_mailingStreet1 = data.d_mailingStreet1;
    state.entry.d_mailingStreet2 = data.d_mailingStreet2;
    state.entry.d_mailingCity = data.d_mailingCity;
    state.entry.d_mailingState = data.d_mailingState;
    state.entry.d_mailingPostalCode = data.d_mailingPostalCode;
    state.entry.j_householdDependants = data.j_householdDependants;
    state.entry.d_familyStructure = data.d_familyStructure;
    state.entry.d_familyStructureOther = data.d_familyStructureOther;
    state.entry.d_familySize = data.d_familySize;
    state.entry.j_applyingChild = data.j_applyingChild;
    state.entry.date1 = data.d_date;
    state.entry.d_agreementUpdatePrimary = data.d_agreementUpdatePrimary;
    state.entry.d_agreementUpdateOtherParent = data.d_agreementUpdateOtherParent;
    state.entry.d_participateAgreement1 = data.d_participateAgreement1;
    state.entry.d_participateAgreement2 = data.d_participateAgreement2;
    state.entry.d_participateAgreement3 = data.d_participateAgreement3;
    state.entry.d_participateAgreement4 = data.d_participateAgreement4;
    state.entry.d_participateAgreement5 = data.d_participateAgreement5;
    state.entry.d_participateAgreement6 = data.d_participateAgreement6;
    state.entry.d_participateAgreement7 = data.d_participateAgreement7;
    state.entry.d_participateAgreement8 = data.d_participateAgreement8;
    state.entry.d_participateAgreement9 = data.d_participateAgreement9;
    state.entry.d_signaturePrimary = data.d_signaturePrimary;
    state.entry.signaturePrimaryDate = data.d_signaturePrimaryDate;
    state.entry.d_signatureOtherParent = data.d_signatureOtherParent;
    state.entry.signatureOtherParentDate = data.d_signatureOtherParentDate;
    state.entry.d_note = data.d_note;
    state.entry.d_mailingAddressSame = data.d_mailingAddressSame;
    state.entry.f_status = data.f_status;
    state.entry.f_clientCreated = data.f_clientCreated;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid1 = true;
    state.entry.valid2 = true;
    state.entry.valid3 = true;
    state.entry.valid4 = true;
    state.entry.valid5 = true;
    state.entry.valid6 = true;
    state.entry.id = '';
    state.entry.fk_countyID_Residence = '';
    state.entry.fk_countyID_Mailing = '';
    state.entry.d_wlsID = '';
    state.entry.d_nameFirst = '';
    state.entry.d_nameMiddle = '';
    state.entry.d_nameLast = '';
    state.entry.d_dateBirth = '';
    state.entry.dateBirth = '';
    state.entry.f_privateRecord = 0;
    state.entry.d_primaryRelationship = '';
    state.entry.j_primaryRace = [];
    state.entry.d_primaryRaceOther = '';
    state.entry.j_employmentPrimary = [{
      d_employer: '',
      d_position: '',
      d_employerCity: '',
      d_income: '',
      d_incomePeriod: '',
      d_hoursPerWeek: '',
    }];
    state.entry.j_schoolPrimary = [{
      d_schoolName: '',
      d_degreeSought: '',
      d_expectedCompletion: '',
      d_enrolled: '',
      d_type: '',
      d_financialAidAmount: '',
      d_financialAidPeriod: '',
      d_tuitionAmount: '',
      d_tuitionAmountPeriod: '',
    }];
    state.entry.j_otherIncomePrimary = [];
    state.entry.j_contactDetails = [{
      d_owner: '',
      d_type: '',
      d_value: '',
    }];
    state.entry.d_proofEmployment = '';
    state.entry.d_proofSchool = '';
    state.entry.d_proofOtherIncome = '';
    state.entry.j_documents = [];
    state.entry.d_nameFirstOtherParent = '';
    state.entry.d_nameMiddleOtherParent = '';
    state.entry.d_nameLastOtherParent = '';
    state.entry.d_dateBirthOtherParent = '';
    state.entry.dateBirthOtherParent = '';
    state.entry.d_otherParentRelationship = '';
    state.entry.j_otherParentRace = [];
    state.entry.d_otherParentRaceOther = '';
    state.entry.j_employmentOtherParent = [{
      d_employer: '',
      d_position: '',
      d_employerCity: '',
      d_income: '',
      d_incomePeriod: '',
      d_hoursPerWeek: '',
    }];
    state.entry.j_schoolOtherParent = [{
      d_schoolName: '',
      d_degreeSought: '',
      d_expectedCompletion: '',
      d_enrolled: '',
      d_type: '',
      d_financialAidAmount: '',
      d_financialAidPeriod: '',
      d_tuitionAmount: '',
      d_tuitionAmountPeriod: '',
    }];
    state.entry.j_otherIncomeOtherParent = [];
    state.entry.d_residenceStreet1 = '';
    state.entry.d_residenceStreet2 = '';
    state.entry.d_residenceCity = '';
    state.entry.d_residenceState = '';
    state.entry.d_residencePostalCode = '';
    state.entry.d_mailingStreet1 = '';
    state.entry.d_mailingStreet2 = '';
    state.entry.d_mailingCity = '';
    state.entry.d_mailingState = '';
    state.entry.d_mailingPostalCode = '';
    state.entry.j_householdDependants = [];
    state.entry.j_applyingChild = [{
      d_nameFirst: '',
      d_nameMiddle: '',
      d_nameLast: '',
      d_dateBirth: '',
      d_gender: '',
      d_weeklyHoursCare: [],
      d_race: [],
      d_raceOther: '',
      d_verifiedDateOfBirth: '',
      d_additionalInfoHealthInsurance: '',
      d_additionalInfoUSCitizen: '',
      d_additionalInfoSpecialNeed: '',
      d_additionalInfoSpecialNeedDescribe: '',
      d_additionalInfoReceivingServices: '',
      d_dateServicePlanEnd: '',
      d_shareFinancial: '',
      d_shareFinancialName: '',
      d_shareFinancialRelationship: '',
      d_shareFinancialDescribe: '',
      d_facilityName: '',
      d_facilityChildEnrolled: '',
      d_facilityDirector: '',
      d_facilityPhone: '',
      d_dateFacilityChildEnrolled: '',
    }];
    state.entry.d_familyStructure = '';
    state.entry.d_familyStructureOther = '';
    state.entry.d_familySize = '';
    state.entry.d_date = '';
    state.entry.date1 = '';
    state.entry.d_agreementUpdatePrimary = '';
    state.entry.d_agreementUpdateOtherParent = '';
    state.entry.d_participateAgreement1 = '';
    state.entry.d_participateAgreement2 = '';
    state.entry.d_participateAgreement3 = '';
    state.entry.d_participateAgreement4 = '';
    state.entry.d_participateAgreement5 = '';
    state.entry.d_participateAgreement6 = '';
    state.entry.d_participateAgreement7 = '';
    state.entry.d_participateAgreement8 = '';
    state.entry.d_participateAgreement9 = '';
    state.entry.d_signaturePrimary = '';
    state.entry.d_signaturePrimaryDate = '';
    state.entry.signaturePrimaryDate = '';
    state.entry.d_signatureOtherParent = '';
    state.entry.d_signatureOtherParentDate = '';
    state.entry.signatureOtherParentDate = '';
    state.entry.d_note = '';
    state.entry.d_mailingAddressSame = '';
    state.entry.f_status = 1;
    state.entry.f_clientCreated = '';
  },
  ADD_EMPLOYMENT(state, { data }) {
    /* Primary */
    if (data.mode === 1) {
      state.entry.j_employmentPrimary.push(data);
    }
    /* Other Parent */
    else {
      state.entry.j_employmentOtherParent.push(data);
    }
  },
  REMOVE_EMPLOYMENT(state, { data }) {
    /* Primary */
    if (data.mode === 1) {
      state.entry.j_employmentPrimary.splice(data.id, 1);
    }
    /* Other Parent */
    else {
      state.entry.j_employmentOtherParent.splice(data.id, 1);
    }
  },
  ADD_SCHOOL(state, { data }) {
    /* Primary */
    if (data.mode === 1) {
      state.entry.j_schoolPrimary.push(data);
    }
    /* Other Parent */
    else {
      state.entry.j_schoolOtherParent.push(data);
    }
  },
  REMOVE_SCHOOL(state, { data }) {
    /* Primary */
    if (data.mode === 1) {
      state.entry.j_schoolPrimary.splice(data.id, 1);
    }
    /* Other Parent */
    else {
      state.entry.j_schoolOtherParent.splice(data.id, 1);
    }
  },
  ADD_OTHER_INCOME(state, { data }) {
    /* Primary */
    if (data.mode === 1) {
      state.entry.j_otherIncomePrimary.push(data);
    }
    /* Other Parent */
    else {
      state.entry.j_otherIncomeOtherParent.push(data);
    }
  },
  REMOVE_OTHER_INCOME(state, { data }) {
    /* Primary */
    if (data.mode === 1) {
      state.entry.j_otherIncomePrimary.splice(data.id, 1);
    }
    /* Other Parent */
    else {
      state.entry.j_otherIncomeOtherParent.splice(data.id, 1);
    }
  },
  ADD_CONTACT(state, { data }) {
    state.entry.j_contactDetails.push(data);
  },
  REMOVE_CONTACT(state, { data }) {
    state.entry.j_contactDetails.splice(data.id, 1);
  },
  ADD_DEPENDANT(state, { data }) {
    state.entry.j_householdDependants.push(data);
  },
  REMOVE_DEPENDANT(state, { data }) {
    state.entry.j_householdDependants.splice(data.id, 1);
  },
  ADD_CHILD(state, { data }) {
    state.entry.j_applyingChild.push(data);
  },
  REMOVE_CHILD(state, { data }) {
    state.entry.j_applyingChild.splice(data.id, 1);
  },
  ADD_DOCUMENT(state, { data }) {
    state.entry.j_documents.push(data);
  },
  REMOVE_DOCUMENT(state, { data }) {
    state.entry.j_documents.splice(data.id, 1);
  },
  SET_MAILING_ADDRESS(state, { value }) {
    if (value === 'Yes') {
      state.entry.d_mailingStreet1 = state.entry.d_residenceStreet1;
      state.entry.d_mailingStreet2 = state.entry.d_residenceStreet2;
      state.entry.d_mailingCity = state.entry.d_residenceCity;
      state.entry.d_mailingState = state.entry.d_residenceState;
      state.entry.d_mailingPostalCode = state.entry.d_residencePostalCode;
      state.entry.fk_countyID_Mailing = state.entry.fk_countyID_Residence;
    } else {
      state.entry.d_mailingStreet1 = '';
      state.entry.d_mailingStreet2 = '';
      state.entry.d_mailingCity = '';
      state.entry.d_mailingState = '';
      state.entry.d_mailingPostalCode = '';
      state.entry.fk_countyID_Mailing = '';
    }
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore({ commit }) {
    commit('CLEAR_STORE');
  },
  clearApplications: ({ commit }) => {
    commit('CLEAR_APPLICATIONS');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/applications', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/applications/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {
      return 'ERROR';
    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/applications/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/applications/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {
      return 'ERROR';
    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/applications/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
      return response;
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/applications/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addEmployment({ commit }, data) {
    commit('ADD_EMPLOYMENT', { data });
  },
  removeEmployment({ commit }, data) {
    commit('REMOVE_EMPLOYMENT', { data });
  },
  addSchool({ commit }, data) {
    commit('ADD_SCHOOL', { data });
  },
  removeSchool({ commit }, data) {
    commit('REMOVE_SCHOOL', { data });
  },
  addOtherIncome({ commit }, data) {
    commit('ADD_OTHER_INCOME', { data });
  },
  removeOtherIncome({ commit }, data) {
    commit('REMOVE_OTHER_INCOME', { data });
  },
  addContact({ commit }, data) {
    commit('ADD_CONTACT', { data });
  },
  removeContact({ commit }, data) {
    commit('REMOVE_CONTACT', { data });
  },
  addDocument({ commit }, data) {
    commit('ADD_DOCUMENT', { data });
  },
  removeDocument({ commit }, data) {
    commit('REMOVE_DOCUMENT', { data });
  },
  addDependant({ commit }, data) {
    commit('ADD_DEPENDANT', { data });
  },
  removeDependant({ commit }, data) {
    commit('REMOVE_DEPENDANT', { data });
  },
  addChild({ commit }, data) {
    commit('ADD_CHILD', { data });
  },
  removeChild({ commit }, data) {
    commit('REMOVE_CHILD', { data });
  },
  setMailingAddress({ commit }, value) {
    commit('SET_MAILING_ADDRESS', { value });
  },
};

const getters = {
  getEntry: (state, getters) => state.entry,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
