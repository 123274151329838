<template>
  <div>
    <!-- New Provider Button -->
    <v-tooltip v-if="providerSection === 1" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="openEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Unlicensed Provider</span>
    </v-tooltip>

    <v-btn v-if="providerSection === 2" rounded outlined @click="openEntry()"
      ><v-icon small left>fal fa-pencil-alt</v-icon>Edit</v-btn
    >

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1000">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Provider Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>General</v-tab>
                <v-tab>Address Info</v-tab>
                <v-tab v-if="providerSection === 2">Contact Info</v-tab>
                <v-tab v-if="providerSection === 2">Referral</v-tab>

                <!--General-->
                <v-tab-item>
                  <v-card flat height="750">
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row v-if="providerSection !== 1" dense>
                          <!--License Number-->
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_licenseNumber"
                              label="License Number"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Facility License Name-->
                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="d_nameLicense"
                              label="Facility License Name"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.facilityLicense"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Business Name-->
                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="d_nameFederal"
                              label="Business Name"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.businessName"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Counties-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="countiesStore.countiesValueList"
                              item-text="d_name"
                              item-value="id"
                              label="County"
                              placeholder=" "
                              persistent-placeholder
                              v-model="fk_countyID"
                              key="id"
                              clearable
                              clear-icon="fal fa-times-circle"
                              :rules="rules.county"
                            />
                          </v-col>

                          <!--Operation Type-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.facilityTypes"
                              item-text="value"
                              item-value="id"
                              label="Operation Type"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_type"
                              clearable
                              clear-icon="fal fa-times-circle"
                              :disabled="providerSection === 1"
                              :rules="rules.operationType"
                            />
                          </v-col>

                          <!--Rating-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="ratingsStore.ratingsValueList"
                              item-text="d_name"
                              item-value="id"
                              label="Rating"
                              placeholder=" "
                              persistent-placeholder
                              v-model="fk_ratingID"
                              clearable
                              clear-icon="fal fa-times-circle"
                              :disabled="providerSection === 1"
                              :rules="rules.rating"
                            />
                          </v-col>
                        </v-row>

                        <v-row v-if="providerSection !== 1" dense>
                          <!--Contracted Status-->
                          <v-col cols="12" sm="12" md="4">
                            <span class="grey--text text--darken-1 text-caption"
                              >Contracted Status</span
                            >
                            <v-radio-group dense class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[26]"
                                v-model="d_contractedStatus"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0"
                                :class="index > 0 ? 'my-0' : ''"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>

                          <!--PreK Setting-->
                          <v-col cols="12" sm="12" md="4">
                            <span class="grey--text text--darken-1 text-caption">PreK Setting</span>
                            <v-radio-group dense class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[27]"
                                v-model="j_prekSetting"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0"
                                :class="index > 0 ? 'my-0' : ''"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row v-if="providerSection !== 1" dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_assignedTA"
                              label="Assigned TA"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row v-if="providerSection !== 1" dense>
                          <!--Taxpayer ID-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_taxpayerID"
                              label="Taxpayer ID#"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <!--Transit Routing Number-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_bankRoutingNumber"
                              label="Transit Routing Number"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Account Number-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_bankAccountNumber"
                              label="Account Number"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row v-if="providerSection !== 1" dense>
                          <!--Provider Status-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.providerStatus"
                              item-text="value"
                              item-value="id"
                              label="Provider Status"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_status"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Address Info-->
                <v-tab-item>
                  <v-card flat height="700">
                    <v-card-text>
                      <v-container fluid>
                        <!-- Facility Address -->
                        <v-row dense>
                          <!--Street Address-->
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="d_facilityStreet1"
                              label="Location Address"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.address"
                            >
                            </v-text-field>
                          </v-col>

                          <!--Street Address 2-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_facilityStreet2"
                              label="Suite/Building"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--City-->
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-model="d_facilityCity"
                              label="City"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.city"
                            >
                            </v-text-field>
                          </v-col>

                          <!--State-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_facilityState"
                              label="State"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.state"
                            >
                            </v-text-field>
                          </v-col>

                          <!--Zip-->
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="d_facilityPostalCode"
                              label="Zip"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.zip"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="12">
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>

                        <!--Business Address The Same-->
                        <v-row dense>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="f_sameAddress"
                              label="Business Address Same As Location"
                              color="primary"
                              class="pr-2"
                              @click.native="setBusinessAddress(f_sameAddress)"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <!-- Business Address -->
                        <v-row dense>
                          <!--Street Address-->
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="d_businessStreet1"
                              label="Business Address"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Street Address 2-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_businessStreet2"
                              label="Suite/Building"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--City-->
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-model="d_businessCity"
                              label="City"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--State-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_businessState"
                              label="State"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Zip-->
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="d_businessPostalCode"
                              label="Zip"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Contact Info-->
                <v-tab-item>
                  <v-card flat height="700">
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <!--Facility Phone-->
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="d_facilityPhone"
                              label="Facility Phone"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Facility Fax-->
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="d_facilityFax"
                              label="Facility Fax"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Business Phone-->
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="d_businessPhone"
                              label="Business Phone"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Business Fax-->
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="d_businessFax"
                              label="Business Fax"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Website-->
                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="d_website"
                              label="Website"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Facility Email-->
                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="d_email"
                              label="Facility Email"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Payment Info Email -->
                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="d_emailPayment"
                              label="Payment Info Email"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Referral-->
                <v-tab-item>
                  <v-card flat height="700">
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <!--Referral ID-->
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model="d_referralID"
                              label="Referral ID"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--On Referral-->
                          <v-col cols="12" sm="12" md="2">
                            <v-select
                              :items="valueListsStore.yesNo"
                              item-text="value"
                              item-value="id"
                              label="On Referral"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_onReferral"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--Why Not On Referral-->
                          <v-col cols="12" sm="12" md="10">
                            <v-text-field
                              v-model="d_referralReason"
                              label="Why Not On Referral?"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Packet Update Date-->
                          <v-col cols="12" sm="12" md="6">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_datePacketUpdate"
                                  label="Packet Update Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date1 = $_parseDate(d_datePacketUpdate)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date1"
                                @input="menu = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";

import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "providersStore/getEntryField",
  mutationType: "providersStore/updateEntryField"
});

export default {
  name: "ProviderEdit",
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: state => state.providersStore,
      countiesStore: state => state.countiesStore,
      ratingsStore: state => state.ratingsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",
      "id",

      "fk_countyID",
      "fk_ratingID",
      "d_nameLicense",
      "d_nameFederal",
      "d_licenseNumber",
      "d_taxpayerID",
      "d_website",
      "d_email",
      "d_emailPayment",
      "d_facilityStreet1",
      "d_facilityStreet2",
      "d_facilityCity",
      "d_facilityState",
      "d_facilityPostalCode",
      "d_businessStreet1",
      "d_businessStreet2",
      "d_businessCity",
      "d_businessState",
      "d_businessPostalCode",
      "d_facilityPhone",
      "d_facilityFax",
      "d_businessPhone",
      "d_businessFax",
      "d_bankRoutingNumber",
      "d_bankAccountNumber",
      "d_mailingsAttendance",
      "d_mailingsPayment",
      "d_overageType",
      "d_referralID",
      "d_referralReason",
      "d_datePacketUpdate",
      "d_directDepositName",
      "d_specialRequests",
      "d_contractedStatus",
      "j_prekSetting",
      "d_assignedTA",
      "f_sameAddress",
      "f_overage",
      "f_onReferral",
      "f_type",
      "f_status",
      "date1"
    ])
  },
  props: {
    /* All List = new unlicensed entry = 1, edit entry = 2 */
    providerSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: false,
      tab: 0,
      rules: {
        facilityLicense: [v => !!v || "Facility License Name is required"],
        businessName: [v => !!v || "Alert Type is required"],
        county: [v => !!v || "County is required"],
        operationType: [v => !!v || "Operation Type is required"],
        rating: [v => !!v || "Rating selection is required"],
        address: [v => !!v || "Location Address Name is required"],
        city: [v => !!v || "City is required"],
        state: [v => !!v || "State is required"],
        zip: [v => !!v || "Zipcode is required"],
      }
    };
  },
  watch: {
    date1() {
      this.d_datePacketUpdate = this.$_formatDate(this.date1);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("providersStore/resetEntry");
    },

    async openEntry() {
      this.resetEntry();
      /* Store authorized user in store */
      const counties = this.$store.getters["countiesStore/getCountyValueList"];
      if (counties.length === 0) {
        await this.$store.dispatch("countiesStore/valueList");
      }
      /* Pull Provider/Facility List */
      const type = {
        type: 2
      };
      /* Grab ratings list */
      await this.$store.dispatch("ratingsStore/valueList", type);

      const data = {
        id: [26, 27]
      };
      await this.$store.dispatch("valueListsStore/items", data);

      if (this.providerSection === 1) {
        this.mode = 1;
        //default values for unlicensed providers
        this.f_type = 3;
        this.fk_ratingID = 13;
        this.f_status = 3;
      } else {
        const provider = this.$store.getters["providersStore/getProvider"];
        await this.$store.dispatch("providersStore/entry", provider.uuid);
        this.mode = 2;
      }

      this.tab = 0;
      this.show = true;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    setBusinessAddress(value) {
      if (value == 1) {
        this.d_businessStreet1 = this.d_facilityStreet1;
        this.d_businessStreet2 = this.d_facilityStreet2;
        this.d_businessCity = this.d_facilityCity;
        this.d_businessState = this.d_facilityState;
        this.d_businessPostalCode = this.d_facilityPostalCode;
      } else {
        this.d_businessStreet1 = "";
        this.d_businessStreet2 = "";
        this.d_businessCity = "";
        this.d_businessState = "";
        this.d_businessPostalCode = "";
      }
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["providersStore/getEntry"];
        /* Create Record */
        if (this.mode === 1) {
          await this.$store.dispatch("providersStore/create", entry);
        } else {
        /* Edit Record */
          await this.$store.dispatch("providersStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
