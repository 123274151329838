<template>
  <div>
    <!--Title Bar For Main List-->
    <title-bar v-if="noteSection === 1" :title-value="'Notes'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-comment</v-icon>
        <v-toolbar-title class="pl-2">Notes</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="noteSection === 1 || noteSection === 3 || noteSection === 5" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense v-if="noteSection === 1">
                <!--Provider License-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_licenseNumber"
                    label="Provider License #"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="noteSection === 1">
                <!--Provider Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_nameLicense"
                    label="Provider Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="noteSection === 1">
                <!--Teacher Name-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="teachersStore.teachersValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Teacher Name"
                    placeholder=" "
                    persistent-placeholder
                    v-model="teacherID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="noteSection === 1">
                <!--Waiting List Applicant First Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="applicantNameFirstWL"
                    label="Applicant First Name (Waiting List)"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="noteSection === 1">
                <!--Waiting List Applicant Last Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="applicantNameLastWL"
                    label="Applicant Last Name (Waiting List)"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="noteSection === 1">
                <!--Household ID-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="householdID"
                    label="Household ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="noteSection === 1">
                <!--Client ID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="clientID"
                    label="Client ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
                <!--v1 Child ID-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_childIDv1"
                    label="v1 Child ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!-- Date -->
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_date"
                        label="Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!-- Note Type -->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="noteTopicsStore.noteTopicsValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!-- Author -->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="usersStore.usersValueList"
                    item-text="name"
                    item-value="id"
                    label="Author"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_userID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="notesStore.notes.data && notesStore.notes.data.length > 0"
      :showTotal="true"
      :currentPage="notesStore.notes.current_page"
      :lastPage="notesStore.notes.last_page"
      :total="notesStore.notes.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="notesStore.notes.data && notesStore.notes.data.length > 0"
        :list-data="notesStore.notes.data"
        :title="'Notes'"
        :section="24"
        :clientID="client || 0"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "notesStore/getFilterField",
  mutationType: "notesStore/updateFilterField"
});

export default {
  name: "Notes",
  components: {
    Pagination,
    ListBasic,
    TitleBar
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      notesStore: state => state.notesStore,
      teachersStore: state => state.teachersStore,
      noteTopicsStore: state => state.noteTopicsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_providerID",
      "fk_householdID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "d_date",
      "date1",
      "f_type",
      "fk_userID",
      "clientID",
      "householdID",
      "teacherID",
      "d_childIDv1",
      "d_licenseNumber",
      "d_nameLicense",
      "applicantNameLastWL",
      "applicantNameFirstWL",
    ])
  },
  props: {
    /* All List = 1, Provider Detail = 2, Provider Sublist = 3, Client Detail = 4, Client Sublist = 5, Teacher Detail = 6, Teacher Sublist = 7, Waiting List Detail = 8, Waiting List Sublist = 9*/
    noteSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      filterMenu: false,
      menu: false,
      //used to show client name on notes list
      client: ""
    };
  },
  methods: {
    async initialize() {
      if (this.noteSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* ---------- Provider Section ---------- */
      if (this.noteSection === 2 || this.noteSection === 3) {
        /* Grab provider detail */
        const provider = this.$store.getters["providersStore/getProvider"];
        /* Hard code filter of provider ID to restrict by provider */
        this.fk_providerID = provider.id;
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
        /* If provider detail, clear all filters to show most recent notes */
        if (this.noteSection === 2) {
          this.date1 = "";
          this.f_type = "";
          this.fk_userID = "";
          // this.page = 1;
        }
      } else if (this.noteSection === 4 || this.noteSection === 5) {
      /* ---------- Client Section ---------- */
        /* Grab client detail */
        const client = this.$store.getters["clientsStore/getClient"];
        /* Hard code filter of client ID to restrict by client */
        this.fk_providerID = "";
        this.fk_householdID = client.fk_householdID;
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
        /* Detail */
        if (this.noteSection === 4) {
          this.date1 = "";
          this.f_type = "";
          this.fk_userID = "";
          this.client = client.id;
          // this.page = 1;
        }
      } else if (this.noteSection === 6 || this.noteSection === 7) {
      /* ---------- Teacher Section ---------- */
        /* Grab teacher detail */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        /* Hard code filter of teacher ID to restrict by teacher */
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = teacher.id;
        this.fk_waitingListID = "";
        /* If teacher detail, clear all filters to show most recent notes */
        if (this.noteSection === 6) {
          this.date1 = "";
          this.f_type = "";
          this.fk_userID = "";
          // this.page = 1;
        }
      } else if (this.noteSection === 8 || this.noteSection === 9) {
      /* ---------- Waiting List Section ---------- */
        /* Grab waiting list detail */
        const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
        /* Hard code filter of wating list ID to restrict by waiting list item */
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = waitingListItem.id;
        /* If waiting list detail, clear all filters to show most recent notes */
        if (this.noteSection === 8) {
          this.date1 = "";
          this.f_type = "";
          this.fk_userID = "";
          // this.page = 1;
        }
      } else {
      /* ---------- All ---------- */
        /* Clear all hard coded filters */
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      }

      /* Grab notes list */
      const filters = this.$store.getters["notesStore/getFilters"];
      await this.$store.dispatch("notesStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists(householdID) {
      let vlData;
      /* Provider */
      if (this.noteSection === 2 || this.noteSection === 3) {
        vlData = {
          f_type: 1
        };
      } else if (this.noteSection === 4 || this.noteSection === 5) {
      /* Client */
        vlData = {
          f_type: 2
        };
      } else if (this.noteSection === 6 || this.noteSection === 7) {
      /* Teacher */
        vlData = {
          f_type: 3
        };
      } else if (this.noteSection === 8 || this.noteSection === 9) {
      /* Waiting List */
        vlData = {
          f_type: 4
        };
      } else {
      /* All */
        vlData = {
          f_type: ""
        };
      }
      await this.$store.dispatch("noteTopicsStore/valueList", vlData);
      const specialist = {
        f_counselor: ""
      };
      await this.$store.dispatch("usersStore/valueList", specialist);
      const data = {
        fk_providerID: "",
        fk_householdID: householdID
      };
      await this.$store.dispatch("clientsStore/valueList", data);
      await this.$store.dispatch("teachersStore/valueList");
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    clearFilterColumns() {
      this.date1 = "";
      this.f_type = "";
      this.fk_userID = "";
      this.clientID = "";
      this.householdID = "";
      this.teacherID = "";
      this.d_childIDv1 = "";
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.applicantNameLastWL = "";
      this.applicantNameFirstWL = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists(this.fk_householdID);
      if (this.noteSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("notesStore/clearNotes");
      }
      /* Save section in store */
      this.section = this.noteSection;
    },

    showFiltered() {
      if (
        this.d_date ||
        this.f_type ||
        this.fk_userID ||
        this.clientID ||
        this.householdID ||
        this.teacherID ||
        this.d_childIDv1 ||
        this.d_licenseNumber ||
        this.d_nameLicense ||
        this.applicantNameLastWL ||
        this.applicantNameFirstWL
      ) {
        return true;
      }
      return false;
    },

    async openEntry(params) {
      const user = this.$store.getters["usersStore/getAuthUser"];
      if (
        params.f_privateRecord === 0 ||
        (params.f_privateRecord === 1 && user.f_viewPrivateRecords === 1)
      ) {
        await this.loadValueLists(params.fk_householdID);
        await this.$store.dispatch("notesStore/entry", params.id);
      } else {
        const data = {
          color: "error",
          text: "Access to this record is prohibited."
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
