import { getHeader } from '@/env';

const getDefaultState = () => ({
  navigation: [],
  navigationAdmin: [],
  nav: '',
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.navigation = list;
  },
  CREATE(state, { data }) {
    state.navigation.push(data);
  },
  READ(state, { data }) {
    state.nav = data;
  },
  UPDATE(state, { data }) {
    const idx = state.navigation.map((item) => item.id).indexOf(data.id);
    state.navigation.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.navigation.map((item) => item.id).indexOf(id);
    state.navigation.splice(idx, 1);
  },
  FETCH_ADMIN(state, { list }) {
    state.navigationAdmin = list;
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  fetch: ({ commit }) => new Promise((resolve, reject) => {
    axios.get('/api/navigation', { headers: getHeader() })
      .then((response) => {
        commit('FETCH', { list: response.data });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }),
  create: ({ commit }, data) => new Promise((resolve, reject) => {
    axios.post('/api/navigation/create', data, { headers: getHeader() })
      .then((response) => {
        commit('CREATE', { data: response.data });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }),
  read: ({ commit }, id) => new Promise((resolve, reject) => {
    axios.get(`/api/navigation/${id}`, { headers: getHeader() })
      .then((response) => {
        commit('READ', { data: response.data });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }),
  update: ({ commit }, data) => new Promise((resolve, reject) => {
    axios.put(`/api/navigation/update/${data.id}`, data, { headers: getHeader() })
      .then((response) => {
        commit('UPDATE', { data: response.data });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }),
  delete: ({ commit }, data) => new Promise((resolve, reject) => {
    axios.post('/api/navigation/delete', data, { headers: getHeader() })
      .then((response) => {
        commit('DELETE', { id: data.id });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }),
  active: ({ commit }) => new Promise((resolve, reject) => {
    axios.get('/api/navigation/list/active', { headers: getHeader() })
      .then((response) => {
        commit('FETCH', { list: response.data });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }),
  async fetchAdmin({ commit }) {
    try {
      const response = await axios.get('/api/navigationadmin', { headers: getHeader() });
      commit('FETCH_ADMIN', { list: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getNavigation: (state, getters) => state.navigation,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
