<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="providersStore.provider.d_licenseNumber + ' - ' +
      providersStore.provider.d_nameLicense" />

      <!--Function Bar-->
      <provider-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <!--DCDEE Data-->
          <v-col cols="12" sm="12" md="2">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-hotel</v-icon>
                <v-toolbar-title class="pl-2">DCDEE Data</v-toolbar-title>
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-card-text>
                <v-row v-if="providersStore.provider.facility" dense>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'License #'" :displayString="providersStore.provider.facility.ffacid" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Name'" :displayString="providersStore.provider.facility.ffacname" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Type'" :displayString="providersStore.provider.facility.foptype" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Location Address'" :displayString="providersStore.provider.facility.flstreet" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'County'" :displayString="providersStore.provider.facility.county.d_name" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :errorText="providersStore.provider.facility.fstatus !== 'Active' ? true : false" :displayLabel="'Status'" :displayString="providersStore.provider.facility.fstatus" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Capacity'" :displayNumber="providersStore.provider.facility.pcap" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Rating'" :displayString="providersStore.provider.facility.starrating" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Rating Effective Date'" :displayString="providersStore.provider.facility.pfromdt ? $_formatDate(providersStore.provider.facility.pfromdt): ''" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!--Provider Information-->
          <v-col cols="12" sm="12" md="7">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-school</v-icon>
                <v-toolbar-title class="pl-2">Details</v-toolbar-title>

                <v-spacer />
                <provider-entry :provider-section="2" />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-card-text>

                <div v-if="providersStore.provider.actions && providersStore.provider.actions.length > 0">
                <v-alert
                  :key="index" v-for="(item, index) in providersStore.provider.actions"
                  :value="true"
                  type="error"
                  icon="fal fa-exclamation-triangle"
                >
                  {{item.type.d_name}}
                </v-alert>
                </div>

                <!--General-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Provider Status'" :displayString="$_switchProviderStatus(providersStore.provider.f_status)" />
                  </v-col>

                  <v-col v-if="providersStore.provider.facility" cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Rating'" :displayString="providersStore.provider.rating ? providersStore.provider.rating.d_name : ''" :errorText="providersStore.provider.rating
                    && providersStore.provider.rating.d_facilityValue !== providersStore.provider.facility.starrating ? true : false"/>
                  </v-col>
                  <v-col v-else cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Rating'" :displayString="providersStore.provider.rating ? providersStore.provider.rating.d_name : ''"/>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'License #'" :displayString="providersStore.provider.d_licenseNumber" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="9">
                    <text-display :displayLabel="'Facility License Name'" :displayString="providersStore.provider.d_nameLicense" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Operation Type'" :displayString="$_switchProviderType(providersStore.provider.f_type)" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'County'" :displayString="providersStore.provider.county.d_name" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Taxpayer ID #'" :displayString="providersStore.provider.d_taxpayerID" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="9">
                    <text-display :displayLabel="'Business Name'" :displayString="providersStore.provider.d_nameFederal" />
                  </v-col>
                </v-row>

                <v-row class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <!--Address-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Location Address'" :displayString="providersStore.provider.d_facilityStreet1" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Suite/Building'" :displayString="providersStore.provider.d_facilityStreet2" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <text-display :displayLabel="'City'" :displayString="providersStore.provider.d_facilityCity" />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <text-display :displayLabel="'State'" :displayString="providersStore.provider.d_facilityState" />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <text-display :displayLabel="'Zip'" :displayString="providersStore.provider.d_facilityPostalCode" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Business Address'" :displayString="providersStore.provider.d_businessStreet1" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Suite/Building'" :displayString="providersStore.provider.d_businessStreet2" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <text-display :displayLabel="'City'" :displayString="providersStore.provider.d_businessCity" />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <text-display :displayLabel="'State'" :displayString="providersStore.provider.d_businessState" />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <text-display :displayLabel="'Zip'" :displayString="providersStore.provider.d_businessPostalCode" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <!--Contact-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Facility Phone'" :displayString="providersStore.provider.d_facilityPhone" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Facility Fax'" :displayString="providersStore.provider.d_facilityFax" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Website'" :displayString="providersStore.provider.d_website" />
                      </v-col>
                    </v-row>

                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Business Phone'" :displayString="providersStore.provider.d_businessPhone" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Business Fax'" :displayString="providersStore.provider.d_businessFax" />
                      </v-col>
                    </v-row>
                    <v-row dense v-if="providersStore.provider.f_textingOptIn">
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Texting Program Contact'" :displayString="providersStore.provider.d_textingName" />
                      </v-col>
                    </v-row>
                    <v-row dense v-if="providersStore.provider.f_textingOptIn">
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Texting Program Number'" :displayString="providersStore.provider.d_textingPhone" />
                      </v-col>
                    </v-row>
                    <v-row dense v-else>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Not enrolled in Texting Program'" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Facility Email'" :displayString="providersStore.provider.d_email" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Payment Info Email'" :displayString="providersStore.provider.d_emailPayment" />
                  </v-col>
                </v-row>

                <v-row class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <!--Other Settings-->
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <text-display :displayLabel="'Referral ID'" :displayString="providersStore.provider.d_referralID" />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <text-display :displayLabel="'On Referral'" :displayString="$_yesNo(providersStore.provider.f_onReferral)" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <text-display :displayLabel="'Why Not On Referral?'" :displayString="providersStore.provider.d_referralReason" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="3">
                    <text-display :displayLabel="'Packet Updated Date'" :displayString="$_formatDate(providersStore.provider.d_datePacketUpdate)" />
                  </v-col>
                  <v-col cols="12" sm="5">
                    <text-display :displayLabel="'Contracted Status'" :displayArray="providersStore.provider.d_contractedStatus" />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <text-display :displayLabel="'PreK Setting'" :displayArray="providersStore.provider.j_prekSetting" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Assigned TA'" :displayString="providersStore.provider.d_assignedTA" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Transit Routing Number'" :displayString="providersStore.provider.d_bankRoutingNumber" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Account Number'" :displayString="providersStore.provider.d_bankAccountNumber" />
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>

          <!--Notes-->
          <v-col cols="12" sm="12" md="3">
            <notes :key="providersStore.provider.id" :noteSection="2" />
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ProviderFunctionBar from '@/components/ProviderFunctionBar';
import Notes from '@/components/Notes';
import TextDisplay from '@/components/TextDisplay';
import ProviderEntry from '@/components/ProviderEntry';

export default {
  name: 'Provider',
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: (state) => state.providersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
    TitleBar,
	  ProviderFunctionBar,
    Notes,
    TextDisplay,
    ProviderEntry,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
      /* Refresh bookmark */
      const user = this.$store.getters['usersStore/getAuthUser'];
      const provider = this.$store.getters['providersStore/getProvider'];
      const data = {
        fk_providerID: provider.id,
        fk_userID: user.id,
      };
      await this.$store.dispatch('bookmarksStore/detail', data);
      this.$store.dispatch('progressStore/set', false);
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
