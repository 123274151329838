import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  agencies: [],
  agency: null,
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    d_name: '',
  },
  filters: {
    page: '',
    d_name: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.agencies = list;
  },
  CREATE(state, { data }) {
    state.agencies.data.unshift(data);
  },
  READ(state, { data }) {
    state.agency = data;
  },
  UPDATE(state, { data }) {
    const idx = state.agencies.data.map((item) => item.id).indexOf(data.id);
    state.agencies.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.agencies.data.map((item) => item.id).indexOf(id);
    state.agencies.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_name = data.d_name;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.d_name = '';
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
  	try {
  		const response = await axios.post('/api/agencies', data, { headers: getHeader() });
		  commit('FETCH', { list: response.data });
	  } catch (error) {

	  }
  },
  async create({ commit }, data) {
  	try {
  		const response = await axios.post('/api/agencies/create', data, { headers: getHeader() });
		  commit('CREATE', { data: response.data });
	  } catch (error) {

	  }
  },
  async read({ commit }, id) {
  	try {
  		const response = await axios.get(`/api/agencies/${id}`, { headers: getHeader() });
		  commit('READ', { data: response.data });
	  } catch (error) {

	  }
  },
  async update({ commit }, data) {
  	try {
  		const response = await axios.put(`/api/agencies/update/${data.id}`, data, { headers: getHeader() });
		  commit('UPDATE', { data: response.data });
	  } catch (error) {

	  }
  },
  async delete({ commit }, data) {
  	try {
  		const response = await axios.post('/api/agencies/delete', data, { headers: getHeader() });
		  commit('DELETE', { id: data.id });
	  } catch (error) {

	  }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/agencies/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
