<template>
  <div>
    <v-tooltip v-if="licenseSection === 1" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-id-card
          </v-icon>
        </v-btn>
      </template>
      <span>Add License</span>
    </v-tooltip>

    <!--License Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="700">
      <v-card flat class="white" height="350">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid grid-list-md>
            <!--License Info-->
              <v-card flat height="100">
                <v-container fluid class="pa-0 mt-5 px-2">
                  <v-row dense>
                    <v-row dense>
                      <!--License Number-->
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="d_name"
                          label="License Number"
                          placeholder=" "
                          persistent-placeholder
                          required
                          :rules="rules.licenseNumber"
                        >
                        </v-text-field>
                      </v-col>
                      <!--License Type-->
                      <v-col cols="12" sm="12" md="4">
                        <v-select
                          :items="valueListsStore.valueListItems[30]"
                          label="License Type"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_value"
                          clearable
                          v-model="f_type"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.licenseType"
                        />
                      </v-col>
                      <!--Years Experience-->
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model="d_yearsExperience"
                          label="Years of Experience"
                          placeholder=" "
                          persistent-placeholder
                          :rules="rules.only_digits"
                          hint="##"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <!--Issue Date-->
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model="d_issueDate"
                          label="Issue Date"
                          placeholder=" "
                          persistent-placeholder
                          prepend-icon="fal fa-calendar-alt"
                          required
                          @blur="d_issueDate = $_formatStringDate(d_issueDate);
                                  date1 = $_parseDate(d_issueDate);"
                          :rules="rules.date"
                          hint="MM/DD/YYYY"
                        />
                      </v-col>
                      <!--Expiration Date-->
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model="d_expirationDate"
                          label="Expiration Date"
                          placeholder=" "
                          persistent-placeholder
                          prepend-icon="fal fa-calendar-alt"
                          required
                          @blur="d_expirationDate = $_formatStringDate(d_expirationDate);
                                  date2 = $_parseDate(d_expirationDate);"
                          :rules="rules.date"
                          hint="MM/DD/YYYY"
                        />
                      </v-col>
                      <!--Status-->
                      <v-col cols="12" sm="12" md="2">
                        <v-autocomplete
                          v-if="mode !== 1"
                          :items="valueListsStore.activeStatus"
                          label="Status"
                          placeholder="Active"
                          persistent-placeholder
                          item-text="value"
                          item-value="id"
                          v-model="f_status"
                        />
                      </v-col>
                      <v-col v-if="usersStore.authUser.f_delete === 1" cols="12" sm="12" md="3">
                        <v-select
                          v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                          :items="[{text: 'Yes', value: '0'}]"
                          item-text="text"
                          item-value="value"
                          label="Remove License?"
                          placeholder=" "
                          persistent-placeholder
                          v-model="f_show"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </v-card>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { createHelpers} from "vuex-map-fields";
  import { mixin } from "@/mixins/mixin.js";
  import TitleBar from '@/components/TitleBar';
  import TextDisplay from "@/components/TextDisplay";

  const { mapFields } = createHelpers({
    getterType: "teacherLicenseStore/getEntryField",
    mutationType: "teacherLicenseStore/updateEntryField"
  });

  export default {
    name: "TeacherLicenseEdit",
    mixins: [mixin],
    computed: {
      ...mapState({
        teacherLicenseStore: state => state.teacherLicenseStore,
        usersStore: state => state.usersStore,
        valueListsStore: state => state.valueListsStore
      }),
      ...mapFields([
        "show",
        "valid",
        "mode",
        "id",

        "fk_teacherID",
        "d_name",
        "f_type",
        "d_issueDate",
        "date1",
        "d_expirationDate",
        "date2",
        "d_yearsExperience",
        "f_status",
        "f_show"
      ]),
    },
    components: {
      TitleBar,
      TextDisplay
    },
    props: {
      /* Teacher Section = 1 */
      licenseSection: {
        type: Number,
        required: false
      }
    },
    data() {
      return {
        rules: {
          licenseNumber: [v => !!v || "License Number is required."],
          licenseType: [v => !!v || "License Type is required."],
          date: [v => !!v || "Date is required (MM/DD/YYYY)"],
          only_digits: [
            //apply validation if the field is populated !v
            v => !v || /^\d+$/.test(v) || "Enter Digits Only"
          ]
        },
      };
    },
    watch: {
      date1() {
        this.d_issueDate = this.$_formatDate(this.date1);
      },
      date2() {
        this.d_expirationDate = this.$_formatDate(this.date2);
      }
    },
    methods: {
      resetEntry() {
        this.$store.dispatch("teacherLicenseStore/resetEntry");
        if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
      },

      async newEntry() {
        this.resetEntry();

        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_teacherID = teacher.id;
        this.valid = true;
        this.mode = 1;
        this.id = "";
        this.f_status = 1;
        this.show = true;
      },

      cancelEntry() {
        this.show = false;
        this.resetEntry();
      },

      async saveEntry() {
        if (this.$refs.entryForm.validate()) {
          const entry = this.$store.getters["teacherLicenseStore/getEntry"];
          /* Create Record */
          if (this.mode === 1) {
            await this.$store.dispatch("teacherLicenseStore/create", entry);
          } else {
          /* Edit Record */
            await this.$store.dispatch("teacherLicenseStore/update", entry);
          }
          this.cancelEntry();

           /* Reload List */
          this.$store.dispatch("progressStore/set", true);
          if (this.mode === 1) {
            const filters = this.$store.getters["teachersStore/getFilters"];
            await this.$store.dispatch("teachersStore/fetch", filters);
          } else {
            const filters = this.$store.getters["teacherLicenseStore/getFilters"];
            await this.$store.dispatch("teacherLicenseStore/fetch", filters);
          }
          this.$store.dispatch("progressStore/set", false);
        }
      }
    }
  };
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>

