<template>
  <div>
    <v-btn rounded outlined @click="openEntry()"
      ><v-icon small left>fal fa-pencil-alt</v-icon>Edit</v-btn
    >

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1000">
      <v-card flat class="white" height="1200">
        <v-card-title class="text-h5 secondary--text"
          >Client Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid grid-list-md>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>General</v-tab>
                <v-tab>Internal Info</v-tab>

                <!--General-->
                <v-tab-item>
                  <v-card flat height="950">
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Client ID-->
                          <v-col cols="12" sm="6" md="2">
                            <text-display
                              :displayLabel="'Client ID'"
                              :displayNumber="clientsStore.client ? clientsStore.client.id : ''"
                            />
                          </v-col>
                          <!--v1 Client ID-->
                          <v-col cols="12" sm="6" md="2">
                            <v-text-field
                              v-model="d_childIDv1"
                              label="v1 Client ID"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Household ID-->
                          <v-col cols="12" sm="6" md="2">
                            <text-display
                              :displayLabel="'Household ID'"
                              :displayNumber="clientsStore.client.fk_householdID"
                            />
                          </v-col>
                          <!--v1 Applicant ID-->
                          <v-col cols="12" sm="6" md="2">
                            <text-display
                              :displayLabel="'v1 Applicant ID'"
                              :displayNumber="
                                clientsStore.client.household
                                  ? clientsStore.client.household.d_applicantIDv1
                                  : ''
                              "
                            />
                          </v-col>
                          <!--v1 Application ID-->
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="d_applicationIDsma"
                              label="SMA Application ID"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Child Number-->
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="d_childNumber"
                              label="Child Number"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--PreK Seat ID-->
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="d_prekSeatID"
                              label="PreK Seat ID"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Birth Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateBirth"
                                  label="Date of Birth"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date1 = $_parseDate(d_dateBirth)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date1"
                                @input="menu = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <!--Date of Birth Verified-->
                          <v-col cols="12" sm="6" md="2">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Date of Birth Verified"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="value"
                              v-model="d_verifiedDateOfBirth"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Calculated Age-->
                          <v-col cols="12" sm="6" md="2">
                            <text-display
                              :displayLabel="'Age'"
                              :displayString="$_calculatedAge(d_dateBirth, new Date())"
                            />
                          </v-col>
                          <!--Gender-->
                          <v-col cols="12" sm="6" md="3">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[5]"
                              label="Gender"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_gender"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Private Record-->
                          <v-col cols="12" sm="12" md="2">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Private Record"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_privateRecord"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--First Name-->
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_nameFirst"
                              label="First Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Middle Name-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_nameMiddle"
                              label="Middle Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Last Name-->
                          <v-col cols="12" sm="12" md="5">
                            <v-text-field
                              v-model="d_nameLast"
                              label="Last Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="6">
                            <!--Race-->
                            <v-col cols="12" sm="12" class="px-0">
                              <span class="grey--text text--darken-1 text-caption">Race</span>
                              <v-radio-group dense class="my-0 py-0">
                                <v-checkbox
                                  dense
                                  :key="index"
                                  v-for="(item, index) in valueListsStore.valueListItems[12]"
                                  v-model="d_race"
                                  :label="item.d_name"
                                  :value="item.d_name"
                                  color="primary"
                                  multiple
                                  class="pr-2 py-0"
                                  :class="index > 0 ? 'my-0' : ''"
                                ></v-checkbox>
                              </v-radio-group>
                            </v-col>
                            <!--Other Race-->
                            <v-col cols="12" sm="12" md="10" class="px-0 py-0">
                              <v-text-field
                                v-model="d_raceOther"
                                label="Other Race"
                                placeholder=" "
                                persistent-placeholder
                              >
                              </v-text-field>
                            </v-col>
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-row dense>
                              <!--Language-->
                              <v-col cols="12" sm="12">
                                <v-autocomplete
                                  :items="valueListsStore.valueListItems[15]"
                                  label="Language"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="d_name"
                                  item-value="d_name"
                                  v-model="d_language"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--US Citizen & NC Resident-->
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="US Citizen & NC Resident"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_additionalInfoUSCitizen"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Special Needs-->
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Child has special need"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_additionalInfoSpecialNeed"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>

                              <!--Covered By Insurance-->
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Health Insurance Coverage"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_additionalInfoHealthInsurance"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Describe Special Needs-->
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  v-model="d_additionalInfoSpecialNeedDescribe"
                                  label="Type of Special Need"
                                  placeholder=" "
                                  persistent-placeholder
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Receiving Services-->
                              <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                  :items="valueListsStore.yesNo"
                                  label="Receiving Services"
                                  placeholder=" "
                                  persistent-placeholder
                                  item-text="value"
                                  item-value="value"
                                  v-model="d_additionalInfoReceivingServices"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Treatment Plan-->
                              <v-col cols="12" sm="12" md="6">
                                <v-menu
                                  :close-on-content-click="false"
                                  v-model="menu6"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template #activator="{ on }">
                                    <v-text-field
                                      v-on="on"
                                      v-model="d_dateServicePlanEnd"
                                      label="Service Plan End Date"
                                      placeholder=" "
                                      persistent-placeholder
                                      prepend-icon="fal fa-calendar-alt"
                                      required
                                      @blur="date6 = $_parseDate(d_dateServicePlanEnd)"
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="date6"
                                    @input="menu = false"
                                    color="primary"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Additional Financial Support-->
                          <v-col cols="12" sm="6" md="3">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Additional Financial Support"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="value"
                              v-model="d_shareFinancial"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Supporter's Name-->
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_shareFinancialName"
                              label="Supporter's Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Supporter's Relationship to Child-->
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="d_shareFinancialRelationship"
                              label="Supporter's Relationship to Child"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Support Description-->
                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="d_shareFinancialDescribe"
                              label="Support Description"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Internal Info-->
                <v-tab-item>
                  <v-card flat height="800">
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                              :items="usersStore.usersValueList"
                              label="Specialist"
                              placeholder=" "
                              persistent-placeholder
                              item-text="name"
                              item-value="id"
                              v-model="fk_userID"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Certification Start Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu2"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateCertificationStart"
                                  label="Scholarship Start Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date2 = $_parseDate(d_dateCertificationStart)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date2"
                                @input="menu2 = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <!--Certification End Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu3"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateCertificationEnd"
                                  label="Scholarship End Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date3 = $_parseDate(d_dateCertificationEnd)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date3"
                                @input="menu3 = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Service Priority-->
                          <v-col cols="12" sm="12" md="8">
                            <span
                              class="grey--text text--darken-2 text-caption font-weight-medium pb-0"
                              >Service Priority</span
                            >
                            <v-radio-group row class="my-0">
                              <v-checkbox
                                dense
                                v-for="(item, index) in valueListsStore.valueListItems[11]"
                                :key="index"
                                v-model="d_priorityService"
                                :label="item.d_name"
                                multiple
                                color="primary"
                                :value="item.d_name"
                                class="pr-2 py-0 mt-0"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                          <!--Pickup Priority-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[14]"
                              label="Pickup Priority"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_priorityPickup"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Termination Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu4"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateTerm"
                                  label="Termination Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date4 = $_parseDate(d_dateTerm)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date4"
                                @input="menu4 = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <!--Termination Reason-->
                          <v-col cols="12" sm="9">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[17]"
                              label="Termination Reason"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_termReason"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <!--Referral Info-->
                        <v-row dense>
                          <v-col cols="12" sm="6" md="12" class="px-0">
                            <span class="text-subtitle-1 secondary--text font-weight-bold"
                              >Referral Info</span
                            >
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Referral Source-->
                          <v-col cols="12" sm="9">
                            <v-text-field
                              v-model="d_referralSource"
                              label="Source"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Referral Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu5"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_referralDate"
                                  label="Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date5 = $_parseDate(d_referralDate)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date5"
                                @input="menu5 = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Referral Name-->
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model="d_referralName"
                              label="Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Referral Email-->
                          <v-col cols="12" sm="5">
                            <v-text-field
                              v-model="d_referralEmail"
                              label="Email"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Referral Phone-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_referralPhone"
                              label="Phone"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "clientsStore/getEntryField",
  mutationType: "clientsStore/updateEntryField"
});

export default {
  name: "ClientEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      clientsStore: state => state.clientsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",

      "fk_householdID",
      "fk_userID",
      "d_childIDv1",
      "d_nameLast",
      "d_nameMiddle",
      "d_nameFirst",
      "d_dateBirth",
      "d_childNumber",
      "d_prekSeatID",
      "d_applicationIDsma",
      "d_gender",
      "d_race",
      "d_raceOther",
      "d_weeklyHoursCare",
      "d_verifiedDateOfBirth",
      "d_additionalInfoHealthInsurance",
      "d_additionalInfoUSCitizen",
      "d_additionalInfoSpecialNeed",
      "d_additionalInfoSpecialNeedDescribe",
      "d_additionalInfoReceivingServices",
      "d_dateServicePlanEnd",
      "d_shareFinancial",
      "d_shareFinancialName",
      "d_shareFinancialRelationship",
      "d_shareFinancialDescribe",
      "d_language",
      "d_dateCertificationStart",
      "d_dateCertificationEnd",
      "d_dateTerm",
      "d_termReason",
      "d_referralSource",
      "d_referralDate",
      "d_referralName",
      "d_referralEmail",
      "d_referralPhone",
      "d_priorityService",
      "d_priorityPickup",
      "f_privateRecord",
      "date1",
      "date2",
      "date3",
      "date4",
      "date5",
      "date6"
    ])
  },
  components: {
    TextDisplay
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      tab: 0
    };
  },
  watch: {
    date1() {
      this.d_dateBirth = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateCertificationStart = this.$_formatDate(this.date2);
    },
    date3() {
      this.d_dateCertificationEnd = this.$_formatDate(this.date3);
    },
    date4() {
      this.d_dateTerm = this.$_formatDate(this.date4);
    },
    date5() {
      this.d_referralDate = this.$_formatDate(this.date5);
    },
    date6() {
      this.d_dateServicePlanEnd = this.$_formatDate(this.date6);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("clientsStore/resetEntry");
    },

    async openEntry() {
      this.resetEntry();

      const specialist = {
        f_counselor: 1
      };
      await this.$store.dispatch("usersStore/valueList", specialist);
      const data2 = {
        id: [5, 11, 12, 14, 15, 16, 17]
      };
      await this.$store.dispatch("valueListsStore/items", data2);
      const client = this.$store.getters["clientsStore/getClient"];

      await this.$store.dispatch("clientsStore/entry", client.uuid);

      this.tab = 0;
      this.mode = 2;
      this.show = true;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["clientsStore/getEntry"];
        /* Create Record */
        if (this.mode === 1) {
          await this.$store.dispatch("clientsStore/create", entry);
        } else {
        /* Edit Record */
          await this.$store.dispatch("clientsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
