<template>
  <div>
    <!--File Upload Progress Bar-->
    <v-dialog v-model="upload" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          File Uploading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tooltip v-if="documentSection === 2 || documentSection === 3 || documentSection === 4" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-file-contract
          </v-icon>
        </v-btn>
      </template>
      <span>Add Document</span>
    </v-tooltip>

    <!--Document Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >{{ mode === 1 ? "Document Upload" : "Document Details" }}<v-spacer /><v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="documentTypesStore.documentTypesValueList"
                    item-text="d_name"
                    item-value="d_name"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_type"
                    clearable
                    :rules="rules.type"
                    required
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Title-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_title"
                    label="Title"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.not_empty"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Client-->
                <v-col cols="12" sm="12" v-if="!fk_providerID && !fk_teacherID">
                  <v-select
                    :items="clientsStore.clientsValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Client"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_clientID"
                    clearable
                    clear-icon="fal fa-times-circle"
                    @input="pullCarePlans(fk_clientID)"
                  />
                </v-col>
                <v-col cols="12" sm="12" v-if="fk_teacherID">
                  <v-select
                    :items="teachersStore.teachersValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Teacher"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_teacherID"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Care Plan - For Clients-->
                <v-col v-if="fk_clientID" cols="12" sm="12">
                  <v-select
                    :items="carePlansStore.carePlansValueList"
                    item-text="dateRange"
                    item-value="id"
                    label="Care Plan Certification Period"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_carePlanID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="fk_carePlanID">
                <!--Care Plan Items-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="carePlanItemsStore.carePlanItemsValueList"
                    item-text="dateRange"
                    item-value="id"
                    label="Care Plan Item"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_carePlanItemID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Description-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="d_description"
                    label="Description"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--File-->
                <v-col cols="12" sm="12" v-if="mode === 1">
                  <v-file-input
                    ref="file"
                    v-model="files"
                    clearable
                    multiple
                    clear-icon="fal fa-times-circle"
                    prepend-icon="fal fa-paperclip"
                    label="Select File"
                    :rules="rules.files"
                    required
                    placeholder=" "
                    persistent-placeholder
                  >
                  </v-file-input>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Private Record-->
                <v-col cols="12" sm="12" md="6" v-if="!fk_teacherID">
                  <v-autocomplete
                    :items="valueListsStore.yesNo"
                    label="Private Record"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_privateRecord"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
                <!--Remove Document-->
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                    :items="[{text: 'Yes', value: '0'}]"
                    item-text="text"
                    item-value="value"
                    label="Remove Document?"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_show"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" v-if="mode === 2">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            block
            rounded
            dark
            :color="mode === 1 ? 'green lighten-1' : 'primary'"
            @click="saveEntry()"
            ><v-icon v-if="mode === 1" small left>fal fa-cloud-upload</v-icon
            ><v-icon v-else small left>fal fa-check</v-icon
            >{{ mode === 1 ? "Upload" : "Submit" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import { clientID, clientSecret, uploadHeader } from "@/env";

const { mapFields } = createHelpers({
  getterType: "documentsStore/getEntryField",
  mutationType: "documentsStore/updateEntryField"
});

export default {
  name: "DocumentEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      documentsStore: state => state.documentsStore,
      documentTypesStore: state => state.documentTypesStore,
      carePlansStore: state => state.carePlansStore,
      carePlanItemsStore: state => state.carePlanItemsStore,
      valueListsStore: state => state.valueListsStore,
      clientsStore: state => state.clientsStore,
      teachersStore: state => state.teachersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",
      "id",
      "fk_householdID",
      "fk_providerID",
      "fk_clientID",
      "fk_teacherID",
      "fk_carePlanID",
      "fk_carePlanItemID",
      "d_path",
      "d_title",
      "d_description",
      "d_type",
      "f_show",
      "f_privateRecord",
      "f_status"
    ])
  },
  props: {
    /* All List = 1, Provider = 2, Client = 3, Teacher = 4 */
    documentSection: {
      type: Number,
      required: false
    }
  },

  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    },

    fk_carePlanID() {
      this.pullCarePlanItems(this.fk_carePlanID);
    }
  },
  data() {
    return {
      menu: false,
      files: [],
      upload: false,
      rules: {
        type: [v => !!v || "Type is required"],
        files: [
          //v => !!v || "File is required",
          v => (v && v.length > 0) || "File is required"
        ]
      }
    };
  },
  methods: {
    initialize() {},

    async newEntry() {
      let data;
      /* If Provider section */
      if (this.documentSection === 2) {
        data = {
          f_type: 1
        };
      } else if (this.documentSection === 3) {
        /* If Client section */
        data = {
          f_type: 2
        };
      } else if (this.documentSection === 4) {
        /* If Teacher section */
        data = {
          f_type: 3
        };
      }
      await this.$store.dispatch("documentTypesStore/valueList", data);

      /* Provider Section */
      if (this.documentSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_providerID = provider.id;
        this.fk_teacherID = "";
        this.f_privateRecord = 0;
      } else if (this.documentSection === 3) {
        /* Client Section */
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_clientID = client.id;
        this.fk_householdID = client.fk_householdID;
        this.fk_providerID = "";
        this.fk_teacherID = "";
        this.pullCarePlans(this.fk_clientID);
        const data2 = {
          fk_providerID: "",
          fk_householdID: client.fk_householdID
        };
        await this.$store.dispatch("clientsStore/valueList", data2);
        if (!client.f_privateRecord || client.f_privateRecord == "") {
          this.f_privateRecord = 0;
        } else {
          this.f_privateRecord = client.f_privateRecord;
        }
      } else if (this.documentSection === 4) {
        await this.$store.dispatch("teachersStore/valueList");
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_providerID = "";
        this.fk_teacherID = teacher.id;
        this.f_privateRecord = 0;
      }
      this.valid = true;
      this.section = this.documentSection;
      this.mode = 1;
      this.id = "";
      this.d_path = "";
      this.d_type = "";
      this.f_status = 1;
      this.show = 1;
    },

    openSnackbar(code) {
      let data;
      if (code === 1) {
        data = {
          color: "green lighten-1",
          text: "Document has been successfully uploaded."
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text:
            "There was an error uploading the file.  Try again and contact support if the issue continues."
        };
      }
      this.$store.commit("messagesStore/setMessage", data);
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["documentsStore/getEntry"];
        /* Create Document */
        if (this.mode === 1) {
          this.file = this.files[0];
          const formData = new FormData();
          formData.append("file", this.file);
          if (this.documentSection === 2) {
            formData.append("section", "providers");
            formData.append("id", this.fk_providerID);
          } else if (this.documentSection === 3) {
            formData.append("section", "clients");
            formData.append("id", this.fk_householdID);
          } else if (this.documentSection === 4) {
            formData.append("section", "teachers");
            formData.append("id", this.fk_teacherID);
          }
          this.upload = true;
          const response = await axios.post("/api/documents/submit", formData, {
            headers: uploadHeader()
          });
          // let response = ''
          const success = 200;
          this.upload = false;
          if (response.status === 200 || success === 200) {
            this.d_path = response.data;
            await this.$store.dispatch("documentsStore/create", entry);
            this.$refs.entryForm.reset();
            this.openSnackbar(1);
          } else {
            this.openSnackbar(2);
          }
        } else {
          await this.$store.dispatch("documentsStore/update", entry);
        }

        this.cancelEntry();
        /* Reload List */
        this.$store.dispatch("progressStore/set", true);
        const filters = this.$store.getters["documentsStore/getFilters"];
        await this.$store.dispatch("documentsStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    },

    async pullCarePlans(id) {
      this.fk_carePlanID = "";
      const userData = {
        fk_clientID: id
      };
      await this.$store.dispatch("carePlansStore/valueList", userData);
    },

    async pullCarePlanItems(id) {
      const userData = {
        fk_carePlanID: id
      };
      await this.$store.dispatch("carePlanItemsStore/valueList", userData);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
