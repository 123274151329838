import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  contacts: [],
  contact: {},
  export: [],
  entry: {
    show: '',
    valid: '',
    section: '',
    mode: '',
    id: '',
    fk_providerID: '',
    d_salutation: '',
    d_nameFirst: '',
    d_nameLast: '',
    d_suffix: '',
    d_title: '',
    d_type: '',
    j_contactMethods: [
      {
        d_detail: '',
        f_type: '',
      },
    ],
    j_education: [
      {
        d_degreeSought: '',
        d_expectedCompletion: '',
        f_completion: '',
        date3: '',
        d_type: '',
        f_status: '',
      },
    ],
    j_language: [],
    d_languageOther: '',
    j_race: [],
    d_raceOther: '',
    d_gender: '',
    d_genderOther: '',
    f_primary: 0,
    f_director: 0,
    f_adminLevel: '',
    f_status: '',
    f_show: ''
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: '',
    d_providerName: '',
    d_nameFirst: '',
    d_nameLast: '',
    d_type: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_CONTACTS(state) {
    state.contacts = [];
  },
  FETCH(state, { list }) {
    state.contacts = list;
  },
  CREATE(state, { data }) {
    state.contacts.data.unshift(data);
  },
  READ(state, { data }) {
    state.contact = data;
  },
  UPDATE(state, { data }) {
    const idx = state.contacts.data.map((item) => item.id).indexOf(data.id);
    state.contacts.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.contacts.data.map((item) => item.id).indexOf(id);
    state.contacts.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.d_salutation = data.d_salutation;
    state.entry.d_nameFirst = data.d_nameFirst;
    state.entry.d_nameLast = data.d_nameLast;
    state.entry.d_suffix = data.d_suffix;
    state.entry.d_title = data.d_title;
    state.entry.d_type = data.d_type;
    state.entry.j_contactMethods = data.j_contactMethods;
    state.entry.j_education = data.education;
    state.entry.j_language = data.j_language;
    state.entry.d_languageOther = data.d_languageOther;
    state.entry.j_race = data.j_race;
    state.entry.d_raceOther = data.d_raceOther;
    state.entry.d_gender = data.d_gender;
    state.entry.d_genderOther = data.d_genderOther;
    state.entry.f_primary = data.f_primary;
    state.entry.f_director = data.f_director;
    state.entry.f_adminLevel = data.f_adminLevel;
    state.entry.f_status = data.f_status;
    state.entry.f_show = '';
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.section = '';
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.d_salutation = '';
    state.entry.d_nameFirst = '';
    state.entry.d_nameLast = '';
    state.entry.d_suffix = '';
    state.entry.d_title = '';
    state.entry.d_type = '';
    state.entry.j_contactMethods = [{
      d_detail: '',
      f_type: '',
    }];
    state.entry.j_education = [{
      d_degreeSought: '',
      d_expectedCompletion: '',
      f_completion: 0,
      date3: '',
      d_enrolled: '',
      d_type: '',
      f_status: 1,
      f_show: 1
    }];
    state.entry.j_language = [];
    state.entry.d_languageOther = '';
    state.entry.j_race = [];
    state.entry.d_raceOther = '';
    state.entry.d_gender = '';
    state.entry.d_genderOther = '';
    state.entry.f_primary = 0;
    state.entry.f_director = 0;
    state.entry.f_adminLevel = '';
    state.entry.f_status = '';
    state.entry.f_show = '';
  },
  ADD_CONTACT_METHOD(state) {
    const data = {
      d_detail: '',
      f_type: '',
    };
    state.entry.j_contactMethods.push(data);
  },
  ADD_EDUCATION(state) {
    const data = {
      id:"",
      d_degreeSought: "",
      d_expectedCompletion: "",
      f_completion: 0,
      date3: "",
      d_type: "",
      f_status: 1,
      f_show: 1
    };
    state.entry.j_education.push(data);
  },
  REMOVE_CONTACT_METHOD(state, { id }) {
    state.entry.j_contactMethods.splice(id, 1);
  },
  REMOVE_EDUCATION(state, { id }) {
    state.entry.j_education[id].f_show = 0;
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearContacts: ({ commit }) => {
    commit('CLEAR_CONTACTS');
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/contacts', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/contacts/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/contacts/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/contacts/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/contacts/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/contacts/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post('/api/contacts', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addContactMethod({ commit }) {
    commit('ADD_CONTACT_METHOD');
  },
  removeContactMethod({ commit }, id) {
    commit('REMOVE_CONTACT_METHOD', { id });
  },
  addEducation({ commit }) {
    commit('ADD_EDUCATION');
  },
  removeEducation({ commit }, id) {
    commit('REMOVE_EDUCATION', { id });
  },
};

const getters = {
  getContact: (state, getters) => state.contact,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
