import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => {
  return {
    templates: [],
    template: {},
    templatesValueList: [],
    entry: {
      show: '',
      valid: '',
      section: '',
      mode: '',
      id: '',
      d_name: '',
      d_subject: '',
      d_body: '',
      d_language: '',
      f_selectorCarePlans: '',
      f_selectorCarePlanOptions: '',
      f_selectorProviderContacts: '',
      f_section: '',
      f_letterhead: '',
      mergeCodeSectionID: '',
      f_type: '',
      f_status: '',
    },
    filters: {
      page: '',
      d_name: '',
      f_type: '',
      f_section: '',
      d_language: '',
    },
  }
}

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.templates = list;
  },
  CREATE(state, { data }) {
    state.templates.data.unshift(data);
  },
  READ(state, { data }) {
    state.template = data;
  },
  UPDATE(state, { data }) {
    const idx = state.templates.data.map((item) => item.id).indexOf(data.id);
    state.templates.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.templates.data.map((item) => item.id).indexOf(id);
    state.templates.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_name = data.d_name;
    state.entry.d_subject = data.d_subject;
    state.entry.d_body = data.d_body;
    state.entry.d_language = data.d_language;
    state.entry.f_selectorCarePlans = data.f_selectorCarePlans;
    state.entry.f_selectorCarePlanOptions = data.f_selectorCarePlanOptions;
    state.entry.f_selectorProviderContacts = data.f_selectorProviderContacts;
    state.entry.f_section = data.f_section;
    state.entry.f_letterhead = data.f_letterhead;
    state.entry.mergeCodeSectionID = data.mergeCodeSectionID;
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  DUPLICATE(state, { data }) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.d_name = '(COPY) ' + data.d_name;
    state.entry.d_subject = '(COPY) ' + data.d_subject;
    state.entry.d_body = data.d_body;
    state.entry.d_language = data.d_language;
    state.entry.f_selectorCarePlans = data.f_selectorCarePlans;
    state.entry.f_selectorCarePlanOptions = data.f_selectorCarePlanOptions;
    state.entry.f_selectorProviderContacts = data.f_selectorProviderContacts;
    state.entry.f_section = data.f_section;
    state.entry.f_letterhead = data.f_letterhead;
    state.entry.mergeCodeSectionID = data.mergeCodeSectionID;
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  FETCH_VALUE_LIST(state, { list }) {
    state.templatesValueList = list;
  },
  CLEAR_VALUE_LIST(state) {
    state.templatesValueList = [];
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearValueList: ({ commit }) => {
    commit('CLEAR_VALUE_LIST');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/templates', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/templates/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/templates/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/templates/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/templates/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/templates/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async duplicate({ commit }, id) {
    try {
      const response = await axios.get(`/api/templates/${id}`, { headers: getHeader() });
      commit('DUPLICATE', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/templates/valuelist', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getTemplatesValueList: (state, getters) => state.templatesValueList,
  getEntry: (state, getters) => state.entry,
  getTemplate: (state, getters) => state.template,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
