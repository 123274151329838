<template>
  <div>
    <v-tooltip v-if="benefitSection === 2" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-heartbeat
          </v-icon>
        </v-btn>
      </template>
      <span>Add Employee Benefits</span>
    </v-tooltip>

    <!--benefits Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="800">
      <v-card flat class="white" height="550">
        <v-card-title class="text-h5 secondary--text"
          >Benefits <span v-if="isDuplicate">&nbsp;(Duplicate)</span><v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Insurance Details</v-tab>
                <v-tab>Paid Non-teaching Days</v-tab>
                <v-tab>Benefits' Start</v-tab>
                <v-tab>Additional Benefits</v-tab>
                <!--Insurance Details-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--administrator-->
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_admin"
                              label="Benefits Administrator"
                              placeholder=" "
                              persistent-placeholder
                              required
                            >
                            </v-text-field>
                          </v-col>
                          <!--Effective Date-->
                          <v-col cols="12" sm="12" md="4" offset-md="2">
                            <v-select
                              :items="yearsStore.yearsValueList"
                              item-text="d_year"
                              item-value="d_year"
                              label="School Year"
                              placeholder=" "
                              persistent-placeholder
                              v-model="d_schoolYear"
                              clearable
                              clear-icon="fal fa-times-circle"
                              :rules="rules.date"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--administrator position-->
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_position"
                              label="Administrator Position"
                              placeholder=" "
                              persistent-placeholder
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!--Insurance-->
                          <v-col cols="12" sm="12">
                            Insurance (Employee Only)
                            <v-card flat :key="index" v-for="(item, index) in j_insurance">
                              <v-container fluid>
                                <v-row dense>
                                  <v-autocomplete
                                    :items="valueListsStore.insuranceCoverage"
                                    item-text="text"
                                    item-value="value"
                                    :label="item.d_type"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model= "item.d_value"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                  />
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Add Dependents-->
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Employees Add Dependents to their Benefits?"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_addDependents"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Additional Insurance Information-->
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              label="Additional Insurance Information"
                              placeholder=" "
                              persistent-placeholder
                              v-model="d_noteInsurance"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Paid Non-teaching Days-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-card flat :key="index" v-for="(item, index) in j_paidTimeOff">
                              <v-container fluid>
                                <v-row dense>
                                  <v-autocomplete
                                    :items="valueListsStore.nonTeachingDays"
                                    item-text="text"
                                    item-value="value"
                                    :label="item.d_type"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model= "item.d_value"
                                    @change="clearSickDaysforDependants(index, item.d_value)"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                  />
                                </v-row>

                                <v-row v-if="index === 0">
                                  <!--Can Paid Sick Days be Use for Dependant Care-->
                                  <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete
                                      :items="valueListsStore.yesNoNa"
                                      label="Can paid sick days be used to care for dependents?"
                                      placeholder=" "
                                      persistent-placeholder
                                      item-text="value"
                                      item-value="id"
                                      v-model="f_sickDaysUsedForDependantCare"
                                      :disabled="item.d_value == null || item.d_value == 'Not Offered'"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Benefits' Start-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-card flat :key="index" v-for="(item, index) in j_effectiveInterval">
                              <v-container fluid>
                                <v-row dense>
                                  <v-autocomplete
                                    :items="valueListsStore.benefitsStart"
                                    item-text="text"
                                    item-value="value"
                                    :label="item.d_type"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model= "item.d_value"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                  />
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Additional Benefits-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="12" class="px-0">
                            Additional Benefits
                            <v-radio-group dense row class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[38]"
                                v-model="j_benefitsAdditional"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Contribution Dependent on Employee Contributions-->
                          <v-col cols="12" sm="12" md="10">
                            <v-autocomplete
                              :items="valueListsStore.yesNoNa"
                              label="Employer Retirement Contribution Dependent on Employee Contributions?"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_employeeRetirementContributions"
                            />
                          </v-col>
                        </v-row>

                        <!--Other Benefits-->
                        <v-row dense>
                          Other Benefits
                          <v-spacer />
                          <v-btn
                            rounded
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="addBenefitOther()"
                          >
                            <v-icon small left>fal fa-plus</v-icon>New</v-btn
                          >
                          <v-col cols="12" sm="12" v-if="j_benefitsOther">
                            <v-card flat :key="index" v-for="(item, index) in j_benefitsOther">
                              <v-container fluid class="pa-0">
                                <v-row row>
                                  <v-col cols="12" sm="12" md="11">
                                    <v-text-field
                                      v-model="item.d_value"
                                      placeholder=" "
                                      persistent-placeholder
                                      flat
                                      hide-details
                                    />
                                  </v-col>

                                  <v-col
                                    v-if="usersStore.authUser.f_delete === 1"
                                    cols="12"
                                    sm="12"
                                    md="1"
                                  >
                                    <v-btn
                                      text
                                      icon
                                      color="red"
                                      @click="removeBenefitOther(index)"
                                    >
                                      <v-icon>fal fa-trash</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row />
                        <v-row dense>
                          <!--Notes-->
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              label="Additional Information"
                              placeholder=" "
                              persistent-placeholder
                              v-model="d_note"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { createHelpers, mapMultiRowFields} from "vuex-map-fields";
  import { mixin } from "@/mixins/mixin.js";
  import TitleBar from '@/components/TitleBar';
  import TextDisplay from "@/components/TextDisplay";

  const { mapFields } = createHelpers({
    getterType: "benefitsStore/getEntryField",
    mutationType: "benefitsStore/updateEntryField"
  });

  export default {
    name: "BenefitsEntry",
    mixins: [mixin],
    computed: {
      ...mapState({
        benefitsStore: state => state.benefitsStore,
        usersStore: state => state.usersStore,
        valueListsStore: state => state.valueListsStore,
        yearsStore: state => state.yearsStore
      }),
      ...mapFields([
        "show",
        "valid",
        "mode",
        "tab",
        "isDuplicate",
        "id",

        "fk_providerID",
        "d_admin",
        "d_position",
        "d_schoolYear",
        "f_addDependents",
        "d_noteInsurance",
        "f_sickDaysUsedForDependantCare",
        "j_benefitsAdditional",
        "f_employeeRetirementContributions",
        "d_note",
        "f_show",
      ]),
      ...mapMultiRowFields("benefitsStore", [
        "entry.j_insurance",
        "entry.j_paidTimeOff",
        "entry.j_effectiveInterval",
        "entry.j_benefitsOther"
      ])
    },
    components: {
      TitleBar,
      TextDisplay
    },
    props: {
      /* Benefits list = 1, Provider Sublist = 2 */
      benefitSection: {
        type: Number,
        required: false
      }
    },
    data() {
      return {
        rules: {
          date: [v => !!v || "Date is required (MM/DD/YYYY)"],
        },
      };
    },
    watch: {
      date1() {
        this.d_date = this.$_formatDate(this.date1);
      },
    },
    methods: {
      resetEntry() {
        this.$store.dispatch("benefitsStore/resetEntry");
        if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
      },

      async newEntry() {
        this.resetEntry();

        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
        const data = {
          id: [38]
        };
        await this.$store.dispatch("valueListsStore/items", data);
        await this.$store.dispatch("yearsStore/valueList");

        this.mode = 1;
        this.id = "";
        this.f_status = 1;
        this.tab = 0;
        this.valid = true;
        this.show = true;
      },

      cancelEntry() {
        this.tab = 0;
        this.show = false;
        this.resetEntry();
      },

      async saveEntry() {
        if (this.$refs.entryForm.validate()) {
          const entry = this.$store.getters["benefitsStore/getEntry"];
          /* Create Record */
          if (this.mode === 1) {
            await this.$store.dispatch("benefitsStore/create", entry);
          } else {
          /* Edit Record */
            await this.$store.dispatch("benefitsStore/update", entry);
          }
          this.cancelEntry();

           /* Reload List */
          this.$store.dispatch("progressStore/set", true);
          if (this.mode === 1) {
            const filters = this.$store.getters["providersStore/getFilters"];
            await this.$store.dispatch("providersStore/fetch", filters);
          } else {
            const filters = this.$store.getters["benefitsStore/getFilters"];
            await this.$store.dispatch("benefitsStore/fetch", filters);
          }
          this.$store.dispatch("progressStore/set", false);
        }
      },

      addBenefitOther() {
        this.$store.dispatch("benefitsStore/addBenefitOther");
      },

      removeBenefitOther(id) {
        this.$store.dispatch("benefitsStore/removeBenefitOther", id);
      },

      clearSickDaysforDependants(index, value) {
        // index 0 is for Sick Time off
        if (index === 0) {
          if (!value || value == "Not Offered") {
          this.f_sickDaysUsedForDependantCare = null;
          }
        }
      }
    }
  };
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>

