<template>
  <div>
    <v-tooltip v-if="section === 3" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Report</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="900">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Report Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Sort-->
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    v-model="d_sort"
                    label="Sort"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Icon-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_icon"
                    label="Icon"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Icon Color-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_iconColor"
                    label="Icon Color"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Note-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="d_note"
                    label="Note"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "reportsStore/getEntryField",
  mutationType: "reportsStore/updateEntryField"
});

export default {
  name: "ReportEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      reportsStore: state => state.reportsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "d_name",
      "d_note",
      "d_sort",
      "d_icon",
      "d_iconColor",
      "f_status",
      "f_show"
    ])
  },
  props: {
    /* All List = 1, Dashboard = 2, Settings */
    section: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: false,
      rules: {
        d_name: [v => !!v || "Status is required"],
        f_status: [v => !!v || "Name is required"]
      }
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("reportsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();
      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["reportsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("reportsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("reportsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
