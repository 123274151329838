<template>
  <div>
    <v-tooltip v-if="noteSection === 2 || noteSection === 3 || noteSection === 4 || noteSection === 5" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-comment
          </v-icon>
        </v-btn>
      </template>
      <span>Add Note</span>
    </v-tooltip>

    <!--Note Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Note Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Date-->
                <v-col cols="6" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_date"
                        label="Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date"
                        @blur="date1 = $_parseDate(d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Pin Note-->
                <v-col cols="3" offset="3" align="right">
                  <v-btn medium icon @click="f_pin = f_pin ? 0 : 1">
                    <v-icon v-if="f_pin" color="yellow">
                      fa-regular fa-thumbtack
                    </v-icon>
                    <v-icon v-else color="grey lighten-1">
                      fa-solid fa-thumbtack
                    </v-icon>
                  </v-btn>
                  <span v-if="f_pin">Pinned</span>
                  <span v-else>Unpinned</span>
                </v-col>

                <!--Client-->
                <v-col cols="12" sm="12" v-if="!fk_providerID && !fk_teacherID && !fk_waitingListID">
                  <v-select
                    :items="clientsStore.clientsValueList"
                    item-text="d_nameDisplay"
                    item-value="id"
                    label="Client"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_clientID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="noteTopicsStore.noteTopicsValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    :rules="rules.type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Subject-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_subject"
                    label="Subject"
                    :rules="rules.subject"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Note-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="d_note"
                    label="Note"
                    :rules="rules.note"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Author-->
                <v-col v-if="author" cols="12" sm="12" md="6">
                  <text-display :displayLabel="'Author'" :displayString="author.name" />
                </v-col>

                <!--Remove Note-->
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                    :items="[{text: 'Yes', value: '0'}]"
                    item-text="text"
                    item-value="value"
                    label="Remove Note?"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_show"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="
                  (notesStore.filters.section === 1 && client) ||
                  (notesStore.filters.section === 1 && provider) ||
                  (notesStore.filters.section === 1 && teacher) ||
                  (notesStore.filters.section === 1 && waitingListItem)
                "
                dense
              >
                <v-col cols="12" sm="12">
                  <v-btn
                    v-if="mode !== 1"
                    block
                    rounded
                    color="primary"
                    @click="
                      detail(
                        client ? 2 : fk_householdID > 0 ? 3 : provider ? 1 : teacher ? 4 : 5,

                        client ? client.uuid : fk_householdID > 0 ? fk_householdID : provider ? provider.uuid : teacher ? teacher.uuid : waitingListItem.uuid
                      )
                    "
                    ><v-icon small left>{{
                      client ? "fal fa-user-circle" :
                      provider ? "fal fa-school" :
                      teacher ? "fal fa-chalkboard-teacher" :
                      "fal fa-list-alt"
                    }}</v-icon
                    >{{
                      client ? "Go To Client" :
                      fk_householdID > 0 ? "Go To Household" :
                      provider ? "Go To Provider" :
                      teacher ? "Go To Teacher" :
                      "Go to Waiting List"
                    }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "notesStore/getEntryField",
  mutationType: "notesStore/updateEntryField"
});

export default {
  name: "NoteEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      notesStore: state => state.notesStore,
      noteTopicsStore: state => state.noteTopicsStore,
      clientsStore: state => state.clientsStore,
      teachersStore: state => state.teachersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",
      "id",
      "fk_providerID",
      "fk_householdID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "fk_userID",
      "d_date",
      "d_subject",
      "d_note",
      "f_pin",
      "f_type",
      "f_show",
      "date1",
      "author",
      "client",
      "provider",
      "teacher",
      "waitingListItem",
    ])
  },
  props: {
    /* All List = 0, Provider = 1, Client = 3, Teacher = 4, Waiting List = 5 */
    noteSection: {
      type: Number,
      required: false
    }
  },
  components: {
    TextDisplay
  },
  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      menu: false,
      rules: {
        date: [v => !!v || "Date is required (MM/DD/YYYY)"],
        type: [v => !!v || "Type selection is required"],
        subject: [v => !!v || "Subject is required"],
        note: [v => !!v || "Note description is required"]
      }
    };
  },
  methods: {
    initialize() {},

    async newEntry() {
      let data;
      /* If Provider section */
      if (this.noteSection === 2) {
        data = {
          f_type: 1
        };
      } else if (this.noteSection === 3) {
      /* If Client section */
        data = {
          f_type: 2
        };
      } else if (this.noteSection === 4) {
      /* If Teacher section */
        data = {
          f_type: 3
        };
      } else if (this.noteSection === 5) {
      /* If Waiting List section */
        data = {
          f_type: 4
        };
      }
      await this.$store.dispatch("noteTopicsStore/valueList", data);
      let users;
      users = this.$store.getters["usersStore/getUsers"];
      if (users.length === 0) {
        await this.$store.dispatch("usersStore/fetch");
      }

      /* Provider Section */
      if (this.noteSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      } else if (this.noteSection === 3) {
      /* Client Section */
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_householdID = client.fk_householdID;
        this.fk_clientID = "";
        this.fk_providerID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";

        const data3 = {
          fk_providerID: "",
          fk_householdID: client.fk_householdID
        };
        await this.$store.dispatch("clientsStore/valueList", data3);
      } else if (this.noteSection === 4) {
      /* Teacher Section */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_teacherID = teacher.id;
        this.fk_waitingListID = "";

        const data4 = {
          fk_teacherID: teacher.id
        };
        await this.$store.dispatch("teachersStore/valueList", data4);
      } else if (this.noteSection === 5) {
        /* Waiting List */
        const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = waitingListItem.id;
      }

      this.valid = true;
      this.section = this.noteSection;
      this.mode = 1;
      this.id = "";
      this.fk_userID = "";
      this.d_date = "";
      this.d_subject = "";
      this.f_pin = 0;
      this.d_note = "";
      this.f_type = "";
      this.date1 = "";
      this.author = "";
      this.client = "";
      this.provider = "";
      this.teacher = "";
      this.waitingListItem = "";
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["notesStore/getEntry"];
        /* Create Note */
        if (this.mode === 1) {
          const user = this.$store.getters["usersStore/getAuthUser"];
          this.fk_userID = user.id;
          await this.$store.dispatch("notesStore/create", entry);
        } else {
        /* Edit Note */
          await this.$store.dispatch("notesStore/update", entry);
        }
        this.cancelEntry();
        /* Reload List */
        this.$store.dispatch("progressStore/set", true);
        const filters = this.$store.getters["notesStore/getFilters"];
        await this.$store.dispatch("notesStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    },

    async detail(mode, uuid) {
      /* If provider related task */
      if (mode === 1) {
        this.$router.push({ name: "provider", params: { providerUUID: uuid } });
      } else if (mode === 4) {
      /* If teacher related task */
        this.$router.push({ name: "teacher", params: { teacherUUID: uuid } });
      } else if (mode === 5) {
      /* If waiting list related task */
        this.$router.push({ name: 'waitingListItem', params: { waitingListUUID: uuid } });
      } else if (mode === 3) {
        this.$router.push({ name: "clients", params: { householdID: uuid } });
      } else {
      /* If client related task */
        this.$router.push({ name: "client", params: { clientUUID: uuid } });
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
