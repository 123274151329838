<template>
  <div>

    <v-toolbar flat dense>

      <record-navigation
        :show="teachersStore.teachers.data && teachersStore.teachers.data.length > 0"
        :section="4"
        :currentIndex="teachersStore.currentIndex"
        :lastRecord="teachersStore.teachers.total"
        :from="teachersStore.teachers.from"
        :to="teachersStore.teachers.to"
      />

      <v-spacer />

      <v-toolbar-items>
        <!--Bookmark-->
        <bookmark :key="teachersStore.teacher.id" :bookmark-section="3" />

        <!--Add Education-->
        <teacher-education-entry/>

        <!--Add License-->
        <teacher-license-entry :license-section="1" />

        <!--Add Correspondence-->
        <correspondence-entry :corres-section="4" />

        <!--Add Action-->
        <action-entry :action-section="4" />

        <!--Add Document-->
        <document-entry :document-section="4" />

        <!--Add Note-->
        <note-entry :note-section="4" />

        <!--Add Task-->
        <task-entry :task-section="5" />

      </v-toolbar-items>
    </v-toolbar>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import RecordNavigation from '@/components/RecordNavigation';
import TaskEntry from '@/components/TaskEntry';
import NoteEntry from '@/components/NoteEntry';
import Bookmark from '@/components/Bookmark';
import DocumentEntry from '@/components/DocumentEntry';
import ActionEntry from '@/components/ActionEntry';
import EducationEntry from '@/components/EducationEntry';
import CorrespondenceEntry from '@/components/CorrespondenceEntry';
import TeacherLicenseEntry from '@/components/TeacherLicenseEntry';
import TeacherEducationEntry from '@/components/TeacherEducationEntry';

export default {
  name: 'teacherFunctionBar',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      teachersStore: (state) => state.teachersStore,
    }),
  },
  components: {
    RecordNavigation,
    TaskEntry,
    NoteEntry,
    Bookmark,
    ActionEntry,
    DocumentEntry,
    EducationEntry,
    CorrespondenceEntry,
    TeacherLicenseEntry,
    TeacherEducationEntry
  },
  methods: {

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
