import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  mergeCodes: [],
  mergeCode: {},
  mergeCodesValueList: [],
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    d_code: '',
    f_section: '',
    f_status: '',
  },
  filters: {
    page: '',
    d_code: '',
    f_section: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.mergeCodes = list;
  },
  CREATE(state, { data }) {
    state.mergeCodes.data.unshift(data);
  },
  READ(state, { data }) {
    state.mergeCode = data;
  },
  UPDATE(state, { data }) {
    const idx = state.mergeCodes.data.map((item) => item.id).indexOf(data.id);
    state.mergeCodes.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.mergeCodes.data.map((item) => item.id).indexOf(id);
    state.mergeCodes.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_code = data.d_code;
    state.entry.f_section = data.f_section;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  FETCH_VALUE_LIST(state, { list }) {
    state.mergeCodesValueList = list;
  },
  CLEAR_VALUE_LIST(state) {
    state.mergeCodesValueList = [];
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearValueList: ({ commit }) => {
    commit('CLEAR_VALUE_LIST');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/mergecodes', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/mergecodes/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/mergecodes/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/mergecodes/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/mergecodes/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/mergecodes/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/mergecodes/valuelist', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getMergeCodes: (state, getters) => state.mergeCodes,
  getMergeCodesValueList: (state, getters) => state.mergeCodesValueList,
  getEntry: (state, getters) => state.entry,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
