import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  ratings: [],
  rating: {},
  ratingsValueList: [],
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    d_name: '',
    d_facilityValue: '',
    f_type: '',
    f_status: '',
    f_show: '',
  },
  filters: {
    page: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    d_name: '',
    d_facilityValue: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.ratings = list;
  },
  CREATE(state, { data }) {
    state.ratings.data.unshift(data);
  },
  READ(state, { data }) {
    state.rating = data;
  },
  UPDATE(state, { data }) {
    const idx = state.ratings.data.map((item) => item.id).indexOf(data.id);
    state.ratings.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.ratings.data.map((item) => item.id).indexOf(id);
    state.ratings.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.d_name = data.d_name;
    state.entry.d_facilityValue = data.d_facilityValue;
    state.entry.f_type = data.f_type;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.d_name = '';
    state.entry.d_facilityValue = '';
    state.entry.f_type = '';
    state.entry.f_status = true;
    state.entry.f_show = true;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  FETCH_VALUE_LIST(state, { list }) {
    state.ratingsValueList = list;
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/ratings', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/ratings/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/ratings/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/ratings/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/ratings/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/ratings/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/ratingsvaluelist', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getRating: (state, getters) => state.rating,
  getRatingsValueList: (state, getters) => state.ratingsValueList,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
