<template>
  <div>
    <v-tooltip v-if="corresSection === 2 || corresSection === 3 || corresSection === 4 || corresSection === 5" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-mail-bulk
          </v-icon>
        </v-btn>
      </template>
      <span>Add Correspondence</span>
    </v-tooltip>

    <!--Correspondence Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1400">
      <v-card flat class="white" height="900">
        <v-card-title class="text-h5 secondary--text"
          >Correspondence Details<v-spacer /><v-btn class="mx-2" text rounded outlined @click="exportToPdf()"
            ><v-icon small left>fal fa-file-pdf fa-fw</v-icon>Print Pdf</v-btn
          ><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="4" class="px-2">
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        v-if="mode === 1"
                        :items="valueListsStore.correspondenceTypes"
                        label="Type"
                        placeholder=" "
                        persistent-placeholder
                        item-text="value"
                        item-value="id"
                        v-model="f_type"
                        @input="pullTemplates(f_type)"
                        required
                        :rules="rules.f_type"
                      />
                      <text-display
                        v-else
                        :displayLabel="'Type'"
                        :displayString="f_type === 1 ? 'Email' : 'Letter'"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-menu
                        v-if="mode === 1"
                        :close-on-content-click="false"
                        v-model="menu"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_date"
                            label="Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            :rules="rules.d_date"
                            @blur="date1 = $_parseDate(d_date)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date1"
                          @input="menu = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                      <text-display v-else :displayLabel="'Date'" :displayString="d_date" />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="f_type === 1">
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-if="mode === 1"
                        rows="2"
                        v-model="d_to"
                        label="To"
                        placeholder=" "
                        persistent-placeholder
                        required
                        @blur="d_to = $_clearSpaces(d_to)"
                        :rules="f_type !== 1 ? [] : rules.d_to"
                      />
                      <text-display v-else :displayLabel="'To'" :displayString="d_to" />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="mode === 1">
                    <v-col cols="12" sm="12">
                      <v-btn v-if="!showGeneric" text rounded outlined @click="showGeneric = true">
                        <v-icon small left>fal fa-address-book</v-icon>Apply Generic Sender
                      </v-btn>
                      <v-select
                        v-if="showGeneric"
                        :items="valueListsStore.genericEmail"
                        item-text="value"
                        item-value="value"
                        label="From (Generic Email)"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_genericFrom"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="f_type === 1">
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-if="mode === 1"
                        rows="2"
                        v-model="d_cc"
                        label="CC"
                        placeholder=" "
                        persistent-placeholder
                        @blur="d_cc = $_clearSpaces(d_cc)"
                      />
                      <text-display v-else-if="d_cc" :displayLabel="'CC'" :displayString="d_cc" />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="f_type === 1">
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-if="mode === 1"
                        rows="2"
                        v-model="d_bcc"
                        label="BCC"
                        placeholder=" "
                        persistent-placeholder
                        @blur="d_bcc = $_clearSpaces(d_bcc)"
                      />
                      <text-display
                        v-else-if="d_bcc"
                        :displayLabel="'BCC'"
                        :displayString="d_bcc"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="mode === 1 && selectorCarePlan === 1">
                    <!--Care Plans-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        v-if="carePlansStore.carePlansValueList.length > 1"
                        :items="carePlansStore.carePlansValueList"
                        item-text="dateRange"
                        item-value="id"
                        label="Care Plan Selection"
                        placeholder=" "
                        persistent-placeholder
                        v-model="selectorCarePlanID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullCarePlanData(selectorCarePlanID)"
                      />
                      <text-display
                        v-else-if="carePlansStore.carePlan"
                        :displayLabel="'Care Plan Selection'"
                        :displayString="
                          $_formatDate(carePlansStore.carePlan.d_dateStart) +
                            ' - ' +
                            $_formatDate(carePlansStore.carePlan.d_dateEnd) +
                            ' - ' +
                            carePlansStore.carePlan.provider.d_nameLicense
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="mode === 1 && selectorCarePlanID && selectorCarePlanOption === 1"
                  >
                    <!--Care Plan Options-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        v-if="carePlanItemsStore.carePlanItemsValueList.length > 1"
                        :items="carePlanItemsStore.carePlanItemsValueList"
                        item-text="dateRange"
                        item-value="id"
                        label="Care Plan Option Selection"
                        placeholder=" "
                        persistent-placeholder
                        v-model="selectorCarePlanOptionID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullCarePlanOptionData(selectorCarePlanOptionID)"
                      />
                      <text-display
                        v-else-if="selectorCarePlanID && carePlanItemsStore.carePlanItem"
                        :displayLabel="'Care Plan Option Selection'"
                        :displayString="
                          $_formatDate(carePlanItemsStore.carePlanItem.d_dateStart) +
                            ' - ' +
                            $_formatDate(carePlanItemsStore.carePlanItem.d_dateEnd)
                        "
                      />
                    </v-col>
                  </v-row>

                  <!--<v-row dense v-if="mode === 1 && selectorProviderContacts === 1">
                    <!-Provider Contacts->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="clientsStore.clientCorres.care_plans"
                        item-text="d_dateStart"
                        item-value="id"
                        label="Provider Contact Selection"
                        placeholder=" "
                        persistent-placeholder
                        v-model="selectorProviderContactsID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullCarePlanOptions(selectorProviderContactsID)"
                      />
                    </v-col>
                  </v-row> -->

                  <v-row
                    class="py-4"
                    v-if="
                      (mode === 1 &&
                        fk_templateID &&
                        selectorCarePlanID &&
                        carePlansStore.carePlansValueList &&
                        carePlansStore.carePlansValueList.length > 1) ||
                        (mode === 1 &&
                          fk_templateID &&
                          carePlanItemsStore.carePlanItemsValueList &&
                          carePlanItemsStore.carePlanItemsValueList.length > 1)
                    "
                  >
                    <v-col cols="12" sm="12">
                      <v-btn block rounded color="primary" @click="pullTemplatesData(fk_templateID)"
                        ><v-icon small left>fal fa-angle-double-left</v-icon>Merge Again<v-icon
                          small
                          right
                          >fal fa-angle-double-right</v-icon
                        ></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="8" class="px-2">
                  <v-row dense v-if="section">
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="templatesStore.templatesValueList"
                        label="Template"
                        placeholder=" "
                        persistent-placeholder
                        :item-text="getItemNameLanugage"
                        item-value="id"
                        v-model="fk_templateID"
                        @input="pullTemplatesData(fk_templateID)"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="section && f_type === 2">
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.letterheadType"
                        label="Letterhead"
                        placeholder=" "
                        persistent-placeholder
                        item-text="value"
                        item-value="id"
                        v-model="f_letterhead"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="f_type === 1">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_subject"
                        label="Subject"
                        placeholder=" "
                        persistent-placeholder
                        :readonly="mode !== 1"
                        required
                        :rules="f_type !== 1 ? [] : rules.d_subject"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-textarea
                        rows="20"
                        auto-grow
                        v-model="d_body"
                        label="Body"
                        placeholder=" "
                        persistent-placeholder
                        :readonly="mode !== 1"
                        required
                        :rules="rules.d_body"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <template v-if="mode === 1">
            <v-btn block rounded color="primary" @click="saveEntry()"
              ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";
import jsPDF from "jspdf";

const { mapFields } = createHelpers({
  getterType: "correspondenceStore/getEntryField",
  mutationType: "correspondenceStore/updateEntryField"
});

export default {
  name: "Correspondence",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      correspondenceStore: state => state.correspondenceStore,
      valueListsStore: state => state.valueListsStore,
      templatesStore: state => state.templatesStore,
      carePlansStore: state => state.carePlansStore,
      carePlanItemsStore: state => state.carePlanItemsStore,
      clientsStore: state => state.clientsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_providerID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "fk_userID",
      "from",
      "from_name",
      "d_genericFrom",
      "fk_templateID",
      "d_date",
      "d_to",
      "d_cc",
      "d_bcc",
      "d_subject",
      "d_body",
      "f_letterhead",
      "f_type",
      "date1",
      "section",
      "templateSubject",
      "templateBody",
      "selectorCarePlan",
      "selectorCarePlanID",
      "selectorCarePlanOption",
      "selectorCarePlanOptionID",
      "selectorProviderContacts",
      "selectorProviderContactsID"
    ])
  },
  props: {
    /* All List = 0, Provider = 2, Client = 3, Teacher = 4, Waiting List = 5 */
    corresSection: {
      type: Number,
      required: false
    }
  },

  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    },
    d_genericFrom() {
      if (this.d_genericFrom) {
        this.from = this.d_genericFrom;
        this.d_bcc = this.d_genericFrom;
      } else {
        const authUser = this.$store.getters["usersStore/getAuthUser"];
        this.from = authUser.email;
        if (authUser.f_bcc == 1) {
          this.d_bcc = authUser.email;
        } else {
          this.d_bcc = '';
        }
      }
    },
  },
  components: {
    TextDisplay
  },
  data() {
    return {
      menu: false,
      showGeneric: false,
      rules: {
        d_date: [v => !!v || "required"],
        d_to: [v => !!v || "required"],
        d_subject: [v => !!v || "required"],
        d_body: [v => !!v || "required"],
        f_type: [v => !!v || "required"]
      }
    };
  },
  methods: {
    getItemNameLanugage(item) {
      let lang = item.d_language ? " (" + item.d_language + ")" : "";
      return `${item.d_name}` + lang;
    },

    resetEntry() {
      this.$store.dispatch("correspondenceStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();
      await this.$store.dispatch("carePlansStore/clearCarePlan");
      await this.$store.dispatch("carePlansStore/clearValueList");
      await this.$store.dispatch("carePlanItemsStore/clearValueList");
      await this.$store.dispatch("carePlanItemsStore/clearCarePlanItem");
      /* Provider Section */
      if (this.corresSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.section = 1;
        this.f_type = 1;
        this.fk_providerID = provider.id;
        this.fk_clientID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
        this.d_to = provider.d_email;
      } else if (this.corresSection === 3) {
        /* Client Section */
        const currentClient = await this.$store.getters["clientsStore/getClient"];
        await this.$store.dispatch("clientsStore/readForCorrespondence", currentClient.id);
        const client = await this.$store.getters["clientsStore/getClientCorres"];
        if (client.care_plans.length === 1) {
          this.selectorCarePlanID = client.care_plans[0].id;
          await this.pullCarePlanData(this.selectorCarePlanID);
        } else if (client.care_plans.length > 0) {
          const data = {
            fk_clientID: client.id
          };
          await this.pullCarePlans(data);
        }
        this.section = 2;
        this.f_type = 1;
        this.fk_clientID = client.id;
        this.fk_providerID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";

        let emailTo = "";
        if (currentClient.primary_contact) {
          for (let i = 0; i < currentClient.primary_contact.length; i++) {
            if (currentClient.primary_contact[i].f_status === 1 &&
                currentClient.primary_contact[i].j_contactMethods) {
              for (
                let ii = 0;
                ii < currentClient.primary_contact[i].j_contactMethods.length;
                ii++
              ) {
                if (currentClient.primary_contact[i].j_contactMethods[ii].d_type === "Email") {
                  if (emailTo === "") {
                    emailTo += currentClient.primary_contact[i].j_contactMethods[ii].d_value;
                  } else {
                    emailTo += `;${currentClient.primary_contact[i].j_contactMethods[ii].d_value}`;
                  }
                }
              }
            }
          }
        }
        this.d_to = emailTo;
      } else if (this.corresSection === 4) {
        /* Teacher Section */
        const teacher = await this.$store.getters["teachersStore/getTeacher"];
        this.section = 3;
        this.f_type = 1;
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_teacherID = teacher.id;
        this.fk_waitingListID = "";

        let emailTo = "";
        if (teacher.j_contact) {
          for (let i = 0; i < teacher.j_contact.length; i++) {
            if (teacher.j_contact[i].d_type === "Email") {
              if (emailTo === "") {
                emailTo += teacher.j_contact[i].d_value;
              } else {
                emailTo += `;${teacher.j_contact[i].d_value}`;
              }
            }
          }
        }
        this.d_to = emailTo;
      } else if (this.corresSection === 5) {
        /* Waiting List Section */
        const waitingListItem = await this.$store.getters["waitingListStore/getWaitingListItem"];
        this.section = 4;
        this.f_type = 1;
        this.fk_waitingListID = waitingListItem.id;
        this.fk_teacherID = "";
        this.fk_providerID = "";
        this.fk_clientID = "";

        let emailTo = "";
        if (waitingListItem.counselor) {
            emailTo = waitingListItem.counselor.email;
        }
        this.d_to = emailTo;
      }

      const d = new Date();
      const nd = d.toISOString().split("T")[0];
      /* Grab tempaltes by section */
      this.$store.dispatch("templatesStore/clearValueList");
      const data = {
        f_type: this.f_type,
        f_section: this.section
      };
      this.$store.dispatch("templatesStore/valueList", data);
      const authUser = this.$store.getters["usersStore/getAuthUser"];

      if (authUser.f_bcc == 1) {
        this.d_bcc = authUser.email;
      }
      this.fk_userID = authUser.id;
      this.from = authUser.email;
      this.from_name = authUser.name;
      this.date1 = nd;
      this.mode = 1;
      this.show = true;

      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation();
      }
    },

    cancelEntry() {
      this.showGeneric = false;
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        /* Override To-address for testing only */
        //const authUser = this.$store.getters["usersStore/getAuthUser"];
        //this.d_to = authUser.email;

        const entry = this.$store.getters["correspondenceStore/getEntry"];
        /* Create Correspondence */
        if (this.mode === 1) {
          /* Email */
          if (this.f_type === 1) {
            const response = await this.$store.dispatch("correspondenceStore/email", entry);
            let message;
            if (response.status === 200) {
              message = {
                color: "success",
                text: "Email has been sent."
              };
            } else {
              message = {
                color: "error",
                text: "There is an issue with sending the email at this time."
              };
            }
            this.$store.commit("messagesStore/setMessage", message);
          }
          /* Letter */
          if (this.f_type === 2) {
            const currentDate = new Date();
            let htmlBody =
              '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/></head><body><style>.wrapper {margin:10} body {background-color: #ffffff;font-size: 9.5px;line-height: 1.5em;margin: 5,2,5,2;text-align: left;height: 100%;line-height: 1.4;padding: 0;width: 100% !important;}</style><table class="wrapper" width="100%" cellpadding="0" cellspacing="0" role="presentation"><tr><td align="center"><table class="content" width="100%" cellpadding="0" cellspacing="0" role="presentation"><tr><td class="body" width="100%" cellpadding="0" cellspacing="0"><table class="inner-body" align="center" width="530" cellpadding="0" cellspacing="0" role="presentation"><tr><td class="content-cell">';
            htmlBody += this.d_body.replaceAll(/(\r\n|\n|\r)/g, "<br />");
            htmlBody += "</td></tr></table></td></tr></table></td></tr></table></body></html>";

            const doc = new jsPDF("p", "pt", "letter");
            const filename = "DPK Correspondence.pdf";
            const letterhead = this.f_letterhead;

            doc.html(htmlBody, {
              x: 33,
              y: 130,
              margin: [10, 80, 33, 10],
              callback(doc) {
                const totalPages = doc.internal.getNumberOfPages();

                // For each page, print the page number and the total pages
                for (let p = 1; p <= totalPages; p++) {
                  doc.setPage(p);

                  /* Durham PreK Letterhead */
                  if (letterhead === 2) {
                    /* Durham PreK Header */
                    doc.addImage(require("@/assets/prek_header.jpg"), "JPEG", 30, 20, 490, 90);
                    /* Durham PreK Footer */
                    doc.addImage(require("@/assets/prek_footer.jpg"), "JPEG", 0, 700, 612, 70);
                  } else {
                    /* CCSA Letterhead */
                    /* CCSA Header */
                    doc.addImage(require("@/assets/ccsa_header.jpg"), "JPEG", 30, 20, 548, 103);
                    /* CCSA Footer */
                    doc.addImage(require("@/assets/ccsa_footer.jpg"), "JPEG", 30, 700, 555, 76);
                  }

                  /* Page Number And Printed Date */
                  doc.setFontSize(7);
                  doc.setFont("helvetica", "normal");
                  doc.setTextColor(0.0);
                  doc.text(
                    `Page ${String(p)} of ${String(totalPages)}     Printed: ${currentDate}`,
                    33,
                    778
                  );
                }

                doc.save(filename);
              }
            });
          }

          const user = this.$store.getters["usersStore/getAuthUser"];
          this.fk_userID = user.id;
          await this.$store.dispatch("correspondenceStore/create", entry);
        } else {
          /* Edit Correspondence */
          await this.$store.dispatch("correspondenceStore/update", entry);
        }
        this.cancelEntry();
      }
    },

    exportToPdf() {
      const currentDate = new Date();
      let htmlBody =
        '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/></head><body><style>.wrapper {margin:10} body {background-color: #ffffff;font-size: 9.5px;line-height: 1.5em;margin: 5,2,5,2;text-align: left;height: 100%;line-height: 1.4;padding: 0;width: 100% !important;}</style><table class="wrapper" width="100%" cellpadding="0" cellspacing="0" role="presentation"><tr><td align="center"><table class="content" width="100%" cellpadding="0" cellspacing="0" role="presentation"><tr><td class="body" width="100%" cellpadding="0" cellspacing="0"><table class="inner-body" align="center" width="530" cellpadding="0" cellspacing="0" role="presentation"><tr><td class="content-cell">';
      htmlBody += this.d_body.replaceAll(/(\r\n|\n|\r)/g, "<br />");
      htmlBody += "</td></tr></table></td></tr></table></td></tr></table></body></html>";

      const doc = new jsPDF("p", "pt", "letter");
      const filename = "DPK Correspondence.pdf";
      const letterhead = this.f_letterhead;

      doc.html(htmlBody, {
        x: 33,
        y: 130,
        margin: [10, 80, 33, 10],
        callback(doc) {
          const totalPages = doc.internal.getNumberOfPages();

          // For each page, print the page number and the total pages
          for (let p = 1; p <= totalPages; p++) {
            doc.setPage(p);

            /* Durham PreK Letterhead */
            if (letterhead === 2) {
              /* Durham PreK Header */
              doc.addImage(require("@/assets/prek_header.jpg"), "JPEG", 30, 20, 490, 90);
              /* Durham PreK Footer */
              doc.addImage(require("@/assets/prek_footer.jpg"), "JPEG", 0, 700, 612, 70);
            } else {
              /* CCSA Letterhead */
              /* CCSA Header */
              doc.addImage(require("@/assets/ccsa_header.jpg"), "JPEG", 30, 20, 548, 103);
              /* CCSA Footer */
              doc.addImage(require("@/assets/ccsa_footer.jpg"), "JPEG", 30, 700, 555, 76);
            }

            /* Page Number And Printed Date */
            doc.setFontSize(7);
            doc.setFont("helvetica", "normal");
            doc.setTextColor(0.0);
            doc.text(
              `Page ${String(p)} of ${String(totalPages)}     Printed: ${currentDate}`,
              33,
              778
            );
          }

          doc.save(filename);
        }
      });
    },

    async pullTemplates(id) {
      this.d_subject = "";
      this.d_body = "";
      this.$store.dispatch("templatesStore/clearValueList");
      this.fk_templateID = "";
      const data = {
        f_type: this.f_type,
        f_section: this.section
      };
      await this.$store.dispatch("templatesStore/valueList", data);
    },

    async pullCarePlans(data) {
      await this.$store.dispatch("carePlansStore/valueList", data);
    },

    async pullCarePlanData(id) {
      await this.$store.dispatch("carePlansStore/read", id);
      await this.pullCarePlanOptions(id);
      this.d_subject = "";
      this.d_body = "";
    },

    async pullCarePlanOptions(carePlanID) {
      await this.$store.dispatch("carePlanItemsStore/clearCarePlanItem");
      await this.$store.dispatch("carePlanItemsStore/clearValueList");
      const data = {
        fk_carePlanID: carePlanID
      };
      await this.$store.dispatch("carePlanItemsStore/valueList", data);
      const carePlanItems = await this.$store.getters["carePlanItemsStore/getValueList"];
      if (carePlanItems.length === 1) {
        this.selectorCarePlanOptionID = carePlanItems[0].id;
        await this.pullCarePlanOptionData(this.selectorCarePlanOptionID, 0);
      }
    },

    async pullCarePlanOptionData(id) {
      await this.$store.dispatch("carePlanItemsStore/read", id);
      this.d_subject = "";
      this.d_body = "";
    },

    async pullTemplatesData(id) {
      if (id) {
        this.templateBody = "";
        this.d_body = "";
        this.templateSubject = "";
        this.d_subject = "";
        let provider = "";
        let client = "";
        let teacher = "";
        let waitingListItem = "";
        let carePlan = "";
        let carePlanItem = "";
        await this.$store.dispatch("templatesStore/read", id);
        const template = await this.$store.getters["templatesStore/getTemplate"];

        /* Provider */
        if (this.section === 1) {
          provider = await this.$store.getters["providersStore/getProvider"];
        }

        /* Client */
        if (this.section === 2) {
          client = await this.$store.getters["clientsStore/getClientCorres"];
          /* If template uses care plan selector */
          if (template.f_selectorCarePlans === 1) {
            this.selectorCarePlan = 1;
            if (this.selectorCarePlanID) {
              carePlan = await this.$store.getters["carePlansStore/getCarePlan"];
            }
          }

          /* If template uses care plan option selector */
          if (template.f_selectorCarePlanOptions === 1) {
            this.selectorCarePlanOption = 1;
            carePlanItem = await this.$store.getters["carePlanItemsStore/getCarePlanItem"];
          }
        }

        /* Teacher */
        if (this.section === 3) {
          teacher = await this.$store.getters["teachersStore/getTeacher"];
        }

        /* Waiting List */
        if (this.section === 4) {
          waitingListItem = await this.$store.getters["waitingListStore/getWaitingListItem"];
        }

        /* If template uses provider contacts selector */
        if (template.f_selectorProviderContacts === 1) {
          this.selectorProviderContacts = 1;
        }

        if (template.f_letterhead) {
          this.f_letterhead = template.f_letterhead;
        } else {
          this.f_letterhead = "";
        }

        this.templateSubject = template.d_subject ? template.d_subject : "";
        let subject = this.templateSubject;
        if (subject) {
          subject = this.mergeTemplateData(subject, provider, client, teacher, waitingListItem, carePlan, carePlanItem);
        }

        this.templateBody = template.d_body;
        let body = template.d_body;
        body = this.mergeTemplateData(body, provider, client, teacher, waitingListItem, carePlan, carePlanItem);

        this.d_subject = subject;
        this.d_body = body;
      }
    },

    mergeTemplateData(rawData, provider, client, teacher, waitingListItem, carePlan, carePlanItem) {
      let mergeText = rawData;

      const authUser = this.$store.getters["usersStore/getAuthUser"];

      /* If provider correspondence */
      if (this.section === 1) {
        // const provider = this.$store.getters['providersStore/getProvider'];

        /* Provider - Details */
        mergeText = mergeText.replaceAll(
          "<<FACILITY_LICENSE_NUMBER>>",
          provider.d_licenseNumber ? provider.d_licenseNumber : "<<FACILITY_LICENSE_NUMBER>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_LICENSE_NAME>>",
          provider.d_nameLicense ? provider.d_nameLicense : "<<FACILITY_LICENSE_NAME>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_BUSINESS_NAME>>",
          provider.d_nameFederal ? provider.d_nameFederal : "<<FACILITY_BUSINESS_NAME>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_BUSINESS_ADDRESS1>>",
          provider.d_businessStreet1 ? provider.d_businessStreet1 : "<<FACILITY_BUSINESS_ADDRESS1>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_BUSINESS_ADDRESS2>>",
          provider.d_businessStreet2 ? provider.d_businessStreet2 : "<<FACILITY_BUSINESS_ADDRESS2>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_BUSINESS_CITY>>",
          provider.d_businessCity ? provider.d_businessCity : "<<FACILITY_BUSINESS_CITY>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_BUSINESS_STATE>>",
          provider.d_businessState ? provider.d_businessState : "<<FACILITY_BUSINESS_STATE>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_BUSINESS_ZIP>>",
          provider.d_businessPostalCode
            ? provider.d_businessPostalCode
            : "<<FACILITY_BUSINESS_ZIP>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_COUNTY>>",
          provider.county ? provider.county.d_name : "<<FACILITY_COUNTY>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_LOCATION_ADDRESS1>>",
          provider.d_facilityStreet1 ? provider.d_facilityStreet1 : "<<FACILITY_LOCATION_ADDRESS1>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_LOCATION_ADDRESS2>>",
          provider.d_facilityStreet2 ? provider.d_facilityStreet2 : "<<FACILITY_LOCATION_ADDRESS2>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_LOCATION_CITY>>",
          provider.d_facilityCity ? provider.d_facilityCity : "<<FACILITY_LOCATION_CITY>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_LOCATION_STATE>>",
          provider.d_facilityState ? provider.d_facilityState : "<<FACILITY_LOCATION_STATE>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_LOCATION_ZIP>>",
          provider.d_facilityPostalCode
            ? provider.d_facilityPostalCode
            : "<<FACILITY_LOCATION_ZIP>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_EMAIL_ADDRESS>>",
          provider.d_email ? provider.d_email : "<<FACILITY_EMAIL_ADDRESS>>"
        );

        /* Provider - Contacts */
        mergeText = mergeText.replaceAll(
          "<<FACILITY_PRIMARY_CONTACT_NAME_FIRST>>",
          provider.primary
            ? provider.primary.d_nameFirst
            : "<<FACILITY_PRIMARY_CONTACT_NAME_FIRST>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_PRIMARY_CONTACT_NAME_LAST>>",
          provider.primary
            ? provider.primary.d_nameLast
            : "<<FACILITY_PRIMARY_CONTACT_NAME_LAST>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_PRIMARY_CONTACT_NAME_FULL>>",
          provider.primary
            ? provider.primary.d_nameFirst + " " + provider.primary.d_nameLast
            : "<<FACILITY_PRIMARY_CONTACT_NAME_FULL>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_PRIMARY_CONTACT_TITLE>>",
          provider.primary.d_title
            ? provider.primary.d_title
            : "<<FACILITY_PRIMARY_CONTACT_TITLE>>"
        );
        /* Primary Contact method */
        let primaryPhone = "";
        let primaryMobile = "";
        let primaryEmail = "";
        for (let method of provider.primary.j_contactMethods) {
          if (method.f_type == 1) {
            primaryPhone = method.d_detail;
          }
          if (method.f_type == 2) {
            primaryMobile = method.d_detail;
          }
          if (method.f_type == 3) {
            primaryEmail = method.d_detail;
          }
        }
        mergeText = mergeText.replaceAll(
          "<<FACILITY_PRIMARY_CONTACT_PHONE>>",
          primaryPhone ? primaryPhone
            : primaryMobile ? primaryMobile
            : "<<FACILITY_PRIMARY_CONTACT_PHONE>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_PRIMARY_CONTACT_EMAIL>>",
          primaryEmail
            ? primaryEmail
            : "<<FACILITY_PRIMARY_CONTACT_EMAIL>>"
        );

        //All Contacts
        let contactInfo = "";
        if (provider.contacts) {
          for (let contact of provider.contacts) {
            if (contact.d_nameFirst && contact.f_status) {
              var n = contact.d_nameFirst + " " + contact.d_nameLast;
              var t = contact.d_title ? ": " + contact.d_title : ": Title Unlisted";
              contactInfo = contactInfo + n + t + "\n";
              for (let method of contact.j_contactMethods) {
                if (method.f_type) {
                  var methodType = this.valueListsStore.contactMethodTypes[method.f_type-1].value
                  contactInfo = contactInfo + " -- " + methodType + ": " + method.d_detail + "\n";
                }
              }
            }
          }
        }
        mergeText = mergeText.replaceAll(
          "<<FACILITY_CONTACT_FULL_LIST>>",
          contactInfo ? contactInfo : "<<FACILITY_CONTACT_FULL_LIST>>"
        );

        /* Provider - Tuition */
        mergeText = mergeText.replaceAll("<<FACILITY_TUITION_LIST>>", "");
        mergeText = mergeText.replaceAll(
          "<<FACILITY_CHARGES_OVERAGE>>",
          provider.f_overage
            ? provider.f_overage === 1
              ? "Yes"
              : "No"
            : "<<FACILITY_CHARGES_OVERAGE>>"
        );
        mergeText = mergeText.replaceAll(
          "<<FACILITY_OVERAGE_TYPE>>",
          provider.d_overageType ? provider.d_overageType : "<<FACILITY_OVERAGE_TYPE>>"
        );

        //* *****Selected Contact
        mergeText = mergeText.replaceAll("<<FACILITY_CONTACT_NAME_FIRST>>", "");
        mergeText = mergeText.replaceAll("<<FACILITY_CONTACT_NAME_LAST>>", "");
        mergeText = mergeText.replaceAll("<<FACILITY_CONTACT_NAME_FULL>>", "");
      }

      /* If client correspondence */
      if (this.section === 2) {
        // const client = this.$store.getters['clientsStore/getClientCorres'];

        let spacer = "";
        /* Client - Details */
        mergeText = mergeText.replaceAll(
          "<<APPLICANT_NAME_FIRST>>",
          client.primary_applicant.d_nameFirst
        );
        mergeText = mergeText.replaceAll(
          "<<APPLICANT_NAME_LAST>>",
          client.primary_applicant.d_nameLast
        );
        if (client.primary_applicant.d_nameFirst && client.primary_applicant.d_nameLast) {
          spacer = " ";
        }
        mergeText = mergeText.replaceAll(
          "<<APPLICANT_NAME_FULL>>",
          client.primary_applicant.d_nameFirst + spacer + client.primary_applicant.d_nameLast
        );
        mergeText = mergeText.replaceAll("<<CLIENT_NAME_FIRST>>", client.d_nameFirst);
        mergeText = mergeText.replaceAll("<<CLIENT_NAME_LAST>>", client.d_nameLast);
        spacer = "";
        if (client.d_nameFirst && client.d_nameLast) {
          spacer = " ";
        }
        mergeText = mergeText.replaceAll(
          "<<CLIENT_NAME_FULL>>",
          client.d_nameFirst + spacer + client.d_nameLast
        );
        mergeText = mergeText.replaceAll("<<CLIENT_DOB>>", this.$_formatDate(client.d_dateBirth));

        /* Client - Household */
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_MAILING_ADDRESS1>>",
          client.household.d_mailingStreet1
            ? client.household.d_mailingStreet1
            : "<<HOUSEHOLD_MAILING_ADDRESS1>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_MAILING_ADDRESS2>>",
          client.household.d_mailingStreet2
            ? client.household.d_mailingStreet2
            : "<<HOUSEHOLD_MAILING_ADDRESS2>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_MAILING_CITY>>",
          client.household.d_mailingCity
            ? client.household.d_mailingCity
            : "<<HOUSEHOLD_MAILING_CITY>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_MAILING_STATE>>",
          client.household.d_mailingState
            ? client.household.d_mailingState
            : "<<HOUSEHOLD_MAILING_STATE>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_MAILING_ZIP>>",
          client.household.d_mailingPostalCode
            ? client.household.d_mailingPostalCode
            : "<<HOUSEHOLD_MAILING_ZIP>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_MAILING_COUNTY>>",
          client.household.county_mailing
            ? client.household.county_mailing.d_name
            : "<<HOUSEHOLD_MAILING_COUNTY>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_RESIDENCE_ADDRESS1>>",
          client.household.d_residenceStreet1
            ? client.household.d_residenceStreet1
            : "<<HOUSEHOLD_RESIDENCE_ADDRESS1>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_RESIDENCE_ADDRESS2>>",
          client.household.d_residenceStreet2
            ? client.household.d_residenceStreet2
            : "<<HOUSEHOLD_RESIDENCE_ADDRESS2>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_RESIDENCE_CITY>>",
          client.household.d_residenceCity
            ? client.household.d_residenceCity
            : "<<HOUSEHOLD_RESIDENCE_CITY>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_RESIDENCE_STATE>>",
          client.household.d_residenceState
            ? client.household.d_residenceState
            : "<<HOUSEHOLD_RESIDENCE_STATE>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_RESIDENCE_ZIP>>",
          client.household.d_residencePostalCode
            ? client.household.d_residencePostalCode
            : "<<HOUSEHOLD_RESIDENCE_ZIP>>"
        );
        mergeText = mergeText.replaceAll(
          "<<HOUSEHOLD_RESIDENCE_COUNTY>>",
          client.household.county_residence
            ? client.household.county_residence.d_name
            : "<<HOUSEHOLD_RESIDENCE_COUNTY>>"
        );

        /* Client - Care Plan */
        if (this.selectorCarePlan === 1 && this.selectorCarePlanID) {
          // const carePlan = this.$store.getters['carePlansStore/getCarePlan'];
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_CERTIFICATION_DATE_START>>",
            carePlan.d_dateStart
              ? this.$_formatDate(carePlan.d_dateStart)
              : "<<CARE_PLAN_CERTIFICATION_DATE_START>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_CERTIFICATION_DATE_END>>",
            carePlan.d_dateEnd
              ? this.$_formatDate(carePlan.d_dateEnd)
              : "<<CARE_PLAN_CERTIFICATION_DATE_END>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_CLASSROOM>>",
            carePlan.classroom ? carePlan.classroom.d_name : "<<CARE_PLAN_CLASSROOM>>"
          );

          /* Provider - Details */
          mergeText = mergeText.replaceAll(
            "<<FACILITY_LICENSE_NUMBER>>",
            carePlan.provider.d_licenseNumber
              ? carePlan.provider.d_licenseNumber
              : "<<FACILITY_LICENSE_NUMBER>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_LICENSE_NAME>>",
            carePlan.provider.d_nameLicense
              ? carePlan.provider.d_nameLicense
              : "<<FACILITY_LICENSE_NAME>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_BUSINESS_NAME>>",
            carePlan.provider.d_nameFederal
              ? carePlan.provider.d_nameFederal
              : "<<FACILITY_BUSINESS_NAME>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_BUSINESS_ADDRESS1>>",
            carePlan.provider.d_businessStreet1
              ? carePlan.provider.d_businessStreet1
              : "<<FACILITY_BUSINESS_ADDRESS1>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_BUSINESS_ADDRESS2>>",
            carePlan.provider.d_businessStreet2
              ? carePlan.provider.d_businessStreet2
              : "<<FACILITY_BUSINESS_ADDRESS2>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_BUSINESS_CITY>>",
            carePlan.provider.d_businessCity
              ? carePlan.provider.d_businessCity
              : "<<FACILITY_BUSINESS_CITY>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_BUSINESS_STATE>>",
            carePlan.provider.d_businessState
              ? carePlan.provider.d_businessState
              : "<<FACILITY_BUSINESS_STATE>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_BUSINESS_ZIP>>",
            carePlan.provider.d_businessPostalCode
              ? carePlan.provider.d_businessPostalCode
              : "<<FACILITY_BUSINESS_ZIP>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_COUNTY>>",
            carePlan.provider.county ? carePlan.provider.county.d_name : "<<FACILITY_COUNTY>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_LOCATION_ADDRESS1>>",
            carePlan.provider.d_facilityStreet1
              ? carePlan.provider.d_facilityStreet1
              : "<<FACILITY_LOCATION_ADDRESS1>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_LOCATION_ADDRESS2>>",
            carePlan.provider.d_facilityStreet2
              ? carePlan.provider.d_facilityStreet2
              : "<<FACILITY_LOCATION_ADDRESS2>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_LOCATION_CITY>>",
            carePlan.provider.d_facilityCity
              ? carePlan.provider.d_facilityCity
              : "<<FACILITY_LOCATION_CITY>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_LOCATION_STATE>>",
            carePlan.provider.d_facilityState
              ? carePlan.provider.d_facilityState
              : "<<FACILITY_LOCATION_STATE>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_LOCATION_ZIP>>",
            carePlan.provider.d_facilityPostalCode
              ? carePlan.provider.d_facilityPostalCode
              : "<<FACILITY_LOCATION_ZIP>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_EMAIL_ADDRESS>>",
            carePlan.provider.d_email ? carePlan.provider.d_email : "<<FACILITY_EMAIL_ADDRESS>>"
          );

          /* Provider - Contacts */
          mergeText = mergeText.replaceAll(
            "<<FACILITY_PRIMARY_CONTACT_NAME_FIRST>>",
            carePlan.provider.primary
              ? carePlan.provider.primary.d_nameFirst
              : "<<FACILITY_PRIMARY_CONTACT_NAME_FIRST>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_PRIMARY_CONTACT_NAME_LAST>>",
            carePlan.provider.primary
              ? carePlan.provider.primary.d_nameLast
              : "<<FACILITY_PRIMARY_CONTACT_NAME_LAST>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_PRIMARY_CONTACT_NAME_FULL>>",
            carePlan.provider.primary
              ? carePlan.provider.primary.d_nameDisplay
              : "<<FACILITY_PRIMARY_CONTACT_NAME_FULL>>"
          );

          /* Provider - Tuition */
          mergeText = mergeText.replaceAll("<<FACILITY_TUITION_LIST>>", "");
          mergeText = mergeText.replaceAll(
            "<<FACILITY_CHARGES_OVERAGE>>",
            carePlan.provider.f_overage
              ? carePlan.provider.f_overage === 1
                ? "Yes"
                : "No"
              : "<<FACILITY_CHARGES_OVERAGE>>"
          );
          mergeText = mergeText.replaceAll(
            "<<FACILITY_OVERAGE_TYPE>>",
            carePlan.provider.d_overageType
              ? carePlan.provider.d_overageType
              : "<<FACILITY_OVERAGE_TYPE>>"
          );
        }

        /* Client - Care Plan Option */
        if (this.selectorCarePlanOption === 1 && this.selectorCarePlanOptionID) {
          // const carePlanItem = this.$store.getters['carePlanItemsStore/getCarePlanOption'];
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_PARENT_FEE>>",
            carePlanItem.d_parentFee
              ? this.$_formatMoney(carePlanItem.d_parentFee)
              : "<<CARE_PLAN_OPTION_PARENT_FEE>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_DAILY_FEE>>",
            carePlanItem.d_dailyFee
              ? this.$_formatMoney(carePlanItem.d_dailyFee)
              : "<<CARE_PLAN_OPTION_DAILY_FEE>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_DATE_START>>",
            carePlanItem.d_dateStart
              ? this.$_formatDate(carePlanItem.d_dateStart)
              : "<<CARE_PLAN_OPTION_DATE_START>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_DATE_CHECK_IN>>",
            carePlanItem.d_dateEnd
              ? this.$_formatDate(carePlanItem.d_dateEnd)
              : "<<CARE_PLAN_OPTION_DATE_CHECK_IN>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_ELIGIBILITY_REASON>>",
            carePlanItem.d_eligibilityReason
              ? carePlanItem.d_eligibilityReason
              : "<<CARE_PLAN_OPTION_ELIGIBILITY_REASON>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_PROGRAM>>",
            carePlanItem.program ? carePlanItem.program.d_name : "<<CARE_PLAN_OPTION_PROGRAM>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_PROGRAM_OPTION>>",
            carePlanItem.program_option
              ? carePlanItem.program_option.d_name
              : "<<CARE_PLAN_OPTION_PROGRAM_OPTION>>"
          );
          mergeText = mergeText.replaceAll(
            "<<CARE_PLAN_OPTION_TIME>>",
            carePlanItem.time_option ? carePlanItem.time_option.d_name : "<<CARE_PLAN_OPTION_TIME>>"
          );
        }

        //* *****Selected Contact
        mergeText = mergeText.replaceAll("<<FACILITY_CONTACT_NAME_FIRST>>", "");
        mergeText = mergeText.replaceAll("<<FACILITY_CONTACT_NAME_LAST>>", "");
        mergeText = mergeText.replaceAll("<<FACILITY_CONTACT_NAME_FULL>>", "");

        /* Staff - Specialist */
        mergeText = mergeText.replaceAll("<<SPECIALIST_NAME_FIRST>>", client.counselor.d_nameFirst);
        mergeText = mergeText.replaceAll("<<SPECIALIST_NAME_LAST>>", client.counselor.d_nameLast);
        mergeText = mergeText.replaceAll("<<SPECIALIST_NAME_FULL>>", client.counselor.name);
        mergeText = mergeText.replaceAll("<<SPECIALIST_EMAIL_ADDRESS>>", client.counselor.email);
      }

      /* If teacher correspondence */
      if (this.section === 3) {
        let spacing = "  |  ";
        /* Teacher - Details */
        mergeText = mergeText.replaceAll("<<TEACHER_NAME_FIRST>>", teacher.d_nameFirst);
        mergeText = mergeText.replaceAll("<<TEACHER_NAME_LAST>>", teacher.d_nameLast);
        mergeText = mergeText.replaceAll("<<TEACHER_NAME_FULL>>", teacher.d_nameDisplay);

        /* Teacher - TEACHER */
        mergeText = mergeText.replaceAll(
          "<<TEACHER_MAILING_ADDRESS>>",
          teacher.d_streetAddress
            ? teacher.d_streetAddress
            : "<<TEACHER_MAILING_ADDRESS>>"
        );
        mergeText = mergeText.replaceAll(
          "<<TEACHER_MAILING_CITY>>",
          teacher.d_city
            ? teacher.d_city
            : "<<TEACHER_MAILING_CITY>>"
        );
        mergeText = mergeText.replaceAll(
          "<<TEACHER_MAILING_STATE>>",
          teacher.d_state
            ? teacher.d_state
            : "<<TEACHER_MAILING_STATE>>"
        );
        mergeText = mergeText.replaceAll(
          "<<TEACHER_MAILING_ZIP>>",
          teacher.d_zipCode
            ? teacher.d_zipCode
            : "<<TEACHER_MAILING_ZIP>>"
        );

        //Email List
        let emailInfo = "";
        if (teacher.j_contact) {
          for (let i = 0; i < teacher.j_contact.length; i++) {
            if (teacher.j_contact[i].d_type === "Email") {
              if (emailInfo === "") {
                emailInfo += teacher.j_contact[i].d_value + "\n";
              } else {
                emailInfo += `${teacher.j_contact[i].d_value}` + "\n";
              }
            }
          }
        }
        mergeText = mergeText.replaceAll(
          "<<TEACHER_EMAIL_ADDRESS_LIST>>",
          emailInfo ? emailInfo : "<<TEACHER_EMAIL_ADDRESS_LIST>>"
        );

        //Phone List
        let contactInfo = "";
        if (teacher.j_contact) {
          for (let i = 0; i < teacher.j_contact.length; i++) {
            if (teacher.j_contact[i].d_type !== "Email") {
              if (contactInfo === "") {
                var t = teacher.j_contact[i].d_type + ": ";
                var v = teacher.j_contact[i].d_value;
                contactInfo += t + v + "\n";
              } else {
                var t = `${teacher.j_contact[i].d_type}: `;
                var v = `${teacher.j_contact[i].d_value}`;
                contactInfo += t + v + "\n";
              }
            }
          }
        }
        mergeText = mergeText.replaceAll(
          "<<TEACHER_PHONE_LIST>>",
          contactInfo ? contactInfo : "<<TEACHER_PHONE_LIST>>"
        );

        //License Info
        if (teacher.licenses) {
          let licenseList = "";

          for (let i = 0; i < teacher.licenses.length; i++) {
            if(!this.$_dateExpired(teacher.licenses[i].d_expirationDate)) {
              mergeText = mergeText.replaceAll(
                "<<TEACHER_LICENSE_CURRENT_NUMBER>>",
                teacher.licenses[i].d_name
              );
              mergeText = mergeText.replaceAll(
                "<<TEACHER_LICENSE_CURRENT_TYPE>>",
                teacher.licenses[i].type.d_name
              );
              mergeText = mergeText.replaceAll(
                "<<DATE_TEACHER_LICENSE_CURRENT_ISSUE>>",
                this.$_formatDate(teacher.licenses[i].d_issueDate)
              );
              mergeText = mergeText.replaceAll(
                "<<DATE_TEACHER_LICENSE_CURRENT_EXPIRATION>>",
                this.$_formatDate(teacher.licenses[i].d_expirationDate)
              );
              mergeText = mergeText.replaceAll(
                "<<TEACHER_LICENSE_CURRENT_YRS_EXPERIENCE>>",
                teacher.licenses[i].d_yearsExperience + " years of experience"
              );
            }

            //Compile license list
            var num = "License Number: " + teacher.licenses[i].d_name + spacing;
            //var type = teacher.licenses[i].type.d_name;
            var issue = "Issued: " + this.$_formatDate(teacher.licenses[i].d_issueDate) + spacing;
            var expire = "Expiration: " + this.$_formatDate(teacher.licenses[i].d_expirationDate) + spacing;
            var exp = "Experience: " + teacher.licenses[i].d_yearsExperience + " years";

            licenseList += num + issue + expire + exp + "\n";
          }
          mergeText = mergeText.replaceAll("<<TEACHER_LICENSE_FULL_LIST>>", licenseList);
        }
      }

      /* If waiting list correspondence */
      if (this.section === 4) {
        console.log('Correspondence Section Does not have Waiting List Specific Merge Codes.')
      }

      /* Staff */
      mergeText = mergeText.replaceAll("<<LOGIN_USER_NAME_FIRST>>", authUser.d_nameFirst);
      mergeText = mergeText.replaceAll("<<LOGIN_USER_NAME_LAST>>", authUser.d_nameLast);
      mergeText = mergeText.replaceAll("<<LOGIN_USER_NAME_FULL>>", authUser.name);
      mergeText = mergeText.replaceAll("<<LOGIN_USER_EMAIL_ADDRESS>>", authUser.email);

      /* Organization */
      mergeText = mergeText.replaceAll(
        "<<ORGANIZATION_NAME>>",
        "Child Care Services Association - Child Care Scholarship Program/Durham PreK"
      );
      mergeText = mergeText.replaceAll("<<ORGANIZATION_ADDRESS1>>", "1201 S. Briggs Ave.");
      mergeText = mergeText.replaceAll("<<ORGANIZATION_ADDRESS2>>", "Suite 200");
      mergeText = mergeText.replaceAll("<<ORGANIZATION_CITY>>", "Durham");
      mergeText = mergeText.replaceAll("<<ORGANIZATION_STATE>>", "NC");
      mergeText = mergeText.replaceAll("<<ORGANIZATION_ZIP>>", "27703");
      mergeText = mergeText.replaceAll("<<ORGANIZATION_PHONE>>", "919-403-6950");
      mergeText = mergeText.replaceAll("<<ORGANIZATION_EMAIL>>", "info@test.com");

      /* Other Information BG 6/2/2021*/
      mergeText = mergeText.replaceAll("<<DATE_TODAY>>", this.$_getCurrentDate());
      //BG 6/28/2021
      mergeText = mergeText.replaceAll("<<DASH>>", " - ");
      mergeText = mergeText.replaceAll("<<COMMA>>", ",");

      /*find and replace any part of the string beginning with << and ending with >>
      containing all caps in the middle characters BG 6/25/2021*/
      let prog_code = /<{2}[A-Z0-9_]+>{2}/g;
      mergeText = mergeText.replaceAll(prog_code, "");

      return mergeText;
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
