import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  payments: [],
  export: [],
  entry: {
    show: false,
    show2: false,
    valid: '',
    mode: '',
    id: '',
    tab: '',
    isDuplicate: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_providerID: '',
    fk_classroomID: '',
    fk_householdID: '',
    fk_applicantID: '',
    fk_clientID: '',
    fk_carePlanItemID: '',
    fk_attendanceItemID: '',
    fk_programID: '',
    fk_programOptionID: '',
    fk_fundingSourceID_Contract: '',
    fk_fundingSourceID_Payment: '',
    d_fundingSource_Contract: '',
    d_fundingSource_Payment: '',
    d_program: '',
    d_programOption: '',
    d_providerNameLicense: '',
    d_providerNameFederal: '',
    d_providerLicenseNumber: '',
    d_providerRating: '',
    j_providerPreKSetting: '',
    d_providerType: '',
    d_providerCounty: '',
    d_classroom: '',
    f_classroomBK: '',
    d_classroomTeacherName: '',
    d_primaryApplicantNameFirst: '',
    d_primaryApplicantNameLast: '',
    d_primaryApplicantDateBirth: '',
    d_prekSeatID: '',
    d_clientNameFirst: '',
    d_clientNameLast: '',
    d_clientDateBirth: '',
    d_clientAge: '',
    d_childNumber: '',
    d_language: '',
    d_additionalInfoSpecialNeed: '',
    d_applicantCounty: '',
    d_carePlanCertificationDateStart: '',
    d_carePlanCertificationDateEnd: '',
    d_carePlanItemDateStart: '',
    d_carePlanItemDateEligibilityCheck: '',
    d_carePlanItemFPL: '',
    d_carePlanItemSMI: '',
    d_carePlanItemParentFee: '',
    d_carePlanItemFamilySize: '',
    d_carePlanItemTime: '',
    d_carePlanItemEligibilityReason: '',
    f_attendanceMonth: '',
    d_attendanceYear: '',
    d_attendanceDaysPresent: '',
    d_attendanceDaysAbsent: '',
    d_attendanceDaysClosed: '',
    d_attendanceDaysTotal: '',
    d_attendanceDaysProrated: '',
    d_attendanceDaysProratedOverride: '',
    d_noteInternal: '',
    d_noteExternal: '',
    d_CCDFTANF: '',
    d_fundingCodeSource: '',
    d_fundingCodeSuffix: '',
    d_fundingCode: '',
    f_month: '',
    d_year: '',
    d_amountTuition: '',
    d_amountRate: '',
    d_amountInitialReimbursement: '',
    d_amountReimbursement: '',
    d_amountParentFee: '',
    d_amountIncome: '',
    d_amountOverride: '',
    d_timePercentage: '',
    d_amountProrated: '',
    d_amount: '',
    f_followup: '',
    f_status: '',
    f_type: '',
    f_show: '',
    f_placeholder: '',
    noFollowup: '',
    date1: '',
    date2: '',
    date3: '',
    date4: '',
    date5: '',
    date6: '',
    created_at: '',
    updated_at: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    id: '',
    fk_providerID: '',
    fk_householdID: '',
    d_licenseNumber: '',
    d_nameLicense: '',
    fk_clientID: '',
    d_childIDv1: '',
    d_prekSeatID: '',
    fk_programID: '',
    fk_programOptionID: '',
    d_nameFirst: '',
    d_nameLast: '',
    f_month: '',
    f_attendanceMonth: '',
    d_attendanceYear: '',
    d_fundingCodeSource: '',
    d_year: '',
    f_followup: '',
    f_status: '',
    noFollowup: '',
    selectedID: [],
    d_dateStart: '',
    d_dateEnd: '',
    date1: '',
    date2: '',
    f_type: '',
    clientID: '',
    f_viewPrivateRecords: '',
    dateStart: '',
    dateEnd: '',
    date3: '',
    date4: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_PAYMENTS(state) {
    state.payments = [];
  },
  CLEAR_EXPORT(state) {
    state.export = [];
  },
  FETCH(state, { list }) {
    state.payments = list;
  },
  CREATE(state, { data }) {
    state.payments.data.push(data);
  },
  UPDATE(state, { data }) {
    const idx = state.payments.data.map((item) => item.id).indexOf(data.id);
    const { header } = state.payments.data[idx];
    data.header = header;
    state.payments.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.payments.data.map((item) => item.id).indexOf(id);
    state.payments.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_classroomID = data.fk_classroomID;
    state.entry.fk_householdID = data.fk_householdID;
    state.entry.fk_applicantID = data.fk_applicantID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.fk_carePlanItemID = data.fk_carePlanItemID;
    state.entry.fk_attendanceItemID = data.fk_attendanceItemID;
    state.entry.fk_programID = data.fk_programID;
    state.entry.fk_programOptionID = data.fk_programOptionID;
    state.entry.fk_fundingSourceID_Contract = data.fk_fundingSourceID_Contract;
    state.entry.fk_fundingSourceID_Payment = data.fk_fundingSourceID_Payment;
    state.entry.d_fundingSource_Contract = data.d_fundingSource_Contract;
    state.entry.d_fundingSource_Payment = data.d_fundingSource_Payment;
    state.entry.d_program = data.d_program;
    state.entry.d_programOption = data.d_programOption;
    state.entry.d_providerNameLicense = data.d_providerNameLicense;
    state.entry.d_providerNameFederal = data.d_providerNameFederal;
    state.entry.d_providerLicenseNumber = data.d_providerLicenseNumber;
    state.entry.d_providerRating = data.d_providerRating;
    state.entry.d_providerType = data.d_providerType;
    state.entry.d_providerCounty = data.d_providerCounty;
    state.entry.d_classroom = data.d_classroom;
    state.entry.f_classroomBK = data.f_classroomBK;
    state.entry.d_classroomTeacherName = data.d_classroomTeacherName;
    state.entry.d_primaryApplicantNameFirst = data.d_primaryApplicantNameFirst;
    state.entry.d_primaryApplicantNameLast = data.d_primaryApplicantNameLast;
    state.entry.date2 = data.d_primaryApplicantDateBirth;
    state.entry.d_prekSeatID = data.d_prekSeatID;
    state.entry.d_clientNameFirst = data.d_clientNameFirst;
    state.entry.d_clientNameLast = data.d_clientNameLast;
    state.entry.date1 = data.d_clientDateBirth;
    state.entry.d_clientAge = data.d_clientAge;
    state.entry.d_childNumber = data.d_childNumber;
    state.entry.d_language = data.d_language;
    state.entry.d_additionalInfoSpecialNeed = data.d_additionalInfoSpecialNeed;
    state.entry.d_applicantCounty = data.d_applicantCounty;
    state.entry.date3 = data.d_carePlanCertificationDateStart;
    state.entry.date4 = data.d_carePlanCertificationDateEnd;
    state.entry.date5 = data.d_carePlanItemDateStart;
    state.entry.date6 = data.d_carePlanItemDateEligibilityCheck;
    state.entry.d_carePlanItemFPL = data.d_carePlanItemFPL;
    state.entry.d_carePlanItemSMI = data.d_carePlanItemSMI;
    state.entry.d_carePlanItemFamilySize = data.d_carePlanItemFamilySize;
    state.entry.d_carePlanItemTime = data.d_carePlanItemTime;
    state.entry.d_carePlanItemEligibilityReason = data.d_carePlanItemEligibilityReason;
    state.entry.f_attendanceMonth = data.f_attendanceMonth;
    state.entry.d_attendanceYear = data.d_attendanceYear;
    state.entry.d_attendanceDaysPresent = data.d_attendanceDaysPresent;
    state.entry.d_attendanceDaysAbsent = data.d_attendanceDaysAbsent;
    state.entry.d_attendanceDaysClosed = data.d_attendanceDaysClosed;
    state.entry.d_attendanceDaysTotal = data.d_attendanceDaysTotal;
    state.entry.d_attendanceDaysProrated = data.d_attendanceDaysProrated;
    state.entry.d_attendanceDaysProratedOverride = data.d_attendanceDaysProratedOverride;
    state.entry.d_noteInternal = data.d_noteInternal;
    state.entry.d_noteExternal = data.d_noteExternal;
    state.entry.d_CCDFTANF = data.d_CCDFTANF;
    state.entry.d_fundingCodeSource = data.d_fundingCodeSource;
    state.entry.d_fundingCodeSuffix = data.d_fundingCodeSuffix;
    state.entry.d_fundingCode = data.d_fundingCode;
    state.entry.f_month = data.f_month;
    state.entry.d_year = data.d_year;
    state.entry.d_amountTuition = data.d_amountTuition;
    state.entry.d_amountRate = data.d_amountRate;
    state.entry.d_amountInitialReimbursement = data.d_amountInitialReimbursement;
    state.entry.d_amountReimbursement = data.d_amountReimbursement;
    state.entry.d_amountParentFee = data.d_amountParentFee;
    state.entry.d_amountIncome = data.d_amountIncome;
    state.entry.d_amountOverride = data.d_amountOverride;
    state.entry.d_timePercentage = data.d_timePercentage;
    state.entry.d_amountProrated = data.d_amountProrated;
    state.entry.d_amount = data.d_amount;
    state.entry.f_followup = data.f_followup;
    state.entry.f_status = data.f_status;
    state.entry.f_type = data.f_type;
    state.entry.f_show = '';
    state.entry.f_placeholder = data.f_placeholder;
    state.entry.f_appliedToPayment = data.f_appliedToPayment;
    state.entry.created_at = data.created_at;
    state.entry.updated_at = data.updated_at;
    state.entry.show = true;
    state.entry.tab = 0;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.tab = 0;
    state.entry.isDuplicate = 0;
    state.entry.fk_providerID = '';
    state.entry.fk_classroomID = '';
    state.entry.fk_householdID = '';
    state.entry.fk_applicantID = '';
    state.entry.fk_clientID = '';
    state.entry.fk_carePlanItemID = '';
    state.entry.fk_attendanceItemID = '';
    state.entry.fk_programID = '';
    state.entry.fk_programOptionID = '';
    state.entry.fk_fundingSourceID_Contract = '';
    state.entry.fk_fundingSourceID_Payment = '';
    state.entry.d_fundingSource_Contract = '';
    state.entry.d_fundingSource_Payment = '';
    state.entry.d_program = '';
    state.entry.d_programOption = '';
    state.entry.d_providerNameLicense = '';
    state.entry.d_providerNameFederal = '';
    state.entry.d_providerLicenseNumber = '';
    state.entry.d_providerRating = '';
    state.entry.d_providerType = '';
    state.entry.d_providerCounty = '';
    state.entry.j_providerPreKSetting = '';
    state.entry.d_classroom = '';
    state.entry.f_classroomBK = '';
    state.entry.d_classroomTeacherName = '';
    state.entry.d_primaryApplicantNameFirst = '';
    state.entry.d_primaryApplicantNameLast = '';
    state.entry.d_primaryApplicantDateBirth = '';
    state.entry.d_prekSeatID = '';
    state.entry.d_clientNameFirst = '';
    state.entry.d_clientNameLast = '';
    state.entry.d_clientDateBirth = '';
    state.entry.d_clientAge = '';
    state.entry.d_childNumber = '';
    state.entry.d_language = '';
    state.entry.d_additionalInfoSpecialNeed = '';
    state.entry.d_applicantCounty = '';
    state.entry.d_carePlanCertificationDateStart = '';
    state.entry.d_carePlanCertificationDateEnd = '';
    state.entry.d_carePlanItemDateStart = '';
    state.entry.d_carePlanItemDateEligibilityCheck = '';
    state.entry.d_carePlanItemFPL = '';
    state.entry.d_carePlanItemSMI = '';
    state.entry.d_carePlanItemParentFee = '';
    state.entry.d_carePlanItemFamilySize = '';
    state.entry.d_carePlanItemTime = '';
    state.entry.d_carePlanItemEligibilityReason = '';
    state.entry.f_attendanceMonth = '';
    state.entry.d_attendanceYear = '';
    state.entry.d_attendanceDaysPresent = '';
    state.entry.d_attendanceDaysAbsent = '';
    state.entry.d_attendanceDaysClosed = '';
    state.entry.d_attendanceDaysTotal = '';
    state.entry.d_attendanceDaysProrated = '';
    state.entry.d_attendanceDaysProratedOverride = '';
    state.entry.d_noteInternal = '';
    state.entry.d_noteExternal = '';
    state.entry.d_CCDFTANF = '';
    state.entry.d_fundingCodeSource = '';
    state.entry.d_fundingCodeSuffix = '';
    state.entry.d_fundingCode = '';
    state.entry.f_month = '';
    state.entry.d_year = '';
    state.entry.d_amountTuition = '';
    state.entry.d_amountRate = '';
    state.entry.d_amountInitialReimbursement = '';
    state.entry.d_amountReimbursement = '';
    state.entry.d_amountParentFee = '';
    state.entry.d_amountIncome = '';
    state.entry.d_amountOverride = '';
    state.entry.d_timePercentage = '';
    state.entry.d_amountProrated = '';
    state.entry.d_amount = '';
    state.entry.f_followup = '';
    state.entry.f_status = '';
    state.entry.f_type = '';
    state.entry.f_show = '';
    state.entry.f_placeholder = '';
    state.entry.f_appliedToPayment = '';
    state.entry.noFollowup = '';
    state.entry.date1 = '';
    state.entry.date2 = '';
    state.entry.date3 = '';
    state.entry.date4 = '';
    state.entry.date5 = '';
    state.entry.date6 = '';
    state.entry.created_at = '';
    state.entry.updated_at = '';
  },
  DUPLICATE(state, { data }) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = '';
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_classroomID = data.fk_classroomID;
    state.entry.fk_householdID = data.fk_householdID;
    state.entry.fk_applicantID = data.fk_applicantID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.fk_carePlanItemID = data.fk_carePlanItemID;
    state.entry.fk_attendanceItemID = data.fk_attendanceItemID;
    state.entry.fk_programID = data.fk_programID;
    state.entry.fk_programOptionID = data.fk_programOptionID;
    state.entry.fk_fundingSourceID_Contract = data.fk_fundingSourceID_Contract;
    state.entry.fk_fundingSourceID_Payment = data.fk_fundingSourceID_Payment;
    state.entry.d_fundingSource_Contract = data.d_fundingSource_Contract;
    state.entry.d_fundingSource_Payment = data.d_fundingSource_Payment;
    state.entry.d_program = data.d_program;
    state.entry.d_programOption = data.d_programOption;
    state.entry.d_providerNameLicense = data.d_providerNameLicense;
    state.entry.d_providerNameFederal = data.d_providerNameFederal;
    state.entry.d_providerLicenseNumber = data.d_providerLicenseNumber;
    state.entry.d_providerRating = data.d_providerRating;
    state.entry.d_providerType = data.d_providerType;
    state.entry.d_providerCounty = data.d_providerCounty;
    state.entry.j_providerPreKSetting = data.j_providerPreKSetting;
    state.entry.d_classroom = data.d_classroom;
    state.entry.f_classroomBK = data.f_classroomBK;
    state.entry.d_classroomTeacherName = data.d_classroomTeacherName;
    state.entry.d_primaryApplicantNameFirst = data.d_primaryApplicantNameFirst;
    state.entry.d_primaryApplicantNameLast = data.d_primaryApplicantNameLast;
    state.entry.date2 = data.d_primaryApplicantDateBirth;
    state.entry.d_prekSeatID = data.d_prekSeatID;
    state.entry.d_clientNameFirst = data.d_clientNameFirst;
    state.entry.d_clientNameLast = data.d_clientNameLast;
    state.entry.date1 = data.d_clientDateBirth;
    state.entry.d_clientAge = data.d_clientAge;
    state.entry.d_childNumber = data.d_childNumber;
    state.entry.d_language = data.d_language;
    state.entry.d_additionalInfoSpecialNeed = data.d_additionalInfoSpecialNeed;
    state.entry.d_applicantCounty = data.d_applicantCounty;
    state.entry.date3 = data.d_carePlanCertificationDateStart;
    state.entry.date4 = data.d_carePlanCertificationDateEnd;
    state.entry.date5 = data.d_carePlanItemDateStart;
    state.entry.date6 = data.d_carePlanItemDateEligibilityCheck;
    state.entry.d_carePlanItemFPL = data.d_carePlanItemFPL;
    state.entry.d_carePlanItemSMI = data.d_carePlanItemSMI;
    state.entry.d_carePlanItemFamilySize = data.d_carePlanItemFamilySize;
    state.entry.d_carePlanItemParentFee = data.d_carePlanItemParentFee;
    state.entry.d_carePlanItemTime = data.d_carePlanItemTime;
    state.entry.d_carePlanItemEligibilityReason = data.d_carePlanItemEligibilityReason;
    state.entry.f_attendanceMonth = data.f_attendanceMonth;
    state.entry.d_attendanceYear = data.d_attendanceYear;
    state.entry.d_attendanceDaysPresent = data.d_attendanceDaysPresent;
    state.entry.d_attendanceDaysAbsent = data.d_attendanceDaysAbsent;
    state.entry.d_attendanceDaysClosed = data.d_attendanceDaysClosed;
    state.entry.d_attendanceDaysTotal = data.d_attendanceDaysTotal;
    state.entry.d_attendanceDaysProrated = data.d_attendanceDaysProrated;
    state.entry.d_attendanceDaysProratedOverride = data.d_attendanceDaysProratedOverride;
    state.entry.d_noteInternal = data.d_noteInternal;
    state.entry.d_noteExternal = data.d_noteExternal;
    state.entry.d_CCDFTANF = data.d_CCDFTANF;
    state.entry.d_fundingCodeSource = data.d_fundingCodeSource;
    state.entry.d_fundingCodeSuffix = data.d_fundingCodeSuffix;
    state.entry.d_fundingCode = data.d_fundingCode;
    state.entry.f_month = data.f_month;
    state.entry.d_year = data.d_year;
    state.entry.d_amountTuition = data.d_amountTuition;
    state.entry.d_amountRate = data.d_amountRate;
    state.entry.d_amountInitialReimbursement = data.d_amountInitialReimbursement;
    state.entry.d_amountReimbursement = data.d_amountReimbursement;
    state.entry.d_amountParentFee = data.d_amountParentFee;
    state.entry.d_amountIncome = data.d_amountIncome;
    state.entry.d_amountOverride = data.d_amountOverride;
    state.entry.d_timePercentage = data.d_timePercentage;
    state.entry.d_amountProrated = data.d_amountProrated;
    state.entry.d_amount = data.d_amount;
    state.entry.f_followup = data.f_followup;
    state.entry.f_status = 1;
    state.entry.f_type = data.f_type;
    state.entry.f_placeholder = data.f_placeholder;
    state.entry.f_appliedToPayment = data.f_appliedToPayment;
    state.entry.created_at = '';
    state.entry.updated_at = '';
    state.entry.show = true;
    state.entry.tab = 0;
    state.entry.isDuplicate = 1;
  },
  RESET_MASS_SELECTION(state) {
    state.filters.selectedID = [];
  },
  SELECT_DESELECT_ALL(state) {
    if (state.filters.selectedID.length > 0) {
      state.filters.selectedID = [];
    } else {
      let i;
      for (i = 0; i < state.payments.data.length; i++) {
        state.filters.selectedID.push(state.payments.data[i].id);
      }
    }
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearPayments: ({ commit }) => {
    commit('CLEAR_PAYMENTS');
  },
  clearExport: ({ commit }) => {
    commit('CLEAR_EXPORT');
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/payments', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/payments/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/payments/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/payments/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async massUpdate({ commit }, data) {
    try {
      const response = await axios.post('/api/payments/massupdate', data, { headers: getHeader() });
      return response;
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/payments/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
      return response;
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/payments/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async duplicate({ commit }, id) {
    try {
      const response = await axios.get(`/api/payments/${id}`, { headers: getHeader() });
      commit('DUPLICATE', { data: response.data });
    } catch (error) {

    }
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post('/api/payments', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data });
    } catch (error) {

    }
  },
  resetMassSelection({ commit }) {
    commit('RESET_MASS_SELECTION');
  },
  selectDeselectAll({ commit }) {
    commit('SELECT_DESELECT_ALL');
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
