import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  rates: [],
  ratesTuition: [{
    d_rate: '',
    id: '',
  }, {
    d_rate: '',
    id: '',
  }, {
    d_rate: '',
    id: '',
  }, {
    d_rate: '',
    id: '',
  }, {
    d_rate: '',
    id: '',
  }, {
    d_rate: '',
    id: '',
  }, {
    d_rate: '',
    id: '',
  }],
  rate: {},
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_countyID: '',
    fk_programID: '',
    fk_programOptionID: '',
    fk_ageCategoryID: '',
    fk_ratingID: '',
    d_dateStart: '',
    d_rate: '',
    f_bk: '',
    f_facilityType: '',
    f_status: '',
    f_show: '',
    date1: '',
  },
  filters: {
    page: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    d_dateStart: '',
    fk_countyID: '',
    fk_programID: '',
    fk_programOptionID: '',
    fk_ageCategoryID: '',
    fk_ratingID: '',
    f_facilityType: '',
    date1: '',
    selectedID: [],
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.rates = list;
  },
  CREATE(state, { data }) {
    state.rates.data.unshift(data);
  },
  READ(state, { data }) {
    state.rate = data;
  },
  UPDATE(state, { data }) {
    const idx = state.rates.data.map((item) => item.id).indexOf(data.id);
    state.rates.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.rates.data.map((item) => item.id).indexOf(id);
    state.rates.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_countyID = data.fk_countyID;
    state.entry.fk_programID = data.fk_programID;
    state.entry.fk_programOptionID = data.fk_programOptionID;
    state.entry.fk_ageCategoryID = data.fk_ageCategoryID;
    state.entry.fk_ratingID = data.fk_ratingID;
    state.entry.date1 = data.d_dateStart;
    state.entry.d_rate = data.d_rate;
    state.entry.f_bk = data.f_bk;
    state.entry.f_facilityType = data.f_facilityType;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_countyID = '';
    state.entry.fk_programID = '';
    state.entry.fk_ageCategoryID = '';
    state.entry.fk_programOptionID = '';
    state.entry.fk_ratingID = '';
    state.entry.date1 = '';
    state.entry.d_dateStart = '';
    state.entry.d_rate = '';
    state.entry.f_bk = '';
    state.entry.f_facilityType = '';
    state.entry.f_status = true;
    state.entry.f_show = true;
  },
  SELECT_DESELECT_ALL(state) {
    if (state.filters.selectedID.length > 0) {
      state.filters.selectedID = [];
    } else {
      let i;
      for (i = 0; i < state.rates.data.length; i++) {
        state.filters.selectedID.push(state.rates.data[i].id);
      }
    }
  },
  DESELECT_ALL(state) {
    state.filters.selectedID = [];
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  FETCH_VALUE_LIST(state, { list }) {
    state.ratesValueList = list;
  },
  TUITION_RATES(state, { list }) {
    state.ratesTuition = list;
  },
  CLEAR_RATES_TUITION(state) {
    state.ratesTuition = [{
      d_rate: '',
      id: '',
    }];
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearSelected: ({ commit }) => {
    commit('DESELECT_ALL');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/rates', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/rates/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/rates/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/rates/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/rates/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/rates/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get('/api/ratesvaluelist', { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async tuitionRates({ commit }, data) {
    try {
      const response = await axios.post('/api/rates/tuition', data, { headers: getHeader() });
      commit('TUITION_RATES', { list: response.data });
    } catch (error) {

    }
  },
  async duplicateSelected({ commit }, data) {
    try {
      const response = await axios.post('/api/rates/masscreate', data, { headers: getHeader() });
      commit('SELECT_DESELECT_ALL');
      return response;
    } catch (error) {

    }
  },
  selectDeselectAll({ commit }) {
    commit('SELECT_DESELECT_ALL');
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  clearRatesTuition({ commit }) {
    commit('CLEAR_RATES_TUITION');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getRate: (state, getters) => state.rate,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
  getRatesTuition: (state, getters) => state.ratesTuition,
  getSelected: (state, getters) => state.filters.selectedID,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
