import { render, staticRenderFns } from "./ProviderEntry.vue?vue&type=template&id=54ee2172&scoped=true&"
import script from "./ProviderEntry.vue?vue&type=script&lang=js&"
export * from "./ProviderEntry.vue?vue&type=script&lang=js&"
import style0 from "./ProviderEntry.vue?vue&type=style&index=0&id=54ee2172&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ee2172",
  null
  
)

export default component.exports