<template>
  <div>
    <v-tooltip v-if="attendanceSection === 2" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon @click="newEntry()" color="primary">
            fal fa-clipboard-list-check
          </v-icon>
        </v-btn>
      </template>
      <span>Add Attendance</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1500">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Attendance Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Provider-->
                <v-col v-if="attendanceSection === 2" cols="12" sm="12" md="6">
                  <text-display
                    :displayLabel="'Provider'"
                    :displayString="
                      providersStore.provider.d_licenseNumber +
                        ' - ' +
                        providersStore.provider.d_nameLicense
                    "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="2">
                  <v-select
                    :items="valueListsStore.months"
                    item-text="value"
                    item-value="id"
                    label="Month"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_month"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <v-select
                    :items="yearsStore.yearsValueList"
                    item-text="d_yearStart"
                    item-value="d_yearStart"
                    label="Year"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_year"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-spacer />
                <v-btn rounded dark color="green lighten-1" class="mb-2" @click="addItem()">
                  <v-icon small left>fal fa-plus</v-icon>New</v-btn
                >
              </v-row>

              <v-row dense>
                <!--Child Name-->
                <v-col cols="12" sm="12" md="2" class="py-0">
                  <v-col cols="12" sm="12" class="py-0">
                    Child
                  </v-col>
                </v-col>

                <!--Dates-->
                <v-col cols="12" sm="12" md="4" class="py-0">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="3" class="py-0"> Care Plan<br />Start </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0"> Care Plan<br />End </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0"> Actual<br />Start </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0"> Actual<br />End </v-col>
                  </v-row>
                </v-col>

                <!--Day Numbers-->
                <v-col cols="12" sm="12" md="3" class="py-0">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      Days Pesent
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      Days Absent
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      Days Closed
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0"> Total<br />Days </v-col>
                  </v-row>
                </v-col>

                <!--Reason Absent, Parent Fee & Amoutn Collected-->
                <v-col cols="12" sm="12" md="3" class="py-0">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6" class="py-0"> Reason<br />Absent </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0"> Parent<br />Fee </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      Amount Collected
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row dense :key="index" v-for="(item, index) in items">
                <!--Child Name-->
                <v-col cols="12" sm="12" md="2" class="py-0">
                  <v-col cols="12" sm="12" class="py-0">
                    <v-select
                      :items="clientsStore.clients.data"
                      item-text="d_nameDisplay"
                      item-value="id"
                      placeholder=" "
                      persistent-placeholder
                      v-model="item.fk_clientID"
                      clearable
                      clear-icon="fal fa-times-circle"
                    />
                  </v-col>
                </v-col>

                <!--Dates-->
                <v-col cols="12" sm="12" md="4" class="py-0">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.date1"
                        placeholder=" "
                        persistent-placeholder
                        @blur="item.d_dateStartCarePlan = $_parseDate(item.date1)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.date2"
                        placeholder=" "
                        persistent-placeholder
                        @blur="item.d_dateEndCarePlan = $_parseDate(item.date2)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.date3"
                        placeholder=" "
                        persistent-placeholder
                        @blur="item.d_dateStartOverride = $_parseDate(item.date3)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.date4"
                        placeholder=" "
                        persistent-placeholder
                        @blur="item.d_dateEndOverride = $_parseDate(item.date4)"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Day Numbers-->
                <v-col cols="12" sm="12" md="3" class="py-0">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.d_daysPresent"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.d_daysAbsent"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.d_daysClosed"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.d_daysTotal"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Reason Absent, Parent Fee & Amoutn Collected-->
                <v-col cols="12" sm="12" md="3" class="py-0">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6" class="py-0">
                      <v-text-field
                        v-model="item.d_reasonAbsent"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.d_parentFee"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" class="py-0">
                      <v-text-field
                        v-model="item.d_amountCollected"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "attendanceStore/getEntryField",
  mutationType: "attendanceStore/updateEntryField"
});

export default {
  name: "AttendanceEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      attendanceStore: state => state.attendanceStore,
      valueListsStore: state => state.valueListsStore,
      providersStore: state => state.providersStore,
      clientsStore: state => state.clientsStore,
      yearsStore: state => state.yearsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_providerID",
      "d_year",
      "d_note",
      "f_month",
      "f_followup",
      "f_status"
    ]),
    ...mapMultiRowFields("attendanceStore", ["entry.items"])
  },
  components: {
    TextDisplay
  },
  props: {
    /* All List = 1, Provider Sublist = 2 */
    attendanceSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: false
    };
  },
  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("attendanceStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      const vldata = {
        id: [10]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);

      await this.$store.dispatch("yearsStore/valueList");

      if (this.attendanceSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;

        const data = {
          fk_providerID: this.fk_providerID
        };
        await this.$store.dispatch("clientsStore/fetch", data);
      }

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["attendanceStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("attendanceStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("attendanceStore/update", entry);
        }
        this.cancelEntry();
      }
    },

    addItem() {
      this.$store.dispatch("attendanceStore/addItem");
    },

    removeItem(id) {
      let data;
      if (this.items[id].f_show === 1 || this.items[id].f_show === true) {
        data = {
          id,
          f_show: 0
        };
      } else {
        data = {
          id,
          f_show: 1
        };
      }
      this.$store.dispatch("attendanceStore/removeItem", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
