<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-file-invoice-dollar
          </v-icon>
        </v-btn>
      </template>
      <span>Add Tuition</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1200">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Tuition Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'License #'"
                    :displayString="providersStore.provider.d_licenseNumber"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <text-display
                    :displayLabel="'Provider Name'"
                    :displayString="providersStore.provider.d_nameLicense"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <text-display v-if="this.mode === 1 && this.duplicate"
                    :displayString="'-- (COPY) --'"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateStart"
                        label="Start Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_dateStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="ratingsStore.ratingsValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Rating Tuition Paid At"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_ratingID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                    @input="pullRates()"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <text-display
                    :displayLabel="'County'"
                    :displayString="providersStore.provider.county.d_name"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'Facility Type'"
                    :displayString="
                      $_switchProviderType(providersStore.provider.f_type)
                    "
                  />
                </v-col>
                <v-col cols="12" sm="12" md="1">
                  <v-tooltip
                  v-if="usersStore.authUser.f_admin === 1 && usersStore.authUser.f_delete === 1"
                  left color="error"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        text
                        icon
                        v-on="on" color="red" @click="openDeleteDialog(id)"
                      >
                        <v-icon>fal fa-trash</v-icon>
                      </v-btn>
                    </template>
                    <span>Remove Tuition</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12">
                  <!--Table-->
                  <v-simple-table :fixed-header="true">
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left grey--text text--darken-1">Age Category</th>
                          <th class="text-left grey--text text--darken-1">Tuition</th>
                          <th class="text-left grey--text text--darken-1">Rate</th>
                          <!--<th class="text-left grey--text text--darken-1">Reimbursement</th>-->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in ageCategoriesStore.ageCategoriesValueList"
                          :key="item.id"
                        >
                          <td class="text-left">{{ item.d_name }}</td>
                          <td class="text-left">
                            <v-text-field
                              v-model="j_rates[item.id].d_rate"
                              @blur="
                                j_rates[item.id].d_rate = j_rates[item.id].d_rate.replace(/[,$]/g, '');
                                calculateReimbursement(
                                  j_rates[item.id].d_rate,
                                  ratesStore.ratesTuition[item.id].d_rate
                                    ? ratesStore.ratesTuition[item.id].d_rate
                                    : 0,
                                  item.id
                                )
                              "
                            />
                          </td>
                          <td class="text-left">
                            {{
                              ratesStore.ratesTuition && ratesStore.ratesTuition[item.id]
                                ? $_formatMoney(ratesStore.ratesTuition[item.id].d_rate)
                                : ""
                            }}
                          </td>
                          <!--<td class="text-left">
                            {{ tuitionStore.entry.j_reimbursement[item.id].rate }}
                          </td>-->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog Box -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete Tuition Record?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDeleteDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                This tuition record will be deleted from the system!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            rounded
            color="error"
            @click.native="removeTuition(recordID)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "tuitionStore/getEntryField",
  mutationType: "tuitionStore/updateEntryField"
});

export default {
  name: "TuitionEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      tuitionStore: state => state.tuitionStore,
      providersStore: state => state.providersStore,
      valueListsStore: state => state.valueListsStore,
      ratingsStore: state => state.ratingsStore,
      ageCategoriesStore: state => state.ageCategoriesStore,
      ratesStore: state => state.ratesStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",
      "id",
      "fk_providerID",
      "fk_ratingID",
      "d_dateStart",
      "f_status",
      "duplicate",
      "date1"
    ]),
    ...mapMultiRowFields("tuitionStore", ["entry.j_rates", "entry.j_reimbursement"])
  },
  components: {
    TextDisplay
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
      this.pullRates();
    }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      edit: false,
      deleteDialog: false,
      recordID: ''
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("tuitionStore/resetEntry");
      this.$store.dispatch("ratesStore/clearRatesTuition");
    },

    async newEntry() {
      this.resetEntry();

      /* Pull Payment Related List */
      const type = {
        type: 1
      };
      /* Grab ratings list */
      await this.$store.dispatch("ratingsStore/valueList", type);
      await this.$store.dispatch("ageCategoriesStore/valueList");

      const provider = this.$store.getters["providersStore/getProvider"];
      this.fk_providerID = provider.id;
      this.fk_ratingID = provider.fk_ratingID;

      const ageCategories = this.$store.getters["ageCategoriesStore/getAgeCategoryValueList"];

      let i;
      for (i = 0; i < ageCategories.length; i++) {
        const data = {
          fk_ageCategoryID: ageCategories[i].id,
          d_rate: ""
        };
        this.$store.dispatch("tuitionStore/addTuitionLine", data);
      }

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["tuitionStore/getEntry"];
        /* Create Tuition */
        if (this.mode === 1) {
          await this.$store.dispatch("tuitionStore/create", entry);
        } else {
        /* Edit Task */
          await this.$store.dispatch("tuitionStore/update", entry);
        }
        this.cancelEntry();
      }
    },

    async pullProgramOption(id) {
      await this.$store.dispatch("programsStore/read", id);
    },

    async pullRates() {
      if (this.d_dateStart && this.fk_ratingID) {
        const provider = this.$store.getters["providersStore/getProvider"];
        const data = {
          fk_countyID: provider.fk_countyID,
          fk_ratingID: this.fk_ratingID,
          f_facilityType: provider.f_type,
          date1: this.date1
        };

        await this.$store.dispatch("ratesStore/tuitionRates", data);

        const rates = this.$store.getters["ratesStore/getRatesTuition"];

        let i;
        for (i = 1; i < this.j_rates.length; i++) {
          this.calculateReimbursement(
            this.j_rates[i].d_rate ? this.j_rates[i].d_rate : 0,
            rates[i].d_rate ? rates[i].d_rate : 0,
            i
          );
        }
      }
    },

    calculateReimbursement(tuition, rate, id) {
      if (tuition && rate) {
        if (Number(tuition) <= Number(rate)) {
          this.j_reimbursement[id].rate = this.$_formatMoney(tuition);
        } else {
          this.j_reimbursement[id].rate = this.$_formatMoney(rate);
        }
      }
    },

    openDeleteDialog(id) {
      this.recordID = id;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
      this.recordID = '';
    },

    async removeTuition(id) {
      this.closeDeleteDialog();
      const data = {
          id: id
        };
      const response = await this.$store.dispatch("tuitionStore/delete", data);
      let message;
      if (response && response.status === 200) {
        message = {
          color: "success",
          text: "Tuition record has been deleted."
        };
        //close the Tuition Details window
        this.cancelEntry();
      } else {
        message = {
          color: "error",
          text: "There is an issue with deleting the tuition record at this time."
        };
      }
      this.$store.commit("messagesStore/setMessage", message);
    },
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
