<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-mobile
          </v-icon>
        </v-btn>
      </template>
      <span>Add Contact Method</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="800">
      <v-card flat class="white" height="700">
        <v-card-title class="text-h5 secondary--text"
          >Contact Method Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <!--Owner-->
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-select
                    :items="valueListsStore.valueListItems[4]"
                    item-text="d_name"
                    item-value="d_name"
                    label="Contact Person"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_owner"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.contact_person"
                  />
                </v-col>
              </v-row>

              <!--Other Owner-->
              <v-row dense>
                <v-col v-if="d_owner === 'Other'" cols="12" md="12">
                  <v-text-field
                    v-model="d_ownerOther"
                    label="Specify Other Person"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.owner_other"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!--Primary-->
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <v-select
                    :items="valueListsStore.yesNo"
                    item-text="value"
                    item-value="id"
                    label="Primary Contact"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_primary"
                    clearable
                    clear-icon="fal fa-times-circle"
                    :rules="rules.primary_contact"
                  />
                </v-col>
              </v-row>

              <!--Status-->
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-spacer />
                <v-btn
                  rounded
                  dark
                  color="green lighten-1"
                  class="mb-2"
                  @click="addContactMethod()"
                >
                  <v-icon small left>fal fa-plus</v-icon>New</v-btn
                >
                <v-col cols="12" sm="12" v-if="j_contactMethods">
                  <v-card flat :key="index" v-for="(item, index) in j_contactMethods">
                    <v-container fluid class="pa-0">
                      <v-row row>
                        <!--Preferred Contact-->
                        <v-col cols="12" sm="12" md="2">
                          <v-autocomplete
                            :items="valueListsStore.yesNo"
                            label="Preferred Contact"
                            placeholder=" "
                            persistent-placeholder
                            item-text="value"
                            item-value="id"
                            v-model="item.f_preferred"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <v-select
                            :items="valueListsStore.valueListItems[6]"
                            item-text="d_name"
                            item-value="d_name"
                            label="Contact Type"
                            placeholder=" "
                            persistent-placeholder
                            v-model="item.d_type"
                            required
                            :rules="rules.contact_type"
                            :blur="item.d_value = reformatInfo(item.d_type, item.d_value)"
                          />
                        </v-col>

                        <v-col cols="12" sm="12" md="5">
                          <v-text-field
                            v-model="item.d_value"
                            label="Contact Info"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.contact_info"
                            :blur="item.d_value = reformatInfo(item.d_type, item.d_value)"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col v-if="usersStore.authUser.f_delete === 1" cols="12" sm="12" md="1">
                          <v-btn text icon color="red" @click="removeContactMethod(index)">
                            <v-icon>fal fa-trash</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "contactMethodsStore/getEntryField",
  mutationType: "contactMethodsStore/updateEntryField"
});

export default {
  name: "ContactMethodEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      contactMethodsStore: state => state.contactMethodsStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_householdID",
      "d_owner",
      "d_ownerOther",
      "d_type",
      "d_value",
      "f_primary",
      "f_status"
    ]),
    ...mapMultiRowFields("contactMethodsStore", ["entry.j_contactMethods"])
  },
  data() {
    return {
      menu: false,
      rules: {
          contact_person: [v => !!v || "Contact Person is required."],
	        owner_other: [v => !!v || "Contact Person is required."],
          primary_contact: [v => v === 0 || v === 1 || "Primary Contact is required."],
          contact_type: [v => !!v || "Contact Type is required."],
          contact_info: [v => !!v || "Contact Info is required."],
        },
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("contactMethodsStore/resetEntry");
      if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
    },

    async newEntry() {
      this.resetEntry();
      const data2 = {
        id: [4, 6]
      };
      await this.$store.dispatch("valueListsStore/items", data2);
      const client = this.$store.getters["clientsStore/getClient"];

      this.fk_householdID = client.fk_householdID;
      this.f_status = 1;
      this.mode = 1;
      this.show = true;
    },

    reformatInfo(type, val) {
      let newValue = val;
      if (val && type) {
        if (type !== "Email" && type !== "Other") {
          newValue = this.$_formatPhone(val);
        } else if (type == "Other") {
          if (val.replace(/[^0-9]/g, "").toString().length >= 10) {
            //if value without letters or characters has 10 or more numbers
            newValue = this.$_formatPhone(val);
          }
        }
      }

      return newValue;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["contactMethodsStore/getEntry"];
        /* Create Care Plan */
        if (this.mode === 1) {
          await this.$store.dispatch("contactMethodsStore/create", entry);
        } else {
        /* Edit Task */
          await this.$store.dispatch("contactMethodsStore/update", entry);
        }
        this.cancelEntry();
      }
    },

    addContactMethod() {
      this.$store.dispatch("contactMethodsStore/addContactMethod");
    },

    removeContactMethod(id) {
      this.$store.dispatch("contactMethodsStore/removeContactMethod", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
