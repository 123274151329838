<template>
  <div>
    <v-tooltip v-if="contactSection === 1" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-address-book
          </v-icon>
        </v-btn>
      </template>
      <span>Add Contact</span>
    </v-tooltip>

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="700">
      <v-card flat class="white" height="800">
        <v-card-title class="text-h5 secondary--text"
          >Contact Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Details</v-tab>
                <v-tab>Contact Information</v-tab>
                <v-tab v-if="f_director">Demographics</v-tab>
                <v-tab v-if="f_director">Education</v-tab>

                <!--Details-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Salutation-->
                          <v-col cols="12" sm="2">
                            <v-text-field
                              v-model="d_salutation"
                              label="Salutation"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--First Name-->
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model="d_nameFirst"
                              label="First Name"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <!--Last Name-->
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="d_nameLast"
                              label="Last Name"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <!--Suffix-->
                          <v-col cols="12" sm="2">
                            <v-text-field
                              v-model="d_suffix"
                              label="Suffix"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Title-->
                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="d_title"
                              label="Title"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Type-->
                          <v-col cols="12" sm="12">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[24]"
                              label="Type"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_type"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Primary Contact-->
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Primary Contact"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_primary"
                            />
                          </v-col>
                          <!--Status-->
                          <v-col cols="12" sm="12" md="3" offset-md="6">
                            <v-autocomplete
                              :items="valueListsStore.activeStatus"
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_status"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Director-->
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="DPK Director"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_director"
                            />
                          </v-col>
                          <!--Director Admin Level-->
                          <v-col cols="12" sm="12" md="3">
                            <v-select
                              v-if="f_director"
                              :items="valueListsStore.valueListItems[37]"
                              item-text="d_name"
                              item-value="d_sort"
                              label="Admin Credential Level"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_adminLevel"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Remove Contact-->
                          <v-col cols="12" sm="12" md="3" offset-md="3">
                            <v-select
                              v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                              :items="[{text: 'Yes', value: '0'}]"
                              item-text="text"
                              item-value="value"
                              label="Remove Contact?"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_show"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Contact Information-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-spacer />
                          <v-btn
                            rounded
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="addContactMethod()"
                          >
                            <v-icon small left>fal fa-plus</v-icon>New</v-btn
                          >
                          <v-col cols="12" sm="12" v-if="j_contactMethods">
                            <v-card flat :key="index" v-for="(item, index) in j_contactMethods">
                              <v-container fluid class="pa-0">
                                <v-row row>
                                  <v-col cols="12" sm="12" md="3">
                                    <v-select
                                      :items="contactMethodTypes"
                                      item-text="value"
                                      item-value="id"
                                      label="Type"
                                      placeholder=" "
                                      persistent-placeholder
                                      v-model="item.f_type"
                                      key="id"
                                    />
                                  </v-col>

                                  <v-col cols="12" sm="12" md="8">
                                    <v-text-field
                                      v-model="item.d_detail"
                                      label="Detail"
                                      placeholder=" "
                                      persistent-placeholder
                                      flat
                                      hide-details
                                    />
                                  </v-col>

                                  <v-col
                                    v-if="usersStore.authUser.f_delete === 1"
                                    cols="12"
                                    sm="12"
                                    md="1"
                                  >
                                    <v-btn
                                      text
                                      icon
                                      color="red"
                                      @click="removeContactMethod(index)"
                                    >
                                      <v-icon>fal fa-trash</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Demographics-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <!--Language-->
                          <v-col cols="12" sm="12" class="px-0">
                            <span class="grey--text text--darken-1 text-caption"
                              >Language(s) Spoken</span
                            >
                            <v-radio-group dense row class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[15]"
                                v-model="j_language"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                          <!--Other Language-->
                          <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field
                              v-model="d_languageOther"
                              label="Other Language"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Race-->
                          <v-col cols="12" sm="12" class="px-0">
                            <span class="grey--text text--darken-1 text-caption">Race</span>
                            <v-radio-group row dense class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[12]"
                                v-model="j_race"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0 my-0"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Other Race-->
                          <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field
                              v-model="d_raceOther"
                              label="Other Race"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Gender-->
                          <v-col cols="12" sm="6" md="5">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[32]"
                              label="Gender"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_gender"
                              :blur="clearGenderOther()"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="5" v-if="d_gender == 'Other'">
                            <v-text-field
                              v-model="d_genderOther"
                              label=" "
                              placeholder=" "
                              persistent-placeholder
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Education-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-spacer />
                          <v-btn
                            rounded
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="addEducation()"
                          >
                            <v-icon small left>fal fa-plus</v-icon>New</v-btn
                          >
                          <v-col cols="12" sm="12" v-if="j_education">
                            <v-card flat :key="index" v-for="(item, index) in j_education">
                              <v-container fluid class="pa-0" v-if="item.f_show">
                                <v-row row>
                                  <v-row dense>
                                    <!--Degree-->
                                    <v-col cols="12" sm="12" md="9">
                                      <v-text-field
                                        v-model="item.d_degreeSought"
                                        label="Program of Study"
                                        placeholder=" "
                                        persistent-placeholder
                                      />
                                    </v-col>
                                    <v-col v-if="usersStore.authUser.f_delete === 1" cols="12" sm="12" md="1" offset-md="2">
                                      <v-btn text icon color="red" @click="removeEducation(index)">
                                        <v-icon>fal fa-trash</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                  <v-row dense>
                                    <!--Matriculation-->
                                    <v-col cols="12" md="12">
                                      <span class="grey--text text--darken-1 text-caption pb-0"
                                        >Degree Type</span
                                      >
                                      <v-radio-group v-model="item.d_type" row class="my-0">
                                        <v-radio
                                          :key="index"
                                          v-for="(item, index) in valueListsStore.valueListItems[33]"
                                          :label="item.d_name"
                                          :value="item.d_name"
                                          color="primary"
                                          class="pr-2"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                  <v-row dense>
                                    <!--Expected Completion Date-->
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="item.d_expectedCompletion"
                                        label="Expected Completion"
                                        placeholder=" "
                                        persistent-placeholder
                                        prepend-icon="fal fa-calendar-alt"
                                        required
                                        @blur="
                                          item.d_expectedCompletion = $_formatStringDate(
                                            item.d_expectedCompletion
                                          );
                                          item.date3 = $_parseDate(item.d_expectedCompletion);
                                        "
                                        hint="MM/DD/YYYY"
                                      />
                                    </v-col>
                                    <!-- Completion-->
                                    <v-col cols="12" sm="12" md="2">
                                      <v-autocomplete
                                        :items="valueListsStore.yesNo"
                                        label="Completed?"
                                        placeholder=" "
                                        persistent-placeholder
                                        item-text="value"
                                        item-value="id"
                                        v-model="item.f_completion"
                                        clearable
                                        clear-icon="fal fa-times-circle"
                                      />
                                    </v-col>
                                    <!--Status-->
                                    <v-col cols="12" sm="12" md="2" offset-md="3">
                                      <v-autocomplete
                                        v-if="mode !== 1"
                                        :items="valueListsStore.activeStatus"
                                        label="Status"
                                        placeholder=" "
                                        persistent-placeholder
                                        item-text="value"
                                        item-value="id"
                                        v-model="item.f_status"
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-divider class="pb-5" />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";

import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "contactsStore/getEntryField",
  mutationType: "contactsStore/updateEntryField"
});

export default {
  name: "ContactEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      contactsStore: state => state.contactsStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "mode",
      "id",
      "fk_providerID",
      "d_salutation",
      "d_nameFirst",
      "d_nameLast",
      "d_suffix",
      "d_title",
      "d_type",
      "j_language",
      "d_languageOther",
      "j_race",
      "d_raceOther",
      "d_gender",
      "d_genderOther",
      "f_primary",
      "f_director",
      "f_adminLevel",
      "f_status",
      "f_show"
    ]),
    ...mapMultiRowFields("contactsStore", [
      "entry.j_contactMethods",
      "entry.j_education"
    ])
  },
  props: {
    /* All List = 0, Provider = 1, Client = 2, Dashboard = 3 */
    contactSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      contactMethodTypes: [
        {
          id: 1,
          value: "Phone"
        },
        {
          id: 2,
          value: "Mobile"
        },
        {
          id: 3,
          value: "Email"
        },
        {
          id: 4,
          value: "Fax"
        }
      ],
      tab: 0
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("contactsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();
      /* Provider Section */
      if (this.contactSection === 1) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
      } else {
        this.fk_providerID = "";
      }
      const data = {
        id: [12, 15, 24, 32, 33, 37]
      };
      await this.$store.dispatch("valueListsStore/items", data);
      this.section = this.contactSection;
      this.tab = 0;
      this.mode = 1;
      this.f_status = true;
      this.show = true;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["contactsStore/getEntry"];
        /* Create Record */
        if (this.mode === 1) {
          await this.$store.dispatch("contactsStore/create", entry);
        } else {
        /* Edit Record */
          await this.$store.dispatch("contactsStore/update", entry);
        }
        this.cancelEntry();
        /* Reload List */
        this.$store.dispatch("progressStore/set", true);
        const filters = this.$store.getters["contactsStore/getFilters"];
        await this.$store.dispatch("contactsStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    },

    addContactMethod() {
      this.$store.dispatch("contactsStore/addContactMethod");
    },

    removeContactMethod(id) {
      this.$store.dispatch("contactsStore/removeContactMethod", id);
    },
    addEducation() {
      this.$store.dispatch("contactsStore/addEducation");
    },

    removeEducation(id) {
      this.$store.dispatch("contactsStore/removeEducation", id);
    },

    clearGenderOther() {
      if (this.d_gender !== "Other") {
        this.d_genderOther = "";
      }
    },
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
