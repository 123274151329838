import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  facilities: [],
  facility: null,
  filters: {
    page: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    ffacid: '',
    ffacname: '',
    fcounty: '',
    foptype: '',
    starrating: '',
    fstatus: '',
  },
  currentIndex: 0,
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.facilities = list;
  },
  CREATE(state, { data }) {
    state.facilities.unshift(data);
  },
  READ(state, { data }) {
    state.facility = data;
  },
  UPDATE(state, { data }) {
    const idx = state.facilities.data.map((item) => item.id).indexOf(data.id);
    state.facilities.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.facilities.data.map((item) => item.id).indexOf(id);
    state.facilities.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  CURRENT_INDEX(state, { uuid }) {
    const idx = state.facilities.data.map((item) => item.uuid).indexOf(uuid);
    state.currentIndex = idx;
  },
  READ_BY_INDEX(state, { index }) {
    state.facility = state.facilities.data[index];
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/facilities', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/facilities/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/facilities/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/facilities/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/facilities/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async currentIndex({ commit }, uuid) {
    try {
      commit('CURRENT_INDEX', { uuid });
    } catch (error) {

    }
  },
  async readByIndex({ commit }, index) {
    try {
      commit('READ_BY_INDEX', { index });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
};

const getters = {
  getFacilities: (state, getters) => state.facilities,
  getFacility: (state, getters) => state.facility,
  getFilters: (state, getters) => state.filters,
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
