import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  teacherSalary: [],
  calculator: {
    prevTeacherId: '',
    minSalary: '',
    numWeeks: '',
  },
  entry: {
    show: false,
    valid: '',
    mode: '',
    items: [{
      id: '',
      fk_teacherID: '',
      d_schoolYear: '',
      f_month: '',
      d_income: '',
      d_incomePeriod: '',
      d_minSalary: '',
      f_twelveMonthProrated: '',
      f_adequate: '',
      d_note: '',
      f_status: '',
      f_show: ''
    }]
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.teacherSalary = list;
  },
  /*UPDATE(state, { data }) {
    const idx = state.teacherSalaries.data.map((item) => item.id).indexOf(data.id);
    state.teacherSalaries.data.splice(idx, 1, data);
  },*/
  UPDATE(state, { list }) {
    state.teacherSalary = list;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.items = data;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.items = [{
      id: '',
      fk_teacherID: '',
      d_schoolYear: '',
      f_month: '',
      d_income: '',
      d_incomePeriod: '',
      d_minSalary: '',
      f_twelveMonthProrated: '',
      f_adequate: '',
      d_note: '',
      f_status: 1,
      f_show: true,
    }]
  },
  ADD_ITEM(state, { data }) {
    const item = {
      id: '',
      fk_teacherID: data.fk_teacherID,
      d_schoolYear: '',
      f_month: '',
      d_income: '',
      d_incomePeriod: '',
      d_minSalary: '',
      f_twelveMonthProrated: '',
      f_adequate: '',
      d_note: '',
      f_status: 1,
      f_show: true,
    };
    state.entry.items.unshift(item);
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateCalcField(state, field) {
    updateField(state.calculator, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/teachersalary', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put('/api/teachersalary/update', data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async entry({ commit }, data) {
    try {
      const response = await axios.post('/api/teachersalary', data, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  addItem({ commit }, data) {
    commit('ADD_ITEM', { data });
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getTeacherSalary: (state, getters) => state.teacherSalary,
  getEntryField(state) {
    return getField(state.entry);
  },
  getCalcField(state) {
    return getField(state.calculator);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
