<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="clientsStore.client.d_nameLast + ', ' + clientsStore.client.d_nameFirst" />

      <!--Function Bar-->
      <client-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <!----------Provider---------->
          <v-col cols="12" sm="12" md="2">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-school</v-icon>
                <v-toolbar-title class="pl-2">Provider</v-toolbar-title>
                <v-spacer />
                <v-tooltip top color="primary">
                  <template #activator="{ on }">
                  <v-btn icon v-on="on" @click="goToProvider(clientsStore.client.provider ? clientsStore.client.provider[0].uuid : '')">
                    <v-icon
                    color="white"
                    >
                    fal fa-arrow-alt-square-right
                    </v-icon>
                  </v-btn>
                  </template>
                  <span>Go To Provider</span>
                </v-tooltip>
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="94%" v-if="clientsStore.client.provider && clientsStore.client.provider[0]">
              <v-card-text>
                <v-row dense>
                  <!--Provider License Number-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'License #'" :displayString="clientsStore.client.provider[0].d_licenseNumber" />
                  </v-col>
                  <!--Provider Name-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Name'" :displayString="clientsStore.client.provider[0].d_nameLicense" />
                  </v-col>
                  <!--Provider Status-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Status'" :displayString="$_switchProviderStatus(clientsStore.client.provider[0].f_status)" />
                  </v-col>
                  <!--Provider Primary Contact Name-->
                  <v-col cols="12" sm="12" v-if="clientsStore.client.provider[0].primary">
                    <text-display :displayLabel="'Primary Contact'" :displayString="clientsStore.client.provider[0].primary.d_nameFirst + ' ' + clientsStore.client.provider[0].primary.d_nameLast" />
                  </v-col>
                  <!--Provider Primary Contact Methods-->
                  <!--<div v-if="clientsStore.client.provider[0].primary">
                    <template v-for="(contact,index) in clientsStore.client.provider[0].primary.j_contactMethods">
                      <v-col :key="index" class='pa-1' cols="12" sm="12" v-if="contact.f_type === 1">
                        <text-display :displayLabel="'Phone'" :displayString="contact.d_detail" />
                      </v-col>
                      <v-col :key="index" class='pa-1' cols="12" sm="12" v-if="contact.f_type === 3">
                        <text-display :displayLabel="'Email'" :displayString="contact.d_detail" />
                      </v-col>
                    </template>
                  </div>-->
                  <!--Provider Contact Methods (BG 6/22/2021 lines 68-73)-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Facility Phone'" :displayString="clientsStore.client.provider[0].d_facilityPhone" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Email'" :displayString="clientsStore.client.provider[0].d_email" />
                  </v-col>
                  <!--Provider Status Date-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'License Effective Date'" :displayString="clientsStore.client.provider[0].facility && clientsStore.client.provider[0].facility.pfromdt ? $_formatDate(clientsStore.client.provider[0].facility.pfromdt) : ''" />
                  </v-col>
                  <!--Provider Rating-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'Rating'" :displayString="clientsStore.client.provider[0].rating ? clientsStore.client.provider[0].rating.d_name : ''" />
                  </v-col>
                  <!--Provider County Name-->
                  <v-col cols="12" sm="12">
                    <text-display :displayLabel="'County'" :displayString="clientsStore.client.provider[0].county ? clientsStore.client.provider[0].county.d_name : ''" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card flat v-else>
              <v-card-text>
                <span class="error--text text-subtitle-1 text-xs-center">No Active Care Plan Exists Or Provider Not Selected In Care Plan</span>
              </v-card-text>
            </v-card>

          </v-col>

          <!----------Child Details---------->
          <v-col cols="12" sm="12" md="7">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-user-circle</v-icon>
                <v-toolbar-title class="pl-2">Details</v-toolbar-title>
                <v-spacer />

                <client-entry />

              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text>

                <div v-if="clientsStore.client.actions && clientsStore.client.actions.length > 0">
                  <v-alert
                    :key="index" v-for="(item, index) in clientsStore.client.actions"
                    :value="true"
                    type="error"
                    icon="fal fa-exclamation-triangle"
                  >
                    {{item.type.d_name}}
                  </v-alert>
                </div>

                <v-row dense>

                  <!--Left Side-->
                  <v-col cols="12" sm="12" md="9" class="pa-0">
                    <v-row dense>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Child ID'" :displayNumber="clientsStore.client.id"  />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'v1 Child ID'" :displayNumber="clientsStore.client.d_childIDv1" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Child Number'" :displayNumber="clientsStore.client.d_childNumber" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'PreK Seat ID'" :displayString="clientsStore.client.d_prekSeatID" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Date Of Birth'" :displayString="$_formatDate(clientsStore.client.d_dateBirth)" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Date of Birth Verified'" :displayString="clientsStore.client.d_verifiedDateOfBirth" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Age'" :displayString="$_calculatedAge(clientsStore.client.d_dateBirth,new Date())"/>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <text-display :displayLabel="'Gender'" :displayString="clientsStore.client.d_gender"  />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Name'" :displayString="$_fullName(clientsStore.client.d_nameFirst, clientsStore.client.d_nameMiddle,clientsStore.client.d_nameLast)" />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'SMA Application ID'" :displayString="clientsStore.client.d_applicationIDsma" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <text-display v-if="clientsStore.client.d_raceOther" :displayLabel="'Race'" :displayString="clientsStore.client.d_raceOther" />
                        <text-display v-else-if="Array.isArray(clientsStore.client.d_race)" :displayLabel="'Race'" :displayArray="clientsStore.client.d_race" />
                        <text-display v-else :displayLabel="'Race'" :displayString="clientsStore.client.d_race" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Language'" :displayString="clientsStore.client.d_language" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'US Citizen & NC Resident'" :displayString="clientsStore.client.d_additionalInfoUSCitizen" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Child has special need'" :displayString="clientsStore.client.d_additionalInfoSpecialNeed" />
                      </v-col>
                      <v-col cols="12" sm="6" md="9">
                        <text-display :displayLabel="'Type of Special Need'" :displayString="clientsStore.client.d_additionalInfoSpecialNeedDescribe" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Receiving Services'" :displayString="clientsStore.client.d_additionalInfoReceivingServices" />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <text-display :displayLabel="'Service Plan End Date'" :displayString="clientsStore.client.d_dateServicePlanEnd" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Health Insurance Coverage'" :displayString="clientsStore.client.d_additionalInfoHealthInsurance" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <text-display :displayLabel="'Additional Financial Support'" :displayString="clientsStore.client.d_shareFinancial" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Supporter\'s Name'" :displayString="clientsStore.client.d_shareFinancialName" />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <text-display :displayLabel="'Supporter\'s Relationship to Child'" :displayString="clientsStore.client.d_shareFinancialRelationship" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Support Description'" :displayString="clientsStore.client.d_shareFinancialDescribe" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-divider vertical class="mr-4"></v-divider>

                  <!--Right Side-->
                  <v-col cols="12" sm="12" md="2" class="pa-0">
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Specialist'" :displayString="clientsStore.client.counselor ? clientsStore.client.counselor.name : ''" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Scholarship Start Date'" :displayString="$_formatDate(clientsStore.client.d_dateCertificationStart)" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Scholarship End Date'" :displayString="$_formatDate(clientsStore.client.d_dateCertificationEnd)" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display v-if="clientsStore.client.d_priorityService" :displayLabel="'Service Priority'" :displayArray="clientsStore.client.d_priorityService" />
                        <text-display v-else :displayLabel="'Service Priority'" :displayString="''" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Pickup Priority'" :displayString="clientsStore.client.d_priorityPickup" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Termination Date'" :errorText2="true" :displayBold="true" :displayString="$_formatDate(clientsStore.client.d_dateTerm)" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <text-display :displayLabel="'Termination Reason'" :errorText2="true" :displayBold="true" :displayString="clientsStore.client.d_termReason" />
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>

                <v-divider class="py-1"></v-divider>

                <!--Primary Applicant-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="12" class="px-0">
                    <span class="text-subtitle-1 secondary--text font-weight-bold">Primary Applicant</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="6" md="3">
                    <text-display :displayLabel="'Household ID'" :displayNumber="clientsStore.client.fk_householdID" />
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <text-display :displayLabel="'v1 Applicant ID'" :displayNumber="clientsStore.client.household ? clientsStore.client.household.d_applicantIDv1 : ''" />
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <text-display :displayLabel="'WLS ID'" :displayString="clientsStore.client.primary_applicant && clientsStore.client.primary_applicant.d_wlsID" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="8">
                    <text-display :displayLabel="'Name'" :displayString="$_fullName(clientsStore.client.primary_applicant ? clientsStore.client.primary_applicant.d_nameFirst : '', clientsStore.client.primary_applicant ? clientsStore.client.primary_applicant.d_nameMiddle : '', clientsStore.client.primary_applicant ? clientsStore.client.primary_applicant.d_nameLast : '')" />
                  </v-col>
                </v-row>

                <v-divider class="py-1"></v-divider>

                <!--Referral Info-->
                <v-row dense>
                  <v-col cols="12" sm="6" md="12" class="px-0">
                    <span class="text-subtitle-1 secondary--text font-weight-bold">Referral Info</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="9">
                    <text-display :displayLabel="'Source'" :displayString="clientsStore.client.d_referralSource" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Date'" :displayString="$_formatDate(clientsStore.client.d_referralDate)" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12"  md="4">
                    <text-display :displayLabel="'Name'" :displayString="clientsStore.client.d_referralName" />
                  </v-col>
                  <v-col cols="12" sm="12"  md="5">
                    <text-display :displayLabel="'Email'" :displayString="clientsStore.client.d_referralEmail" />
                  </v-col>
                  <v-col cols="12" sm="12"  md="3">
                    <text-display :displayLabel="'Phone'" :displayString="clientsStore.client.d_referralPhone" />
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>

          <!--Notes-->
          <v-col cols="12" sm="12" md="3">
            <notes :key="clientsStore.client.id" :noteSection="4" />
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="12" sm="12">
            <!--Care Plans List-->
            <care-plan-items :key="clientsStore.client.id" :carePlanSection="2" />
          </v-col>
        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ClientFunctionBar from '@/components/ClientFunctionBar';
import Notes from '@/components/Notes';
import TextDisplay from '@/components/TextDisplay';
import ClientEntry from '@/components/ClientEntry';
import CarePlanItems from '@/components/CarePlanItems';

export default {
  name: 'Client',
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      clientsStore: (state) => state.clientsStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
    TitleBar,
	  ClientFunctionBar,
    Notes,
    TextDisplay,
    ClientEntry,
    CarePlanItems,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
      /* Refresh bookmark */
      const user = this.$store.getters['usersStore/getAuthUser'];
      const client = this.$store.getters['clientsStore/getClient'];
      const data = {
        fk_clientID: client.id,
        fk_userID: user.id,
      };
      await this.$store.dispatch('bookmarksStore/detail', data);
      this.$store.dispatch('progressStore/set', false);
    },

    goToProvider(uuid) {
      const routeData = this.$router.resolve({ name: 'provider', params: { providerUUID: uuid } });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
