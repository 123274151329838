import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  actions: [],
  action: {},
  entry: {
    show: '',
    valid: true,
    section: '',
    mode: 1,
    /* -----ADD ENTRY FIELDS BELOW-----*/
    id: '',
    fk_providerID: '',
    fk_clientID: '',
    fk_teacherID: '',
    fk_waitingListID: '',
    fk_userID: '',
    fk_actionTypeID: '',
    d_date: '',
    date1: '',
    d_note: '',
    f_showOnDetail: '',
    f_status: 1,
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: '',
    fk_clientID: '',
    fk_teacherID: '',
    fk_waitingListID: '',
    fk_userID: '',
    fk_actionTypeID: '',
    d_date: '',
    date1: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_ACTIONS(state) {
    state.actions = [];
  },
  FETCH(state, { list }) {
    state.actions = list;
  },
  CREATE(state, { data }) {
    state.actions.data.unshift(data);
  },
  READ(state, { data }) {
    state.action = data;
  },
  UPDATE(state, { data }) {
    const idx = state.actions.data.map((item) => item.id).indexOf(data.id);
    state.actions.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.actions.data.map((item) => item.id).indexOf(id);
    state.actions.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.fk_teacherID = data.fk_teacherID;
    state.entry.fk_waitingListID = data.fk_waitingListID;
    state.entry.fk_userID = data.fk_userID;
    state.entry.fk_actionTypeID = data.fk_actionTypeID;
    state.entry.date1 = data.d_date;
    state.entry.f_showOnDetail = data.f_showOnDetail;
    state.entry.d_note = data.d_note;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.fk_clientID = '';
    state.entry.fk_teacherID = '';
    state.entry.fk_waitingListID = '';
    state.entry.fk_userID = '';
    state.entry.fk_actionTypeID = '';
    state.entry.date1 = '';
    state.entry.f_showOnDetail = '';
    state.entry.d_note = '';
    state.entry.f_status = 1;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearActions: ({ commit }) => {
    commit('CLEAR_ACTIONS');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/actions', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/actions/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/actions/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/actions/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/actions/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/actions/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getAction: (state, getters) => state.action,
  getEntry: (state, getters) => state.entry,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
