import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  checkRequests: [],
  checkRequest: {},
  entry: {
    show: '',
    valid: '',
    section: '',
    mode: '',
    id: '',
    d_date: '',
    f_status: '',
    date1: '',
  },
  filters: {
    d_date: '',
    f_status: '',
    page: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.checkRequests = list;
  },
  CREATE(state, { data }) {
    state.checkRequests.data.push(data);
  },
  READ(state, { data }) {
    state.checkRequest = data;
  },
  UPDATE(state, { data }) {
    const idx = state.checkRequests.data.map((item) => item.id).indexOf(data.id);
    state.checkRequests.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.checkRequests.data.map((item) => item.id).indexOf(id);
    state.checkRequests.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.date1 = data.d_date;
    state.entry.f_status = data.f_status;
    state.entry.show = true;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/checkrequests', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/checkrequests/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/checkrequests/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/checkrequests/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/checkrequests/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/checkrequests/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getCheckRequest: (state, getters) => state.checkRequest,
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntry: (state, getters) => state.entry,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
