import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  correspondence: [],
  correspondenceItem: {},
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_providerID: '',
    fk_clientID: '',
    fk_teacherID: '',
    fk_waitingListID: '',
    fk_templateID: '',
    fk_userID: '',
    from: '',
    from_name: '',
    d_genericFrom: '',
    d_date: '',
    d_to: '',
    d_cc: '',
    d_bcc: '',
    d_subject: '',
    d_body: '',
    f_letterhead: '',
    f_type: '',
    date1: '',
    section: '',
    templateSubject: '',
    templateBody: '',
    selectorCarePlan: '',
    selectorCarePlanID: '',
    selectorCarePlanOption: '',
    selectorCarePlanOptionID: '',
    selectorProviderContacts: '',
    selectorProviderContactsID: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    fk_providerID: '',
    fk_clientID: '',
    fk_teacherID: '',
    fk_waitingListID: '',
    d_date: '',
    date1: '',
    f_type: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.correspondence = list;
  },
  CREATE(state, { data }) {
    state.correspondence.data.unshift(data);
  },
  READ(state, { data }) {
    state.correspondence = data;
  },
  UPDATE(state, { data }) {
    const idx = state.correspondence.data.map((item) => item.id).indexOf(data.id);
    state.correspondence.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.correspondence.data.map((item) => item.id).indexOf(id);
    state.correspondence.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_clientID = data.fk_clientID;
    state.entry.fk_teacherID = data.fk_teacherID;
    state.entry.fk_waitingListID = data.fk_waitingListID;
    state.entry.fk_templateID = data.fk_templateID;
    state.entry.fk_userID = data.fk_userID;
    state.entry.date1 = data.d_date;
    state.entry.d_to = data.d_to;
    state.entry.d_cc = data.d_cc;
    state.entry.d_bcc = data.d_bcc;
    state.entry.d_subject = data.d_subject;
    state.entry.d_body = data.d_body;
    state.entry.f_type = data.f_type;
    state.entry.f_letterhead = data.f_letterhead;
    state.entry.section = '';
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.fk_clientID = '';
    state.entry.fk_teacherID = '';
    state.entry.fk_waitingListID = '';
    state.entry.fk_templateID = '';
    state.entry.fk_userID = '';
    state.entry.date1 = '';
    state.entry.d_genericFrom = '';
    state.entry.d_to = '';
    state.entry.d_cc = '';
    state.entry.d_bcc = '';
    state.entry.d_subject = '';
    state.entry.d_body = '';
    state.entry.f_type = '';
    state.entry.f_letterhead = '';
    state.entry.section = '';
    state.entry.templateSubject = '';
    state.entry.templateBody = '';
    state.entry.selectorCarePlan = '';
    state.entry.selectorCarePlanID = '';
    state.entry.selectorCarePlanOption = '';
    state.entry.selectorCarePlanOptionID = '';
    state.entry.selectorProviderContacts = '';
    state.entry.selectorProviderContactsID = '';
    state.entry.f_show = 1;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/correspondence', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/correspondence/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/correspondence/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/correspondence/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/correspondence/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async email({ commit }, data) {
    try {
      const response = await axios.post('/api/correspondence/email', data, { headers: getHeader() });
      return response;
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/correspondence/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getCorrespondence: (state, getters) => state.correspondence,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
