<template>
  <div>
    <v-toolbar flat dense v-if="show === true && lastPage > 1 || showTotal === true && total" class="py-2">

      <!--First-->
      <div v-if="lastPage > 1">
        <v-btn :disabled="currentPage <= 1" icon @click="paginate(1)">
            <v-icon color="primary">
            fal fa-step-backward
            </v-icon>
        </v-btn>

        <!--Previous-->
        <v-btn :disabled="currentPage <= 1"
                icon
                @click="paginate(currentPage - 1)">
            <v-icon color="primary">
            fal fa-caret-left
            </v-icon>
        </v-btn>

        <!--Display Of Current Page And Total Pages-->
        <span>{{currentPage}}
                of {{lastPage}}</span>

        <!--Next-->
        <v-btn :disabled="currentPage ===
        lastPage" icon @click="paginate(currentPage + 1)">
            <v-icon color="primary">
            fal fa-caret-right
            </v-icon>
        </v-btn>

        <!--Last-->
        <v-btn :disabled="currentPage ===
        lastPage" icon @click="paginate(lastPage)">
            <v-icon color="primary">
            fal fa-step-forward
            </v-icon>
        </v-btn>
      </div>

      <span v-if="showTotal">{{total}} Total</span>

    </v-toolbar>

  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    show: Boolean,
    showTotal: {
      type: Boolean,
      required: false,
    },
    currentPage: Number,
    lastPage: Number,
    total: Number,
  },
  methods: {
    paginate(pageNumber) {
      this.$emit('paginate-event', pageNumber);
    },
  },

};
</script>

<style>
 .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
  button:focus {outline:0;}
</style>
