<template>
  <div>

    <!--Title Bar-->
    <title-bar v-if="bookmarkSection === 1" :title-value="'Bookmarks'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-bookmark</v-icon>
        <v-toolbar-title class="pl-2">Bookmarks </v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!--Pagination  -->
    <pagination
      :show="bookmarksStore.bookmarks.data && bookmarksStore.bookmarks.data.length > 0"
      :showTotal="true"
      :currentPage="bookmarksStore.bookmarks.current_page"
      :lastPage="bookmarksStore.bookmarks.last_page"
      :total="bookmarksStore.bookmarks.total"
      v-on:paginate-event="filter($event)" />

    <div v-if="progressStore.progress !== true">

      <!--List-->
      <v-list v-if="bookmarksStore.bookmarks.data && bookmarksStore.bookmarks.data.length > 0">
        <template v-for="(item, index) in bookmarksStore.bookmarks.data">
          <v-list-item
            :key="item.id"
            @click="goToRelated(item.waiting_list_item ? 4 : item.teacher ? 3 : item.client ? 2 : item.provider ? 1 : '', item.waiting_list_item ? item.waiting_list_item.uuid : item.teacher ? item.teacher.uuid : item.client ? item.client.uuid : item.provider.uuid ? item.provider.uuid : '')"
            class="my-1"
          >
            <v-list-item-content>
              <v-list-item-title v-if="item.provider" class="light-blue--text text--darken-4"><v-icon color="orange darken-4" left>fal fa-school fa-fw</v-icon>{{item.provider.d_display}}</v-list-item-title>
              <v-list-item-title v-if="item.client" class="light-blue--text text--darken-4"><v-icon color="green darken-2" left>fal fa-user-circle fa-fw</v-icon>{{item.client.id}} - {{item.client.d_nameDisplay}}</v-list-item-title>
              <v-list-item-title v-if="item.client && item.client.primary_applicant" class="light-blue--text text--darken-4 pl-10">Primary Applicant: {{item.client.primary_applicant.d_nameFirst}} {{item.client.primary_applicant.d_nameLast}}</v-list-item-title>
              <v-list-item-title v-if="item.teacher" class="light-blue--text text--darken-4"><v-icon color="blue darken-2" left>fal fa-chalkboard-teacher fa-fw</v-icon>{{item.teacher.id}} - {{item.teacher.d_nameDisplay}}</v-list-item-title>
              <v-list-item-title v-if="item.waiting_list_item" class="light-blue--text text--darken-4"><v-icon color="brown darken-2" left>fal fa-list-alt fa-fw</v-icon>{{item.waiting_list_item.id}} - {{item.waiting_list_item.d_nameFirst}} {{item.waiting_list_item.d_nameLast}}</v-list-item-title>
              <v-list-item-title v-if="item.d_note" class="light-blue--text text--darken-4 pl-10">Memo: {{item.d_note}}</v-list-item-title>
              <v-list-item-title class="text-caption grey--text text--darken-1 pl-10">Created: {{$_formatTimestamp(item.created_at)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <!--No Recrods Found Message-->
      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center">No
              Bookmarks Exist</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import Pagination from '@/components/Pagination';

const { mapFields } = createHelpers({
  getterType: 'bookmarksStore/getFilterField',
  mutationType: 'bookmarksStore/updateFilterField',
});

export default {
  name: 'Bookmarks',
  components: {
    TitleBar,
    Pagination,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      bookmarksStore: (state) => state.bookmarksStore,
      progressStore: (state) => state.progressStore,
    }),
    ...mapFields([
      'page',
      'section',

      'fk_providerID',
      'fk_clientID',
      'fk_teacherID',
      'fk_waitingListID',
      'fk_userID',
      'f_status',
    ]),
  },
  props: {
    /* All List = 1, Dashboard = 2 */
    bookmarkSection: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      const user = this.$store.getters['usersStore/getAuthUser'];
      this.fk_userID = user.id;
      this.f_status = 1;
      this.section = this.bookmarkSection;
      /* Pull bookmarks list */
      const filters = this.$store.getters['bookmarksStore/getFilters'];
      await this.$store.dispatch('bookmarksStore/fetch', filters);

      this.$store.dispatch('progressStore/set', false);
	  },

    async filter(page) {
      this.page = page;
      this.initialize();
    },

    goToRelated(section, uuid) {
      /* Provider */
      if (section === 1) {
        this.$store.dispatch('providersStore/currentIndex', uuid);
	      this.$router.push({ name: 'provider', params: { providerUUID: uuid } });
      }
      /* Client */
      else if (section === 2) {
        this.$store.dispatch('clientsStore/currentIndex', uuid);
	      this.$router.push({ name: 'client', params: { clientUUID: uuid } });
      }
      /* Teacher */
      else if (section === 3) {
        this.$store.dispatch('teachersStore/currentIndex', uuid);
	      this.$router.push({ name: 'teacher', params: { teacherUUID: uuid } });
      }
      /* Waiting List */
      else if (section === 4) {
        this.$store.dispatch('waitingListStore/currentIndex', uuid);
	      this.$router.push({ name: 'waitingListItem', params: { waitingListUUID: uuid } });
      }
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
