import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  tuitions: [],
  tuition: {},
  export: [],
  entry: {
    show: '',
    duplicate: '',
    valid: '',
    section: '',
    mode: '',
    id: '',
    fk_providerID: '',
    fk_ratingID: '',
    d_dateStart: '',
    d_dateEnd: '',
    date1: '',
    j_rates: [{
      fk_ageCategoryID: 0,
      d_rate: '',
    }, {
      fk_ageCategoryID: 1,
      d_rate: '',
    }, {
      fk_ageCategoryID: 2,
      d_rate: '',
    }, {
      fk_ageCategoryID: 3,
      d_rate: '',
    }, {
      fk_ageCategoryID: 4,
      d_rate: '',
    }, {
      fk_ageCategoryID: 5,
      d_rate: '',
    }, {
      fk_ageCategoryID: 6,
      d_rate: '',
    }, {
      fk_ageCategoryID: 7,
      d_rate: '',
    }],
    j_reimbursement: [{
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }],
    f_status: '',
  },
  filters: {
    page: '',
    fk_providerID: '',
    f_status: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.tuitions = list;
  },
  CREATE(state, { data }) {
    state.tuitions.data.unshift(data);
  },
  READ(state, { data }) {
    state.tuition = data;
  },
  UPDATE(state, { data }) {
    const idx = state.tuitions.data.map((item) => item.id).indexOf(data.id);
    state.tuitions.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.tuitions.data.map((item) => item.id).indexOf(id);
    state.tuitions.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_ratingID = data.fk_ratingID;
    state.entry.date1 = data.d_dateStart;
    state.entry.j_rates = data.j_rates;
    state.entry.f_status = data.f_status;
    state.entry.show = 1;
  },
  DUPLICATE(state, { data }) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_providerID = data.fk_providerID;
    state.entry.fk_ratingID = data.fk_ratingID;
    state.entry.date1 = data.d_dateStart;
    state.entry.j_rates = data.j_rates;
    state.entry.f_status = data.f_status;
    state.entry.duplicate = 1;
    state.entry.show = 1;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.fk_providerID = '';
    state.entry.fk_ratingID = '';
    state.entry.date1 = '';
    state.entry.j_rates = [{
      fk_ageCategoryID: 0,
      d_rate: '',
    }, {
      fk_ageCategoryID: 1,
      d_rate: '',
    }, {
      fk_ageCategoryID: 2,
      d_rate: '',
    }, {
      fk_ageCategoryID: 3,
      d_rate: '',
    }, {
      fk_ageCategoryID: 4,
      d_rate: '',
    }, {
      fk_ageCategoryID: 5,
      d_rate: '',
    }, {
      fk_ageCategoryID: 6,
      d_rate: '',
    }, {
      fk_ageCategoryID: 7,
      d_rate: '',
    },
    ];
    state.entry.j_reimbursement = [{
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }, {
      rate: '',
    }];
    state.entry.f_status = 1;
    state.entry.duplicate = '';
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  ADD_TUITION_LINE(state, { data }) {
    const rate = {
      fk_ageCategoryID: data.fk_ageCategoryID,
      d_rate: data.d_rate,
    };
    state.entry.j_rates[data.fk_ageCategoryID] = rate;
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/tuition', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/tuition/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/tuition/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/tuition/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/tuition/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
      return response;
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/tuition/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async duplicate({ commit }, id) {
    try {
      const response = await axios.get(`/api/tuition/${id}`, { headers: getHeader() });
      commit('DUPLICATE', { data: response.data });
    } catch (error) {

    }
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post('/api/tuition', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  addTuitionLine({ commit }, data) {
    commit('ADD_TUITION_LINE', { data });
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getTuition: (state, getters) => state.tuition,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
