<template>
  <div>
    <!--File Upload Progress Bar-->
    <v-dialog v-model="upload" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          File Uploading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog Box -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDeleteDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                {{ deleteMessage }} will be deleted from the system!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="deleteMode === 1"
            block
            rounded
            color="error"
            @click.native="removeEmployment()"
            >Delete</v-btn
          >
          <v-btn v-if="deleteMode === 2" block rounded color="error" @click.native="removeSchool()"
            >Delete</v-btn
          >
          <v-btn
            v-if="deleteMode === 3"
            block
            rounded
            color="error"
            @click.native="removeOtherIncome()"
            >Delete</v-btn
          >
          <v-btn
            v-if="deleteMode === 4"
            block
            rounded
            color="error"
            @click.native="removeDocument()"
            >Delete</v-btn
          >
          <v-btn v-if="deleteMode === 5" block rounded color="error" @click.native="removeContact()"
            >Delete</v-btn
          >
          <v-btn
            v-if="deleteMode === 6"
            block
            rounded
            color="error"
            @click.native="removeDependant()"
            >Delete</v-btn
          >
          <v-btn v-if="deleteMode === 7" block rounded color="error" @click.native="removeChild()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Application Button -->
    <v-tooltip v-if="applicationSection === 1" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Application</span>
    </v-tooltip>

    <!-- New Application Button -->
    <v-tooltip v-if="applicationSection === 2" top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-ballot-check
          </v-icon>
        </v-btn>
      </template>
      <span>Add Application</span>
    </v-tooltip>

    <v-dialog v-model="show" scrollable persistent max-width="1200">
      <v-card flat height="1000">
        <v-card-title class="text-h6 secondary--text pb-0 font-weight-bold">
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left>fal fa-times</v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                :editable="f_status === 2"
                step="1"
                edit-icon="fal fa-check"
                >Primary Applicant</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 2"
                :editable="f_status === 2"
                step="2"
                edit-icon="fal fa-check"
                >Other Parent/Guardian</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 3"
                :editable="f_status === 2"
                step="3"
                edit-icon="fal fa-check"
                >Documents</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 4"
                :editable="f_status === 2"
                step="4"
                edit-icon="fal fa-check"
                >Household</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 5"
                :editable="f_status === 2"
                step="5"
                edit-icon="fal fa-check"
                >Applying Children</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step step="6" :editable="f_status === 2" edit-icon="fal fa-check"
                >Agreements</v-stepper-step
              >
            </v-stepper-header>

            <!-------------------- Parent Guardian -------------------->
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card flat>
                  <v-card-title class="text-h6 secondary--text pb-0 font-weight-bold">
                    Primary Applicant
                  </v-card-title>
                  <v-card-title class="text-subtitle-1 pt-0 v-card-word-break">
                    Please complete all sections of this application and include any documentation
                    requested.
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="step1" v-model="valid1" lazy-validation>
                      <v-container fluid>
                        <v-row dense>
                          <!--Private Record-->
                          <v-col cols="12" sm="12" md="2">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Private Record"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_privateRecord"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--WLS ID-->
                          <v-col cols="12" sm="12" md="6" offset-md="2">
                            <v-autocomplete
                              :items="waitingListStore.waitingListValueList"
                              label="WLS ID (Waiting List)"
                              placeholder=" "
                              persistent-placeholder
                              item-text= "detailedwlsID"
                              item-value="d_wlsID"
                              v-model="d_wlsID"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <!--PRIMARY APPLICANT INFO-->
                        <v-row dense>
                          <!--First Name-->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="d_nameFirst"
                              label="First Name"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.nameFirst"
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--MI-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_nameMiddle"
                              label="MI"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Last Name-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_nameLast"
                              label="Last Name"
                              placeholder=" "
                              persistent-placeholder
                              :rules="rules.nameLast"
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_dateBirth"
                              label="Date of Birth"
                              placeholder=" "
                              persistent-placeholder
                              hint="MM/DD/YYYY"
                              persistent-hint
                              @blur="
                                d_dateBirth = $_formatStringDate(d_dateBirth);
                                dateBirth = $_parseDate(d_dateBirth);
                              "
                              :rules="rules.dateBirth"
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="4">
                            <v-select
                              :items="valueListsStore.valueListItems[2]"
                              item-text="d_name"
                              item-value="d_name"
                              label="Relationship To Child"
                              placeholder=" "
                              persistent-placeholder
                              v-model="d_primaryRelationship"
                              :clearable="f_status !== 2"
                              clear-icon="fal fa-times-circle"
                              :readonly="f_status === 2"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" class="px-0">
                            <span class="grey--text text--darken-1 text-caption">Race</span>
                            <v-radio-group dense row class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[12]"
                                v-model="j_primaryRace"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                          <!--Other Race-->
                          <v-col cols="12" sm="12" md="4" class="px-0 py-0">
                            <v-text-field
                              v-model="d_primaryRaceOther"
                              label="Other Race"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <!--PRIMARY JOBS-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Employment</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addEmployment(1)"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row dense :key="index" v-for="(job, index) in j_employmentPrimary">
                              <!--Employer-->
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="job.d_employer"
                                  label="Employer"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Position-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="job.d_position"
                                  label="Position"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--City Of Work-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="job.d_employerCity"
                                  label="City of Work"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(1, 'This employment record', index, 1)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <!--Income-->
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="job.d_income"
                                  label="Income"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Income Period-->
                              <v-col cols="12" md="6">
                                <span class="grey--text text--darken-1 text-caption pb-0"
                                  >Per:</span
                                >
                                <v-radio-group
                                  v-model="job.d_incomePeriod"
                                  row
                                  class="my-0"
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[18]"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--Hours Worked Per Week-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="job.d_hoursPerWeek"
                                  label="Hours Worked Per Week"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_digits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" sm="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <!--PRIMARY SCHOOL-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>School</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addSchool(1)"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row dense :key="index" v-for="(school, index) in j_schoolPrimary">
                              <!--School Name-->
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="school.d_schoolName"
                                  label="School Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Degree-->
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="school.d_degreeSought"
                                  label="Degree Sought"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Expected Completion-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="school.d_expectedCompletion"
                                  label="Expected Completion"
                                  placeholder=" "
                                  persistent-placeholder
                                  hint="MM/DD/YYYY"
                                  persistent-hint
                                  @blur="
                                    school.d_expectedCompletion = $_formatStringDate(
                                      school.d_expectedCompletion
                                    )
                                  "
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(2, 'This school record', index, 1)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <!--Enrolled-->
                              <v-col cols="12" md="3">
                                <span class="grey--text text--darken-1 text-caption pb-0"
                                  >Enrolled</span
                                >
                                <v-radio-group
                                  v-model="school.d_enrolled"
                                  row
                                  class="my-0"
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[21]"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--School Type-->
                              <v-col cols="12" md="9">
                                <span class="grey--text text--darken-1 text-caption pb-0"
                                  >School Type</span
                                >
                                <v-radio-group
                                  v-model="school.d_type"
                                  row
                                  class="my-0"
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    label="K-12"
                                    value="K-12"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Undergraduate"
                                    value="Undergraduate"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Graduate, Master's"
                                    value="Graduate, Master's"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Graduate, PhD"
                                    value="Graduate, PhD"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Post-Graduate"
                                    value="Post-Graduate"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--Financial Aid-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="school.d_financialAidAmount"
                                  label="Financial Aid"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Financial Aid Period-->
                              <v-col cols="12" md="4">
                                <v-radio-group
                                  label="Per: "
                                  v-model="school.d_financialAidPeriod"
                                  row
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    label="Semester"
                                    color="primary"
                                    value="Semester"
                                  ></v-radio>
                                  <v-radio label="Year" color="primary" value="Year"></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--Tuition-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="school.d_tuitionAmount"
                                  label="Tuition"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Tuition Period-->
                              <v-col cols="12" md="4">
                                <v-radio-group
                                  label="Per: "
                                  v-model="school.d_tuitionAmountPeriod"
                                  row
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    label="Semester"
                                    color="primary"
                                    value="Semester"
                                  ></v-radio>
                                  <v-radio label="Year" color="primary" value="Year"></v-radio>
                                </v-radio-group>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <!--PRIMARY OTHER INCOME-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Other Income</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addOtherIncome(1)"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row
                              dense
                              :key="index"
                              v-for="(income, index) in j_otherIncomePrimary"
                            >
                              <!--Income Type-->
                              <v-col cols="12" md="3">
                                <v-select
                                  :items="valueListsStore.valueListItems[3]"
                                  item-text="d_name"
                                  item-value="d_name"
                                  label="Type"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="income.d_type"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                  :readonly="f_status === 2"
                                  required
                                />
                              </v-col>

                              <!--Amount-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="income.d_amount"
                                  label="Amount"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Income Period-->
                              <v-col cols="12" md="5">
                                <v-radio-group
                                  label="Per: "
                                  v-model="income.d_period"
                                  row
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[18]"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(3, 'This other income record', index, 1)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-------------------- Other Parent/Guardian -------------------->
              <v-stepper-content step="2">
                <v-card flat>
                  <v-card-title class="text-h6 secondary--text pb-0 font-weight-bold">
                    Other Parent/Guardian
                  </v-card-title>
                  <v-card-title class="text-subtitle-1 pt-0 v-card-word-break">
                    Lives with the applicant and shares financial responsibility for a
                    child/children for whom this application requests a scholarship.
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="step2" v-model="valid2" lazy-validation>
                      <v-container fluid>
                        <!--PRIMARY APPLICANT INFO-->
                        <v-row dense>
                          <!--First Name-->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="d_nameFirstOtherParent"
                              label="First Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--MI-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_nameMiddleOtherParent"
                              label="MI"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Last Name-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_nameLastOtherParent"
                              label="Last Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--DOB-->
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_dateBirthOtherParent"
                              label="Date of Birth"
                              placeholder=" "
                              persistent-placeholder
                              hint="MM/DD/YYYY"
                              persistent-hint
                              @blur="
                                d_dateBirthOtherParent = $_formatStringDate(d_dateBirthOtherParent);
                                dateBirthOtherParent = $_parseDate(d_dateBirthOtherParent);
                              "
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="4">
                            <v-select
                              :items="valueListsStore.valueListItems[2]"
                              item-text="d_name"
                              item-value="d_name"
                              label="Relationship To Child"
                              placeholder=" "
                              persistent-placeholder
                              v-model="d_otherParentRelationship"
                              :clearable="f_status !== 2"
                              clear-icon="fal fa-times-circle"
                              :readonly="f_status === 2"
                              required
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" class="px-0">
                            <span class="grey--text text--darken-1 text-caption">Race</span>
                            <v-radio-group dense row class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[12]"
                                v-model="j_otherParentRace"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                          <!--Other Race-->
                          <v-col cols="12" sm="12" md="4" class="px-0 py-0">
                            <v-text-field
                              v-model="d_otherParentRaceOther"
                              label="Other Race"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <!--PRIMARY JOBS-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Employment</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addEmployment(2)"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row
                              dense
                              :key="index"
                              v-for="(job, index) in j_employmentOtherParent"
                            >
                              <!--Employer-->
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="job.d_employer"
                                  label="Employer"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Position-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="job.d_position"
                                  label="Position"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--City Of Work-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="job.d_employerCity"
                                  label="City of Work"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(1, 'This employment record', index, 2)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <!--Income-->
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="job.d_income"
                                  label="Income"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Income Period-->
                              <v-col cols="12" md="6">
                                <v-radio-group
                                  label="Per: "
                                  v-model="job.d_incomePeriod"
                                  row
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[18]"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--Hours Worked Per Week-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="job.d_hoursPerWeek"
                                  label="Hours Worked Per Week"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_digits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <!--OTHER PARENT SCHOOL-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>School</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addSchool(2)"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row
                              dense
                              :key="index"
                              v-for="(school, index) in j_schoolOtherParent"
                            >
                              <!--School Name-->
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="school.d_schoolName"
                                  label="School Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Degree-->
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="school.d_degreeSought"
                                  label="Degree Sought"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Expected Completion-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="school.d_expectedCompletion"
                                  label="Expected Completion"
                                  placeholder=" "
                                  persistent-placeholder
                                  hint="MM/DD/YYYY"
                                  persistent-hint
                                  @blur="
                                    school.d_expectedCompletion = $_formatStringDate(
                                      school.d_expectedCompletion
                                    )
                                  "
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(2, 'This school record', index, 2)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <!--Enrolled-->
                              <v-col cols="12" md="3">
                                <span class="grey--text text--darken-1 text-caption pb-0"
                                  >Enrolled</span
                                >
                                <v-radio-group
                                  v-model="school.d_enrolled"
                                  row
                                  class="my-0"
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[21]"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--School Type-->
                              <v-col cols="12" md="9">
                                <span class="grey--text text--darken-1 text-caption pb-0"
                                  >School Type</span
                                >
                                <v-radio-group
                                  v-model="school.d_type"
                                  row
                                  class="my-0"
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    label="K-12"
                                    value="K-12"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Undergraduate"
                                    value="Undergraduate"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Graduate, Master's"
                                    value="Graduate, Master's"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Graduate, PhD"
                                    value="Graduate, PhD"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                  <v-radio
                                    label="Post-Graduate"
                                    value="Post-Graduate"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--Financial Aid-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="school.d_financialAidAmount"
                                  label="Financial Aid"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Financial Aid Period-->
                              <v-col cols="12" md="4">
                                <v-radio-group
                                  label="Per: "
                                  v-model="school.d_financialAidPeriod"
                                  row
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    label="Semester"
                                    color="primary"
                                    value="Semester"
                                  ></v-radio>
                                  <v-radio label="Year" color="primary" value="Year"></v-radio>
                                </v-radio-group>
                              </v-col>

                              <!--Tuition-->
                              <v-col cols="12" md="2">
                                <v-text-field
                                  v-model="school.d_tuitionAmount"
                                  label="Tuition"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Tuition Period-->
                              <v-col cols="12" md="4">
                                <v-radio-group
                                  label="Per: "
                                  v-model="school.d_tuitionAmountPeriod"
                                  row
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    label="Semester"
                                    color="primary"
                                    value="Semester"
                                  ></v-radio>
                                  <v-radio label="Year" color="primary" value="Year"></v-radio>
                                </v-radio-group>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <!--PRIMARY OTHER INCOME-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Other Income</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addOtherIncome(2)"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row
                              dense
                              :key="index"
                              v-for="(income, index) in j_otherIncomeOtherParent"
                            >
                              <!--Income Type-->
                              <v-col cols="12" md="3">
                                <v-select
                                  :items="valueListsStore.valueListItems[3]"
                                  item-text="d_name"
                                  item-value="d_name"
                                  label="Type"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="income.d_type"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                  :readonly="f_status === 2"
                                  required
                                />
                              </v-col>

                              <!--Amount-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="income.d_amount"
                                  label="Amount"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Income Period-->
                              <v-col cols="12" md="5">
                                <v-radio-group
                                  label="Per: "
                                  v-model="income.d_period"
                                  row
                                  :readonly="f_status === 2"
                                >
                                  <v-radio
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[18]"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(3, 'This other income record', index, 2)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-------------------- Documents -------------------->
              <v-stepper-content step="3">
                <v-card flat>
                  <v-card-title class="text-h6 secondary--text pb-0 font-weight-bold">
                    Supporting Eligibility Documentation
                  </v-card-title>
                  <v-card-title class="text-subtitle-1 pt-0 v-card-word-break">
                    <p>
                      With this application, the other parent/guardian named above must submit the
                      following documentation of activity and income listed above for the purposes
                      of eligibility determination. If there are questions about any of these items
                      or one of the forms listed below is needed, please call (919) 403-6950 and ask
                      to speak to a Scholarship Specialist.
                    </p>
                  </v-card-title>
                  <v-card-text>
                    <v-container fluid>
                      <!--Proof of Employment-->
                      <v-row dense>
                        <v-col cols="12" sm="12">
                          <v-checkbox
                            v-model="d_proofEmployment"
                            label="Proof of employment and income"
                            value="Yes"
                            color="primary"
                            class="pr-2"
                            :readonly="f_status === 2"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12">
                          <p>
                            Examples: 2-4 pay stubs within one month of contract start date.
                            Employer letter or verification form with start date, rate and frequency
                            of pay and average weekly work hours. Self-employed applicants must
                            submit a work schedule with their most recent tax form or a statement of
                            3 months’ profit/loss.
                          </p>
                        </v-col>
                      </v-row>

                      <!--Proof of School-->
                      <v-row dense>
                        <v-col cols="12" sm="12">
                          <v-checkbox
                            v-model="d_proofSchool"
                            label="Proof of school enrollment and details of financial aid and tuition"
                            value="Yes"
                            color="primary"
                            class="pr-2"
                            :readonly="f_status === 2"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12">
                          <p>
                            Examples: Printout from school website showing current semester
                            activity, including classes, credit hours and dates of enrollment. A
                            letter from a school official verifying enrollment and credit hours or
                            schedule. A third party certification of enrollment status and dates of
                            current school term, such as (<a
                              href="http://www.studentclearinghouse.org"
                              target="blank"
                              >http://www.studentclearinghouse.org</a
                            >). Statement of financial aid award. Bill for tuition for current term
                            or full academic year.
                          </p>
                        </v-col>
                      </v-row>

                      <!--Proof of other types of income-->
                      <v-row dense>
                        <v-col cols="12" sm="12">
                          <v-checkbox
                            v-model="d_proofOtherIncome"
                            label="Proof of other types of income"
                            value="Yes"
                            color="primary"
                            class="pr-2"
                            :readonly="f_status === 2"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12">
                          <p>
                            Examples: Child support court order. Recent SSI award statement. Record
                            of child support (<a href="www.ncchildsupport.com" target="blank"
                              >www.ncchildsupport.com</a
                            >) or SSI payments (<a
                              href="https://secure.ssa.gov/RIL/SiView.do"
                              target="blank"
                              >https://secure.ssa.gov/RIL/SiView.do</a
                            >) made for last 3 months+. Personal statement by child support payer
                            including amount and frequency of payments.
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-form ref="step3" v-model="valid3" lazy-validation>
                      <v-container fluid>
                        <!--DOCUMENTS-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Documents</v-toolbar-title>
                          </v-toolbar>
                        </v-card>

                        <v-card flat v-if="f_status !== 2">
                          <v-container fluid>
                            <v-row dense>
                              <!--Type-->
                              <v-col cols="12" md="5">
                                <v-select
                                  :items="documentTypesStore.documentTypesValueList"
                                  item-text="d_name"
                                  item-value="d_name"
                                  label="Type"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="fileType"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                  required
                                />
                              </v-col>

                              <!--File-->
                              <v-col cols="12" md="5">
                                <v-file-input
                                  ref="file"
                                  v-model="files"
                                  clearable
                                  multiple
                                  clear-icon="fal fa-times-circle"
                                  prepend-icon="fal fa-paperclip"
                                  label="Select File"
                                  placeholder=" "
                                  persistent-placeholder
                                >
                                </v-file-input>
                              </v-col>

                              <!--Upload Button-->
                              <v-col cols="12" md="2">
                                <v-btn
                                  block
                                  rounded
                                  dark
                                  color="green lighten-1"
                                  class="mb-2"
                                  @click="addFile()"
                                >
                                  <v-icon small left>fal fa-cloud-upload</v-icon>Upload
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <template v-for="(document, index) in j_documents">
                              <v-list-item
                                :key="index"
                                @click="openDocument(document.d_path)"
                                class="my-1"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ document.d_type }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ document.d_path }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    v-if="f_status !== 2"
                                    text
                                    icon
                                    color="red"
                                    @click.stop="openDeleteDialog(4, 'This document', index, 1)"
                                  >
                                    <v-icon>fal fa-trash</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider></v-divider>
                            </template>
                          </v-container>
                        </v-card>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-------------------- Household -------------------->
              <v-stepper-content step="4">
                <v-card flat>
                  <v-card-title class="text-h6 secondary--text pb-0 font-weight-bold">
                    Household Information
                  </v-card-title>
                  <v-card-title class="text-subtitle-1 pt-0 v-card-word-break">
                    <p>
                      A household, for the purposes of this application, is considered the primary
                      applicant and other parent/guardian (if applicable) residing at the street
                      address listed below and any of their dependents. A dependent is someone who
                      relies on the primary applicant and/or other parent/guardian for financial
                      support, such as children claimed on a tax return or an elderly disabled
                      parent.
                    </p>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="step4" v-model="valid4" lazy-validation>
                      <v-container fluid>
                        <!-- Residence Address -->
                        <v-row dense>
                          <!--Street Address-->
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="d_residenceStreet1"
                              label="Street Address"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              :rules="rules.d_residenceStreet1"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Street Address 2-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_residenceStreet2"
                              label="App/Unit #"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--City-->
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="d_residenceCity"
                              label="City"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              :rules="rules.d_residenceCity"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--State-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_residenceState"
                              label="State"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              :rules="rules.d_residenceState"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Zip-->
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_residencePostalCode"
                              label="Zip"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              :rules="rules.d_residencePostalCode"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--County-->
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              :items="countiesStore.countiesValueList"
                              item-text="d_name"
                              item-value="id"
                              label="County"
                              placeholder=" "
                              persistent-placeholder
                              v-model="fk_countyID_Residence"
                              key="id"
                              :clearable="f_status !== 2"
                              :readonly="f_status === 2"
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="12">
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>

                        <!--Mailing Address The Same-->
                        <v-row dense>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="d_mailingAddressSame"
                              label="Mailing Address Same As Physical"
                              value="Yes"
                              color="primary"
                              class="pr-2"
                              :readonly="f_status === 2"
                              @click.native="setMailingAddress(d_mailingAddressSame)"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <!-- Mailing Address -->
                        <v-row dense>
                          <!--Street Address-->
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="d_mailingStreet1"
                              label="Mailing Street Address"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Street Address 2-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_mailingStreet2"
                              label="App/Unit #"
                              placeholder=" "
                              persistent-placeholder
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--City-->
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="d_mailingCity"
                              label="City"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--State-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_mailingState"
                              label="State"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Zip-->
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_mailingPostalCode"
                              label="Zip"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--County-->
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              :items="countiesStore.countiesValueList"
                              item-text="d_name"
                              item-value="id"
                              label="County"
                              placeholder=" "
                              persistent-placeholder
                              v-model="fk_countyID_Mailing"
                              key="id"
                              :clearable="f_status !== 2"
                              :readonly="f_status === 2"
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <!--Contact Details-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Contact Details</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addContact()"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row dense :key="index" v-for="(contact, index) in j_contactDetails">
                              <!--Owner-->
                              <v-col cols="12" md="3">
                                <v-select
                                  :items="valueListsStore.valueListItems[4]"
                                  item-text="d_name"
                                  item-value="d_name"
                                  label="Belongs To"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="contact.d_owner"
                                  :clearable="f_status !== 2"
                                  :readonly="f_status === 2"
                                  clear-icon="fal fa-times-circle"
                                  required
                                />
                              </v-col>

                              <!--Value-->
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="contact.d_value"
                                  label="Contact Info"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Type-->
                              <v-col cols="12" md="3">
                                <v-select
                                  :items="valueListsStore.valueListItems[6]"
                                  item-text="d_name"
                                  item-value="d_name"
                                  label="Type"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="contact.d_type"
                                  :clearable="f_status !== 2"
                                  :readonly="f_status === 2"
                                  clear-icon="fal fa-times-circle"
                                  required
                                />
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(5, 'This contact record', index, 1)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <v-col cols="12" sm="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <!--Household Dependents-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Household Dependents</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addDependant()"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <v-card flat>
                          <v-container fluid>
                            <v-row
                              dense
                              :key="index"
                              v-for="(dependant, index) in j_householdDependants"
                            >
                              <!--Name-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="dependant.d_name"
                                  label="Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <!--Relationship-->
                              <v-col cols="12" md="2">
                                <v-select
                                  :items="valueListsStore.valueListItems[7]"
                                  item-text="d_name"
                                  item-value="d_name"
                                  label="Relationship"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="dependant.d_relationship"
                                  :clearable="f_status !== 2"
                                  :readonly="f_status === 2"
                                  clear-icon="fal fa-times-circle"
                                  required
                                />
                              </v-col>

                              <!--Related To-->
                              <v-col cols="12" md="3">
                                <v-select
                                  :items="valueListsStore.valueListItems[4]"
                                  item-text="d_name"
                                  item-value="d_name"
                                  label="Related To"
                                  placeholder=" "
                                  persistent-placeholder
                                  v-model="dependant.d_parent"
                                  :clearable="f_status !== 2"
                                  :readonly="f_status === 2"
                                  clear-icon="fal fa-times-circle"
                                  required
                                />
                              </v-col>

                              <!--DOB-->
                              <v-col cols="12" md="3">
                                <v-text-field
                                  v-model="dependant.d_dateBirth"
                                  label="Date Of Birth"
                                  placeholder=" "
                                  persistent-placeholder
                                  hint="MM/DD/YYYY"
                                  persistent-hint
                                  @blur="
                                    dependant.d_dateBirth = $_formatStringDate(
                                      dependant.d_dateBirth
                                    )
                                  "
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="1" class="text-center">
                                <v-btn
                                  v-if="f_status !== 2"
                                  text
                                  icon
                                  color="red"
                                  @click="openDeleteDialog(6, 'This dependent record', index, 1)"
                                >
                                  <v-icon>fal fa-trash</v-icon>
                                </v-btn>
                              </v-col>

                              <v-col cols="12" sm="12">
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <!--Family Structure-->
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Family Structure</v-toolbar-title>
                          </v-toolbar>
                        </v-card>

                        <!--Family Size-->
                        <v-card flat>
                          <v-container fluid>
                            <v-row dense>
                              <v-col cols="12" sm="12" md="3">
                                <v-text-field
                                  v-model="d_familySize"
                                  label="Family Size"
                                  placeholder="##"
                                  :readonly="f_status === 2"
                                  :rules="rules.d_familySize.concat(rules.only_digits)"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <v-col cols="12" sm="12">
                                <v-radio-group
                                  v-model="d_familyStructure"
                                  row
                                  class="my-0"
                                  :rules="rules.d_familyStructure"
                                  :readonly="f_status === 2"
                                  required
                                >
                                  <v-radio
                                    :key="index"
                                    v-for="(item, index) in valueListsStore.valueListItems[8]"
                                    :label="item.d_name"
                                    :value="item.d_name"
                                    color="primary"
                                    class="pr-2"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>

                            <v-row dense v-if="d_familyStructure === 'Other'">
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  v-model="d_familyStructureOther"
                                  label="Other Family Structure"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-------------------- Applying Children -------------------->
              <v-stepper-content step="5">
                <v-card flat>
                  <v-card-text>
                    <v-form ref="step5" v-model="valid5" lazy-validation>
                      <v-container fluid>
                        <v-card flat>
                          <v-toolbar dense flat color="secondary" dark>
                            <v-toolbar-title>Applying Children</v-toolbar-title>
                            <v-spacer />
                            <v-btn v-if="f_status !== 2" rounded outlined @click="addChild()"
                              ><v-icon small left>fal fa-plus</v-icon>Add
                            </v-btn>
                          </v-toolbar>
                        </v-card>

                        <!--Line Item Pagination-->
                        <v-row>
                          <v-col cols="12" sm="12">
                            <!--First-->
                            <v-btn :disabled="currentChild === 1" icon @click="paginate(1)">
                              <v-icon color="primary">
                                fal fa-step-backward
                              </v-icon>
                            </v-btn>

                            <!--Previous-->
                            <v-btn
                              :disabled="currentChild === 1"
                              icon
                              @click="paginate(currentChild - 1)"
                            >
                              <v-icon color="primary">
                                fal fa-caret-left
                              </v-icon>
                            </v-btn>

                            <span>{{ currentChild }} of {{ j_applyingChild.length }}</span>

                            <!--Next-->
                            <v-btn
                              :disabled="currentChild === j_applyingChild.length"
                              icon
                              @click="paginate(currentChild + 1)"
                            >
                              <v-icon color="primary">
                                fal fa-caret-right
                              </v-icon>
                            </v-btn>

                            <!--Last-->
                            <v-btn
                              :disabled="currentChild === j_applyingChild.length"
                              icon
                              @click="paginate(j_applyingChild.length)"
                            >
                              <v-icon color="primary">
                                fal fa-step-forward
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--First Name-->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="j_applyingChild[currentChild - 1].d_nameFirst"
                              label="First Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              :rules="rules.childNameFirst"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Middle Name-->
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="j_applyingChild[currentChild - 1].d_nameMiddle"
                              label="Middle Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Last Name-->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="j_applyingChild[currentChild - 1].d_nameLast"
                              label="Last Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              :rules="rules.childNameLast"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Delete Child-->
                          <v-col cols="12" md="1">
                            <v-btn
                              v-if="f_status !== 2"
                              text
                              icon
                              color="red"
                              @click="
                                openDeleteDialog(
                                  7,
                                  'This child will be deleted from the application',
                                  currentChild - 1,
                                  1
                                )
                              "
                            >
                              <v-icon>fal fa-trash</v-icon>
                            </v-btn>
                          </v-col>

                          <!--Birth Date-->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="j_applyingChild[currentChild - 1].d_dateBirth"
                              label="Date of Birth"
                              placeholder=" "
                              persistent-placeholder
                              hint="MM/DD/YYYY"
                              persistent-hint
                              @blur="
                                j_applyingChild[currentChild - 1].d_dateBirth = $_formatStringDate(
                                  j_applyingChild[currentChild - 1].d_dateBirth
                                )
                              "
                              :readonly="f_status === 2"
                              :rules="rules.childDateBirth"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <!--Date of Birth Verified-->
                          <v-col cols="12" sm="6" md="2">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Date of Birth Verified"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="value"
                              v-model="j_applyingChild[currentChild - 1].d_verifiedDateOfBirth"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--Gender-->
                          <v-col cols="12" md="4">
                            <span class="grey--text text--darken-1 text-caption pb-0">Gender</span>
                            <v-radio-group
                              v-model="j_applyingChild[currentChild - 1].d_gender"
                              row
                              class="my-0"
                              :readonly="f_status === 2"
                            >
                              <v-radio
                                v-for="(item, index) in valueListsStore.valueListItems[5]"
                                :key="index"
                                :label="item.d_name"
                                color="primary"
                                :value="item.d_name"
                                class="pr-2"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>

                          <!--Race-->
                          <v-col cols="12" md="6">
                            <span class="grey--text text--darken-1 text-caption">Race</span>
                            <v-radio-group dense class="my-0 py-0" :readonly="f_status === 2">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[12]"
                                v-model="j_applyingChild[currentChild - 1].d_race"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0"
                                :class="index > 0 ? 'my-0' : ''"
                              ></v-checkbox>
                            </v-radio-group>

                            <v-row class="my-0 py-0">
                              <v-col cols="12" sm="12" md="10">
                                <v-text-field
                                  v-model="j_applyingChild[currentChild - 1].d_raceOther"
                                  label="Other Race"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Weekly Hours of Care Needed-->
                          <v-col cols="12" md="6">
                            <span class="grey--text text--darken-1 text-caption"
                              >Weekly Hours of Care Needed</span
                            >
                            <v-radio-group dense class="my-0 py-0">
                              <v-checkbox
                                dense
                                label="Full Time"
                                v-model="j_applyingChild[currentChild - 1].d_weeklyHoursCare"
                                color="primary"
                                multiple
                                value="Full Time"
                                class="pr-2 py-0"
                                :readonly="f_status === 2"
                              ></v-checkbox>
                              <v-checkbox
                                dense
                                label="3/4 Time"
                                v-model="j_applyingChild[currentChild - 1].d_weeklyHoursCare"
                                color="primary"
                                multiple
                                value="3/4 Time"
                                class="pr-2 py-0 my-0"
                                :readonly="f_status === 2"
                              ></v-checkbox>
                              <v-checkbox
                                dense
                                label="Half Time"
                                v-model="j_applyingChild[currentChild - 1].d_weeklyHoursCare"
                                color="primary"
                                multiple
                                value="Half Time"
                                class="pr-2 py-0 my-0"
                                :readonly="f_status === 2"
                              ></v-checkbox>
                              <v-checkbox
                                dense
                                label="Before School"
                                v-model="j_applyingChild[currentChild - 1].d_weeklyHoursCare"
                                color="primary"
                                multiple
                                value="Before School"
                                class="pr-2 py-0 my-0"
                                :readonly="f_status === 2"
                              ></v-checkbox>
                              <v-checkbox
                                dense
                                label="After School"
                                v-model="j_applyingChild[currentChild - 1].d_weeklyHoursCare"
                                color="primary"
                                multiple
                                value="After School"
                                class="pr-2 py-0 my-0"
                                :readonly="f_status === 2"
                              ></v-checkbox>
                              <v-checkbox
                                dense
                                label="Before and After School"
                                v-model="j_applyingChild[currentChild - 1].d_weeklyHoursCare"
                                color="primary"
                                multiple
                                value="Before and After School"
                                class="pr-2 py-0 my-0"
                                :readonly="f_status === 2"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>

                          <!--Additional Information-->
                          <v-col cols="12" md="6">
                            <span class="grey--text text--darken-1 text-caption"
                              >Additional Information</span
                            >
                            <v-checkbox
                              dense
                              label="Child is covered by health insurance"
                              v-model="
                                j_applyingChild[currentChild - 1].d_additionalInfoHealthInsurance
                              "
                              color="primary"
                              value="Yes"
                              class="pr-2 py-0"
                              :readonly="f_status === 2"
                            ></v-checkbox>
                            <v-checkbox
                              dense
                              label="Child is U.S. Citizen and N.C. Resident"
                              v-model="j_applyingChild[currentChild - 1].d_additionalInfoUSCitizen"
                              color="primary"
                              value="Yes"
                              class="pr-2 py-0 my-0"
                              :readonly="f_status === 2"
                            ></v-checkbox>
                            <v-checkbox
                              dense
                              label="Child has special need"
                              v-model="
                                j_applyingChild[currentChild - 1].d_additionalInfoSpecialNeed
                              "
                              color="primary"
                              value="Yes"
                              class="pr-2 py-0 my-0"
                              :readonly="f_status === 2"
                            ></v-checkbox>
                            <v-row>
                              <v-col cols="12" sm="12" md="10">
                                <v-text-field
                                  v-model="
                                    j_applyingChild[currentChild - 1]
                                      .d_additionalInfoSpecialNeedDescribe
                                  "
                                  label="Type of Special Need"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-checkbox
                              dense
                              label="Child is receiving services for special need"
                              v-model="
                                j_applyingChild[currentChild - 1].d_additionalInfoReceivingServices
                              "
                              color="primary"
                              value="Yes"
                              class="pr-2 py-0 my-0"
                              :readonly="f_status === 2"
                            ></v-checkbox>
                            <v-row dense>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="j_applyingChild[currentChild - 1].d_dateServicePlanEnd"
                                  label="Service Plan End Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  hint="MM/DD/YYYY"
                                  persistent-hint
                                  @blur="
                                    j_applyingChild[
                                      currentChild - 1
                                    ].d_dateServicePlanEnd = $_formatStringDate(
                                      j_applyingChild[currentChild - 1].d_dateServicePlanEnd
                                    )
                                  "
                                  :readonly="f_status === 2"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Provider-->
                          <v-col cols="12" md="6">
                            <v-row dense class="py-0 my-0">
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  v-model="j_applyingChild[currentChild - 1].d_facilityName"
                                  label="Child Care Provider"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" sm="12">
                                <v-text-field
                                  v-model="j_applyingChild[currentChild - 1].d_facilityDirector"
                                  label="Director/Contact"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" sm="12">
                                <v-text-field
                                  v-model="j_applyingChild[currentChild - 1].d_facilityPhone"
                                  label="Phone"
                                  placeholder=" "
                                  persistent-placeholder
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" sm="12">
                                <v-checkbox
                                  dense
                                  label="Currently Enrolled"
                                  v-model="
                                    j_applyingChild[currentChild - 1].d_facilityChildEnrolled
                                  "
                                  color="primary"
                                  value="Yes"
                                  class="pr-2 py-0"
                                  :readonly="f_status === 2"
                                ></v-checkbox>
                              </v-col>

                              <v-col cols="12" md="12">
                                <v-text-field
                                  v-model="
                                    j_applyingChild[currentChild - 1].d_dateFacilityChildEnrolled
                                  "
                                  label="Enrolled Since"
                                  placeholder=" "
                                  persistent-placeholder
                                  hint="MM/DD/YYYY"
                                  @blur="
                                    j_applyingChild[
                                      currentChild - 1
                                    ].d_dateFacilityChildEnrolled = $_formatStringDate(
                                      j_applyingChild[currentChild - 1].d_dateFacilityChildEnrolled
                                    )
                                  "
                                  persistent-hint
                                  :readonly="f_status === 2"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>

                          <!--Anyone else share financial responsibility-->
                          <v-col cols="12" md="12">
                            <span class="grey--text text--darken-1 text-caption pb-0"
                              >Additional Financial Support</span
                            >
                            <v-radio-group
                              v-model="j_applyingChild[currentChild - 1].d_shareFinancial"
                              row
                              class="my-0"
                              :readonly="f_status === 2"
                            >
                              <v-radio
                                :key="index"
                                v-for="(item, index) in valueListsStore.yesNo"
                                :label="item.value"
                                :value="item.value"
                                color="primary"
                                class="pr-2"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="j_applyingChild[currentChild - 1].d_shareFinancialName"
                              label="Supporter's Name"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="
                                j_applyingChild[currentChild - 1].d_shareFinancialRelationship
                              "
                              label="Supporter's Relationship to Child"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="j_applyingChild[currentChild - 1].d_shareFinancialDescribe"
                              label="Support Description"
                              placeholder=" "
                              persistent-placeholder
                              :readonly="f_status === 2"
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-------------------- Agreements/Acknowledgement -------------------->
              <v-stepper-content step="6">
                <v-form ref="step6" v-model="valid6" lazy-validation>
                  <v-card flat>
                    <v-row dense>
                      <v-col cols="12" md="3">
                        <!--Left Side-->
                        <v-card-title class="text-h6 secondary--text pb-0 font-weight-bold">
                          Confirmation
                        </v-card-title>
                        <v-card-text>
                          <v-container fluid>
                            <v-row dense>
                              <v-row dense>
                                <!--Application Date-->
                                <v-col cols="12" md="12">
                                  <v-text-field
                                    v-model="d_date"
                                    label="Application Date"
                                    placeholder=" "
                                    persistent-placeholder
                                    hint="MM/DD/YYYY"
                                    persistent-hint
                                    @blur="
                                      d_date = $_formatStringDate(d_date);
                                      date1 = $_parseDate(d_date);
                                    "
                                    :rules="rules.d_date"
                                    :readonly="f_status === 2"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>

                              <!--<v-row dense>
                                    <v-col cols="12" md="12">
                                      <span class="grey--text text--darken-1 text-caption pb-0">Primary Applicant Agreement To Update</span>
                                      <v-radio-group v-model="d_agreementUpdatePrimary" row class="my-0" :readonly="f_status === 2">
                                        <v-radio :key="index" v-for="(item,index) in valueListsStore.yesNo" :label="item.value" :value="item.value" color="primary" class="pr-2"></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>

                                  <v-row dense>
                                    <v-col cols="12" md="12">
                                      <span class="grey--text text--darken-1 text-caption pb-0">Other Parent/Guardian Agreement To Update</span>
                                      <v-radio-group v-model="d_agreementUpdateOtherParent" row class="my-0" :readonly="f_status === 2">
                                        <v-radio :key="index" v-for="(item,index) in valueListsStore.yesNo" :label="item.value" :value="item.value" color="primary" class="pr-2"></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>-->

                              <v-row dense>
                                <v-col cols="12" md="12">
                                  <v-select
                                    :items="valueListsStore.applicationStatus"
                                    item-text="value"
                                    item-value="id"
                                    label="Status"
                                    placeholder=" "
                                    persistent-placeholder
                                    v-model="f_status"
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                    required
                                  />
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" md="12">
                                  <v-textarea
                                    v-model="d_note"
                                    label="Note"
                                    placeholder=" "
                                    persistent-placeholder
                                    :readonly="f_status === 2"
                                  />
                                </v-col>
                              </v-row>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-col>

                      <!--Right Side-->
                      <v-col cols="12" md="9">
                        <v-card-title class="text-h6 secondary--text pb-0 font-weight-bold">
                          Parent/Guardian Participation Agreement
                        </v-card-title>
                        <v-card-title class="text-subtitle-1 py-0 v-card-word-break">
                          The primary applicant and, if present in the home, other parent/guardian
                          should read and initial each statement below, then sign and date this form
                          at the bottom. For any item on this page requiring clarification, please
                          reach out to any member of the Scholarship team at
                          FamilyScholarship@childcareservices.org or 919-403-6950 x2108.
                        </v-card-title>
                        <v-card-text class="py-0">
                          <v-container fluid>
                            <!--Agreement1-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement1"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :rules="rules.participateAgreement"
                                    :readonly="f_status === 2"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      I understand that my application to the Scholarship Program is
                                      part of my eligibility review for Scholarship service but does
                                      not guarantee service. I understand that my Scholarship
                                      Specialist will inform me of the results of my eligibility
                                      review once I’ve submitted all required documentation.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement2-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement2"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      If awarded Scholarship service, I understand that I will
                                      receive a Notice of Scholarship Award containing the details
                                      of my service including but not limited to the dates and
                                      amount of care, child care facility information, parent fees,
                                      and contact information for my assigned Scholarship Specialist.
                                      I am aware that if the details of my award should change, I
                                      will be issued subsequent notices containing updated
                                      information about my award. I understand that any child care I
                                      use that is not covered in an initial or subsequent Notice of
                                      Scholarship Award will be entirely my financial
                                      responsibility.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement3-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement3"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      I accept responsibility for keeping the details included in my
                                      Scholarship application up to date with my Scholarship
                                      Specialist, including any changes in employer or employment
                                      status, hours devoted to school or work, income of any type,
                                      and household size and make-up. I understand that any
                                      significant change in parent/family circumstances may affect
                                      the type of scholarship, if any, I am eligible for, and
                                      failure to report such a change may result in termination of
                                      services.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement4-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement4"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      I agree to remain reachable throughout the term of any
                                      Scholarship service my family may be awarded and respond in a
                                      timely manner to any request for additional documentation by
                                      my Scholarship Specialist. I understand that failure to
                                      respond, to provide requested documentation, or to make
                                      updates to my eligibility details may result in termination of
                                      Scholarship service or full or partial repayment of the
                                      scholarship paid by CCSA to my provider on my child’s behalf.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement5-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement5"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      I understand that, should I be awarded Scholarship service,
                                      monthly parent fees assigned to me will be based on a
                                      percentage of my income, as calculated by documentation
                                      submitted for my eligibility review, and that I would be
                                      expected to pay these fees directly to my child care provider
                                      on the schedule my provider would set. Should I begin or end
                                      care mid-month, I understand that my provider should divide my
                                      parent fee by 21.67 to reach a daily rate for the purposes of
                                      prorating the fee for that month. I realize that nonpayment of
                                      parent fees to my child care facility may result in
                                      termination of my Scholarship service there and that
                                      Scholarship service cannot be transferred to any other child
                                      care program until all outstanding child care fees are paid in
                                      full.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement6-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement6"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      If awarded Scholarship service, I agree to bring my child
                                      regularly to child care utilizing the amount of care in my
                                      Notice of Scholarship Award and to notify my Scholarship
                                      Specialist if my child’s absence from care exceeds 5 business
                                      days in any month of Scholarship service. I further agree to
                                      give my child care provider and Scholarship Specialist two full
                                      weeks’ notice, preferably in writing, prior to removing my
                                      child from care, realizing that failure to do so could delay
                                      transfer of Scholarship service to another provider.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement7-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement7"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      I understand that child care providers are business owners or
                                      operators. If awarded Scholarship service, I understand that
                                      my enrollment at a particular site makes me subject to the
                                      same rules, hours, paperwork, fees, policies, etc. as families
                                      paying privately for care at the same facility.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement8-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement8"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      I confirm that I am not currently using any other form of
                                      child care subsidy, scholarship, or financial assistance to
                                      pay for child care and that if such assistance were to be
                                      offered, I would inform my Scholarship Specialist right away.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!--Agreement9-->
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-row dense>
                                  <v-checkbox
                                    style="margin-top:8px"
                                    v-model="d_participateAgreement9"
                                    value="Yes"
                                    color="primary"
                                    class="pr-2"
                                    :readonly="f_status === 2"
                                    :rules="rules.participateAgreement"
                                    required
                                  >
                                  </v-checkbox>
                                  <v-col cols="12" md="11">
                                    <p>
                                      I understand that failure to follow the items I have initialed
                                      in this Parent Participation Agreement could jeopardize my
                                      continued eligibility for Scholarship service.
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" md="12">
                        <v-card-title class="text-h6 secondary--text pl-3 py-0 font-weight-bold">
                          Acknowledgement
                        </v-card-title>
                        <v-col cols="12" md="12" class="py-1">
                          <p>
                            By signing below, I affirm that the information provided in this
                            application is true, accurate and complete. I understand that any
                            information provided in this application may be shared with and released
                            to third parties, including but not limited to Durham and Orange County
                            Departments of Social Services, Head Start/Early Head Start, NC Pre-K
                            and other local subsidy partners. I hereby authorize and consent to the
                            release and sharing of such information by Child Care Services
                            Association (CCSA) to and with third parties. On behalf of the child,
                            and in my individual capacity, I hereby release CCSA, its employees and
                            agents from any liability or damages that may result from the release or
                            sharing of such information, including liability or damages resulting
                            from possible inaccuracies, errors or other inadvertent occurrences
                            relating to such information or the maintenance of such information.
                          </p>
                        </v-col>
                      </v-col>
                    </v-row>
                    <!--relocated signature fields-->
                    <v-col cols="12" md="12" class="py-1">
                      <v-row dense>
                        <v-col cols="12" md="4">
                          <span class="grey--text text--darken-1 text-caption pb-0"
                            >Primary Applicant Signature</span
                          >
                          <v-radio-group
                            v-model="d_signaturePrimary"
                            row
                            class="my-0"
                            :rules="rules.d_signaturePrimary"
                            :readonly="f_status === 2"
                          >
                            <v-radio
                              :key="index"
                              v-for="(item, index) in valueListsStore.yesNo"
                              :label="item.value"
                              :value="item.value"
                              color="primary"
                              class="pr-2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model="d_signaturePrimaryDate"
                            label="Signature Date"
                            placeholder=" "
                            persistent-placeholder
                            hint="MM/DD/YYYY"
                            persistent-hint
                            @blur="
                              d_signaturePrimaryDate = $_formatStringDate(d_signaturePrimaryDate);
                              signaturePrimaryDate = $_parseDate(d_signaturePrimaryDate);
                            "
                            :readonly="f_status === 2"
                            :rules="rules.d_signaturePrimaryDate"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="12" md="4">
                          <span class="grey--text text--darken-1 text-caption pb-0"
                            >Other Parent/Guardian Signature</span
                          >
                          <v-radio-group
                            v-model="d_signatureOtherParent"
                            row
                            class="my-0"
                            :readonly="f_status === 2"
                          >
                            <v-radio
                              :key="index"
                              v-for="(item, index) in valueListsStore.yesNo"
                              :label="item.value"
                              :value="item.value"
                              color="primary"
                              class="pr-2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model="d_signatureOtherParentDate"
                            label="Signature Date"
                            placeholder=" "
                            persistent-placeholder
                            hint="MM/DD/YYYY"
                            persistent-hint
                            @blur="
                              d_signatureOtherParentDate = $_formatStringDate(
                                d_signatureOtherParentDate
                              );
                              signatureOtherParentDate = $_parseDate(d_signatureOtherParentDate);
                            "
                            :readonly="f_status === 2"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" md="2" v-if="e1 != 1">
                <v-btn block rounded color="primary" dark @click="previousTab()">
                  <v-icon small left>fal fa-arrow-alt-left</v-icon>Previous
                </v-btn>
              </v-col>

              <v-col v-if="f_status === 1 && e1 != 6" cols="12" :sm="e1 == 1 ? 12 : 10">
                <v-btn block rounded color="primary" dark @click="saveEntry()">
                  <v-icon small left>fal fa-check</v-icon>Submit & Continue
                </v-btn>
              </v-col>

              <v-col v-if="f_status !== 1 && e1 != 6" cols="12" :sm="e1 == 1 ? 12 : 10">
                <v-btn block rounded color="primary" dark @click="nextTab()">
                  <v-icon small left>fal fa-check</v-icon>Next
                </v-btn>
              </v-col>

              <v-col cols="12" md="10" v-if="e1 == 6">
                <v-btn block rounded color="primary" dark @click="saveEntry()">
                  <v-icon small left>fal fa-check</v-icon>Submit & Close
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import { clientID, clientSecret, uploadHeader } from "../env";

const { mapFields } = createHelpers({
  getterType: "applicationsStore/getEntryField",
  mutationType: "applicationsStore/updateEntryField"
});

export default {
  name: "ApplicationEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      applicationsStore: state => state.applicationsStore,
      documentTypesStore: state => state.documentTypesStore,
      countiesStore: state => state.countiesStore,
      valueListsStore: state => state.valueListsStore,
      waitingListStore: state => state.waitingListStore,
    }),
    ...mapFields([
      "show",
      "valid1",
      "valid2",
      "valid3",
      "valid4",
      "valid5",
      "valid6",
      "section",
      "mode",
      "id",
      "fk_clientID",
      "fk_countyID_Residence",
      "fk_countyID_Mailing",
      "d_wlsID",
      "d_nameFirst",
      "d_nameMiddle",
      "d_nameLast",
      "d_dateBirth",
      "dateBirth",
      "f_privateRecord",
      "d_primaryRelationship",
      "j_primaryRace",
      "d_primaryRaceOther",
      "d_proofEmployment",
      "d_proofSchool",
      "d_proofOtherIncome",
      "d_nameFirstOtherParent",
      "d_nameMiddleOtherParent",
      "d_nameLastOtherParent",
      "d_dateBirthOtherParent",
      "dateBirthOtherParent",
      "d_otherParentRelationship",
      "j_otherParentRace",
      "d_otherParentRaceOther",
      "d_residenceStreet1",
      "d_residenceStreet2",
      "d_residenceCity",
      "d_residenceState",
      "d_residencePostalCode",
      "d_mailingStreet1",
      "d_mailingStreet2",
      "d_mailingCity",
      "d_mailingState",
      "d_mailingPostalCode",
      "d_familySize",
      "d_familyStructure",
      "d_familyStructureOther",
      "d_date",
      "date1",
      "d_agreementUpdatePrimary",
      "d_agreementUpdateOtherParent",
      "d_participateAgreement1",
      "d_participateAgreement2",
      "d_participateAgreement3",
      "d_participateAgreement4",
      "d_participateAgreement5",
      "d_participateAgreement6",
      "d_participateAgreement7",
      "d_participateAgreement8",
      "d_participateAgreement9",
      "d_signaturePrimary",
      "d_signaturePrimaryDate",
      "signaturePrimaryDate",
      "d_signatureOtherParent",
      "d_signatureOtherParentDate",
      "signatureOtherParentDate",
      "f_clientCreated",
      "d_note",
      "d_mailingAddressSame",
      "f_status"
    ]),
    ...mapMultiRowFields("applicationsStore", [
      "entry.j_employmentPrimary",
      "entry.j_schoolPrimary",
      "entry.j_otherIncomePrimary",
      "entry.j_documents",
      "entry.j_contactDetails",
      "entry.j_employmentOtherParent",
      "entry.j_schoolOtherParent",
      "entry.j_otherIncomeOtherParent",
      "entry.j_householdDependants",
      "entry.j_applyingChild"
    ])
  },
  props: {
    /* All List = 1, Client = 2 */
    applicationSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      e1: 1,
      rules: {
        nameFirst: [v => !!v || "First Name is required"],
        nameLast: [v => !!v || "Last Name is required"],
        dateBirth: [v => !!v || "Birth Date is required (MM/DD/YYYY)"],
        primaryRelationship: [v => !!v || "Relationship To Child is required"],
        d_employer: [v => !!v || "Employer is required"],
        d_position: [v => !!v || "Position is required"],
        d_employerCity: [v => !!v || "Employer City is required"],
        d_income: [v => !!v || "Income is required"],
        d_incomePeriod: [v => !!v || "Income Period is required"],
        d_hoursPerWeek: [v => !!v || "Hours Per Week is required"],
        d_residenceStreet1: [v => !!v || "Street Address is required"],
        d_residenceCity: [v => !!v || "City is required"],
        d_residenceState: [v => !!v || "State is required"],
        d_residencePostalCode: [v => !!v || "Zip code is required"],
        d_familySize: [v => !!v || "Family Size is required"],
        d_familyStructure: [v => !!v || "Family Structure is required"],
        childNameFirst: [v => !!v || "First Name is required"],
        childNameLast: [v => !!v || "Last Name is required"],
        childDateBirth: [v => !!v || "Date of Birth is required (MM/DD/YYYY)"],
        participateAgreement: [v => !!v || "Agreement acceptance is required"],
        d_date: [v => !!v || "Application Date is required (MM/DD/YYYY)"],
        d_signaturePrimary: [v => !!v || "Primary Signature is required"],
        d_signaturePrimaryDate: [v => !!v || "Primary Signature Date is required (MM/DD/YYYY)"],
        only_digits: [
          //apply validation if the field is populated !v
          v => !v || /^\d+$/.test(v) || "Enter Digits Only"
        ],
        only_dollarDigits: [
          v =>
            !v || /^\d+(?:\.\d{1,2})?$/.test(v) && /^[^,]+$/.test(v) || "Enter Dollar Amount - Digits and Decimal Only"
        ]
      },
      files: [],
      fileType: "",
      upload: false,
      deleteDialog: false,
      deleteMessage: "",
      deleteRep: "",
      deleteMode: "",
      deleteSection: "",
      currentChild: 1
    };
  },
  watch: {
    dateBirth() {
      this.d_dateBirth = this.$_formatDate(this.dateBirth);
    },
    dateBirthOtherParent() {
      this.d_dateBirthOtherParent = this.$_formatDate(this.dateBirthOtherParent);
    },
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    },
    signaturePrimaryDate() {
      this.d_signaturePrimaryDate = this.$_formatDate(this.signaturePrimaryDate);
    },
    signatureOtherParentDate() {
      this.d_signatureOtherParentDate = this.$_formatDate(this.signatureOtherParentDate);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("applicationsStore/resetEntry");
      this.currentChild = 1;
    },

    async newEntry() {
      this.resetEntry();
      const data = {
        f_type: 2
      };
      await this.$store.dispatch("documentTypesStore/valueList", data);
      await this.$store.dispatch("countiesStore/valueList");
      const data2 = {
        id: [2, 3, 4, 5, 6, 7, 8, 12, 18, 21]
      };
      await this.$store.dispatch("valueListsStore/items", data2);
      //Populate WLSID Value List
      await this.$store.dispatch("waitingListStore/valueList");

      /* Client Section */
      if (this.applicationSection === 2) {
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_clientID = client.id;
      } else {
        this.fk_clientID = "";
      }
      this.section = this.applicationSection;
      this.mode = 1;
      this.show = true;

      if (this.$refs.step1) this.$refs.step1.resetValidation();
      if (this.$refs.step2) this.$refs.step2.resetValidation();
      if (this.$refs.step4) this.$refs.step4.resetValidation();
      if (this.$refs.step5) this.$refs.step5.resetValidation();
      if (this.$refs.step6) this.$refs.step6.resetValidation();
    },

    cancelEntry() {
      this.show = false;
      this.e1 = 1;
      this.resetEntry();
    },

    previousTab() {
      this.e1--;
    },

    nextTab() {
      this.e1++;
    },

    requiredComplete(page) {
      //test if required fields are complete before proceeding to the next page
      var complete = true;
      switch (page) {
        case 1:
          //Primary Applicant
          complete = this.$refs.step1.validate();
          break;
        case 2:
          //Other Parent Guardian
          complete = this.$refs.step2.validate();
          break;
        case 4:
          //Household
          complete = this.$refs.step4.validate();
          break;
        case 5:
          //Applying Children
          complete = this.$refs.step5.validate();
          break;
        //Agreements
        case 6:
          complete = this.$refs.step6.validate();
      }
      if (!complete) {
        const msg = {
          color: "error",
          text: "Please check that all required fields are completed before continuing."
        };
        this.$store.commit("messagesStore/setMessage", msg);
      }
      return complete;
    },

    async saveEntry() {
      const data = this.$store.getters["applicationsStore/getEntry"];
      /* If the application record exists */
      if (data.id) {
        /* If on last tab and status is completed */
        if (this.e1 == 6 && this.f_status === 2) {
          const response = await this.$store.dispatch("applicationsStore/update", data);
          if (response === "ERROR") {
            const data = {
              color: "error",
              text:
                "There was an error completing the application.  Please check the data entered and try to submit again. If the problem persists, please contact support."
            };
            this.$store.commit("messagesStore/setMessage", data);
          } else {
            this.cancelEntry();
          }
        } else {
          const response = await this.$store.dispatch("applicationsStore/update", data);
          if (this.e1 == 6) {
            if (response === "ERROR") {
              const data = {
                color: "error",
                text:
                  "There was an error completing the application.  Please check the data entered and try to submit again. If the problem persists, please contact support."
              };
              this.$store.commit("messagesStore/setMessage", data);
            } else {
              //this check pages 6 for validation BG 7/2/2021
              if (this.requiredComplete(this.e1)) this.cancelEntry();
              //this.cancelEntry();
            }
          } else {
            //this check pages 2,4-5 for validation BG 7/2/2021
            if (this.requiredComplete(this.e1)) this.e1++;
            //this.e1++;
          }
        }
      } else if (this.$refs.step1.validate()) {
        await this.$store.dispatch("applicationsStore/create", data);
        this.e1++;
      }
    },

    addEmployment(mode) {
      const data = {
        mode,
        d_employer: "",
        d_position: "",
        d_employerCity: "",
        d_income: "",
        d_incomePeriod: "",
        d_hoursPerWeek: ""
      };
      this.$store.dispatch("applicationsStore/addEmployment", data);
    },

    removeEmployment() {
      this.closeDeleteDialog();
      const data = {
        mode: this.deleteSection,
        id: this.deleteRep
      };
      this.$store.dispatch("applicationsStore/removeEmployment", data);
    },

    addSchool(mode) {
      const data = {
        mode,
        d_schoolName: "",
        d_degreeSought: "",
        d_expectedCompletion: "",
        d_enrolled: "",
        d_type: "",
        d_financialAidAmount: "",
        d_financialAidPeriod: "",
        d_tuitionAmount: "",
        d_tuitionAmountPeriod: ""
      };
      this.$store.dispatch("applicationsStore/addSchool", data);
    },

    removeSchool() {
      this.closeDeleteDialog();
      const data = {
        mode: this.deleteSection,
        id: this.deleteRep
      };
      this.$store.dispatch("applicationsStore/removeSchool", data);
    },

    addOtherIncome(mode) {
      const data = {
        mode,
        d_type: "",
        d_amount: "",
        d_period: ""
      };
      this.$store.dispatch("applicationsStore/addOtherIncome", data);
    },

    removeOtherIncome() {
      this.closeDeleteDialog();
      const data = {
        mode: this.deleteSection,
        id: this.deleteRep
      };
      this.$store.dispatch("applicationsStore/removeOtherIncome", data);
    },

    addContact() {
      const data = {
        d_owner: "",
        d_type: "",
        d_value: ""
      };
      this.$store.dispatch("applicationsStore/addContact", data);
    },

    removeContact() {
      this.closeDeleteDialog();
      const data = {
        mode: this.deleteSection,
        id: this.deleteRep
      };
      this.$store.dispatch("applicationsStore/removeContact", data);
    },

    addDependant() {
      const data = {
        d_name: "",
        d_relationship: "",
        d_parent: "",
        d_dateBirth: ""
      };
      this.$store.dispatch("applicationsStore/addDependant", data);
    },

    removeDependant() {
      this.closeDeleteDialog();
      const data = {
        mode: this.deleteSection,
        id: this.deleteRep
      };
      this.$store.dispatch("applicationsStore/removeDependant", data);
    },

    addChild() {
      const data = {
        d_nameFirst: "",
        d_nameMiddle: "",
        d_nameLast: "",
        d_dateBirth: "",
        d_gender: "",
        d_weeklyHoursCare: [],
        d_race: [],
        d_raceOther: "",
        d_verifiedDateOfBirth: "",
        d_additionalInfoHealthInsurance: "",
        d_additionalInfoUSCitizen: "",
        d_additionalInfoSpecialNeed: "",
        d_additionalInfoSpecialNeedDescribe: "",
        d_additionalInfoReceivingServices: "",
        d_dateServicePlanEnd: "",
        d_shareFinancial: "",
        d_shareFinancialName: "",
        d_shareFinancialRelationship: "",
        d_shareFinancialDescribe: "",
        d_facilityName: "",
        d_facilityChildEnrolled: "",
        d_facilityDirector: "",
        d_facilityPhone: "",
        d_dateFacilityChildEnrolled: ""
      };
      this.$store.dispatch("applicationsStore/addChild", data);
      this.currentChild = this.j_applyingChild.length;
    },

    removeChild() {
      this.closeDeleteDialog();
      const data = {
        mode: this.deleteSection,
        id: this.deleteRep
      };
      this.$store.dispatch("applicationsStore/removeChild", data);
      if (this.currentChild !== 1) {
        this.currentChild -= 1;
      }
    },

    addDocument(raw) {
      const data = {
        d_type: raw.d_type,
        d_path: raw.d_path
      };
      this.$store.dispatch("applicationsStore/addDocument", data);
    },

    removeDocument() {
      this.closeDeleteDialog();
      const data = {
        mode: this.deleteSection,
        id: this.deleteRep
      };
      this.$store.dispatch("applicationsStore/removeDocument", data);
    },

    setMailingAddress(value) {
      this.$store.dispatch("applicationsStore/setMailingAddress", value);
    },

    async addFile() {
      if (this.fileType && this.files[0]) {
        const data = this.$store.getters["applicationsStore/getEntry"];
        this.file = this.files[0];
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("id", data.fk_householdID);
        formData.append("section", "clients");
        this.upload = true;
        const response = await axios.post("/api/documents/submit", formData, {
          headers: uploadHeader()
        });
        // let response = ''
        const success = 200;
        this.upload = false;
        if (response.status === 200 || success === 200) {
          const newFile = {
            d_type: this.fileType,
            d_path: response.data
          };
          this.fileType = "";
          this.files = [];
          this.file = "";
          this.addDocument(newFile);
          this.openSnackbar(1);
        } else {
          this.openSnackbar(2);
        }
      }
    },

    async openDocument(path) {
      const formData = new FormData();
      formData.append("file", path);
      const response = await axios.post("/api/documents/presignedurl", formData, {
        headers: uploadHeader()
      });
      window.open(response.data, "_blank");
    },

    openSnackbar(code) {
      let data;
      if (code === 1) {
        data = {
          color: "green lighten-1",
          text: "Artwork file has been successfully uploaded."
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text:
            "There was an error uploading the file.  Try again and contact support if the issue continues."
        };
      }
      this.$store.commit("messagesStore/setMessage", data);
    },

    openDeleteDialog(mode, message, rep, section) {
      this.deleteSection = section;
      this.deleteMode = mode;
      this.deleteMessage = message;
      this.deleteRep = rep;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    paginate(value) {
      this.currentChild = value;
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
.v-stepper {
  box-shadow: none;
}
.v-card-word-break {
  word-break: normal;
}
</style>
