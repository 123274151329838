<template>
  <div>

    <v-toolbar flat dense>

      <record-navigation
        :show="clientsStore.clients.data && clientsStore.clients.data.length > 0"
        :section="3"
        :currentIndex="clientsStore.currentIndex"
        :lastRecord="clientsStore.clients.total"
        :from="clientsStore.clients.from"
        :to="clientsStore.clients.to"
      />

      <v-spacer />

      <v-toolbar-items>
        <!--Bookmark-->
        <bookmark :bookmark-section="2" />

        <!--Add Contact Method-->
        <contact-method-entry />

        <!--Add Dependent-->
        <dependant-entry />

        <!--Add Application-->
        <application-entry v-if="1 === 2" :application-section="2" />

        <!--Care Plan-->
        <care-plan />

        <!--Add Correspondence-->
        <correspondence-entry :corres-section="3" />

        <!--Add Action-->
        <action-entry :action-section="3" />

        <!--Add Document-->
        <document-entry :document-section="3" />

        <!--Add Note-->
        <note-entry :note-section="3" />

        <!--Task-->
        <task-entry :task-section="3" />

      </v-toolbar-items>
    </v-toolbar>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import RecordNavigation from '@/components/RecordNavigation';
import TaskEntry from '@/components/TaskEntry';
import NoteEntry from '@/components/NoteEntry';
import Bookmark from '@/components/Bookmark';
import ApplicationEntry from '@/components/ApplicationEntry';
import DocumentEntry from '@/components/DocumentEntry';
import ActionEntry from '@/components/ActionEntry';
import CarePlan from '@/components/CarePlan';
import ContactMethodEntry from '@/components/ContactMethodEntry';
import CorrespondenceEntry from '@/components/CorrespondenceEntry';
import DependantEntry from '@/components/DependantEntry';

export default {
  name: 'ClientFunctionBar',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      clientsStore: (state) => state.clientsStore,
    }),
  },
  components: {
    RecordNavigation,
    TaskEntry,
    NoteEntry,
    Bookmark,
    ActionEntry,
    ApplicationEntry,
    CarePlan,
    DocumentEntry,
    ContactMethodEntry,
    CorrespondenceEntry,
    DependantEntry,
  },
  methods: {

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
