export const appName = "Durham PreK Scholarship Database";
export const clientID = process.env.VUE_APP_CLIENT_ID;
export const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
export const supportEmail = "app@test.com";
export const siteURL = process.env.VUE_APP_SITE_URL;
export const siteVersion = "2.10112024.0";

export const getHeader = function authHeader() {
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${tokenData.access_token}`
  };
  return headers;
};

export const uploadHeader = function setUploadHeader() {
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${tokenData.access_token}`
  };
  return headers;
};

export const authorizeHeader = function setAuthorizeHeader() {
  const headers = {
    Accept: "application/json"
  };
  return headers;
};
