import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const getDefaultState = () => ({
  teacherRates: [],
  teacherRate: {},
  entry: {
    show: false,
    mode: "",
    id: "",
    /* -----ADD ENTRY FIELDS BELOW-----*/
    d_experience: "",
    d_rate: "",
    d_dateStart: "",
    f_status: "",
    f_show: "",
    date1: ""
  },
  filters: {
    page: "",
    /* -----ADD FILTER FIELDS BELOW-----*/
    d_experience: "",
    d_rate: "",
    d_dateStart: "",
    f_status: "",
    date1: "",
    selectedID: []
  }
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  FETCH(state, { list }) {
    state.teacherRates = list;
  },
  CREATE(state, { data }) {
    state.teacherRates.data.unshift(data);
  },
  READ(state, { data }) {
    state.teacherRate = data;
  },
  UPDATE(state, { data }) {
    const idx = state.teacherRates.data.map(item => item.id).indexOf(data.id);
    state.teacherRates.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.teacherRates.data.map(item => item.id).indexOf(id);
    state.teacherRates.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.id = data.id;
    state.entry.d_experience = data.d_experience;
    state.entry.d_rate = data.d_rate;
    state.entry.date1 = data.d_dateStart;
    state.entry.f_status = data.f_status;
    state.entry.f_show = data.f_show;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.id = "";
    state.entry.d_experience = "";
    state.entry.d_rate = "";
    state.entry.date1 = "";
    state.entry.d_dateStart = "";
    state.entry.f_status = 1;
    state.entry.f_show = true;
  },
  SELECT_DESELECT_ALL(state) {
    if (state.filters.selectedID.length > 0) {
      state.filters.selectedID = [];
    } else {
      let i;
      for (i = 0; i < state.teacherRates.data.length; i++) {
        state.filters.selectedID.push(state.teacherRates.data[i].id);
      }
    }
  },
  DESELECT_ALL(state) {
    state.filters.selectedID = [];
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  FETCH_VALUE_LIST(state, { list }) {
    state.teacherRatesValueList = list;
  }
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearSelected: ({ commit }) => {
    commit("DESELECT_ALL");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherrates", data, { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherrates/create", data, { headers: getHeader() });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/teacherrates/${id}`, { headers: getHeader() });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/teacherrates/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherrates/delete", data, { headers: getHeader() });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/teacherrates/${id}`, { headers: getHeader() });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get("/api/teacherratesvaluelist", { headers: getHeader() });
      commit("FETCH_VALUE_LIST", { list: response.data });
    } catch (error) {}
  },
  async duplicateSelected({ commit }, data) {
    try {
      const response = await axios.post("/api/teacherrates/masscreate", data, {
        headers: getHeader()
      });
      commit("SELECT_DESELECT_ALL");
      return response;
    } catch (error) {}
  },
  selectDeselectAll({ commit }) {
    commit("SELECT_DESELECT_ALL");
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  clearteacherRates({ commit }) {
    commit("CLEAR_teacherRateS_");
  }
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getTeacherRate: (state, getters) => state.teacherRate,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
  getTeacherRates: (state, getters) => state.teacherRates,
  getSelected: (state, getters) => state.filters.selectedID
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
