<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="waitingListStore.waitingListItem.d_nameLast + ', ' +
      waitingListStore.waitingListItem.d_nameFirst + ' - ' +
      waitingListStore.waitingListItem.d_wlsID" />

      <!--Function Bar-->
      <waiting-list-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <!--Children-->
          <v-col cols="12" sm="12" md="2">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-children</v-icon>
                <v-toolbar-title class="pl-2">Children:
                  {{waitingListStore.waitingListItem.j_children ?
                    waitingListStore.waitingListItem.j_children.length : '0'}}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text v-if="waitingListStore.waitingListItem.j_children && waitingListStore.waitingListItem.j_children.length > 0">
                <div :key="index" v-for="(item, index) in waitingListStore.waitingListItem.j_children">
                  <v-row dense v-if="index > 0" class="py-2">
                    <v-divider light></v-divider>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <text-display :displayLabel="'Child:' + (index+1)" :displayString="item.d_name" />
                    </v-col>

                    <v-col cols="12" sm="12" md="7">
                      <text-display :displayLabel="'Date of Birth:'" :displayString="$_formatDate(item.d_dateBirth)" />
                    </v-col>

                    <v-col cols="12" sm="12" md="5">
                      <text-display :displayLabel="'Gender:'" :displayString="item.d_gender" />
                    </v-col>

                    <v-col cols="12" sm="12" md="7">
                      <text-display :displayLabel="'Subsidy Status:'" :displayString="item.d_subsidy" />
                    </v-col>

                    <v-col cols="12" sm="12" md="5">
                      <text-display :displayLabel="'IEP/IFSP:'" :displayString="$_yesNo(item.f_IEPIFSP)" />
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <text-display :displayLabel="'Special Needs:'" :displayString="$_yesNo(item.f_specialNeeds)" />
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-text v-else>
                <v-row dense>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <span class="error--text text-subtitle-1 text-xs-center">No Children Exist</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!--Waiting List Information-->
          <v-col cols="12" sm="12" md="7">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-list-alt</v-icon>
                <v-toolbar-title class="pl-2">Details</v-toolbar-title>
                <v-spacer />

                <waiting-list-entry />

              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-card-text>

                <div v-if="waitingListStore.waitingListItem.actions && waitingListStore.waitingListItem.actions.length > 0">
                <v-alert
                  :key="index" v-for="(item, index) in waitingListStore.waitingListItem.actions"
                  :value="true"
                  type="error"
                  icon="fal fa-exclamation-triangle"
                >
                  {{item.type.d_name}}
                </v-alert>
                </div>

                <!--General-->
                <v-row dense>
                  <!--<v-col cols="12" sm="12" md="1">
                    <text-display :displayLabel="'ID'" :displayString="waitingListStore.waitingListItem.id" />
                  </v-col>-->

                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Status'" :displayString="waitingListStore.waitingListItem.status ? waitingListStore.waitingListItem.status.d_name : ''" />
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Work Life System ID'" :displayString="waitingListStore.waitingListItem.d_wlsID" />
                  </v-col>

                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Wait List Pull ID'" :displayString="waitingListStore.waitingListItem.d_waitListPullID" />
                  </v-col>

                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Latest Screening Date'" :displayString="$_formatDate(waitingListStore.waitingListItem.d_dateLastScreening)" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Latest Screening Status'" :displayString="waitingListStore.waitingListItem.d_statusLastScreening" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'WLS Update From Screening Date'" :displayString="$_formatDate(waitingListStore.waitingListItem.d_dateWLSupdateFromScreening)" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Specialist'" :displayString="waitingListStore.waitingListItem.counselor ? waitingListStore.waitingListItem.counselor.name : ''" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Initial Specialist'" :displayString="waitingListStore.waitingListItem.d_nameInitialCounselor" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Case Type'" :displayString="waitingListStore.waitingListItem.d_caseType" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Type'" :displayString="waitingListStore.waitingListItem.d_type" />
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Income Rank'" :displayNumber="waitingListStore.waitingListItem.f_incomeRank" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Priority'"
                      :displayString="waitingListStore.waitingListItem.j_priority ? waitingListStore.waitingListItem.j_priority.join(', ') : ''" />
                  </v-col>
                </v-row>

                <v-row class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <!--Applicant Information-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Applicant First Name'" :displayString="waitingListStore.waitingListItem.d_nameFirst" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Applicant Last Name'" :displayString="waitingListStore.waitingListItem.d_nameLast" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Relationship to Child'" :displayString="waitingListStore.waitingListItem.d_relationshipToChild" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Primary Language'" :displayString="waitingListStore.waitingListItem.d_languagePrimary" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Gender'" :displayString="waitingListStore.waitingListItem.d_gender" />
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Date of Birth'" :displayString="$_formatDate(waitingListStore.waitingListItem.d_dateBirth)" />
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Age'" :displayString="waitingListStore.waitingListItem.d_age" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Street Address'" :displayString="waitingListStore.waitingListItem.d_residenceStreet" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'City'" :displayString="waitingListStore.waitingListItem.d_residenceCity" />
                  </v-col>
                  <v-col cols="12" sm="12" md="1">
                    <text-display :displayLabel="'State'" :displayString="waitingListStore.waitingListItem.d_residenceState" />
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Zip Code'" :displayString="waitingListStore.waitingListItem.d_residencePostalCode" />
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'County'" :displayString="waitingListStore.waitingListItem.d_residenceCounty" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Login Email'" :displayString="waitingListStore.waitingListItem.d_emailLogin" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Primary Phone'" :displayString="$_formatPhone(waitingListStore.waitingListItem.d_phonePrimary)" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Secondary Phone'" :displayString="$_formatPhone(waitingListStore.waitingListItem.d_phoneSecondary)" />
                  </v-col>
                </v-row>

                <v-row class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <!--Household Information-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Family Size'" :displayNumber="waitingListStore.waitingListItem.d_familySize" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <text-display :displayLabel="'Adults In Household'" :displayString="waitingListStore.waitingListItem.d_adultsInHousehold" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Income'" :displayString="waitingListStore.waitingListItem.d_income" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Adjusted Income'" :displayString="$_formatMoney(waitingListStore.waitingListItem.d_incomeAdjusted)" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Family Income'" :displayString="$_formatMoney(waitingListStore.waitingListItem.d_incomeFamily)" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Employment Status'" :displayString="waitingListStore.waitingListItem.d_statusEmployment" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Employer'" :displayString="waitingListStore.waitingListItem.d_employer" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Employer (Other)'" :displayString="waitingListStore.waitingListItem.d_employerOther" />
                  </v-col>
                </v-row>

                <v-row class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <!--Other Settings-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'UNC Chapel HIll Affiliate'" :displayString="waitingListStore.waitingListItem.d_affiliateUNCCH" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'UNC Chapel HIll Affiliate (Other)'" :displayString="waitingListStore.waitingListItem.d_affiliateUNCCHother" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'First Name (Caller)'" :displayString="waitingListStore.waitingListItem.d_nameFirstCaller" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Last Name (Caller)'" :displayString="waitingListStore.waitingListItem.d_nameLastCaller" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Email (Caller)'" :displayString="waitingListStore.waitingListItem.d_emailCaller" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Gave CCSA WL Consent'" :displayString="$_yesNo(waitingListStore.waitingListItem.f_ccsaWLConsent)" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'CCSA Scholarship WL'" :displayString="$_yesNo(waitingListStore.waitingListItem.f_ccsaScholarshipWL)" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Subsidy-Homeless WL'" :displayString="$_yesNo(waitingListStore.waitingListItem.f_subsidyHomelessWL)" />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'CCSA Scholarship Receiving'" :displayString="$_yesNo(waitingListStore.waitingListItem.f_ccsaScholarshipReceiving)" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Enrolled/Needs Assistance'" :displayString="$_yesNo(waitingListStore.waitingListItem.f_enrolledNeedsAssistance)" />
                  </v-col>
                </v-row>

                <v-row class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <!-- Notes (Previous System)-->
                <v-row dense>
                  <v-col cols="12" sm="12" md="12">
                    <text-display :displayLabel="'Notes (Previous System)'" :displayString="waitingListStore.waitingListItem.d_note" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!--Notes-->
          <v-col cols="12" sm="12" md="3">
            <notes :key="waitingListStore.waitingListItem.id" :noteSection="8" />
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import WaitingListFunctionBar from '@/components/WaitingListFunctionBar';
import Notes from '@/components/Notes';
import TextDisplay from '@/components/TextDisplay';
import WaitingListEntry from "@/components/WaitingListEntry";

export default {
  name: 'WaitingListItem',
  mixins: [mixin],
  computed: {
    ...mapState({
      waitingListStore: (state) => state.waitingListStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
    TitleBar,
    WaitingListFunctionBar,
    Notes,
    TextDisplay,
    WaitingListEntry
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('waitingListStore/read', this.$route.params.waitingListUUID);
      /* Refresh bookmark */
      const user = this.$store.getters['usersStore/getAuthUser'];
      const waitingListItem = this.$store.getters['waitingListStore/getWaitingListItem'];
      const data = {
        fk_waitingListID: waitingListItem.id,
        fk_userID: user.id,
      };
      await this.$store.dispatch('bookmarksStore/detail', data);

      /* Pull valueListsStore items for Entry forms */
      const data2 = {
        id: [32, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
      };
      await this.$store.dispatch("valueListsStore/items", data2);

      this.$store.dispatch('progressStore/set', false);
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
