<template>
  <div>
    <!--Title Bar-->
    <title-bar
      v-if="reportSection === 1 || reportSection === 3"
      :title-value="'Reports / Process'"
    ></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-chart-line</v-icon>
        <v-toolbar-title class="pl-2">Reports / Process </v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="reportSection !== 2" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-row dense>
                <!--Clear Filter Button -->
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Report Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="Report Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12" v-if="reportSection === 3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <report-entry :section="reportSection" />
    </v-toolbar>

    <!--Report Date Picker Dialog-->
    <report-date-picker />

    <!--Pagination  -->
    <pagination
      :show="reportsStore.reports.data && reportsStore.reports.data.length > 0"
      :showTotal="reportSection === 2 ? false : true"
      :currentPage="reportsStore.reports.current_page"
      :lastPage="reportsStore.reports.last_page"
      :total="reportsStore.reports.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="reportsStore.reports.data && reportsStore.reports.data.length > 0"
        :list-data="reportsStore.reports.data"
        :title="'Reports'"
        :section="reportSection === 1 || reportSection === 2 ? 37 : 37.3"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";
import ReportEntry from "@/components/ReportEntry";
import ReportDatePicker from "@/components/ReportDatePicker";
import * as XLSX from "xlsx";

const { mapFields } = createHelpers({
  getterType: "reportsStore/getFilterField",
  mutationType: "reportsStore/updateFilterField"
});

export default {
  name: "Reports",
  components: {
    TitleBar,
    Pagination,
    ListBasic,
    ReportEntry,
    ReportDatePicker
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      reportsStore: state => state.reportsStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields(["page", "section", "d_name", "fk_userID", "f_status"])
  },
  props: {
    /* All List = 1, Dashboard = 2, Settings */
    reportSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      /* If not running from dashboard */
      if (this.reportSection !== 2) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* If called from dashbaord or main list */
      if (this.reportSection === 1 || this.reportSection === 2) {
        const user = this.$store.getters["usersStore/getAuthUser"];
        this.fk_userID = user.id;
        this.f_status = 1;
      } else {
        this.fk_userID = "";
      }
      /* Pull reports list */
      const filters = this.$store.getters["reportsStore/getFilters"];
      await this.$store.dispatch("reportsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_name = "";
      this.f_status = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      if (this.reportSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("reportsStore/clearReports");
      }
      /* Save section to store */
      this.section = this.reportSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_name ||
        (this.reportSection === 3 && this.f_status) ||
        (this.reportSection === 3 && this.f_status === 0)
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      /* If called from settings */
      if (this.reportSection === 3) {
        await this.$store.dispatch("reportsStore/entry", id);
      } else {
        /* If called from dashboard or main list */
        let data;
        /* Grab current date, month and year for reports */
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        let message;
        /* If client caseload resport */
        if (id === 7) {
          /* Pull counselor value list */
          const specialist = {
            f_counselor: 1
          };
          await this.$store.dispatch("usersStore/valueList", specialist);
          message = "Filters care plans with eligibility check-in >= date entered.";
        } else if (id === 9 || id === 13 || id === 24 || id === 25 || id === 26 || id === 29) {
          message = "Select Start Date and End Date.";
        } else {
          message = "Select Month and Year before continuing.";
        }

        data = {
          id,
          f_month: currentMonth,
          d_year: currentYear,
          message,
          fk_providerID: "",
          restrictAccountingCodes: ""
        };

        if (
          id <= 5 ||
          id === 8 ||
          id === 11 ||
          id === 13 ||
          id === 14 ||
          id === 15 ||
          id === 16 ||
          id === 17 ||
          id === 18 ||
          id === 21 ||
          id === 24 ||
          id === 25 ||
          id === 26 ||
          id === 29
        ) {
          await this.$store.dispatch("yearsStore/valueList");
        }

        /* Process Monthly Attendance */
        if (id === 1) {
          data.title = "Process Monthly Attendance";
        } else if (id === 2) {
          /* Process Monthly Payments */
          data.title = "Process Monthly Payments";
        } else if (id === 3) {
          /* Check Request */
          data.title = "Check Request";
        } else if (id === 4) {
          /* Attendence Sheets */
          data.title = "Attendance Sheets";
        } else if (id === 5) {
          /* Provider Payment Detail */
          data.title = "Provider Payment Detail";
        } else if (id === 6) {
          /* Provider Payment Detail */
          data.title = "Provider Tuition";
        } else if (id === 7) {
          /* Provider Payment Detail */
          data.title = "Client Caseload";
        } else if (id === 8) {
          /* Provider Payment Detail */
          data.title = "SSRS";
          data.restrictAccountingCodes = "Yes";
        } else if (id === 9) {
          /* Provider Payment Detail */
          data.title = "Payment Based Client Contact Info";
        } else if (id === 10) {
          /* Durham PreK/NC Pre-K Providers */
          data.title = "Durham PreK/NC Pre-K Providers";
          const filters = {
            page: 1,
            section: 99,
            fk_userID: "",
            d_licenseNumber: "",
            d_nameLicense: "",
            fk_countyID: "",
            f_type: "",
            fk_ratingID: "",
            f_status: "",
            d_email: "",
            fk_actionTypeID: "",
            contactName: "",
            d_contractedStatus: "",
            fk_reportID: id,
            f_month: "",
            d_year: ""
          };
          this.$store.dispatch("providersStore/storeFilters", filters);
          this.$router.push({ name: "providers" });
        } else if (id === 11) {
          /* Monthly Cross Check */
          data.title = "Monthly Cross Check";
        } else if (id === 12) {
          /* Attendance With Care Plan Item With VOID Follow Up */
          data.title = "Attendance With Care Plan Item With VOID Follow Up";
          const filters = {
            page: 1,
            section: 99,
            /* -----ADD FILTER FIELDS BELOW-----*/
            fk_providerID: "",
            d_licenseNumber: "",
            d_nameLicense: "",
            d_childIDv1: "",
            fk_clientID: "",
            fk_householdID: "",
            d_prekSeatID: "",
            d_nameFirst: "",
            d_nameLast: "",
            f_month: "",
            d_year: "",
            f_followup: "",
            f_status: "",
            noFollowup: "",
            selectedID: [],
            d_dateStart: "",
            d_dateEnd: "",
            date1: "",
            date2: "",
            fk_programID: "",
            fk_programOptionID: "",
            clientID: "",
            f_viewPrivateRecords: "",
            dateStart: "",
            dateEnd: "",
            date3: "",
            date4: "",
            d_daysAbsent: "",
            d_daysTotal: "",
            attendanceEntered: "",
            attendanceNotEntered: "",
            f_placeholder: "",
            fk_reportID: id
          };
          this.$store.dispatch("attendanceItemsStore/storeFilters", filters);
          this.$router.push({ name: "attendance" });
        } else if (id === 13) {
          /* School Information */
          data.title = "School Information";
        } else if (id === 14) {
          /* Process Monthly Teacher Reviews */
          data.title = "Process Monthly Teacher Reviews";
        } else if (id === 15) {
          /* Process Monthly Teacher Payments */
          data.title = "Process Monthly Teacher Payments";
        } else if (id === 16) {
          /* Teacher Check Request */
          data.title = "Teacher Check Request";
        } else if (id === 17) {
          /* Attendence Sheets */
          data.title = "Teacher Confirmation Forms";
        } else if (id === 18) {
          /* Provider Payment Detail */
          data.title = "Provider Teacher Payment Detail";
        } else if (id === 19) {
          /* Teacher Turnover */
          data.title = "Teacher Turnover";
        } else if (id === 20) {
          /* Teacher Transfer */
          data.title = "Teacher Transfer";
        } else if (id === 21) {
          /* Teacher Review Cross Check */
          data.title = "Teacher Review Cross Check";
        } else if (id === 24) {
          /* Client Task Assignments */
          data.title = "Client Task Assignments";
        } else if (id === 25) {
          /* Provider Task Assignments */
          data.title = "Provider Task Assignments";
        } else if (id === 26) {
          /* Teacher Task Assignments */
          data.title = "Teacher Task Assignments";
        } else if (id === 29) {
          /* Waiting List Task Assignments */
          data.title = "Waiting List Task Assignments";
        }
        /*
        Process Monthly Attendance,
        Process Monthly Payments,
        Check Request,
        Attendence Sheets,
        Provider Payment Detail,
        Payment Based Client Contact Info,
        School Information,
        Process Monthly Teacher Reviews,
        Process Monthly Teacher Payments,
        Teach Check Request,
        Teacher Confirmation Forms,
        Provider Teacher Payment Detail
        Provider Tuition
        Provider Reimbursement Rates
        Teacher Turnover
        Teacher Transfer
        Teacher Salary
        Teacher Review Cross Check
        Client Task Assignments
        Provider Task Assignments
        Teacher Task Assignments
        Waiting List Task Assignments
        DPK Provider Director Contacts
        */
        if (
          id === 1 ||
          id === 2 ||
          id === 3 ||
          id === 4 ||
          id === 5 ||
          id === 7 ||
          id === 8 ||
          id === 9 ||
          id === 11 ||
          id === 13 ||
          id === 14 ||
          id === 15 ||
          id === 16 ||
          id === 17 ||
          id === 18 ||
          id === 21 ||
          id === 24 ||
          id === 25 ||
          id === 26 ||
          id === 29
        ) {
          this.$store.dispatch("reportsStore/resetDatePicker");
          /* If process monthly attendance */
          if (id === 1 || id === 14) {
            await this.$store.dispatch("providersStore/valueList");
          }
          await this.$store.dispatch("reportsStore/datePicker", data);
        } else if (id === 6) {
          /* Initialize variables */
          let filename;
          let ws_name;
          let exportData;
          filename = "ProvidersTuition.csv";
          ws_name = "Providers Tuition";

          await this.$store.dispatch("reportsStore/runReport", data);
          exportData = this.$store.getters["reportsStore/getReport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);

            const range = XLSX.utils.decode_range(ws["!ref"]);
            /* Start loop through rows at the second row.  First row is header */
            for (let R = range.s.r + 1; R <= range.e.r; ++R) {
              for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: R };
                /* Grab cell location */
                const cell_ref = XLSX.utils.encode_cell(cell_address);
                /* Format Number */
                if (C === 6 || C === 8 || C === 9 || C === 10) {
                  ws[cell_ref].z = "0";
                } else if (C === 3) {
                  /* Format Date */
                  ws[cell_ref].z = "dd/mm/yyyy";
                } else if (C === 5 || C === 7 || C === 11) {
                  /* Format Money */
                  ws[cell_ref].z = "0.00";
                } else {
                  ws[cell_ref].z = "";
                }
                XLSX.utils.format_cell(cell_ref);
              }
            }

            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);

            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          }
        } else if (id === 19 || id === 20 || id === 23) {
          /* Initialize variables */
          let filename;
          let ws_name;
          let export_cd;
          let exportData;
          let currentDate = new Date();
          let reportDate =  currentDate.getFullYear() + '_' +
                            (currentDate.getMonth() + 1) + "_" +
                            currentDate.getDate();
          if (id === 19) {
            filename = "TeacherTurnover_" + reportDate + ".csv";
            ws_name = "TeacherTurnover_" + reportDate;
            export_cd = 2;
          } else if (id === 20) {
            filename = "TeacherTransfer_" + reportDate + ".csv";
            ws_name = "TeacherTransfer_" + reportDate;
            export_cd = 3;
          } else if (id === 23) {
            filename = "TeacherSalary_" + reportDate + ".csv";
            ws_name = "TeacherSalary_" + reportDate;
            export_cd = 4;
          }
          const filters = {
            export: export_cd
          };
          await this.$store.dispatch("teachersStore/exportExcel", filters);
          exportData = this.$store.getters["teachersStore/getExport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);

            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);

            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          }
        } else if (id === 22) {
          /* Initialize variables */
          let currentDate = new Date();
          let reportDate =  currentDate.getFullYear() + '_' +
                            (currentDate.getMonth() + 1) + "_" +
                            currentDate.getDate();
          let filename = "DPK_Provider_Directors_" + reportDate + ".csv";
          let ws_name = "DPK_Directors_" + reportDate;
          let exportData;

          const filters = {
            export: 2
          };
          await this.$store.dispatch("providersStore/exportExcel", filters);
          exportData = this.$store.getters["providersStore/getExport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);

            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);

            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          }
        } else if (id === 27) {
          /* Initialize variables */
          let currentDate = new Date();
          let reportDate =  currentDate.getFullYear() + '_' +
                            (currentDate.getMonth() + 1) + "_" +
                            currentDate.getDate();
          let filename = "Provider_Benefits_" + reportDate + ".csv";
          let ws_name = "Provider_Benefits_" + reportDate;
          let exportData;

          const filters = {
            export: 3
          };
          await this.$store.dispatch("providersStore/exportExcel", filters);
          exportData = this.$store.getters["providersStore/getExport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);

            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);

            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          }
        } else if (id === 28) {
          /* Initialize variables */
          let filename;
          let ws_name;
          let exportData;
          filename = "ProviderReimbursementRates.csv";
          ws_name = "Provider Reimbursement Rates";

          await this.$store.dispatch("reportsStore/runReport", data);
          exportData = this.$store.getters["reportsStore/getReport"];

          /* If data exists to export */
          if (exportData) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);

            const range = XLSX.utils.decode_range(ws["!ref"]);
            /* Start loop through rows at the second row.  First row is header */
            for (let R = range.s.r + 1; R <= range.e.r; ++R) {
              for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: R };
                /* Grab cell location */
                const cell_ref = XLSX.utils.encode_cell(cell_address);
                /* Format Number */
                if (C === 6 || C === 8 || C === 9 || C === 10) {
                  ws[cell_ref].z = "0";
                } else if (C === 3) {
                  /* Format Date */
                  ws[cell_ref].z = "dd/mm/yyyy";
                } else if (C === 5 || C === 7 || C === 11) {
                  /* Format Money */
                  ws[cell_ref].z = "0.00";
                } else {
                  ws[cell_ref].z = "";
                }
                XLSX.utils.format_cell(cell_ref);
              }
            }

            /* Add worksheet to workbook */
            XLSX.utils.book_append_sheet(wb, ws, ws_name);

            /* Create Excel file */
            XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
