import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const getDefaultState = () => ({
  carePlanItems: [],
  carePlansNoItems: [],
  carePlanItem: {},
  export: [],
  carePlanItemsValueList: [],
  entry: {
    show: false,
    show2: false,
    valid: '',
    mode: '',
    id: '',
    tab: '',
    /* -----ADD ENTRY FIELDS BELOW-----*/
    fk_carePlanID: '',
    fk_programID: '',
    fk_programOptionID: '',
    fk_timeOptionID: '',
    d_dateStart: '',
    d_dateEnd: '',
    d_income: '',
    d_fpl: '',
    d_smi: '',
    d_parentFee: '',
    d_dailyFee: '',
    d_eligibilityReason: '',
    f_followup: '',
    f_status: 1,
    options: [],
    header: '',
    date1: '',
    date2: '',
    f_show: '',
    care_plan: '',
    noFollowup: '',
    carePlanStatus: '',
  },
  filters: {
    page: '',
    section: '',
    /* -----ADD FILTER FIELDS BELOW-----*/
    d_licenseNumber: '',
    d_nameLicense: '',
    fk_clientID: '',
    d_childIDv1: '',
    d_nameLast: '',
    fk_userID: '',
    fk_programID: '',
    fk_classroomID: '',
    fk_householdID: '',
    f_followup: '',
    f_status: '',
    noFollowup: '',
    selectedID: [],
    d_dateEnd: '',
    d_dateStart: '',
    date1: '',
    date2: '',
    carePlanStatus: '',
    clientID: '',
    f_viewPrivateRecords: '',
    fk_programOptionID: '',
    fk_reportID: '',
    f_month: '',
    d_year: '',
  },
});

const state = getDefaultState();

const mutations = {
  CLEAR_STORE(state) {
    Object.assign(state, getDefaultState());
  },
  CLEAR_CARE_PLAN_ITEMS(state) {
    state.carePlanItems = [];
    state.carePlansNoItems = [];
  },
  CLEAR_CARE_PLAN_ITEM(state) {
    state.carePlanItem = {};
  },
  CLEAR_VALUE_LIST(state) {
    state.carePlanItemsValueList = [];
  },
  CLEAR_EXPORT(state) {
    state.export = [];
  },
  FETCH(state, { list }) {
    state.carePlanItems = list;
  },
  FETCH_NO_ITEMS(state, { list }) {
    state.carePlansNoItems = list;
  },
  CREATE(state, { data }) {
    state.carePlanItems.data.push(data);
  },
  READ(state, { data }) {
    state.carePlanItem = data;
  },
  UPDATE(state, { data }) {
    const idx = state.carePlanItems.data.map((item) => item.id).indexOf(data.id);
    const { header } = state.carePlanItems.data[idx];
    data.header = header;
    state.carePlanItems.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.carePlanItems.data.map((item) => item.id).indexOf(id);
    state.carePlanItems.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /* -----ADD ENTRY FIELDS BELOW-----*/
    state.entry.fk_carePlanID = data.fk_carePlanID;
    state.entry.fk_programID = data.fk_programID;
    state.entry.fk_programOptionID = data.fk_programOptionID;
    state.entry.fk_timeOptionID = data.fk_timeOptionID;
    state.entry.date1 = data.d_dateStart;
    state.entry.date2 = data.d_dateEnd;
    state.entry.d_income = data.d_income;
    state.entry.d_fpl = data.d_fpl;
    state.entry.d_smi = data.d_smi;
    state.entry.d_parentFee = data.d_parentFee;
    state.entry.d_dailyFee = data.d_dailyFee;
    state.entry.d_eligibilityReason = data.d_eligibilityReason;
    state.entry.f_followup = data.f_followup;
    state.entry.f_status = data.f_status;
    state.entry.options = data.options;
    state.entry.header = data.header;
    state.entry.care_plan = data.care_plan;
    state.entry.client = data.client;
    state.entry.show = 1;
    state.entry.tab = 0;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.mode = '';
    state.entry.id = '';
    state.entry.tab = 0;
    state.entry.fk_carePlanID = '';
    state.entry.fk_programID = '';
    state.entry.fk_programOptionID = '';
    state.entry.fk_timeOptionID = '';
    state.entry.date1 = '';
    state.entry.date2 = '';
    state.entry.d_income = '';
    state.entry.d_fpl = '';
    state.entry.d_smi = '';
    state.entry.d_parentFee = '';
    state.entry.d_dailyFee = '';
    state.entry.d_eligibilityReason = '';
    state.entry.f_followup = '';
    state.entry.f_status = '';
    state.entry.options = '';
    state.entry.header = '';
    state.entry.care_plan = '';
    state.entry.client = '';
    state.entry.noFollowup = '';
    state.entry.carePlanStatus = '';
  },
  RESET_MASS_SELECTION(state) {
    state.filters.selectedID = [];
  },
  SELECT_DESELECT_ALL(state) {
    if (state.filters.selectedID.length > 0) {
      state.filters.selectedID = [];
    } else {
      let i;
      for (i = 0; i < state.carePlanItems.data.length; i++) {
        state.filters.selectedID.push(state.carePlanItems.data[i].id);
      }
    }
  },
  FETCH_VALUE_LIST(state, { list }) {
    state.carePlanItemsValueList = list;
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearCarePlanItems: ({ commit }) => {
    commit('CLEAR_CARE_PLAN_ITEMS');
  },
  clearCarePlanItem: ({ commit }) => {
    commit('CLEAR_CARE_PLAN_ITEM');
  },
  clearValueList: ({ commit }) => {
    commit('CLEAR_VALUE_LIST');
  },
  clearExport: ({ commit }) => {
    commit('CLEAR_EXPORT');
  },
  async fetch({ commit }, data) {
    try {
      data.export = 0;
      const response = await axios.post('/api/careplanitems', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });

      const response2 = await axios.post('/api/careplansNoitems', data, { headers: getHeader() });
      commit('FETCH_NO_ITEMS', { list: response2.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/careplanitems/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/careplanitems/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/careplanitems/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async massUpdate({ commit }, data) {
    try {
      const response = await axios.post('/api/careplanitems/massupdate', data, { headers: getHeader() });
      return response;
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/careplanitems/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/careplanitems/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/careplanitems/valuelist', data, { headers: getHeader() });
      commit('FETCH_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async exportExcel({ commit }, data) {
    try {
      data.export = data.export ? data.export : 1;
      data.page = 1;
      const response = await axios.post('/api/careplanitems', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
  resetMassSelection({ commit }) {
    commit('RESET_MASS_SELECTION');
  },
  selectDeselectAll({ commit }) {
    commit('SELECT_DESELECT_ALL');
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getFilters: (state, getters) => state.filters,
  getCarePlanItem: (state, getters) => state.carePlanItem,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getValueList: (state, getters) => state.carePlanItemsValueList,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
