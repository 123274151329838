<template>
  <div>
    <!--Entry-->
    <v-card flat class="white">
      <v-card-text>
        <v-form ref="entryForm" v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row dense>
              <v-spacer />
              <v-btn rounded dark color="green lighten-1" class="mb-2" @click="addItem()">
                <v-icon small left>fal fa-plus</v-icon>New</v-btn
              >
              <v-col cols="12" sm="12">
                <v-card flat :key="index" v-for="(item, index) in items">
                  <v-container fluid class="pa-0">
                    <v-row dense>
                      <!--Student (Applicant)-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          :items="applicantsStore.applicantsValueList"
                          label="Student"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_nameDisplay"
                          item-value="id"
                          v-model="item.fk_applicantID"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--1:School-->
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model="item.d_schoolName"
                          label="School"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--Type-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          :items="valueListsStore.schoolTypes"
                          label="School Type"
                          placeholder=" "
                          persistent-placeholder
                          item-text="value"
                          item-value="value"
                          v-model="item.d_type"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--2:Excpected Completion-->
                      <v-col cols="12" sm="12" md="2">
                        <v-text-field
                          v-model="item.date1"
                          label="Expected Completion"
                          placeholder=" "
                          persistent-placeholder
                          @blur="item.d_expectedCompletion = $_parseDate(item.date1)"
                        />
                      </v-col>

                      <!--3:Enrollment Status-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[21]"
                          label="Enrollment"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="item.d_enrolled"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--4:Financial Aid-->
                      <v-col cols="12" sm="12" md="1">
                        <v-text-field
                          v-model="item.d_financialAidAmount"
                          label="Financial Aid"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--5:Tuition-->
                      <v-col cols="12" sm="12" md="2">
                        <v-text-field
                          v-model="item.d_tuitionAmount"
                          label="Tuition"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--6:Status-->
                      <v-col cols="12" sm="12" md="2">
                        <v-autocomplete
                          :items="valueListsStore.activeStatus"
                          label="Status"
                          placeholder=" "
                          persistent-placeholder
                          item-text="value"
                          item-value="id"
                          v-model="item.f_status"
                        />
                      </v-col>

                      <!--7:Total-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Total'"
                          :displayString="
                            totalIncome(item.d_financialAidAmount, item.d_tuitionAmount)
                          "
                          :displayBold="true"
                        />
                      </v-col>

                      <!--8:Modified/Created-->
                      <v-col cols="12" sm="12" md="5">
                        <span class="text-caption grey--text">
                          Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
	                        Modified: {{ $_formatTimestamp(item.updated_at) }}
                          </span>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-divider />
                    <v-divider />
                  </v-container>
                </v-card>
                <v-divider> </v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "educationStore/getEntryField",
  mutationType: "educationStore/updateEntryField"
});

export default {
  name: "EducationEdit",
  components: {
    TextDisplay
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      educationStore: state => state.educationStore,
      valueListsStore: state => state.valueListsStore,
      applicantsStore: state => state.applicantsStore
    }),
    ...mapFields(["show", "valid", "mode"]),
    ...mapMultiRowFields("educationStore", ["entry.items"])
  },
  data() {
    return {
      tab: 0
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("educationStore/resetEntry");
    },

    addItem() {
      const client = this.$store.getters["clientsStore/getClient"];
      const data = {
        fk_householdID: client.fk_householdID
      };
      this.$store.dispatch("educationStore/addItem", data);
    },

    totalIncome(aid, tuition) {
      return this.$_formatMoney(aid - tuition);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
